import { Chip, ChipProps, Collapse } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { UserDtoPermissionsEnum } from "../../../../api";

interface PermissionChipProps extends ChipProps {
  checkAccessRights: (pageName: UserDtoPermissionsEnum) => boolean;
  disabled?: boolean;
  label: UserDtoPermissionsEnum;
}

const PermissionChip = (props: PermissionChipProps): JSX.Element => {
  const { checkAccessRights, disabled, label, onClick } = props;

  return (
    <Chip
      disabled={disabled}
      onClick={onClick}
      id={label}
      icon={
        <Collapse
          orientation="horizontal"
          in={checkAccessRights(label)}
          timeout={100}
        >
          <CheckIcon />
        </Collapse>
      }
      label={label}
      variant={checkAccessRights(label) ? "checked" : "filled"}
      sx={{ m: 0.5, width: "fit-content" }}
    />
  );
};

export default PermissionChip;
