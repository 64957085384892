import { CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import DownloadIcon from "../../components/icons/DownloadIcon";
import ApiWrapper from "../../wrappers/ApiWrapper";
import { stringToYMD } from "../../service/CustomDateService";
import SnackbarContext from "../../contexts/SnackbarContext";
import IconButton from "../button/IconButton";

export type DocumentLoaderDocumentType =
  | "LS"
  | "SR"
  | "NB"
  | "GS"
  | "MB"
  | "TB";

type DocumentLoaderProps = {
  bg?: boolean;
  documentId?: string;
  documentDate?: string;
  customerNumber?: string;
  documentType?: DocumentLoaderDocumentType;
};

const DocumentLoader = (props: DocumentLoaderProps) => {
  const [loading, setLoading] = useState(false);
  const api = new ApiWrapper().documentsControllerApi();
  const { bg, documentId, documentDate, customerNumber, documentType } = props;
  const { handleError } = useContext(SnackbarContext);

  const isNotNull = (value?: string) => {
    return value && value !== "0";
  };

  const isDownloadable =
    isNotNull(customerNumber) && isNotNull(documentId) && documentDate;

  const executeDownload = (e: any) => {
    // stop click event from bubbling to parent
    e.stopPropagation();

    setLoading(true);
    api
      .getDocumentAsBase64(
        customerNumber || "",
        documentId || "",
        stringToYMD(documentDate || ""),
        documentType || "TB"
      )
      .then((data) => {
        const element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:application/pdf;base64," + encodeURIComponent(data.data)
        );
        element.setAttribute(
          "download",
          `apoportal-beleg-${customerNumber}-${documentId}.pdf`
        );
        element.click();
      })
      .catch((e) => {
        if (e?.response?.data?.message != undefined) {
          handleError(e.response.data.message);
        } else {
          handleError(
            "Der Belegdownload ist aktuell nicht möglich. Bitte kontaktieren Sie das Service Center."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isDownloadable && (
        <IconButton
          icon={
            loading ? (
              <CircularProgress
                color="secondary"
                style={{
                  textAlign: "center",
                  width: "2.2rem",
                  height: "2.2rem",
                }}
              />
            ) : (
              <DownloadIcon
                bg={bg}
                sx={{ width: "2.2rem", height: "2.6rem" }}
              />
            )
          }
          onClick={executeDownload}
        />
      )}
    </>
  );
};

export default DocumentLoader;
