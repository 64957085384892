import { Box, SxProps, Theme } from "@mui/material";
import Banner from "../../../strapi/models/Banner";
import Carousel from "../../components/carousel/Carousel";
import ImageWrapper from "../../wrappers/ImageWrapper";

interface BannerProps {
  baseUrl: string;
  banners: Array<Banner>;
  loading: boolean;
  sx?: SxProps<Theme>;
}

const BannerCarousel = (props: BannerProps) => {
  const { baseUrl, banners, sx, loading } = props;

  if (banners.length > 0 && window.innerHeight > 770 && !loading) {
    return (
      <Box sx={{ ...sx }}>
        <Carousel maxSteps={banners?.length || 1}>
          {banners?.map((banner, index) => (
            <a
              key={`banner-${index}`}
              style={{ cursor: "pointer " }}
              href={banner.attributes.url}
              target="blank"
            >
              <ImageWrapper
                alt={`banner ad linked to ${banner.attributes.url}`}
                src={`${baseUrl}/contentmanager${banner.attributes.bild.data.attributes.url}`}
                sx={{ borderRadius: "5px", width: "100%" }}
              />
            </a>
          ))}
        </Carousel>
      </Box>
    );
  }
  return <></>;
};

export default BannerCarousel;
