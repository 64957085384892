import { useContext, useState } from "react";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import TableContainer, {
  tablePageDefaultLength,
} from "../../../components/table/TableContainer";
import SnackbarContext from "../../../contexts/SnackbarContext";
import CustomerContext from "../../../contexts/CustomerContext";
import { CancelDto, OrderDto, OrderNotePositionDto } from "../../../../api";
import TableAccordionWrapper from "../../../components/table/TableAccordionWrapper";
import TableAccordionRow from "../../../components/table/TableAccordionRow";
import OrderPositionsTable from "../positions/OrderPositionsTable";
import { utcToGermanDateString } from "../../../service/CustomDateService";
import { TableFilterType } from "../../../components/table/tableFilter/models";

const DailyOrdersSearchContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrderDto[]>([]);
  const [shownOrders, setShownOrders] = useState<OrderDto[]>([]);
  const [selectedOrderPositions, setSelectedOrderPositions] = useState<
    string[]
  >([]);

  const { handleHttpError, handleCancelOrderPositions } =
    useContext(SnackbarContext);
  const { customerNumber } = useContext(CustomerContext);

  const api = new ApiWrapper().ordersControllerApi();

  interface OrderSearchFormikValues {
    pzn?: string;
  }

  const initialValues: OrderSearchFormikValues = {
    pzn: "",
  };

  const sortByPZN = (
    loadMore: boolean,
    searchParams?: OrderSearchFormikValues
  ) => {
    if (
      typeof searchParams !== "undefined" &&
      typeof searchParams.pzn !== "undefined" &&
      searchParams.pzn.length > 0
    ) {
      const filterPzn =
        (searchPzn: string) => (orderNotePosition: OrderNotePositionDto) =>
          orderNotePosition.pzn === searchPzn;
      const newShownOrders: OrderDto[] = orders
        .filter((order) =>
          order.orderNotePositions?.some(filterPzn(searchParams.pzn || ""))
        )
        .map((order) => ({
          ...order,
          orderNotePositions: order.orderNotePositions?.filter(
            filterPzn(searchParams.pzn || "")
          ),
        }));
      setShownOrders(newShownOrders);
    } else {
      setShownOrders(orders);
    }
  };

  const loadOrders = (
    // no load more, all orders are loaded on initialization
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _loadMore = false,
    searchParams?: OrderSearchFormikValues
  ): void => {
    if (customerNumber && !loading) {
      setLoading(true);

      api
        .getAllOrders(
          customerNumber,
          0,
          100,
          true,
          searchParams?.pzn || undefined
        )
        .then((res) => {
          setOrders(res.data);
          setShownOrders(res.data);
        })
        .catch((e) => handleHttpError(e))
        .finally(() => setLoading(false));
    }
  };

  const generateCancelDtoObjects = (): CancelDto[] => {
    const orderIds = selectedOrderPositions.map(
      (orderPosition) => orderPosition.split("-")[0]
    );

    const selectedOrdersData = orders.filter((order) =>
      orderIds.includes(order.orderNoteId)
    );

    const ordersToCancel: CancelDto[] = [];

    selectedOrdersData.forEach((orderData) => {
      orderData.orderNotePositions?.forEach((orderPosition) => {
        const cancelDtoObj = {
          orderId: orderPosition.positionId.split("-")[0],
          orderPosition: orderPosition.positionId,
          pzn: orderPosition.pzn,
        };

        if (selectedOrderPositions.includes(cancelDtoObj.orderPosition)) {
          ordersToCancel.push(cancelDtoObj);
        }
      });
    });

    return ordersToCancel;
  };

  const onCancelSuccess = () => {
    setSelectedOrderPositions([]);
    setLoading(false);
  };

  const cancelOrderPositions = (): void => {
    if (customerNumber && !loading) {
      setLoading(true);
      const orderCancelDtos = generateCancelDtoObjects();

      api
        .cancelOrderPositions(customerNumber, orderCancelDtos)
        .then((res) => {
          // 200 always returned, even for errors
          // if the data array is empty, there are no errors
          handleCancelOrderPositions(
            orderCancelDtos,
            res.data,
            onCancelSuccess
          );
        })
        .catch(handleHttpError)
        .finally(() => {
          loadOrders(false);
        });
    }
  };

  return (
    <TableContainer
      buttonAction={cancelOrderPositions}
      buttonText="Auswahl stornieren"
      liftStateToParent={setSelectedOrderPositions}
      loadCallback={loadOrders}
      loading={loading}
      tableFilterProps={{
        initialValues: initialValues,
        searchFields: [
          {
            type: TableFilterType.textfield,
            name: "pzn",
            label: "Suche nach PZN",
          },
        ],
        loadCallbackOverride: sortByPZN,
      }}
      Table={({
        handleSelectRow,
        isSelected,
        handleSelectAll,
        allSelected,
      }) => (
        <TableAccordionWrapper tableHeader="Bestellungen">
          {shownOrders.map((order, index) => {
            return (
              <TableAccordionRow
                transitionDelay={index % tablePageDefaultLength}
                key={`order-list-item-${index}`}
                header={`${utcToGermanDateString(order.orderDate)}: Auftrag ${
                  order.orderNoteId
                }, Auftragskennung: ${order.label}`}
              >
                <OrderPositionsTable
                  allSelected={allSelected}
                  isSelected={isSelected}
                  handleSelectRow={handleSelectRow}
                  handleSelectAll={handleSelectAll as (ids: string[]) => void}
                  positions={order.orderNotePositions}
                />
              </TableAccordionRow>
            );
          })}
        </TableAccordionWrapper>
      )}
      selectableRows
    />
  );
};

export default DailyOrdersSearchContainer;
