import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import EmployeeForm from "./EmployeeForm";
import { iconStyles } from "../employeesStyles";
import { UserDto } from "../../../../api";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { useContext, useState } from "react";
import CustomerContext from "../../../contexts/CustomerContext";
import SnackbarContext from "../../../contexts/SnackbarContext";

interface EmployeeAccordionProps {
  employeeData: UserDto;
  expanded: boolean;
  handleExpansion: () => void;
  loadUsers: () => Promise<void>;
  handleEmployeesError: () => void;
}

const EmployeeAccordion = (props: EmployeeAccordionProps) => {
  const {
    employeeData,
    handleExpansion,
    expanded,
    loadUsers,
    handleEmployeesError,
  } = props;
  let rowValue = `${employeeData.firstName} ${employeeData.lastName || ""}`;
  if (employeeData.title) {
    rowValue = `${employeeData.title} ${rowValue}`;
  }

  const [isDeleting, setIsDeleting] = useState(false);

  const { customerNumber } = useContext(CustomerContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const api = new ApiWrapper().userControllerApi();

  const deleteUser = () => {
    setIsDeleting(true);
    api
      .deleteUser(customerNumber, employeeData.userName || "")
      .then(() => {
        loadUsers()
          .then(() => setIsDeleting(false))
          .finally(() => {
            showSnackbar("success", "Benutzer erfolgreich gelöscht");
          });
      })
      .catch(() => {
        setIsDeleting(false);
        handleEmployeesError();
      });
  };

  return (
    <Accordion
      expanded={isDeleting ? false : expanded}
      onChange={handleExpansion}
    >
      <AccordionSummary>
        <Typography variant="body2" sx={{ py: 1.5, pl: 2.1 }}>
          {rowValue}
        </Typography>
        <Stack direction="row">
          <EditIcon
            fontSize="large"
            sx={{
              ...iconStyles,
              mr: 2,
            }}
          />
          {isDeleting ? (
            <CircularProgress color="secondary" size={18} />
          ) : (
            <DeleteForeverIcon
              onClick={deleteUser}
              fontSize="large"
              sx={iconStyles}
            />
          )}
        </Stack>
      </AccordionSummary>
      <EmployeeForm employeeData={employeeData} shown={expanded}/>
    </Accordion>
  );
};

export default EmployeeAccordion;
