import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DeliveryDto } from "../../../../api";
import DeliveryDetailTableFooter from "./DeliveryDetailTableFooter";
import DeliveryDetailTableRow from "./DeliveryDetailTableRow";

const DeliveryDetailTable = (props: { delivery?: DeliveryDto }) => {
  const { delivery } = props;
  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow
          sx={{
            "& th": {
              px: 1,
            },
          }}
        >
          <TableCell>
            <Typography variant="bodyBold">Menge</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="bodyBold">Artikelbezeichnung</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="bodyBold">PZN</Typography>
          </TableCell>
          <TableCell sx={{ width: "7.5%" }}>
            <Typography variant="bodyBold">Ek.Preis</Typography>
          </TableCell>
          <TableCell sx={{ width: " 10%" }}>
            <Typography variant="bodyBold">Zeilenwert</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="bodyBold">MwSt</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="bodyBold">Kz</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          "& > tr > td": {
            py: 1,
            px: 1,
          },
        }}
      >
        {delivery?.deliveryNotePositions?.map((row, index) => (
          <DeliveryDetailTableRow row={row} key={index} />
        ))}
      </TableBody>
      <DeliveryDetailTableFooter
        sumNetto={
          typeof delivery?.sumNetto !== "undefined" ? delivery?.sumNetto : 0
        }
      />
    </Table>
  );
};

export default DeliveryDetailTable;
