import DashboardCard from "./DashboardCard";
import NewsIcon from "../../../components/icons/NewsIcon";
import { CardProps, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Article from "../../../../strapi/models/Article";
import { useKeycloak } from "@react-keycloak/web";
import StrapiApi from "../../../../strapi/StrapiApi";
import SnackbarContext from "../../../contexts/SnackbarContext";
import CustomerContext from "../../../contexts/CustomerContext";
import CenteredTextMessage from "../../../components/other/CenteredTextMessage";

const NewsCard = (props: CardProps): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { customerNumber } = useContext(CustomerContext);
  const strapiApi = new StrapiApi(keycloak.token || "", customerNumber);
  const { handleError } = useContext(SnackbarContext);
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed && customerNumber) {
      setLoading(true);

      strapiApi
        .getLatesttArticle()
        .then((res) => {
          const articleArr = res.data.data;

          if (articleArr.length > 0) {
            setArticle(articleArr[0].attributes);
          } else {
            setArticle(null);
          }
        })
        .catch(() =>
          handleError(
            "Aktuell können keine Daten angezeigt werden. Bitte kontaktieren Sie das Service Center."
          )
        )
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  return (
    <DashboardCard
      loading={loading}
      buttonText="Mehr erfahren"
      header="Neuigkeiten"
      icon={<NewsIcon sx={{ width: "2.9rem", height: "2.9rem" }} />}
      image={
        article?.heroBild?.data?.attributes?.url
          ? `${strapiApi.axiosRequestConfig.baseURL}/contentmanager${article?.heroBild?.data?.attributes?.url}`
          : ""
      }
      to="news"
      {...props}
    >
      {article ? (
        <>
          <Typography mt={3} variant="h2">
            {article?.titel}
          </Typography>
          <Typography
            mt={1}
            variant="h4"
            sx={{ pr: 3, mt: 1, overflow: "hidden", maxHeight: "5.2rem" }}
          >
            {article?.teaserText}
          </Typography>
        </>
      ) : (
        <CenteredTextMessage message="Für die Vorschau sind keine Daten vorhanden" />
      )}
    </DashboardCard>
  );
};

export default NewsCard;
