import { useEffect, useState } from "react";
import { Box, Tabs, Tab, useTheme } from "@mui/material";
import Card, { CardProps } from "./Card";

interface CardTabProps extends CardProps {
  name: string;
  state: number;
}

interface TabableCardProps extends CardProps {
  children: React.ReactElement[];
}

type CardTab = {
  id: string;
  name: string;
  element: React.ReactElement;
};

export const TabableCard = ({
  sx = [],
  children,
}: TabableCardProps): JSX.Element => {
  const [tabs, setTabs] = useState<CardTab[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const theme = useTheme();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  const parseTabsFromChildren = (): CardTab[] => {
    const tabsFromChildren: CardTab[] = [];

    if (typeof children !== "undefined") {
      children.forEach((element) => {
        if (element.props.id && element.props.name) {
          tabsFromChildren.push({
            id: element.props.id,
            name: element.props.name,
            element,
          });
        }
      });
    }

    return tabsFromChildren;
  };

  useEffect(() => {
    const tabsFromChildren = parseTabsFromChildren();
    setTabs(tabsFromChildren);
  }, [children]);

  return (
    <Card
      innerBoxStyles={{ height: "100%", width: "100%" }}
      sx={{
        ...sx,
        pl: 3,
        pr: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Tabs
        sx={{
          textAlign: "center",
          minHeight: "0px",
          marginTop: "15px",
          marginBottom: "30px",
          width: "100%",
          position: "relative",
        }}
        textColor="secondary"
        indicatorColor="secondary"
        value={currentTabIndex}
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.name}
            sx={{
              width: `${100 / tabs.length}%`,
              fontSize: "1.1rem",
              minHeight: "0px",
              color: theme.palette.base?.main,
              borderBottom: `2px solid ${theme.palette.base?.light}`,
            }}
            label={tab.name}
          />
        ))}
      </Tabs>
      {tabs[currentTabIndex]?.element}
    </Card>
  );
};

export const CardTabContent = ({ children }: CardTabProps): JSX.Element => {
  return <Box sx={{ height: "calc(100% - 125px)" }}>{children}</Box>;
};
