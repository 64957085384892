import { Box, Typography } from "@mui/material";
import { aepPalette } from "../../../../styles/aepPalette";

const ErrorMessageOverlay = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: -50,
        backgroundColor: aepPalette.error.main,
        p: 1,
        zIndex: 5,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -5,
          left: "25%",
          width: 0,
          height: 0,
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderBottom: `5px solid ${aepPalette.error.main}`,
          zIndex: 5,
        }}
      />
      <Typography color="common.white">
        Eine Menge muss zwischen 0 und 9999 liegen
      </Typography>
    </Box>
  );
};

export default ErrorMessageOverlay;
