import { CardProps } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useContext, useEffect, useState } from "react";
import Offer from "../../../../strapi/models/Offer";
import StrapiData from "../../../../strapi/models/StrapiData";
import StrapiApi from "../../../../strapi/StrapiApi";
import DiscoverIcon from "../../../components/icons/DiscoverIcon";
import SnackbarContext from "../../../contexts/SnackbarContext";
import DashboardCard from "./DashboardCard";
import { shuffleArr } from "../../../service/CustomArrayService";
import OfferDashboardCard from "./OfferDashboardCard";
import CustomerContext from "../../../contexts/CustomerContext";
import CenteredTextMessage from "../../../components/other/CenteredTextMessage";

type StrapiOfferData = StrapiData<Offer>;

const OffersCard = (props: CardProps): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { customerNumber } = useContext(CustomerContext);
  const strapiApi = new StrapiApi(keycloak.token || "", customerNumber);
  const { handleError } = useContext(SnackbarContext);
  const [offers, setOffers] = useState<Array<StrapiOfferData>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed && customerNumber) {
      setLoading(true);
      strapiApi
        .getDashboardOffers()
        .then((res) => {
          const shuffledOffers = shuffleArr<StrapiOfferData>(res.data.data);

          if (shuffledOffers.length > 0) {
            setOffers(shuffledOffers);
          } else {
            strapiApi.getTopOffer().then((res) => {
              const topOffers = res.data.data;
              if (topOffers.length > 0) {
                setOffers(topOffers);
              } else {
                setOffers([]);
              }
            });
          }
        })
        .catch(() =>
          handleError(
            "Aktuell können keine Daten angezeigt werden. Bitte kontaktieren Sie das Service Center."
          )
        )
        .finally(() => setLoading(false));
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  return (
    <DashboardCard
      loading={loading}
      to="angebote"
      buttonText="Mehr entdecken"
      header="AEP Entdecken"
      icon={<DiscoverIcon sx={{ width: "37px", height: "21px" }} />}
      {...props}
    >
      {!loading && offers.length > 0 ? (
        <OfferDashboardCard
          dashboard
          offerAttributes={offers[0].attributes}
          key={offers[0].id}
          baseUrl={strapiApi.axiosRequestConfig.baseURL || ""}
        />
      ) : (
        <CenteredTextMessage message="Für die Vorschau sind keine Daten vorhanden" />
      )}
    </DashboardCard>
  );
};

export default OffersCard;
