import { Box, TextField as MuiTextField, TextFieldProps } from "@mui/material";

const Textfield = (props: TextFieldProps): JSX.Element => {
  const { children } = props;
  const element = <MuiTextField {...props} />;

  if (children) {
    return (
      <Box sx={{ position: "relative" }}>
        {element}
        {children}
      </Box>
    );
  }

  return element;
};

export default Textfield;
