import { Box } from "@mui/material";
import { aepPalette } from "../../../../styles/aepPalette";

interface FilterTabProps {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  value: string;
  selected?: boolean;
}

const FilterTab = (props: FilterTabProps) => {
  const { label, setValue, selected, value } = props;

  return (
    <Box
      onClick={(e) => {
        setValue(value);
      }}
      sx={{
        px: 1.5,
        py: 1,
        mx: 1,
        border: `1px solid ${aepPalette.secondary.main}`,
        borderRadius: "8px",
        color: selected ? aepPalette.common.white : aepPalette.secondary.main,
        backgroundColor: selected ? aepPalette.secondary.main : "",
        cursor: "pointer",
      }}
    >
      {label}
    </Box>
  );
};

export default FilterTab;
