import { useContext, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Grid } from "@mui/material";
import ContactMessageCard from "./cards/ContactMessageCard";
import ContactCard from "../../components/card/ContactCard";
import StrapiApi from "../../../strapi/StrapiApi";
import StrapiData from "../../../strapi/models/StrapiData";
import ContactType from "../../../strapi/models/Contact";
import SalesContactType from "../../../strapi/models/SalesContact";
import CustomerContext from "../../contexts/CustomerContext";
import SalesContactCard from "../../components/card/SalesContactCard";
import { gridContainerStyles } from "./contactStyles";

const Contact = () => {
  const { keycloak } = useKeycloak();
  const { customerNumber } = useContext(CustomerContext);
  const strapiApi = new StrapiApi(keycloak.token || "", customerNumber);
  const [contacts, setContacts] = useState<StrapiData<ContactType>[]>();
  const [salesContacts, setSalesContacts] =
    useState<StrapiData<SalesContactType>[]>();

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed && customerNumber) {
      strapiApi.getContacts().then((res) => {
        setContacts(res.data.data);
      });
      strapiApi.getSalesContacts().then((res) => {
        setSalesContacts(res.data.data);
      });
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  return (
    <Grid container spacing={3} sx={gridContainerStyles}>
      <Grid item xs={12} md={6} lg={4}>
        <ContactMessageCard sx={{ minHeight: "487px" }} />
      </Grid>
      {salesContacts && salesContacts[0] && (
        <Grid item xs={12} md={6} lg={3}>
          <SalesContactCard
            contactInfo={salesContacts[0].attributes}
            baseUrl={strapiApi.axiosRequestConfig.baseURL || ""}
            sx={{
              minHeight: {
                xs: "0px",
                lg: "487px",
              },
            }}
          />
        </Grid>
      )}
      {contacts && contacts[0] && (
        <Grid item xs={12} md={6} lg={4}>
          <ContactCard contactInfo={contacts[0].attributes} />
        </Grid>
      )}
    </Grid>
  );
};

export default Contact;
