import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const HomeIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 28 28" {...props}>
    <path d="M1.53738 11.5244L1.53714 11.5245C1.42898 11.6102 1.27901 11.6482 1.12926 11.6324C0.979611 11.6165 0.845073 11.5486 0.762471 11.4419C0.584744 11.2121 0.618698 10.8561 0.856624 10.6645C0.856771 10.6644 0.856918 10.6643 0.857066 10.6641L14.0332 0.261942C14.1419 0.188245 14.2485 0.152523 14.3594 0.150128C14.4709 0.14772 14.5948 0.179226 14.7239 0.262876L27.8998 10.665L27.8998 10.665L27.9039 10.668C28.1343 10.8376 28.1633 11.212 27.9833 11.441L28.1012 11.5337L27.9833 11.441C27.8102 11.6612 27.4423 11.7017 27.2188 11.5245L27.2185 11.5244L14.4709 1.46499L14.3779 1.39166L14.285 1.46499L1.53738 11.5244ZM23.0495 20.9624H23.1995V20.8124V9.64279L24.2868 10.5009V21.5065C24.2868 21.8067 24.0433 22.0502 23.7432 22.0502H17.1525C16.8524 22.0502 16.6091 21.8068 16.6091 21.5065V17.3441C16.6091 16.0962 15.6256 15.1127 14.3777 15.1127C13.1298 15.1127 12.1463 16.0962 12.1463 17.3441V21.5065C12.1463 21.8067 11.9028 22.0502 11.6027 22.0502H5.01204C4.71186 22.0502 4.46859 21.8069 4.46859 21.5068V10.5012L5.55596 9.64303L5.55573 20.8124L5.55573 20.9624H5.70573H10.9088H11.0589V20.8124V17.3437C11.0589 15.5167 12.5506 14.0249 14.3776 14.0249C16.2045 14.0249 17.6963 15.5167 17.6963 17.3437V20.8124V20.9624H17.8463H23.0495Z" />
  </IconWrapper>
);

export default HomeIcon;
