import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const SearchIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 22 22" {...props}>
    <path d="M21.821 20.9565C22.0597 21.1952 22.0597 21.5822 21.821 21.821C21.5823 22.0597 21.1952 22.0597 20.9565 21.821L14.3761 15.2405C14.2554 15.1199 14.0504 15.1105 13.9221 15.2158C13.9221 15.2158 13.8219 15.3023 13.6459 15.4328C12.2234 16.4874 10.4625 17.111 8.55569 17.111C3.83043 17.111 0 13.2805 0 8.55574C0 3.83045 3.83054 0 8.55569 0C13.2805 0 17.1109 3.83056 17.1109 8.55574C17.1109 10.419 16.5154 12.1433 15.5038 13.5483C15.3525 13.7589 15.2157 13.92 15.2157 13.92C15.1087 14.0513 15.1181 14.2538 15.2404 14.3761L21.821 20.9565ZM8.55562 15.8889C12.6056 15.8889 15.8888 12.6057 15.8888 8.55567C15.8888 4.50565 12.6056 1.22204 8.55562 1.22204C4.50563 1.22204 1.22203 4.50565 1.22203 8.55567C1.22203 12.6057 4.50563 15.8889 8.55562 15.8889Z" />
  </IconWrapper>
);

export default SearchIcon;
