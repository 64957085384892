import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

interface CollapseIconProps extends SvgIconProps {
  flip?: string;
}

const CollapseIcon = (props: CollapseIconProps) => (
  <IconWrapper sx={{ cursor: "pointer" }} {...props}>
    <path
      style={{ transform: props.flip ? "" : "scaleX(-1) translateX(-100%)" }}
      d="M9.65837 6.175L12.6417 9.16667H0.833374V10.8333H12.6417L9.65004 13.825L10.8334 15L15.8334 10L10.8334 5L9.65837 6.175ZM16.6667 5V15H18.3334V5H16.6667Z"
    />
  </IconWrapper>
);

export default CollapseIcon;
