import { Box, BoxTypeMap, Grow, SxProps, Theme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { growTimeout } from "../App";

// Image wrapper to allow theme aware passing of props directly without wrapper box in every component
interface ImageWrapperProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sx?: any;
  containerSx?: SxProps<Theme>;
  src?: string;
  alt: string;
  boxProps?: OverridableComponent<BoxTypeMap<"div">>;
  objectTagType?: string;
}

const ImageWrapper = (props: ImageWrapperProps): JSX.Element => {
  const { src, alt, sx, containerSx, boxProps, objectTagType } = props;

  if (src) {
    return (
      <Box
        sx={{
          width: "inherit",
          ...containerSx,
          "& > object": sx,
          "& > img": sx,
        }}
        {...boxProps}
      >
        <Grow in={!!src} timeout={growTimeout}>
          {objectTagType ? (
            <object aria-label={alt} data={src} type={objectTagType} />
          ) : (
            <img src={src} alt="." />
          )}
        </Grow>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default ImageWrapper;
