import { SxProps, Theme } from "@mui/material";

export const gridBoxStyles: SxProps<Theme> = {
  display: "grid",
  flexGrow: 1,
  gap: 3,
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "repeat(12, 1fr)",
  minHeight: "620px",
  overflow: "auto",
  position: "relative",
  width: "100%",
};
