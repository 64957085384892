import { useContext } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { UserProfileDto } from "../../../api";
import { Formik, FormikErrors } from "formik";
import ApiWrapper from "../../wrappers/ApiWrapper";
import CustomerContext from "../../contexts/CustomerContext";
import SnackbarContext from "../../contexts/SnackbarContext";
import {
  formTextFieldStyles,
  inputColumnStackStyles,
  inputColumnStyles,
} from "./userProfileStyles";
import UserContext from "../../contexts/UserContext";

export interface LocalFormikValues {
  title: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  passwordRepeat: string;
}

interface UserProfileFormProps {
  userData: UserProfileDto;
}

const UserProfileForm = (props: UserProfileFormProps) => {
  const api = new ApiWrapper().userControllerApi();
  const { userData } = props;
  const { customerNumber } = useContext(CustomerContext);
  const { userName } = useContext(UserContext);
  const { handleHttpError, showSnackbar } = useContext(SnackbarContext);

  const passwordInputProps = { type: "password", autoComplete: "new-password" };

  const initialValues: LocalFormikValues = {
    title: userData?.title || "",
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    userName: userData?.userName || "",
    email: userData?.email || "",
    password: "",
    passwordRepeat: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: FormikErrors<LocalFormikValues> = {};
        const hasValidEmail = values.email
          ? /\S+@\S+\.\S+/.test(values.email)
          : true;

        if (!values.firstName) {
          errors.firstName = "Erforderlich";
        }
        if (!values.lastName) {
          errors.lastName = "Erforderlich";
        }
        if (!hasValidEmail) {
          errors.email = "Ungültige E-Mail-Adresse";
        }
        if (values.password !== values.passwordRepeat) {
          errors.passwordRepeat = "Passwort stimmt nicht überein";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const apiValues: UserProfileDto = {
          title: values.title,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
        };
        if (values.password === "") {
          delete apiValues.password;
        }
        api
          .updateUserProfile(customerNumber, userName, apiValues)
          .then(() => {
            showSnackbar("success", "Benutzerprofil erfolgreich aktualisiert");
          })
          .catch(handleHttpError)
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{ position: "relative", width: "100%" }}
        >
          <Typography variant="h2" color="font.main" sx={{ pb: 2 }}>
            Mein Profil
          </Typography>
          <Stack sx={inputColumnStackStyles}>
            <Stack sx={inputColumnStyles}>
              <TextField
                fullWidth
                sx={formTextFieldStyles}
                error={!!(errors.title && touched.title)}
                label="Titel"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
              />
            </Stack>
            <Stack sx={inputColumnStyles}>
              <TextField
                fullWidth
                sx={formTextFieldStyles}
                error={!!(errors.firstName && touched.firstName)}
                helperText={errors.firstName}
                label="Vorname*"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
              />
              <TextField
                fullWidth
                sx={formTextFieldStyles}
                error={!!(errors.lastName && touched.lastName)}
                helperText={errors.lastName}
                label="Nachname*"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
              />
            </Stack>
            <Stack sx={inputColumnStyles}>
              <TextField
                fullWidth
                disabled
                sx={formTextFieldStyles}
                label="Benutzername"
                name="userName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userName}
              />
              <TextField
                fullWidth
                sx={formTextFieldStyles}
                error={!!(errors.email && touched.email)}
                helperText={touched.email && errors.email}
                label="E-Mail-Adresse"
                name="email"
                inputProps={{ autoComplete: "off" }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
            </Stack>
            <Stack sx={inputColumnStyles}>
              <TextField
                fullWidth
                sx={formTextFieldStyles}
                helperText={touched.password && errors.password}
                inputProps={passwordInputProps}
                error={!!(errors.password && touched.password)}
                label="Neues Passwort"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
              <TextField
                fullWidth
                sx={formTextFieldStyles}
                helperText={touched.passwordRepeat && errors.passwordRepeat}
                inputProps={passwordInputProps}
                error={!!(errors.passwordRepeat && touched.passwordRepeat)}
                label={`Passwort wiederholen${values.password && "*"}`}
                name="passwordRepeat"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passwordRepeat}
              />
            </Stack>
          </Stack>
          <Button
            type="submit"
            disabled={isSubmitting}
            sx={{ mt: 6, mb: 4, float: "right" }}
          >
            {isSubmitting ? "In Arbeit..." : "Speichern"}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default UserProfileForm;
