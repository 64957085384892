import { Table, TableHead, TableBody, TableRow } from "@mui/material";
import { CancellationPositionDto } from "../../../../api";
import { CustomTableProps } from "../../../components/table/TableContainer";
import CancellationPositionsTableRow from "./CancellationPositionsTableRow";
import PositionsRowCommonHeader from "./PositionsRowCommonHeader";

interface CancellationPositionsTableProps extends CustomTableProps {
  positions?: CancellationPositionDto[];
}

export enum CancellationPositionStatus {
  OPEN = "Auftrag angenommen",
  IN_PRODUCTION = "In produktion",
}

const CancellationPositionsTable = (props: CancellationPositionsTableProps) => {
  const { positions } = props;

  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow
          sx={{
            backgroundColor: "lime",
            "& > th": {
              pl: 4,
            },
          }}
        >
          <PositionsRowCommonHeader />
        </TableRow>
      </TableHead>
      <TableBody>
        {positions?.map((positionData, index) => {
          return (
            <CancellationPositionsTableRow
              key={`position-${index}`}
              positionData={positionData}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CancellationPositionsTable;
