import { useContext, useState } from "react";
import { Formik, FormikErrors } from "formik";
import {
  Typography,
  TextField,
  Button,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import Card from "../../components/card/Card";
import ApiWrapper from "../../wrappers/ApiWrapper";
import CustomerContext from "../../contexts/CustomerContext";
import SnackbarContext from "../../contexts/SnackbarContext";
import {
  ArticleAdmissionRequest,
  ArticleAdmissionRequestFrequencyEnum,
} from "../../../api";
import {
  buttonStyles,
  formTextFieldStyles,
  inputLabelStyles,
  rootCardStyles,
} from "./articleAdmissionsStyles";

interface LocalFormikValues {
  pzn: string;
  frequency: string;
  amount: string;
}

const initialValues: LocalFormikValues = {
  pzn: "",
  frequency: "",
  amount: "",
};

const ArticleAdmissionsCard = (): JSX.Element => {
  const api = new ApiWrapper().articleAdmissionControllerApi();
  const { customerNumber } = useContext(CustomerContext);
  const { showSnackbar, handleHttpError } = useContext(SnackbarContext);
  const [frequency, setFrequency] = useState("");

  return (
    <Card sx={rootCardStyles}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<LocalFormikValues> = {};
          if (!values.pzn) {
            errors.pzn = "Erforderlich";
          }
          if (values.pzn.match(/\D/g)) {
            errors.pzn = "Nur Zahlen erlaubt";
          }
          if (!(frequency in ArticleAdmissionRequestFrequencyEnum)) {
            errors.frequency = "Erforderlich";
          }
          if (!values.amount) {
            errors.amount = "Erforderlich";
          }
          if (values.amount.match(/\D/g)) {
            errors.amount = "Nur Zahlen sind erlaubt";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const apiValues: ArticleAdmissionRequest = {
            pzn: values.pzn,
            frequency: frequency as ArticleAdmissionRequestFrequencyEnum,
            amount: parseInt(values.amount, 10),
          };

          api
            .postArticleAdmission(customerNumber, apiValues)
            .then(() => {
              showSnackbar("success", "Artikel erfolgreich erstellt");
              resetForm();
              setFrequency("");
            })
            .catch(handleHttpError)
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
            }}
          >
            <Typography variant="h2" color="font.main" sx={{ pb: 4 }}>
              Formular für Artikelneuaufnahme
            </Typography>
            <Stack
              direction="column"
              height="60%"
              justifyContent="space-between"
            >
              <TextField
                fullWidth
                error={!!(errors.pzn && touched.pzn)}
                helperText={errors.pzn}
                label="PZN *"
                name="pzn"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.pzn}
                inputProps={{ maxLength: 8 }}
                sx={formTextFieldStyles}
              />
              <FormControl
                sx={{ ...formTextFieldStyles, transform: "translateY(-20%)" }}
              >
                <InputLabel
                  id="order-frequency-select-label"
                  sx={inputLabelStyles}
                >
                  Bestellfrequenz *
                </InputLabel>
                <Select
                  id="order-frequency-select"
                  label="Bestellfrequenz"
                  onChange={(e) => {
                    setFrequency(e.target.value);
                  }}
                  value={frequency}
                  variant="standard"
                >
                  <MenuItem value={ArticleAdmissionRequestFrequencyEnum.Daily}>
                    <Typography>Täglich</Typography>
                  </MenuItem>
                  <MenuItem value={ArticleAdmissionRequestFrequencyEnum.Weekly}>
                    <Typography>Wöchentlich</Typography>
                  </MenuItem>
                  <MenuItem
                    value={ArticleAdmissionRequestFrequencyEnum.Monthly}
                  >
                    <Typography>Monatlich</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                error={!!(errors.amount && touched.amount)}
                label="Menge *"
                helperText={touched.amount && errors.amount}
                name="amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                inputProps={{ maxLength: 100 }}
                sx={formTextFieldStyles}
              />
            </Stack>
            <Button type="submit" disabled={isSubmitting} sx={buttonStyles}>
              {isSubmitting ? "Senden..." : "Anfrage absenden"}
            </Button>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default ArticleAdmissionsCard;
