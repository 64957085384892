import { useEffect, useState, useContext } from "react";
import { selectStyles } from "../appBarStyles";
import {
  MenuItem,
  SelectChangeEvent,
  Select,
  Typography,
  Box,
} from "@mui/material";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { getPharmacies, getMainPharmacyId } from "../../../service/JwtService";
import CustomerContext from "../../../contexts/CustomerContext";

const PharmacyPicker = () => {
  const accessToken = new ApiWrapper().accessToken();
  const mainPharmacy = getMainPharmacyId(accessToken);

  const [pharmacy, setPharmacy] = useState("");
  const { updateCustomerNumber } = useContext(CustomerContext);

  const handleApothekeSelect = (event: SelectChangeEvent<string>) => {
    updateSelection(event.target.value);
  };

  const updateSelection = (selectedId: string): void => {
    updateCustomerNumber(selectedId);
    setPharmacy(selectedId);
  };

  useEffect(() => {
    updateSelection(mainPharmacy);
  }, []);

  const getAllPharmacies = () => {
    return getPharmacies(accessToken)
      .map((group) => {
        const id = group.split("_")[1];
        const nameParts = group.split("_")[0].split("/");
        const name = `${nameParts[nameParts.length - 1]} (${id})`;
        return { id, name };
      })
      .sort((a, b) => {
        if (a.id === mainPharmacy) return -1;
        if (b.id === mainPharmacy) return 1;
        return a.name.localeCompare(b.name);
      });
  };

  const renderOptions = () => {
    return getAllPharmacies().map((entry) => {
      return (
        <MenuItem key={entry.id} value={entry.id}>
          {entry.name}
        </MenuItem>
      );
    });
  };

  const pharmacies = getAllPharmacies();
  if (pharmacies.length === 1) {
    return (
      <>
        <Box sx={selectStyles}>
          <Typography
            variant="subline"
            sx={{
              fontSize: "1.4rem",
              borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
              fontWeight: 500,
              padding: "0px 0 5px 0",
              boxSizing: "border-box",
              height: "inherit",
            }}
          >
            {pharmacies[0].name}
          </Typography>
        </Box>
      </>
    );
  } else {
    return (
      <Select
        onChange={handleApothekeSelect}
        sx={selectStyles}
        value={pharmacy}
        variant="standard"
      >
        {renderOptions()}
      </Select>
    );
  }
};

export default PharmacyPicker;
