import React from "react";
import {
  Alert,
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
  AlertProps,
  Stack,
  Typography,
} from "@mui/material";
import { multilineSeparator } from "../../contexts/SnackbarContext";

interface SnackbarProps extends MuiSnackbarProps {
  alertprops?: AlertProps;
}

const Snackbar = (props: SnackbarProps) => {
  const { alertprops, message, ...snackbarProps } = props;

  const formatText = () => {
    return (message as string)
      .split(multilineSeparator)
      .map((messageFragment, i) => (
        <Typography sx={{ mt: i > 0 ? 1 : 0 }} key={`error-message-${i}`}>
          {messageFragment}
        </Typography>
      ));
  };

  return (
    <MuiSnackbar {...snackbarProps}>
      <Alert {...alertprops}>
        <Stack alignItems="flex-start" direction="column">
          {formatText()}
        </Stack>
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
