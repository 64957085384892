import { TableRow, TableCell, Typography } from "@mui/material";
import IconButton from "../../components/button/IconButton";
import DownloadIcon from "../../components/icons/DownloadIcon";
import { TextDivider } from "../../components/other/TextDivider";
import { ddmmyyyyFormat } from "../../service/CustomDateService";
import { StrapiDownloadData } from "./DownloadSearchContainer";

interface DownloadsTableRowProps {
  downloadData: StrapiDownloadData;
  loading?: boolean;
}

const DownloadsTableRow = (props: DownloadsTableRowProps) => {
  const { downloadData } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const baseUrl = window._env_.REACT_APP_API_URL.replace("/api", "/cms");
  const downloadUrl =
    downloadData?.attributes?.datei?.data[0]?.attributes?.url || "";

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">
          {downloadData?.attributes?.kategorie.data[0]?.attributes?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {downloadData?.attributes?.datei.data[0]?.attributes?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {downloadData?.attributes?.infotext}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {ddmmyyyyFormat(new Date(downloadData?.attributes?.createdAt))}
        </Typography>
      </TableCell>
      <TableCell>
        <TextDivider />
      </TableCell>
      <TableCell>
        <a target="blank" href={`${baseUrl}/${downloadUrl}`}>
          <IconButton
            icon={<DownloadIcon sx={{ width: "2.2rem", height: "2.6rem" }} />}
          />
        </a>
      </TableCell>
    </TableRow>
  );
};

export default DownloadsTableRow;
