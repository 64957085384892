import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const GraphsIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 20 22" {...props}>
    <path d="M0 21.6562C0 21.8624 0.124905 22 0.312588 22L19.6874 21.9998C19.8749 21.9998 20 21.8624 20 21.6559V0.343841C20 0.137632 19.8751 0 19.6874 0C19.4999 0 19.3748 0.137393 19.3748 0.343841V21.3122H0.3125C0.125252 21.3122 0.000347137 21.4496 0.000347137 21.656L0 21.6562ZM18.1248 19.5936V5.15636C18.1248 4.95015 17.9999 4.81252 17.8123 4.81252H14.6873C14.4998 4.81252 14.3747 4.94991 14.3747 5.15636V19.5936C14.3747 19.7998 14.4996 19.9374 14.6873 19.9374H17.8125C17.9999 19.9374 18.1248 19.7998 18.1248 19.5936H18.1248ZM17.4999 19.2497H14.9999V5.50001H17.4999V19.2497ZM5.00001 19.5936V1.71893C5.00001 1.51272 4.87511 1.37509 4.68743 1.37509H1.56246C1.37499 1.37509 1.24987 1.51248 1.24987 1.71893V19.5936C1.24987 19.7998 1.37477 19.9374 1.56246 19.9374H4.68765C4.87511 19.9374 5.00002 19.7998 5.00002 19.5936H5.00001ZM4.37507 19.2497H1.87504V2.06258H4.37507V19.2497ZM13.75 19.5936V8.59379C13.75 8.38759 13.6251 8.24995 13.4374 8.24995H10.3124C10.125 8.24995 9.99984 8.38735 9.99984 8.59379V19.5936C9.99984 19.7998 10.1247 19.9374 10.3124 19.9374H13.4374C13.6251 19.9374 13.75 19.7998 13.75 19.5936ZM13.1251 19.2497H10.625V8.93744H13.1251V19.2497ZM9.37514 19.5936V7.21882C9.37514 7.01261 9.25024 6.87498 9.06255 6.87498H5.93758C5.75012 6.87498 5.625 7.01237 5.625 7.21882V19.5936C5.625 19.7998 5.7499 19.9374 5.93758 19.9374H9.06255C9.25002 19.9374 9.37514 19.7998 9.37514 19.5936ZM8.74998 19.2497H6.24995L6.25016 7.56247H8.7502L8.74998 19.2497Z" />
  </IconWrapper>
);

export default GraphsIcon;
