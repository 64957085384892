import { Box, Stack, Typography } from "@mui/material";
import sanitizeHtml from "sanitize-html";
import { useEffect, useRef } from "react";
import { MessageResponse } from "../../../../api";
import Card, { CardProps } from "../../../components/card/Card";
import { ddmmyyyyFormat } from "../../../service/CustomDateService";
import {
  detailCardStyles,
  detailStackStyles,
  inlineText,
  messageBodyStyles,
  messageBodyTextStyles,
} from "../postBoxStyles";

interface MessageDetailCardProps extends CardProps {
  loading: boolean;
  selectedMessage?: MessageResponse;
}

const MessageDetailCard = (props: MessageDetailCardProps) => {
  const { loading, selectedMessage } = props;

  const messageEl = useRef<HTMLDivElement>(null);
  const sanitizedHTML = sanitizeHtml(selectedMessage?.message || "", {
    allowedTags: ["b", "br"],
  });

  useEffect(() => {
    if (messageEl.current !== null) messageEl.current.innerHTML = sanitizedHTML;
  }, [selectedMessage]);

  return (
    <Card sx={detailCardStyles} loading={loading}>
      <Stack direction="column" sx={detailStackStyles}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", width: "100%" }}
        >
          <Typography variant="h4">{selectedMessage?.userid}</Typography>
          <Typography variant="body2">
            {ddmmyyyyFormat(new Date(selectedMessage?.creationDate || ""))}
          </Typography>
        </Stack>
        <Box>
          <Typography variant="bodyBold" sx={inlineText}>
            Betreff:{" "}
          </Typography>
          <Typography variant="body2" sx={inlineText}>
            {selectedMessage?.subject}
          </Typography>
        </Box>
      </Stack>
      <Box sx={messageBodyStyles}>
        <Typography
          variant="body2"
          height="100%"
          width="100%"
          sx={messageBodyTextStyles}
        >
          <div ref={messageEl} />
        </Typography>
      </Box>
    </Card>
  );
};

export default MessageDetailCard;
