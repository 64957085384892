import { SxProps, Theme } from "@mui/material";
import Card from "../../components/card/Card";

interface OfferCardProps {
  children: JSX.Element | JSX.Element[];
  highlight?: boolean;
  loading: boolean;
  sx?: SxProps<Theme>;
}

const OfferCard = (props: OfferCardProps) => {
  const { children, highlight, sx, loading } = props;

  return (
    <Card
      loading={loading}
      shrink
      sx={{
        position: "relative",
        pb: highlight ? 11 : 3,
        minWidth: highlight ? "540px" : "300px",
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};

export default OfferCard;
