import { SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const itemRootStackStyles: SxProps<Theme> = {
  maxWidth: {
    xs: "237px",
    md: "800px",
  },
  mb: 10,
  flexDirection: {
    xs: "column",
    md: "row",
  },
};

export const itemImageWrapperStyles: SxProps<Theme> = {
  objectFit: "cover",
  mb: {
    xs: 4,
    md: 0,
  },
  mr: {
    xs: 0,
    md: 3,
  },
  height: {
    xs: "158px",
    md: "200px",
  },
  width: {
    xs: "237px",
    md: "300px",
  },
};

export const itemDisplayDateStyles: SxProps<Theme> = {
  color: aepPalette.primary.main,
  fontSize: "1.1rem",
};

export const itemHeaderStyles: SxProps<Theme> = { mb: 1, whiteSpace: "nowrap" };

export const itemTeaserTextStyles: SxProps<Theme> = {
  overflow: "hidden",
  fontSize: {
    xs: "1.3rem",
    md: "1.4rem",
  },
  maxHeight: {
    xs: "",
    md: "90px",
  },
};

export const itemNavButtonStyles: SxProps<Theme> = {
  bottom: {
    xs: -40,
    md: 0,
  },
  left: {
    xs: -16,
    md: 0,
  },
  color: "secondary.dark",
  position: "absolute",
  textDecoration: "none",
};

export const itemDetailsRootStackStyles: SxProps<Theme> = {
  maxWidth: "650px",
  width: "90%",
};

export const arrowUpwardsStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.base.light,
  borderRadius: "50%",
  bottom: "100px",
  cursor: "pointer",
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  px: 1,
  py: 1,
  right: "7%",
  "&:hover": {
    backgroundColor: aepPalette.base.main,
  },
};
