import { useState, useEffect, useContext, RefObject } from "react";
import Chart from "../Chart";
import CustomerContext from "../../../contexts/CustomerContext";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import SnackbarContext from "../../../contexts/SnackbarContext";
import { SwarmPlotData } from "../elements/SwarmPlot";

interface swarmPlotDataSwarmPlotProps {
  containerRef?: RefObject<HTMLElement>;
  dataSet: "BESTZEIT";
  inCarousel?: boolean;
  liftLoadingState?: React.Dispatch<React.SetStateAction<boolean>>;
}

const swarmPlotDataSwarmPlot = (props: swarmPlotDataSwarmPlotProps) => {
  const { containerRef, dataSet, inCarousel, liftLoadingState } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [swarmPlotData, setSwarmPlotData] = useState<SwarmPlotData>();
  const [filteredSwarmPlotData, setFilteredSwarmPlotData] =
    useState<SwarmPlotData>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mini, setMini] = useState<boolean>(false);

  const { customerNumber } = useContext(CustomerContext);
  const { handleHttpError } = useContext(SnackbarContext);

  const api = new ApiWrapper().analysisControllerApi();

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed && customerNumber) {
      setLoading(true);

      api
        .getData(customerNumber, dataSet, 1)
        .then((res) => {
          const response = res.data as unknown;
          setSwarmPlotData(response as SwarmPlotData);
          const filteredResponse = {} as SwarmPlotData;
          Object.assign(filteredResponse, response);
          filteredResponse.data = (response as SwarmPlotData).data?.filter(
            (dataPoint) => {
              // data from last 5 days for mini view
              const bottomLimit = new Date().getDate() - 5;
              return dataPoint.x > bottomLimit.toString();
            }
          );
          setFilteredSwarmPlotData(filteredResponse);
        })
        .catch((err) => {
          setFilteredSwarmPlotData({data: []} as unknown as SwarmPlotData)
          handleHttpError(err)
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  useEffect(() => {
    if (liftLoadingState) {
      liftLoadingState(loading);
    }
  }, [loading]);

  return swarmPlotData ? (
    <Chart
      loading={loading}
      inCarousel={inCarousel}
      type="swarm"
      data={filteredSwarmPlotData as SwarmPlotData}
      fullData={swarmPlotData}
      upliftMiniState={setMini}
      containerRef={containerRef}
    />
  ) : (
    <></>
  );
};

export default swarmPlotDataSwarmPlot;
