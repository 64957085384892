import { Stack, SxProps, Theme, Typography } from "@mui/material";

const CeneteredTextMessage = (props: {
  message: string;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        ...props.sx,
      }}
    >
      <Typography variant="bodyBold">{props.message}</Typography>
    </Stack>
  );
};

export default CeneteredTextMessage;
