import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const InvoicesIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 18 27" {...props}>
    <path d="M1.40645 14.1732H2.34397V3.21253C2.34509 2.86048 2.49315 2.52285 2.75598 2.27309L4.74963 0.389305C5.01396 0.140959 5.3713 0.00105287 5.74387 0H16.25C16.6229 0 16.9806 0.139906 17.2444 0.38913C17.5082 0.638359 17.6562 0.976352 17.6562 1.32875V11.2203H18.5938C18.9667 11.2203 19.3244 11.3602 19.5882 11.6095C19.8519 11.8587 20 12.1967 20 12.5491V23.6712C20 24.0236 19.8519 24.3616 19.5882 24.6109C19.3244 24.8601 18.9667 25 18.5938 25H1.40625C1.0333 25 0.675585 24.8601 0.411825 24.6109C0.14806 24.3616 0 24.0236 0 23.6712V15.5021C0 15.1497 0.148066 14.8117 0.411825 14.5624C0.675591 14.3132 1.0333 14.1733 1.40625 14.1733L1.40645 14.1732ZM3.58872 2.46067H4.9481V1.17621L3.58872 2.46067ZM16.9272 1.32879C16.9272 0.975511 16.624 0.689013 16.2501 0.689013H5.74399C5.72167 0.689013 5.69917 0.689013 5.67741 0.692001V2.80517C5.67741 2.99534 5.51409 3.14965 5.31283 3.14965H3.07641C3.07641 3.17039 3.07325 3.19148 3.07325 3.21257V14.1732H9.35464C9.51628 14.1732 9.67253 14.1186 9.7953 14.0192L12.8561 11.5403C13.1111 11.334 13.4355 11.2204 13.7713 11.2204H16.9275L16.9272 1.32879ZM0.729218 23.6712C0.729218 24.0245 1.03242 24.311 1.40631 24.311H18.5938C18.9677 24.311 19.2709 24.0245 19.2709 23.6712V12.5491C19.2709 12.1958 18.9677 11.9093 18.5938 11.9093H13.771C13.6093 11.9093 13.4531 11.9639 13.3303 12.0632L10.2694 14.5422H10.2695C10.0145 14.7485 9.69011 14.8621 9.35435 14.8621H1.40631C1.03242 14.8621 0.729218 15.1485 0.729218 15.5018V23.6712Z" />
    <path d="M14.4793 4.42876H7.08362C6.88235 4.42876 6.71904 4.27445 6.71904 4.08428V2.60792C6.71904 2.51653 6.75736 2.42899 6.82581 2.36431C6.89426 2.29963 6.9869 2.26343 7.08362 2.26343H14.4793C14.6806 2.26343 14.8439 2.41775 14.8439 2.60791V4.08427C14.8439 4.27445 14.6806 4.42876 14.4793 4.42876L14.4793 4.42876ZM14.1147 2.95241H7.44808V3.73981H14.1147V2.95241Z" />
    <path d="M14.4793 7.43071H5.52125C5.31998 7.43071 5.15667 7.2764 5.15667 7.08623V5.60987C5.15667 5.51848 5.19499 5.43095 5.26344 5.36627C5.33189 5.30159 5.42453 5.26538 5.52125 5.26538H14.4793C14.6806 5.26538 14.8439 5.4197 14.8439 5.60987V7.08622C14.8439 7.2764 14.6806 7.43071 14.4793 7.43071L14.4793 7.43071ZM14.1147 5.95436H5.88571V6.74176H14.1147V5.95436Z" />
    <path d="M5.15642 10.0887V8.61231C5.15642 8.52092 5.19474 8.43339 5.26319 8.36871C5.33165 8.30403 5.42428 8.26782 5.52101 8.26782H14.479C14.6803 8.26782 14.8436 8.42214 14.8436 8.61231V10.0887C14.8436 10.2788 14.6803 10.4331 14.479 10.4331H5.52101C5.31974 10.4331 5.15643 10.2788 5.15643 10.0887L5.15642 10.0887ZM5.88556 9.74418H14.1146V8.95678H5.88556V9.74418Z" />
  </IconWrapper>
);

export default InvoicesIcon;
