import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useContext } from "react";
import Card from "./Card";
import CustomerContext from "../../contexts/CustomerContext";
import DocumentLoader from "../documentloader/DocumentLoader";
import { Link } from "react-router-dom";

interface InfoCardProps {
  download?: boolean;
  header: string;
  headerValue?: string;
  key1?: string;
  key1Value?: string;
  key2?: string;
  key2Value?: string;
  // stack header and header value
  multilineHeader?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  documentType?: "LS" | "SR" | "NB" | "GS" | "MB" | "TB";
}

const InfoCard = (props: InfoCardProps): JSX.Element => {
  const {
    download,
    header,
    headerValue,
    key1,
    key1Value,
    key2,
    key2Value,
    multilineHeader,
    onClick,
    sx,
    documentType,
  } = props;

  const { customerNumber } = useContext(CustomerContext);

  return (
    <Card
      secondary
      sx={{
        minWidth: "260px",
        pt: 1,
        pb: 1,
        pr: 2,
        pl: 2,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={onClick}
      >
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: multilineHeader ? "column" : "row",
            }}
          >
            <Typography variant="body2" color="secondary.main">
              {header}:
            </Typography>
            <Typography
              variant="body2"
              sx={{ paddingLeft: multilineHeader ? "" : "0.5rem" }}
            >
              {headerValue}
            </Typography>
          </Box>
          {key1 && (
            <Box sx={{ display: "flex" }}>
              <Typography variant="body2" sx={{ mr: 0.6 }}>
                {key1}:
              </Typography>
              <Typography variant="body2">{key1Value}</Typography>
            </Box>
          )}
          {key2 && (
            <Box sx={{ display: "flex" }}>
              <Typography variant="body2" sx={{ mr: 0.6 }}>
                {key2}:
              </Typography>
              <Typography variant="body2">{key2Value}</Typography>
            </Box>
          )}
        </div>
        {download && (
          <DocumentLoader
            customerNumber={customerNumber}
            documentId={headerValue}
            documentDate={key1Value}
            documentType={documentType}
          />
        )}
      </Box>
    </Card>
  );
};

export default InfoCard;
