import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import AepThemeProvider from "./styles/AepThemeProvider";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak, keycloakInitOptions, keycloakOnEvent } from "./keycloak";
import ContextsProvider from "./app/contexts/ContextsProvider";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import de from "date-fns/locale/de";

//React Render
ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakInitOptions}
        onEvent={keycloakOnEvent}
      >
        <AepThemeProvider>
          <ContextsProvider>
            <BrowserRouter basename="/">
              <App />
            </BrowserRouter>
          </ContextsProvider>
        </AepThemeProvider>
      </ReactKeycloakProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
