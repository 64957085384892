import React, { RefObject } from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import Card, { CardProps } from "../../../components/card/Card";
import { TextDivider } from "../../../components/other/TextDivider";
import NavButton from "../../../components/button/NavButton";

export interface DashboardCardProps extends CardProps {
  buttonText?: string;
  header?: string;
  loading?: boolean;
  icon?: JSX.Element;
  image?: string;
  innerBoxStyles?: SxProps<Theme>;
  innerRef?: RefObject<HTMLElement>;
  to?: string;
}

const DashboardCard = (props: DashboardCardProps): JSX.Element => {
  const {
    buttonText,
    children,
    header,
    icon,
    image,
    innerBoxStyles,
    loading,
    innerRef,
    sx,
    to,
  } = props;

  return (
    <Card
      image={image}
      sx={{ height: "100%", minWidth: "260px", ...sx }}
      loading={loading}
      innerRef={innerRef as RefObject<HTMLElement>}
    >
      <Box
        sx={{
          px: 3,
          pt: 2,
          pb: 6,
          height: "100%",
          position: "relative",
          ...innerBoxStyles,
        }}
      >
        {header && (
          <Box sx={{ alignItems: "center", display: "flex" }}>
            {icon}
            <TextDivider sx={{ margin: "0 15px" }} />
            <Typography variant="h3" sx={{ whiteSpace: "nowrap" }}>
              {header}
            </Typography>
          </Box>
        )}
        {children}
        {buttonText && (
          <NavButton
            buttonText={buttonText || ""}
            sx={{
              position: "absolute",
              bottom: 13,
              right: 19,
            }}
            to={to || ""}
          />
        )}
      </Box>
    </Card>
  );
};

export default DashboardCard;
