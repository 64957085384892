import { useContext, useState } from "react";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import TableContainer from "../../../components/table/TableContainer";
import SnackbarContext from "../../../contexts/SnackbarContext";
import { TableFilterType } from "../../../components/table/tableFilter/models";
import CustomerContext from "../../../contexts/CustomerContext";
import { CancellationDto, CancellationPositionDto } from "../../../../api";
import TableAccordionWrapper from "../../../components/table/TableAccordionWrapper";
import TableAccordionRow from "../../../components/table/TableAccordionRow";
import CancellationPositionsTable from "../positions/CancellationPositionsTable";
import { utcToGermanDateString } from "../../../service/CustomDateService";

const CancellationsSearchContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cancellations, setCancellations] = useState<CancellationDto[]>([]);
  const [shownCancellations, setShownCancellations] = useState<
    CancellationDto[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCancellationPositions, setSelectedCancellationPositions] =
    useState<string[]>([]);

  const { handleHttpError } = useContext(SnackbarContext);
  const { customerNumber } = useContext(CustomerContext);

  const api = new ApiWrapper().cancellationsControllerApi();

  interface CancellationSearchFormikValues {
    pzn?: string;
  }

  const initialValues: CancellationSearchFormikValues = {
    pzn: "",
  };

  const sortByPZN = (
    loadMore: boolean,
    searchParams?: CancellationSearchFormikValues
  ) => {
    if (
      typeof searchParams !== "undefined" &&
      typeof searchParams.pzn !== "undefined" &&
      searchParams.pzn.length > 0
    ) {
      const filterPzn =
        (searchPzn: string) =>
        (cancellationPosition: CancellationPositionDto) =>
          cancellationPosition.pzn === searchPzn;
      const newShownCancellations: CancellationDto[] = cancellations
        .filter((cancellation) =>
          cancellation.cancellationPositions?.some(
            filterPzn(searchParams.pzn as string)
          )
        )
        .map((cancellation) => ({
          ...cancellation,
          cancellationPositions: cancellation.cancellationPositions?.filter(
            filterPzn(searchParams.pzn as string)
          ),
        }));
      setShownCancellations(newShownCancellations);
    } else {
      setShownCancellations(cancellations);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadCancellations = (loadMore: boolean): void => {
    if (customerNumber && !loading) {
      setLoading(true);

      api
        .getAllCancellations(customerNumber)
        .then((res) => {
          setCancellations(res.data);
          setShownCancellations(res.data);
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }
  };

  return (
    <TableContainer
      liftStateToParent={setSelectedCancellationPositions}
      loadCallback={loadCancellations}
      loading={loading}
      tableFilterProps={{
        loadCallbackOverride: sortByPZN,
        initialValues: initialValues,
        searchFields: [
          {
            type: TableFilterType.textfield,
            name: "pzn",
            label: "Suche nach PZN",
          },
        ],
      }}
      Table={({
        handleSelectRow,
        isSelected,
        handleSelectAll,
        allSelected,
      }) => (
        <TableAccordionWrapper tableHeader="Bestellungen">
          {shownCancellations.map((cancellation, index) => {
            return (
              <TableAccordionRow
                key={`cancellation-list-item-${index}`}
                header={`${utcToGermanDateString(
                  cancellation.orderDate
                )}: Auftrag ${cancellation.cancellationId}, Auftragskennung: ${
                  cancellation.label
                }`}
              >
                <CancellationPositionsTable
                  allSelected={allSelected}
                  isSelected={isSelected}
                  handleSelectRow={handleSelectRow}
                  handleSelectAll={handleSelectAll as (ids: string[]) => void}
                  positions={cancellation.cancellationPositions}
                />
              </TableAccordionRow>
            );
          })}
        </TableAccordionWrapper>
      )}
    />
  );
};

export default CancellationsSearchContainer;
