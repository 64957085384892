import { SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const rootCardStyles: SxProps<Theme> = {
  minHeight: {
    xs: "440px",
    lg: "487px",
  },
  pb: 3,
  px: 3,
  pt: 2,
};

export const formTextFieldStyles: SxProps<Theme> = {
  minWidth: "180px",
  width: "60%",
};

export const buttonStyles: SxProps<Theme> = {
  width: "200px",
  position: "absolute",
  bottom: 0,
  right: 0,
};

export const inputLabelStyles: SxProps<Theme> = {
  color: aepPalette.base.light,
  left: "-14px",
  top: "4px",
  "&.Mui-focused": {
    color: aepPalette.secondary.main,
  },
};
