import { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Box,
  Button,
  Collapse,
  Fade,
  LinearProgress,
  Typography,
} from "@mui/material";
import EmployeeAccordion from "./elements/EmployeeAccordion";
import AddIcon from "@mui/icons-material/Add";
import { employeesTimeout, topBoxStyles } from "./employeesStyles";
import CustomerContext from "../../contexts/CustomerContext";
import ApiWrapper from "../../wrappers/ApiWrapper";
import { UserDto } from "../../../api";
import EmployeeForm from "./elements/EmployeeForm";
import SnackbarContext from "../../contexts/SnackbarContext";

const Employees = () => {
  const api = new ApiWrapper().userControllerApi();

  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setError] = useState<boolean>(false);
  const [addNewEmployee, setAddNewEmployee] = useState<boolean>(false);
  const [employees, setEmployees] = useState<UserDto[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const { customerNumber } = useContext(CustomerContext);
  const { handleError } = useContext(SnackbarContext);

  const verifyEmployeeData = (employeeData: UserDto): boolean => {
    return !!employeeData.firstName;
  };

  const handleExpansion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const handleAddUser = (user: UserDto) => {
    setEmployees([...employees, user]);
    setAddNewEmployee(false);
  };

  const handleEmployeesError = () => {
    handleError(
      "Aktuell ist die Anzeige der Mitarbeiter nicht möglich. Bitte kontaktieren Sie das Service Center."
    );
    setExpandedIndex(null);
    setError(true);
    setLoading(false);
  };

  const loadUsers = (): Promise<void> => {
    return api
      .getAllUsers(customerNumber)
      .then((res) => {
        setEmployees(res.data);
        setExpandedIndex(null);
      })
      .catch(() => {
        handleEmployeesError();
      });
  };

  useEffect(() => {
    let isApiSubscribed = true;
    setEmployees([]);
    setError(false);

    if (isApiSubscribed && customerNumber) {
      setLoading(true);

      loadUsers()
        .catch()
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  return (
    <Box>
      <Box
        sx={{
          ...topBoxStyles,
          border: `1px solid rgba(197, 217, 232, ${loading ? 0 : 1})`,
          transition: `height ${employeesTimeout / 1000}s`,
        }}
      >
        <Typography variant="body1" sx={{ px: 8, py: 2 }}>
          Ihre Mitarbeiter{" "}
          <Fade in={loading} timeout={loading ? 0 : employeesTimeout}>
            <span>werden geladen...</span>
          </Fade>
        </Typography>
        <Fade in={loading} timeout={employeesTimeout}>
          <LinearProgress color="secondary" />
        </Fade>
        {!loading && employees.length === 0 ? (
          <Typography variant="body2" sx={{ textAlign: "center", py: 5 }}>
            In der aktuell ausgewählten Apotheke sind keine Mitarbeiter
            registriert!
          </Typography>
        ) : (
          <></>
        )}
        {employees.map((employee: UserDto, index) => {
          if (verifyEmployeeData(employee)) {
            return (
              <Collapse
                key={index}
                in={!loading}
                timeout={loading ? 0 : employeesTimeout}
              >
                <div>
                  <EmployeeAccordion
                    employeeData={employee}
                    expanded={index === expandedIndex}
                    handleExpansion={() => handleExpansion(index)}
                    loadUsers={loadUsers}
                    handleEmployeesError={handleEmployeesError}
                  />
                </div>
              </Collapse>
            );
          }
        })}
      </Box>
      {addNewEmployee && (
        <Box sx={{ border: "1px solid #C5D9E8", mt: 5 }}>
          <Accordion expanded={true}>
            <EmployeeForm
              employeeData={{ firstName: "", lastName: "" }}
              newEmployee
              userCreated={(data) => handleAddUser(data)}
              shown
            />
          </Accordion>
        </Box>
      )}
      {!isError && !loading ? (
        <Button
          onClick={() => {
            setAddNewEmployee(!addNewEmployee);
          }}
          variant="primary"
          startIcon={<AddIcon />}
          sx={{ mt: 3, float: "right" }}
        >
          Mitarbeiter hinzufügen
        </Button>
      ) : (
        <></>
      )}
      {/* Box for page bottom margin */}
      <Box height="100px" />
    </Box>
  );
};

export default Employees;
