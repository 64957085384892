import InfoCard from "../../../components/card/InfoCard";
import { SxProps, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface DeliveryShipmentInfoCardProps {
  deliveryNo: string;
  deliveryDate: string;
  sx?: SxProps<Theme>;
}

const deliverInfoCardStyles = {
  cursor: "pointer",
  mb: 2,
  "&:hover, &:active": {
    backgroundColor: "secondary.light",
  },
};

export const DeliveryShipmentInfoCard = (
  props: DeliveryShipmentInfoCardProps
): JSX.Element => {
  const { deliveryNo, deliveryDate } = props;
  const navigate = useNavigate();

  return (
    <InfoCard
      download
      header="Lieferschein-Nr."
      headerValue={deliveryNo}
      key1="Lieferdatum"
      key1Value={deliveryDate}
      onClick={() =>
        navigate("bestellungen/lieferungen/details", { state: [deliveryNo] })
      }
      sx={deliverInfoCardStyles}
      documentType="LS"
    />
  );
};
