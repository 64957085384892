import { useEffect, useState, useContext } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import Card, { CardProps } from "../../../components/card/Card";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { DeliveryDto } from "../../../../api";
import CustomerContext from "../../../contexts/CustomerContext";
import SnackbarContext from "../../../contexts/SnackbarContext";
import DeliveryDetailTable from "./DeliveryDetailTable";
interface DeliveryDetailCardProps extends CardProps {
  deliveryId: string;
  index: number;
}

const DeliveryDetailCard = ({ deliveryId, index }: DeliveryDetailCardProps) => {
  const api = new ApiWrapper().deliveriesControllerApi();
  const [delivery, setDelivery] = useState<DeliveryDto>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { customerNumber } = useContext(CustomerContext);
  const { handleHttpError } = useContext(SnackbarContext);

  useEffect(() => {
    setLoading(true);
    api
      .getDeliveryById(customerNumber, deliveryId)
      .then((res) => {
        setDelivery(res.data);
        if (index === 0) {
          setExpanded(true);
        }
      })
      .catch(handleHttpError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const toggle = () => {
    if (!loading) {
      if (expanded) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    }
  };

  return (
    <Card sx={{ height: "auto", mb: 4 }}>
      <Accordion expanded={expanded} onChange={toggle}>
        <AccordionSummary sx={{ borderTop: "0px" }}>
          <Stack direction="column" p={3}>
            <Stack direction="row">
              <Typography variant="body1">Lieferschein-Nr:&nbsp;</Typography>
              <Typography variant="body2">{deliveryId}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body1">Lieferdatum:&nbsp;</Typography>
              <Typography variant="body2">{delivery?.deliveryDate}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body1">Status:&nbsp;</Typography>
              <Typography variant="body2">{delivery?.deliveryState}</Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <DeliveryDetailTable delivery={delivery} />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default DeliveryDetailCard;
