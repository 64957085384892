import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReceiptDto } from "../../../../api";
import {
  CustomTableProps,
  tablePageDefaultLength,
} from "../../../components/table/TableContainer";
import ReceiptsSearchTableRow from "./ReceiptsSearchTableRow";

interface ReceiptsSearchTableProps extends CustomTableProps {
  receipts: ReceiptDto[];
  loading: boolean;
  selectableRows: boolean;
}

const ReceiptsSearchTable = (props: ReceiptsSearchTableProps) => {
  const { receipts, handleSelectRow, isSelected, selectableRows } = props;

  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "7%" }} align="center">
            <Checkbox disabled sx={{ opacity: 0 }} />
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <Typography variant="body2">Belegart</Typography>
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <Typography variant="body2">Belegnummer</Typography>
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <Typography variant="body2">Belegdatum</Typography>
          </TableCell>
          <TableCell sx={{ width: "2%" }}></TableCell>
          <TableCell sx={{ width: "8%" }}></TableCell>
          <TableCell sx={{ width: "8%" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {receipts.map((row, index) => (
          <ReceiptsSearchTableRow
            transitionDelay={index % tablePageDefaultLength}
            row={row}
            key={`receipt-${index}`}
            isSelected={isSelected}
            handleSelectRow={handleSelectRow}
            selectableRows={selectableRows}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ReceiptsSearchTable;
