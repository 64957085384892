import * as React from "react";
import { styled } from "@mui/material/styles";
import PickersDay, { PickersDayProps } from "@mui/lab/PickersDay";

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    backgroundColor: theme.palette.primary.dark,
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderBottomRightRadius: "0%",
    borderTopRightRadius: "0%",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isLastDay && {
    backgroundColor: theme.palette.primary.dark,
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

export default CustomPickersDay;
