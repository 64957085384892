import { SxProps, Theme } from "@mui/material/styles";
import { aepPalette } from "../../../../styles/aepPalette";

export const searchFieldStyles: SxProps<Theme> = {
  maxWidth: "190px",
};

export const textFieldStyles: SxProps<Theme> = {
  fontSize: "1.3rem",
  lineHeight: "1.35rem",
  ...searchFieldStyles,
};

export const dropDownStyles: SxProps<Theme> = {
  ...searchFieldStyles,
};

export const cardStyles: SxProps<Theme> = {
  paddingLeft: "4.8%",
  paddingRight: "4.8%",
  py: 3,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const cardInnerBoxStyles: SxProps<Theme> = {
  width: "inherit",
  display: "flex",
  position: "relative",
};

export const searchIconStyles: SxProps<Theme> = {
  color: "secondary.lighter",
  fontSize: "2rem",
  paddingRight: "0.5rem",
};

export const clearIconStyles: SxProps<Theme> = {
  color: "secondary.lighter",
  fontSize: "2rem",
  paddingRight: "0.5rem",
};

export const buttonStyles: SxProps<Theme> = {
  mt: 1.5,
  ml: 2,
  right: 0,
};

export const buttonSecondaryStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.base.main,
  border: `1px solid ${aepPalette.base.main}`,
  mt: 1.5,
  ml: 1,
  pl: 3.2,
  pr: 3.2,
};

export const searchFieldBoxStyles = (
  numberOfSearchFields: number,
  overrides?: SxProps<Theme>
): SxProps<Theme> => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: `repeat(${
    numberOfSearchFields > 4 ? 4 : numberOfSearchFields
  }, 1fr)`,
  gridTemplateRows: numberOfSearchFields > 4 ? "50% 50%" : "100%",
  columnGap: 2,
  ...overrides,
});
