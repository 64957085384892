/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import StrapiData from "../../../strapi/models/StrapiData";
import StrapiApi from "../../../strapi/StrapiApi";
import NewsItem from "./NewsItem";
import Article from "../../../strapi/models/Article";
import SnackbarContext from "../../contexts/SnackbarContext";
import NewsItemSkeleton from "./NewsItemSkeleton";
import NavButton from "../../components/button/NavButton";
import NewsItemDetails from "./NewsItemDetails";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { aepPalette } from "../../../styles/aepPalette";
import { fadeTimeout } from "../../App";
import { arrowUpwardsStyles } from "./newsStyles";
import CustomerContext from "../../contexts/CustomerContext";
import CenteredTextMessage from "../../components/other/CenteredTextMessage";

const News = () => {
  const { keycloak } = useKeycloak();
  const { customerNumber } = useContext(CustomerContext);
  const strapiApi = new StrapiApi(keycloak.token || "", customerNumber);
  const { handleHttpError } = useContext(SnackbarContext);
  const [articles, setArticles] = useState<Array<StrapiData<Article>>>([]);
  const [loading, setLoading] = useState(true);
  const [detailView, setDetailView] = useState(false);

  const renderOverviewSkeleton = (): JSX.Element => (
    <Box position="absolute">
      <NewsItemSkeleton fadeIn={loading && !detailView} timeout={fadeTimeout} />
      <NewsItemSkeleton fadeIn={loading && !detailView} timeout={fadeTimeout} />
      <NewsItemSkeleton fadeIn={loading && !detailView} timeout={fadeTimeout} />
    </Box>
  );

  const toggleDetailView = (open: boolean, id?: string): void => {
    setDetailView(open);

    if (open && id) {
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView();
      }, 0);
    }
  };
  const scrollToTop = (): void => {
    document.getElementById(`artikel-${articles[0].id}`)?.scrollIntoView();
  };

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed && customerNumber) {
      strapiApi
        .getAllArticles()
        .then((res) => {
          setArticles(res.data.data);
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  if (articles.length == 0) {
    return (
      <CenteredTextMessage message="Für die Vorschau sind keine Daten vorhanden" />
    );
  }

  return detailView ? (
    // DETAIL VIEW
    <Box sx={{ mr: 5, pb: 5, position: "relative" }}>
      <NavButton
        back
        sx={{ mb: 5, ml: -2, width: "fit-content" }}
        buttonText="Zur Übersicht"
        onClick={() => toggleDetailView(false)}
      />
      {articles.map((article, index) => {
        return (
          <NewsItemDetails
            id={`artikel-${article.id}`}
            key={`artikel-${article.id}`}
            fadeIn={!loading && detailView}
            timeout={fadeTimeout}
            article={article}
            baseURL={strapiApi.axiosRequestConfig.baseURL || ""}
            lastInList={index === articles.length - 1}
          />
        );
      })}
      <Box onClick={scrollToTop} sx={arrowUpwardsStyles}>
        <ArrowUpwardIcon
          sx={{
            color: aepPalette.common.white,
            height: "2rem",
            width: "2rem",
          }}
        />
      </Box>
    </Box>
  ) : (
    // OVERVIEW
    <Box mr={5} pb={5}>
      <Typography variant="h2" sx={{ mb: 7 }}>
        Neuigkeiten und Aktionen von AEP
      </Typography>
      {renderOverviewSkeleton()}
      {articles?.map((article) => {
        return (
          <NewsItem
            key={`artikel-overview-${article.id}`}
            fadeIn={!loading && !detailView}
            timeout={fadeTimeout}
            article={article}
            baseURL={strapiApi.axiosRequestConfig.baseURL || ""}
            toggleDetailView={() =>
              toggleDetailView(true, `artikel-${article.id}`)
            }
          />
        );
      })}
      <Box height="50px" />
    </Box>
  );
};

export default News;
