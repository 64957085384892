import {
  Button,
  Stack,
  TableContainerProps as MuiTableContainerProps,
  Typography,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";
import { FormikValues } from "formik";
import { useEffect, useContext, useRef, useState } from "react";
import CustomerContext from "../../contexts/CustomerContext";
import Card from "../card/Card";
import CenteredTextMessage from "../other/CenteredTextMessage";
import CounterField from "../textfield/CounterField";
import { TableFilterSearchField } from "./tableFilter/models";
import TableFilter from "./tableFilter/TableFilter";
import TableLoadMoreRow from "./TableLoadMoreRow";
import {
  containerCardStyles,
  rootContainerStackStyles,
  submitButtonStyles,
  containerCardBoxStyles,
  containerControlStackStyles,
} from "./tableStyles";

export interface CustomTableProps {
  allSelected?: (ids: string[]) => boolean;
  handleSelectAll?: (ids: string[]) => void;
  handleSelectRow: (id: string) => void;
  isSelected: (id: string) => boolean;
  transitionDelay?: number;
}

export const tablePageDefaultLength = 16;

interface TableContainerProps extends MuiTableContainerProps {
  buttonAction?: (stateValue: unknown[]) => void;
  buttonText?: string;
  fullSizeRowsInit?: boolean;
  hasButtonCounter?: boolean;
  hasDetailViewSwitch?: boolean;
  liftStateToParent?: React.Dispatch<React.SetStateAction<string[]>>;
  loadCallback: (loadMore: boolean, searchParams?: FormikValues) => void;
  loading: boolean;
  noData?: boolean;
  noDataMessage?: string;
  noInitialApiCall?: boolean;
  pageable?: boolean;
  preselectedRows?: string[];
  selectableRows?: boolean;
  Table: (props: CustomTableProps) => JSX.Element;
  tableFilterProps?: {
    initialValues: FormikValues;
    searchFields: TableFilterSearchField[];
    loadCallbackOverride?: any;
  };
}

const TableContainer = (props: TableContainerProps) => {
  const {
    buttonAction,
    buttonText,
    fullSizeRowsInit,
    hasButtonCounter,
    hasDetailViewSwitch,
    liftStateToParent,
    loadCallback,
    loading,
    noData,
    noDataMessage,
    noInitialApiCall,
    pageable,
    preselectedRows,
    selectableRows,
    sx,
    Table,
    tableFilterProps,
  } = props;
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [loadMoreLocked, setLoadMoreLocked] = useState<boolean>(false);
  const [fullSizeRows, setfullSizeRows] = useState<boolean>(
    fullSizeRowsInit || false
  );

  const [initialLoadingComplete, setInitialLoadingComplete] =
    useState<boolean>(false);
  const [resetSearchParameters, setResetSearchParameters] =
    useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>(
    preselectedRows || []
  );
  const { customerNumber } = useContext(CustomerContext);
  const tableRef = useRef<HTMLElement>(null);
  const [count, setCount] = useState<number>(0);

  const changeCount = (newCount?: number) => {
    setCount(newCount || 0);
  };

  const throttleLoadMore = () => {
    if (loadMore === false) {
      setLoadMore(true);
    }
  };

  const preventLoadMore = () => {
    setLoadMoreLocked(true);
    setTimeout(() => setLoadMoreLocked(false), 500);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    preventLoadMore();
    setfullSizeRows(event.target.checked);
  };

  const handleResetFilter = () => {
    setResetSearchParameters(true);
    setTimeout(() => setResetSearchParameters(false), 300);
  };

  const handleResetRows = () => {
    setSelectedRows([]);
  };

  const handleLoadMore = (e: Event): void => {
    const tableEl: unknown = e.target;

    const reachedBottom =
      (tableEl as HTMLElement).scrollTop ===
      (tableEl as HTMLElement).scrollHeight -
        (tableEl as HTMLElement).offsetHeight +
        2;

    if (!loadMoreLocked && !loading && reachedBottom) {
      throttleLoadMore();
    }
  };

  const handleSelectRow = (id: string) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else {
      newSelected = selectedRows.filter((data, idx) => idx != selectedIndex);
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAll = (rowIds: string[]) => {
    const rowsAlreadyInSelection = rowIds?.filter((rowId) =>
      selectedRows.includes(rowId)
    );
    const rowsToAdd: string[] = [];

    for (let i = 0; i < rowIds?.length; i++) {
      if (!rowsAlreadyInSelection?.includes(rowIds[i])) {
        rowsToAdd.push(rowIds[i]);
      }
    }

    const containsAll = rowsToAdd.every((row) => {
      return selectedRows.includes(row);
    });

    if (containsAll) {
      // deselect all rows
      setSelectedRows((prevState) => [
        ...prevState.filter((row) => !rowIds.includes(row)),
      ]);
    } else {
      setSelectedRows((prevState) => [...prevState, ...rowsToAdd]);
    }
  };

  const isSelected = (id: string) => selectedRows.includes(id);
  const allSelected = (ids: string[]): boolean =>
    ids.filter((id) => selectedRows.includes(id)).length === ids.length;

  useEffect(() => {
    // TODO There needs to be a better way to achieve this
    // but if we only blindly apply setSelectedRows(preselectedRows || [])
    // this part gets into an infinite loop
    let changed = false;
    for (let i = 0; i < selectedRows.length; i++) {
      if (!(preselectedRows || []).some((p) => p === selectedRows[i])) {
        changed = true;
      }
    }
    for (let i = 0; i < (preselectedRows || []).length; i++) {
      if (!(selectedRows || []).some((p) => p === (preselectedRows || [])[i])) {
        changed = true;
      }
    }
    if (changed) {
      setSelectedRows(preselectedRows || []);
    }
  }, [preselectedRows]);

  useEffect(() => {
    let isApiSubscribed = true;

    setSelectedRows([]);
    // initial load
    !noInitialApiCall && loadCallback(false);

    if (isApiSubscribed && customerNumber) {
      if (pageable) {
        tableRef.current?.addEventListener("scroll", handleLoadMore);
      }
      setInitialLoadingComplete(true);
    }

    return () => {
      isApiSubscribed = false;
      tableRef.current?.removeEventListener("scroll", handleLoadMore);
    };
  }, [customerNumber]);

  useEffect(() => {
    if (loadMore) {
      setLoadMore(false);
    }
  }, [loadMore]);

  useEffect(() => {
    if (liftStateToParent) liftStateToParent(selectedRows);
  }, [selectedRows]);

  const renderButton = (counter?: boolean) => {
    if (buttonText && buttonAction) {
      return counter && changeCount ? (
        <Stack sx={rootContainerStackStyles}>
          <CounterField
            label="Boxen"
            loading={loading}
            count={count}
            changeCount={changeCount}
          />
          <Button
            variant="primary"
            disabled={count === 0 || loading}
            onClick={() => buttonAction([count])}
            sx={{
              ml: 2,
              ...submitButtonStyles,
            }}
          >
            <Typography variant="buttonText">{buttonText}</Typography>
          </Button>
        </Stack>
      ) : (
        <Button
          variant="primary"
          disabled={selectedRows.length === 0 || loading}
          onClick={() => buttonAction(selectedRows)}
          sx={submitButtonStyles}
        >
          <Typography variant="buttonText">
            {buttonText +
              ` ${selectableRows ? `(${selectedRows.length})` : ""}`}
          </Typography>
        </Button>
      );
    }
  };

  return (
    <Stack sx={{ height: "100%", position: "relative", ...sx }}>
      {tableFilterProps && (
        <TableFilter
          handleResetFilter={handleResetFilter}
          triggerSubmit={loadMore}
          handleResetRows={handleResetRows}
          resetSearchParameters={resetSearchParameters}
          initialValues={tableFilterProps.initialValues}
          onSubmit={tableFilterProps.loadCallbackOverride || loadCallback}
          searchFields={tableFilterProps.searchFields}
        />
      )}
      <Card
        initialLoadingComplete={initialLoadingComplete}
        loading={loading}
        innerRef={tableRef}
        sx={containerCardStyles}
      >
        <Box sx={containerCardBoxStyles(fullSizeRows, hasDetailViewSwitch)}>
          {Table({
            allSelected,
            handleSelectAll,
            handleSelectRow,
            isSelected,
          })}
          {noData && !loading ? (
            <Box sx={{ mt: 4 }}>
              <CenteredTextMessage
                message={noDataMessage || "Keine Daten"}
                sx={{ my: 2 }}
              />
            </Box>
          ) : (
            <></>
          )}
          {pageable && !noData ? (
            <TableLoadMoreRow
              loading={loading || loadMoreLocked}
              hide={false}
              loadCallback={throttleLoadMore}
            />
          ) : (
            <></>
          )}
        </Box>
      </Card>
      <Stack
        direction="row"
        sx={containerControlStackStyles(selectableRows, hasDetailViewSwitch)}
      >
        {hasDetailViewSwitch && (
          <FormControlLabel
            control={
              <Switch
                disabled={noData}
                color="secondary"
                checked={fullSizeRows}
                onChange={handleChangeDense}
              />
            }
            label="Zeilen erweitern"
          />
        )}
        {renderButton(hasButtonCounter)}
      </Stack>
    </Stack>
  );
};

export default TableContainer;
