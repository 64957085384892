import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import TableAccordionRow from "../../components/table/TableAccordionRow";
import TableAccordionWrapper from "../../components/table/TableAccordionWrapper";
import TableContainer, {
  CustomTableProps
} from "../../components/table/TableContainer";
import SpecialOrdersTable from "./elements/SpecialOrdersTable";
import SpecialOrdersContext from "../../contexts/SpecialOrdersContext";
import { rootBoxStyles } from "./specialOrdersStyles";

const SpecialOrders = () => {
  const {
    specialOrderRequests,
    specialOrderResponses,
    postOrders,
    loadSpecialOrders,
    setSelectedOrderIds,
    loading
  } = useContext(SpecialOrdersContext);

  const categorizeOrders = (props: CustomTableProps) => {
    const allCategories = specialOrderResponses.map((order) => order.category);
    const uniqueCategories = Array.from(new Set(allCategories));

    return uniqueCategories.sort().map((category: string, index: number) => {
      const ordersInCategory = specialOrderResponses.filter(
        (order) => order.category === category
      );

      return (
        <TableAccordionRow key={`order-list-item-${index}`} header={category}>
          <SpecialOrdersTable {...props} orders={ordersInCategory} />
        </TableAccordionRow>
      );
    });
  };

  return (
    <Box sx={rootBoxStyles}>
      <Typography variant="h2" sx={{ pt: 3, pb: 5, pl: 1 }}>
        Bestellformulare
      </Typography>
      <TableContainer
        noData={specialOrderResponses.length === 0}
        buttonAction={() => {
          postOrders(specialOrderRequests);
        }}
        buttonText="Bestellung vormerken"
        liftStateToParent={setSelectedOrderIds}
        loadCallback={loadSpecialOrders}
        loading={loading}
        Table={(props: CustomTableProps) => (
          <TableAccordionWrapper tableHeader="Sonderbestellungen">
            {categorizeOrders(props)}
          </TableAccordionWrapper>
        )}
        selectableRows
        sx={{ height: "87%" }}
        preselectedRows={specialOrderRequests.map((s) => s.id + "")}
      />
    </Box>
  );
};

export default SpecialOrders;
