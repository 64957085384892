import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeliveryDto } from "../../../../api";
import CustomerContext from "../../../contexts/CustomerContext";
import SnackbarContext from "../../../contexts/SnackbarContext";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import DeliverySearchTable from "./DeliverySearchTable";
import { TableFilterType } from "../../../components/table/tableFilter/models";
import TableContainer from "../../../components/table/TableContainer";

const DeliverySearchContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deliveries, setDeliveries] = useState<DeliveryDto[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { handleHttpError } = useContext(SnackbarContext);
  const { customerNumber } = useContext(CustomerContext);

  const api = new ApiWrapper().deliveriesControllerApi();
  const navigate = useNavigate();
  const pageLength = 16;

  interface DeliverySearchFormikValues {
    pzn?: string;
    fromDate?: string;
    toDate?: string;
    deliveryNoteId?: string;
  }

  useEffect(() => {
    setPageNumber(0);
    setDeliveries([]);
  }, [customerNumber]);

  const loadDeliveries = (
    loadMore: boolean,
    searchParams?: DeliverySearchFormikValues
  ): void => {
    // this check guards from firing multiple calls
    if (customerNumber && !loading) {
      setLoading(true);

      api
        .getAllDeliveries(
          customerNumber,
          loadMore ? pageNumber : 0,
          pageLength,
          true,
          searchParams?.pzn || undefined,
          searchParams?.fromDate || undefined,
          searchParams?.toDate || undefined,
          searchParams?.deliveryNoteId || undefined
        )
        .then((res) => {
          const newDeliveries = res.data;

          if (loadMore) {
            setDeliveries((existingDeliveries) => {
              return existingDeliveries.concat(newDeliveries);
            });
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          } else {
            setDeliveries(newDeliveries);
            setPageNumber(1);
          }
        })
        .catch((e) => {
          handleHttpError(e);
          setPageNumber(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const navigateToDetails = (stateValue: unknown[]) => {
    navigate("details", { state: stateValue });
  };

  const initialValues: DeliverySearchFormikValues = {
    pzn: "",
    fromDate: "",
    toDate: "",
    deliveryNoteId: "",
  };

  return (
    <TableContainer
      tableFilterProps={{
        initialValues: initialValues,
        searchFields: [
          {
            type: TableFilterType.textfield,
            name: "pzn",
            label: "Suche nach PZN",
          },
          {
            type: TableFilterType.textfield,
            name: "deliveryNoteId",
            label: "Lieferscheinnummer",
          },
          {
            type: TableFilterType.dateRange,
            name: "",
            label: "Zeitraum",
          },
        ],
      }}
      buttonAction={navigateToDetails}
      buttonText="Auswahl anzeigen"
      pageable
      loadCallback={loadDeliveries}
      loading={loading}
      Table={({ handleSelectRow, isSelected }) => (
        <DeliverySearchTable
          deliveries={deliveries}
          loading={loading}
          handleSelectRow={handleSelectRow}
          isSelected={isSelected}
        />
      )}
      selectableRows
    />
  );
};

export default DeliverySearchContainer;
