import { SxProps, Theme } from "@mui/material/styles";
import { aepPalette } from "../../../styles/aepPalette";

export const rootBoxStyles: SxProps<Theme> = {
  width: "100%",
  "& > div > div > svg > g > g > text": {
    // important to reach into inaccessible Nivo styles
    fontFamily: "Museo Sans !important",
  },
};

export const modalBoxStyles: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90vh",
  backgroundColor: aepPalette.secondary.lighter,
  borderRadius: "10px",
  padding: 4,
};

export const iconStyles: SxProps<Theme> = {
  position: "absolute",
  top: "16px",
  right: "16px",
  fontSize: "2rem",
};

export const headerStyles: SxProps<Theme> = {
  textAlign: "center",
  mt: 3,
  position: "absolute",
  top: "16px",
  width: "100%",
};

export const chartTheme = {
  textColor: aepPalette.font.main,
};
