import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, FormikErrors, FormikValues } from "formik";
import { useState, useEffect, useContext } from "react";
import { aepPalette } from "../../../../styles/aepPalette";
import CustomerContext from "../../../contexts/CustomerContext";
import { extractDatesFromPeriod } from "../../../service/CustomDateService";
import Card from "../../card/Card";
import DatePicker from "../../datepicker/DatePicker";
import { DatePickerDate } from "../../datepicker/DateRangePicker";
import SearchIcon from "../../icons/SearchIcon";
import ClearIcon from "@mui/icons-material/Clear";
import ChipsField from "../../textfield/ChipsField";
import {
  searchIconStyles,
  buttonStyles,
  cardStyles,
  cardInnerBoxStyles,
  textFieldStyles,
  dropDownStyles,
  searchFieldBoxStyles,
  buttonSecondaryStyles,
  clearIconStyles,
} from "./filterStyles";
import FilterTabsField from "./FilterTabsField";
import {
  TableFilterProps,
  TableFilterType,
  TableFilterSearchField,
  TableFilterMenuItem,
} from "./models";

const TableFilter = (props: TableFilterProps) => {
  const {
    initialValues,
    onSubmit,
    resetSearchParameters,
    searchFields,
    handleResetRows,
    triggerSubmit,
    handleResetFilter,
  } = props;
  const { customerNumber } = useContext(CustomerContext);

  const [dateRange, setDateRange] = useState<
    Date | DatePickerDate[] | undefined
  >();
  const [resetChildren, setResetChildren] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [currentsearchTerm, setCurrentSearchTerm] = useState<string>("");
  const [initialLoadComplete, setInitialLoadComplete] =
    useState<boolean>(false);

  const hasChips =
    searchFields.filter((item) => item.type === TableFilterType.chips).length >
    0;
  const hasDateRange =
    searchFields.filter((item) => item.type === TableFilterType.dateRange)
      .length > 0;
  const hasTabs =
    searchFields.filter((item) => item.type === TableFilterType.tabs).length >
    0;

  const triggerResetChildren = () => {
    if (!resetChildren) {
      setResetChildren(true);
      setTimeout(() => setResetChildren(false), 1000);
    }
  };

  const submitPageSearch = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    loadMore = false
  ) => {
    if (hasDateRange) {
      values.fromDate = extractDatesFromPeriod(dateRange).fromDate || "";
      values.toDate = extractDatesFromPeriod(dateRange).toDate || "";
    }
    if (hasTabs) {
      values.selectedTab = selectedTab;
    }
    if (hasChips) {
      values.searchTerms = searchTerms;

      if (currentsearchTerm) {
        values.searchTerms.push(currentsearchTerm);
        setCurrentSearchTerm("");
      }
    }

    onSubmit(loadMore, { ...values });

    if (handleResetRows) handleResetRows();

    setTimeout(() => setSubmitting(false), 300);
  };

  useEffect(() => {
    setInitialLoadComplete(true);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors: FormikErrors<any> = {};

        searchFields.forEach((searchField) => {
          const validationFunction = searchField.validate;

          if (validationFunction) {
            if (!validationFunction(values[searchField.name])) {
              errors[searchField.name] = searchField.errorMessage;
            }
          }
        });

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        submitPageSearch(values, setSubmitting);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        submitForm,
      }) => {
        const renderSearchField = (item: TableFilterSearchField, i: number) => {
          if (item.type === TableFilterType.chips) {
            return (
              <ChipsField
                key={`tabs-${i}`}
                searchTerms={searchTerms}
                setValues={setSearchTerms}
                setCurrentValue={setCurrentSearchTerm}
                currentValue={currentsearchTerm}
                label={item.label}
                submitForm={submitForm}
              />
            );
          }

          if (item.type === TableFilterType.textfield) {
            return (
              <TextField
                disabled={item.disabled}
                key={`text-field-${i}`}
                label={item.label}
                name={item.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors[item.name] && touched[item.name])}
                helperText={errors[item.name]}
                sx={textFieldStyles}
                value={values[item.name]}
              />
            );
          }

          if (item.type === TableFilterType.dropdown) {
            return (
              <FormControl key={`drop-down-${i}`} sx={dropDownStyles}>
                <InputLabel
                  id={`drop-down-${i}-select-label`}
                  sx={{ ml: -1.7, mt: 0.4 }}
                >
                  {item.label}
                </InputLabel>
                <Select
                  error={!!(errors[item.name] && touched[item.name])}
                  onChange={handleChange}
                  value={values[item.name]}
                  name={item.name}
                  variant="standard"
                >
                  {item.menuItems?.map((menuItem: TableFilterMenuItem) => (
                    <MenuItem key={menuItem.value} value={menuItem.value}>
                      {menuItem.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors[item.name] && (
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: aepPalette.error.main,
                    }}
                  >
                    {errors[item.name]}
                  </Typography>
                )}
              </FormControl>
            );
          }

          if (item.type === TableFilterType.dateRange) {
            return (
              <DatePicker
                key={`date-picker-${i}`}
                range
                reset={resetSearchParameters}
                setValue={setDateRange}
                label="Zeitraum"
              />
            );
          }

          if (item.type === TableFilterType.tabs) {
            return (
              <FilterTabsField
                key={`tabs-${i}`}
                selectedTab={selectedTab}
                setValue={setSelectedTab}
                label={item.label}
                menuItems={item.menuItems}
              />
            );
          }
        };

        const handleResetAll = () => {
          handleReset();
          setDateRange(undefined);
          setSelectedTab("");
          setCurrentSearchTerm("");
          setSearchTerms([]);
          triggerResetChildren();
          handleResetFilter();
        };

        // reset effect, resets controlled and uncontrolled (date range) search parameters
        useEffect(() => {
          handleResetAll();
        }, [customerNumber]);

        // submit form on tab change to refresh filters
        useEffect(() => {
          if (hasTabs && initialLoadComplete) submitForm();
        }, [selectedTab]);

        // submit form on terms change
        useEffect(() => {
          if (hasChips && initialLoadComplete) submitForm();
        }, [searchTerms]);

        // submit form on page change to persist search parameters
        useEffect(() => {
          if (triggerSubmit && initialLoadComplete)
            submitPageSearch(values, setSubmitting, true);
        }, [triggerSubmit]);

        const searchFieldsInCardArea = searchFields.filter(
          (item) => item.type !== "tabs"
        );

        return (
          <form onSubmit={handleSubmit}>
            <Box>
              <Card
                secondary
                innerBoxStyles={{
                  ...cardInnerBoxStyles,
                  justifyContent:
                    searchFieldsInCardArea.length > 2
                      ? "center"
                      : "space-between",
                }}
                sx={cardStyles}
              >
                <Box
                  sx={searchFieldBoxStyles(
                    searchFieldsInCardArea.length,
                    hasChips
                      ? {
                          gridTemplateColumns: { lg: "4fr 1fr", xs: "1fr" },
                          gridTemplateRows: { lg: "100%", xs: "50% 50%" },
                          columnGap: 5,
                        }
                      : undefined
                  )}
                >
                  {searchFields
                    .filter((item) => item.type !== "tabs")
                    .map((item, i) => {
                      return renderSearchField(item, i);
                    })}
                </Box>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  startIcon={
                    <SearchIcon color={"secondary"} sx={searchIconStyles} />
                  }
                  sx={buttonStyles}
                >
                  <Typography variant="buttonText">Suchen</Typography>
                </Button>
                <Button
                  type="reset"
                  disabled={isSubmitting}
                  sx={{
                    ...buttonSecondaryStyles,
                    "& > span:first-of-type": {
                      mr: 0,
                    },
                  }}
                  startIcon={
                    <ClearIcon color={"secondary"} sx={clearIconStyles} />
                  }
                  onClick={handleResetAll}
                >
                  <Typography variant="buttonText">Löschen</Typography>
                </Button>
              </Card>
              {searchFields
                .filter((item) => item.type === "tabs")
                .map((item, i) => {
                  return renderSearchField(item, i);
                })}
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default TableFilter;
