import { SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const employeesTimeout = 1000;

export const topBoxStyles: SxProps<Theme> = {
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  transition: `border ${employeesTimeout / 1000}s`,
};

// employee accordion
export const iconStyles: SxProps<Theme> = {
  color: aepPalette.base.main,
  "&:hover": { color: aepPalette.secondary.main },
};

// employee form
export const rootBoxStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.secondary.lighter,
  height: "100%",
  position: "relative",
  pt: 5,
  px: 8,
};

export const inputColumnStackStyles: SxProps<Theme> = {
  flexDirection: "column",
  mt: 1,
  width: "550px",
};

export const inputColumnStyles: SxProps<Theme> = {
  flexDirection: "row",
  justifyContent: "space-between",
};

export const formTextFieldStyles: SxProps<Theme> = {
  width: "220px",
  mb: 1,
};

// permission chips
export const expandMoreIconStyles: SxProps<Theme> = {
  width: "24px",
  height: "24px",
  position: "absolute",
  top: 7,
  right: 16,
  cursor: "pointer",
  transition: "transform 0.2s ease-in-out",
};

export const permissionChipDropdownBoxOuterStyles: SxProps<Theme> = {
  display: "inline-flex",
  width: "fit-content",
  flexDirection: "column",
};

export const permissionChipDropdownBoxInnerStyles: SxProps<Theme> = {
  display: "inline",
  position: "relative",
  width: "fit-content",
  "&:hover": {
    "& > svg": {
      color: aepPalette.base.lighter,
    },
  },
};
