import { SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const employeesTimeout = 1000;

export const topBoxStyles: SxProps<Theme> = {
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  transition: `border ${employeesTimeout / 1000}s`,
};

// employee accordion
export const iconStyles: SxProps<Theme> = {
  color: aepPalette.base.main,
  "&:hover": { color: aepPalette.secondary.main },
};

// employee form
export const rootBoxStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.secondary.lighter,
  height: "100%",
  position: "relative",
  py: 3,
  px: 4,
  border: `1px solid ${aepPalette.secondary.light}`,
  borderRadius: "10px",
  display: "flex",
  flexGrow: 1,
  flexDirection: {
    xs: "column",
    md: "row",
  },
  maxHeight: "470px",
  maxWidth: "650px",
  width: "100%",
};

export const inputColumnStackStyles: SxProps<Theme> = {
  flexDirection: "column",
  mt: 1,
  width: "80%",
};

export const inputColumnStyles: SxProps<Theme> = {
  flexDirection: "row",
  justifyContent: "space-between",
};

export const formTextFieldStyles: SxProps<Theme> = {
  width: "200px",
  mb: 1,
};
