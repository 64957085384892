import { useContext, useEffect, useState } from "react";
import { Button, Stack, Typography, AccordionDetails } from "@mui/material";
import TextField from "../../../components/textfield/TextField";
import { UserDto, UserDtoPermissionsEnum } from "../../../../api";
import { Formik, FormikErrors } from "formik";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import CustomerContext from "../../../contexts/CustomerContext";
import {
  formTextFieldStyles,
  inputColumnStackStyles,
  inputColumnStyles,
  rootBoxStyles,
} from "../employeesStyles";
import SnackbarContext from "../../../contexts/SnackbarContext";
import EmployeePermissions from "./EmployeePermissions";

interface EmployeeFormProps {
  employeeData: UserDto;
  newEmployee?: boolean;
  shown: boolean;
  userCreated?: (user: UserDto) => void;
}

interface LocalFormikValues extends UserDto {
  initialPasswordRepeat?: string;
}

const EmployeeForm = (props: EmployeeFormProps) => {
  const api = new ApiWrapper().userControllerApi();
  const { customerNumber } = useContext(CustomerContext);
  const { handleHttpError, showSnackbar, handleError } =
    useContext(SnackbarContext);
  const { shown, employeeData, newEmployee, userCreated } = props;
  const [permissions, setPermissions] = useState<UserDtoPermissionsEnum[]>([]);
  const pwFillerString = "******";

  const initialValues: LocalFormikValues = {
    title: employeeData.title || "",
    firstName: employeeData.firstName || "",
    lastName: employeeData.lastName || "",
    initialPassword: !newEmployee ? pwFillerString : "",
    initialPasswordRepeat: !newEmployee ? pwFillerString : "",
    email: employeeData.email || "",
    permissions: employeeData.permissions || [],
  };

  const loadUser = () => {
    if (employeeData.userName) {
      return api
        .getUser(customerNumber, employeeData.userName)
        .then((res) => {
          setPermissions(res.data.permissions || []);
        })
        .catch(() => {
          handleEmployeesError();
        });
    }
  };

  const handleEmployeesError = () => {
    handleError(
      "Aktuell ist die Anzeige der Mitarbeiter nicht möglich. Bitte kontaktieren Sie das Service Center."
    );
  };

  useEffect(() => {
    if (shown && permissions.length === 0) {
      loadUser();
    }
  }, [employeeData, shown]);

  return (
    <AccordionDetails sx={rootBoxStyles}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<LocalFormikValues> = {};
          const hasValidEmail = values.email
            ? /\S+@\S+\.\S+/.test(values.email)
            : true;

          if (!values.firstName) {
            errors.firstName = "Erforderlich";
          }
          if (!values.lastName) {
            errors.lastName = "Erforderlich";
          }
          if (!hasValidEmail) {
            errors.email = "Ungültige E-Mail-Adresse";
          }
          if (newEmployee && !values.initialPassword) {
            errors.initialPassword = "Erforderlich";
          }
          if (values.initialPassword !== values.initialPasswordRepeat) {
            errors.initialPasswordRepeat = "Passwort stimmt nicht überein";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          values.permissions = permissions;

          if (values.initialPassword === pwFillerString) {
            values.initialPassword = "";
          }
          const apiValues = Object.assign({}, values);
          delete apiValues.initialPasswordRepeat;

          if (newEmployee) {
            api
              .createUser(customerNumber, apiValues)
              .then((res) => {
                showSnackbar("success", "Benutzer erfolgreich erstellt");
                if (userCreated) {
                  apiValues.userName = res.data.userName;
                  apiValues.initialPassword = "";
                  userCreated(apiValues);
                }
              })
              .catch(handleHttpError)
              .finally(() => {
                setSubmitting(false);
              });
          } else if (employeeData.userName) {
            api
              .updateUser(customerNumber, employeeData.userName, apiValues)
              .then(() => {
                showSnackbar("success", "Benutzer erfolgreich aktualisiert");
                if (values.initialPassword === "") {
                  values.initialPassword = pwFillerString;
                  values.initialPasswordRepeat = pwFillerString;
                }
              })
              .catch(handleHttpError)
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="body1" color="font.main" sx={{ pb: 2 }}>
              Profil Ihres Mitarbeiters
            </Typography>
            <Stack sx={inputColumnStackStyles}>
              <Stack sx={inputColumnStyles}>
                <TextField
                  fullWidth
                  sx={formTextFieldStyles}
                  error={!!(errors.title && touched.title)}
                  label="Titel"
                  name="title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                />
              </Stack>
              <Stack sx={inputColumnStyles}>
                <TextField
                  fullWidth
                  sx={formTextFieldStyles}
                  error={!!(errors.firstName && touched.firstName)}
                  helperText={errors.firstName}
                  label="Vorname*"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                />
                <TextField
                  fullWidth
                  sx={formTextFieldStyles}
                  error={!!(errors.lastName && touched.lastName)}
                  label="Nachname*"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                />
              </Stack>
              <Stack sx={inputColumnStyles}>
                <TextField
                  fullWidth
                  sx={formTextFieldStyles}
                  label="Benutzername"
                  helperText="automatisch generiert"
                  disabled
                  value={employeeData.userName}
                />
                <TextField
                  fullWidth
                  sx={formTextFieldStyles}
                  error={!!(errors.email && touched.email)}
                  helperText={errors.email}
                  label="E-Mail-Adresse"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
              </Stack>
              <Stack sx={inputColumnStyles}>
                <TextField
                  fullWidth
                  sx={formTextFieldStyles}
                  helperText={touched.initialPassword && errors.initialPassword}
                  inputProps={{ type: "password" }}
                  error={!!(errors.initialPassword && touched.initialPassword)}
                  label="Passwort*"
                  name="initialPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.initialPassword}
                />
                <TextField
                  fullWidth
                  sx={formTextFieldStyles}
                  helperText={errors.initialPasswordRepeat}
                  inputProps={{ type: "password" }}
                  error={
                    !!(
                      errors.initialPasswordRepeat &&
                      touched.initialPasswordRepeat
                    )
                  }
                  label="Passwort wiederholen*"
                  name="initialPasswordRepeat"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.initialPasswordRepeat}
                />
              </Stack>
            </Stack>
            <EmployeePermissions
              permissions={permissions}
              setPermissions={setPermissions}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              sx={{ mt: 1, mb: 4, float: "right" }}
            >
              {isSubmitting ? "In Arbeit..." : "Speichern"}
            </Button>
          </form>
        )}
      </Formik>
    </AccordionDetails>
  );
};

export default EmployeeForm;
