import { useState, useEffect, useContext, useRef, RefObject } from "react";
import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import {
  avatarStyles,
  containerStyles,
  greetingStyles,
  greetingStylesTablet,
  iconStackStyles,
  imageStyles,
  leftContainerStyles,
  rightContainerStyles,
  rootStyles,
  textDividerStyles,
} from "./appBarStyles";
import { TextDivider } from "../other/TextDivider";
import MailPhoneIcon from "../icons/MailPhoneIcon";
import MailIcon from "../icons/MailIcon";
import { aepPalette } from "../../../styles/aepPalette";
import PharmacyPicker from "./elements/PharmacyPicker";
import UserContext from "../../contexts/UserContext";
import CustomerContext from "../../contexts/CustomerContext";
import { UserDtoPermissionsEnum } from "../../../api";
import UserMenu from "./elements/UserMenu";
import AepLogo from "../../../assets/images/AEP-Logo-mit-claim-RGB.svg";
import ImageWrapper from "../../wrappers/ImageWrapper";
import IconButton from "../button/IconButton";
import { CustomBadge } from "./CustomBadge";
import ApiWrapper from "../../wrappers/ApiWrapper";
import sanitizeHtml from "sanitize-html";
import Counter from "./elements/Counter";

interface AppBarProps {
  bodyRef: React.MutableRefObject<HTMLElement | undefined>;
}

const AppBar = (props: AppBarProps) => {
  const counterApi = new ApiWrapper().counterControllerApi();
  const messageApi = new ApiWrapper().messageControllerApi();

  const { customerNumber, unreadMessageCount, updateUnreadMessageCount } =
    useContext(CustomerContext);
  const { bodyRef } = props;
  const { keycloak } = useKeycloak();
  const { checkUserRole } = useContext(UserContext);
  const [greeting, setGreeting] = useState("");
  const [boxShadow, setBoxShadow] = useState(false);
  const [sanitizedCounterModalHtml, setSanitizedCounterModalHtml] =
    useState<string>("");
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const avatarRef = useRef<HTMLElement>();

  const navigate = useNavigate();

  const formatInitials = () => {
    const initials = [
      keycloak.tokenParsed?.given_name.charAt(0),
      keycloak.tokenParsed?.family_name.charAt(0),
    ];

    return initials.join("");
  };

  const user = {
    initials: formatInitials(),
    name: keycloak.tokenParsed?.name,
    title: keycloak.tokenParsed?.title,
  };

  const renderGreeting = (tablet?: boolean) => {
    return (
      <Box sx={tablet ? greetingStylesTablet : greetingStyles}>
        <Typography variant="h2" sx={{ whiteSpace: "nowrap" }}>
          {greeting} {user.title && `${user.title} `}
          {user.name},
        </Typography>
        <Typography variant="subline" sx={{ whiteSpace: "nowrap" }}>
          was können wir heute für Sie tun?
        </Typography>
      </Box>
    );
  };

  const toggleUserMenuOpen = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const generateGreeting = (): string => {
    const localTime = new Date().getHours();

    if (localTime <= 10) {
      return "Guten Morgen";
    } else if (localTime <= 18) {
      return "Guten Tag";
    } else {
      return "Guten Abend";
    }
  };

  const addScrollEventToBody = (): void => {
    bodyRef.current?.addEventListener("scroll", () => {
      if (bodyRef.current?.scrollTop !== 0) {
        setBoxShadow(true);
      } else {
        setBoxShadow(false);
      }
    });
  };

  useEffect(() => {
    setGreeting(generateGreeting());
    addScrollEventToBody();
  }, []);

  useEffect(() => {
    updateUnreadMessageCount(0);
    setSanitizedCounterModalHtml("");

    if (customerNumber) {
      messageApi.getUnreadMessageCount(customerNumber).then((res) => {
        updateUnreadMessageCount(res.data.count);
      });

      counterApi.getCounter(customerNumber).then((res) => {
        const sanitizedHTML = sanitizeHtml(res.data.content || "", {
          allowedTags: ["b", "br"],
        });

        setSanitizedCounterModalHtml(sanitizedHTML);
      });
    }
  }, [customerNumber]);

  return (
    <MuiAppBar
      position="relative"
      color="transparent"
      sx={{
        ...rootStyles,
        boxShadow: boxShadow
          ? `0 0.3rem 0.7rem ${aepPalette.secondary.light}`
          : "none",
      }}
    >
      <Stack sx={containerStyles}>
        <Stack sx={leftContainerStyles}>
          <Stack direction="row">
            <ImageWrapper
              src={AepLogo}
              alt="AEP Logo"
              sx={imageStyles}
              objectTagType="image/svg+xml"
            />
            {renderGreeting()}
          </Stack>
          <Box sx={rightContainerStyles}>
            <Stack
              direction="row"
              sx={iconStackStyles(!!sanitizedCounterModalHtml)}
            >
              <PharmacyPicker />
              <IconButton
                icon={<MailPhoneIcon />}
                onClick={() => navigate("kontakt")}
              />
              <TextDivider sx={textDividerStyles} />
              {checkUserRole([UserDtoPermissionsEnum.Postfach]) && (
                <IconButton
                  icon={
                    unreadMessageCount > 0 ? (
                      <CustomBadge badgeContent={unreadMessageCount}>
                        <MailIcon color="action" />
                      </CustomBadge>
                    ) : (
                      <MailIcon color="action" />
                    )
                  }
                  onClick={() => navigate("postfach")}
                />
              )}
              {checkUserRole([UserDtoPermissionsEnum.Analysen]) &&
              !!sanitizedCounterModalHtml ? (
                <Counter sanitizedModalHtml={sanitizedCounterModalHtml} />
              ) : (
                <></>
              )}
              <Avatar
                ref={avatarRef as RefObject<HTMLDivElement>}
                onClick={toggleUserMenuOpen}
                sx={avatarStyles}
              >
                {user.initials}
              </Avatar>
              <UserMenu
                anchorEl={avatarRef.current}
                onClose={() => setUserMenuOpen(false)}
                open={userMenuOpen}
              />
            </Stack>
          </Box>
        </Stack>
        {renderGreeting(true)}
      </Stack>
    </MuiAppBar>
  );
};

export default AppBar;
