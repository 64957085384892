import {
  Box,
  Card as MuiCard,
  CardProps as MuiCardProps,
  Fade,
  LinearProgress,
  SxProps,
  Theme,
  Grow,
} from "@mui/material";
import { RefObject } from "react";
import { aepPalette } from "../../../styles/aepPalette";
import { fadeTimeout, growTimeout } from "../../App";

export interface CardProps extends MuiCardProps {
  shrink?: boolean;
  image?: string;
  initialLoadingComplete?: boolean;
  innerBoxStyles?: SxProps<Theme>;
  innerRef?: RefObject<HTMLElement>;
  loading?: boolean;
  // indicates whether card is the smaller variant, does not correspond to theme.palette.secondary
  secondary?: boolean;
  sx?: SxProps<Theme>;
  to?: string;
}

const Card = (props: CardProps): JSX.Element => {
  const {
    children,
    shrink,
    image,
    initialLoadingComplete,
    innerBoxStyles,
    secondary,
    sx,
    id,
    innerRef,
    loading,
  } = props;

  return (
    <Grow in timeout={growTimeout}>
      <MuiCard
        ref={innerRef as RefObject<HTMLDivElement>}
        id={id}
        sx={{
          bgcolor: secondary ? aepPalette.common.white : "secondary.lighter",
          minHeight: secondary ? "53px" : "0px",
          height: shrink ? "auto" : "100%",
          "& > div": {
            border: "none",
            position: "relative",
            width: "100%",
          },
          display: "flex",
          ...sx,
        }}
      >
        <Fade
          in={!secondary && loading}
          timeout={fadeTimeout}
          mountOnEnter
          unmountOnExit
        >
          <LinearProgress
            color="secondary"
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
              left: 0,
              zIndex: 5,
            }}
          />
        </Fade>
        {image ? (
          <img
            aria-label="card thumbnail"
            alt="card-thumbnail"
            src={image}
            style={{
              objectFit: "cover",
              height: "101%",
              width: "auto",
              overflow: "hidden",
              transform: "translateY(-2px)",
            }}
          />
        ) : (
          <></>
        )}
        <Fade
          in={initialLoadingComplete ? initialLoadingComplete : !loading}
          timeout={fadeTimeout}
        >
          <Box sx={innerBoxStyles}>{children}</Box>
        </Fade>
      </MuiCard>
    </Grow>
  );
};

export default Card;
