import IconButton from "../button/IconButton";
import DetialViewIcon from "../icons/DetailViewIcon";

const IconButtonDetailView = (props: { onClick: () => void }) => {
  const { onClick } = props;
  return (
    <IconButton
      icon={
        <DetialViewIcon sx={{ height: "2rem", transform: "translateY(2px)" }} />
      }
      onClick={onClick}
    />
  );
};

export default IconButtonDetailView;
