import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const CollaborationIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 22 19" {...props}>
    <path d="M4.51827 9.78699C3.60397 8.63315 3.03644 6.97261 3.03644 5.36303C3.03644 2.40599 4.9294 0 7.2562 0C9.00901 0 10.5146 1.36763 11.1505 3.30679C11.643 3.05051 12.1801 2.90666 12.7442 2.90666C15.0702 2.90666 16.9634 5.31265 16.9634 8.26969C16.9634 9.87927 16.3958 11.5402 15.4815 12.6936C18.0081 13.7123 19.8309 16.0329 19.9989 18.5348C20.007 18.6549 19.9691 18.7733 19.8944 18.8614C19.8197 18.9494 19.7145 18.9998 19.6043 18.9998H10.5744V18.1332L19.155 18.1334C18.7936 15.996 16.9659 14.0129 14.6413 13.3073C14.5013 13.2652 14.3937 13.1415 14.361 12.9858C14.3291 12.83 14.3775 12.6675 14.4873 12.5633C15.5109 11.5886 16.1722 9.90282 16.1722 8.27009C16.1722 5.79076 14.6341 3.77356 12.7443 3.77356C10.8539 3.77356 9.31576 5.7907 9.31576 8.27009C9.31576 9.90282 9.9771 11.588 11.0007 12.5633C11.1108 12.6677 11.1594 12.8302 11.127 12.9858C11.0952 13.1415 10.9876 13.265 10.8467 13.3073C8.52214 14.0129 6.69408 15.9966 6.33352 18.1334H8.86005V19H5.88391C5.77427 19 5.66923 18.9498 5.59436 18.8616C5.51911 18.7735 5.48141 18.6551 5.48931 18.535C5.54652 17.685 5.79782 16.8571 6.20016 16.0929H0.395763C0.285572 16.0929 0.181078 16.0427 0.105652 15.9545C0.0309639 15.8665 -0.00730133 15.748 0.00115776 15.6279C0.16783 13.1262 1.99071 10.8056 4.51836 9.78675L4.51827 9.78699ZM6.74089 15.2263C7.54187 14.1307 8.67454 13.2311 10.006 12.6938C9.09175 11.5402 8.52422 9.87941 8.52422 8.26983C8.52422 6.38524 9.29482 4.72749 10.4547 3.77088C9.96333 2.07666 8.71773 0.866038 7.25596 0.866038C5.36554 0.866038 3.82744 2.88318 3.82744 5.36256C3.82744 6.9953 4.48878 8.68065 5.51234 9.65577C5.62198 9.76013 5.67055 9.92273 5.63872 10.0783C5.60634 10.234 5.49872 10.3575 5.35836 10.3998C3.03326 11.1054 1.20576 13.0891 0.844639 15.2259H6.74087L6.74089 15.2263Z" />
  </IconWrapper>
);

export default CollaborationIcon;
