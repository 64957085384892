import { Box, Skeleton, Stack, Typography, Fade } from "@mui/material";
import {
  itemHeaderStyles,
  itemImageWrapperStyles,
  itemRootStackStyles,
  itemTeaserTextStyles,
} from "./newsStyles";

interface NewsSkeletonProps {
  fadeIn?: boolean;
  timeout?: number;
}

const NewsSkeleton = (props: NewsSkeletonProps) => {
  const { fadeIn, timeout } = props;

  return (
    // fade out only
    <Fade in={fadeIn} timeout={fadeIn ? 0 : timeout}>
      <Stack
        sx={{
          ...itemRootStackStyles,
          maxWidth: {
            xs: "237px",
            md: "800px",
          },
          overflow: "hidden",
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={itemImageWrapperStyles}
        />
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              width: {
                xs: "237px",
                md: "460px",
              },
              ml: {
                xs: "",
                md: 2,
              },
            }}
          >
            <Skeleton variant="text" animation="wave" sx={{ width: "80px" }} />
            <Typography
              variant="h2"
              sx={{ ...itemHeaderStyles, width: "170px" }}
            >
              <Skeleton variant="text" animation="wave" />
            </Typography>
            <Typography
              variant="h4"
              sx={{ ...itemTeaserTextStyles, width: "100%" }}
            >
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "100%" }}
              />
            </Typography>
          </Box>
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              position: "absolute",
              bottom: 5,
              width: "120px",
              ml: { xs: 0, md: 2 },
            }}
          />
        </Box>
      </Stack>
    </Fade>
  );
};

export default NewsSkeleton;
