import { useContext, useState } from "react";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import TableContainer from "../../../components/table/TableContainer";
import SnackbarContext from "../../../contexts/SnackbarContext";
import TableFilter from "../../../components/table/tableFilter/TableFilter";
import { TableFilterType } from "../../../components/table/tableFilter/models";
import CustomerContext from "../../../contexts/CustomerContext";
import {
  CancelDto,
  DispensationDto,
  DispensationPositionDto,
} from "../../../../api";
import TableAccordionWrapper from "../../../components/table/TableAccordionWrapper";
import TableAccordionRow from "../../../components/table/TableAccordionRow";
import DispensationPositionsTable from "../positions/DispensationPositionsTable";
import {
  ddmmyyyyFormat,
  utcToGermanDateString,
} from "../../../service/CustomDateService";

const DispensationsSearchContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dispensations, setDispensations] = useState<DispensationDto[]>([]);
  const [shownDispensations, setShownDispensations] = useState<
    DispensationDto[]
  >([]);
  const [selectedDispensationPositions, setSelectedDispensationPositions] =
    useState<string[]>([]);
  const { handleHttpError, handleCancelOrderPositions } =
    useContext(SnackbarContext);

  const { customerNumber } = useContext(CustomerContext);

  const api = new ApiWrapper().dispensationsControllerApi();
  const cancelApi = new ApiWrapper().ordersControllerApi();

  interface DispensationSearchFormikValues {
    pzn?: string;
  }

  const initialValues: DispensationSearchFormikValues = {
    pzn: "",
  };

  const sortByPZN = (
    loadMore: boolean,
    searchParams?: DispensationSearchFormikValues
  ) => {
    if (
      typeof searchParams !== "undefined" &&
      typeof searchParams.pzn !== "undefined" &&
      searchParams.pzn.length > 0
    ) {
      const filterPzn =
        (searchPzn: string) =>
        (dispensationPosition: DispensationPositionDto) =>
          dispensationPosition.pzn === searchPzn;
      const newShownDispensations: DispensationDto[] = dispensations
        .filter((dispensation) =>
          dispensation.dispensationPositions?.some(
            filterPzn(searchParams.pzn as string)
          )
        )
        .map((dispensation) => ({
          ...dispensation,
          dispensationPositions: dispensation.dispensationPositions?.filter(
            filterPzn(searchParams.pzn as string)
          ),
        }));
      setShownDispensations(newShownDispensations);
    } else {
      setShownDispensations(dispensations);
    }
  };

  const loadDispensations = (): void => {
    if (customerNumber && !loading) {
      setLoading(true);

      api
        .getAllDispensations(customerNumber)
        .then((res) => {
          setDispensations(res.data);
          setShownDispensations(res.data);
        })
        .catch((e) => handleHttpError(e))
        .finally(() => setLoading(false));
    }
  };

  const generateCancelDtoObjects = (): CancelDto[] => {
    const dispensationIds = selectedDispensationPositions.map(
      (dispensationPosition) => dispensationPosition.split("-")[0]
    );

    const selectedDispensationsData = dispensations.filter((dispensation) =>
      dispensationIds.includes(dispensation.dispensationId)
    );

    const dispensationsToCancel: CancelDto[] = [];

    selectedDispensationsData.forEach((dispensationData) => {
      dispensationData.dispensationPositions?.forEach(
        (dispensationPosition) => {
          const cancelDtoObj = {
            orderId: dispensationPosition.positionId.split("-")[0],
            orderPosition: dispensationPosition.positionId,
            pzn: dispensationPosition.pzn,
          };

          if (
            selectedDispensationPositions.includes(cancelDtoObj.orderPosition)
          ) {
            dispensationsToCancel.push(cancelDtoObj);
          }
        }
      );
    });

    return dispensationsToCancel;
  };

  const onCancelSuccess = () => {
    setSelectedDispensationPositions([]);
    setLoading(false);
  };

  const cancelDispensationPositions = (): void => {
    if (customerNumber && !loading) {
      setLoading(true);

      const dispensationCancelDtos = generateCancelDtoObjects();
      cancelApi
        .cancelOrderPositions(customerNumber, dispensationCancelDtos)
        .then((res) => {
          // 200 always returned, even for errors
          // if the data array is empty, there are no errors
          handleCancelOrderPositions(
            dispensationCancelDtos,
            res.data,
            onCancelSuccess
          );
        })
        .catch(handleHttpError)
        .finally(() => {
          loadDispensations();
        });
    }
  };

  return (
    <TableContainer
      buttonAction={cancelDispensationPositions}
      buttonText="Auswahl stornieren"
      liftStateToParent={setSelectedDispensationPositions}
      loadCallback={loadDispensations}
      loading={loading}
      tableFilterProps={{
        initialValues: initialValues,
        searchFields: [
          {
            type: TableFilterType.textfield,
            name: "pzn",
            label: "Suche nach PZN",
          },
        ],
        loadCallbackOverride: sortByPZN,
      }}
      Table={({
        handleSelectRow,
        isSelected,
        handleSelectAll,
        allSelected,
      }) => (
        <TableAccordionWrapper tableHeader="Bestellungen">
          {shownDispensations.map((dispensation, index) => {
            return (
              <TableAccordionRow
                key={`dispensation-list-item-${index}`}
                header={`${utcToGermanDateString(
                  dispensation.orderDate
                )}: Auftrag ${dispensation.dispensationId}, Auftragskennung: ${
                  dispensation.label
                }${
                  dispensation.expiryDate
                    ? `, Ablaufdatum: ${ddmmyyyyFormat(
                        new Date(dispensation.expiryDate)
                      )}`
                    : ""
                }`}
              >
                <DispensationPositionsTable
                  allSelected={allSelected}
                  isSelected={isSelected}
                  handleSelectRow={handleSelectRow}
                  handleSelectAll={handleSelectAll as (ids: string[]) => void}
                  positions={dispensation.dispensationPositions}
                />
              </TableAccordionRow>
            );
          })}
        </TableAccordionWrapper>
      )}
      selectableRows
    />
  );
};

export default DispensationsSearchContainer;
