import { Box } from "@mui/material";
import { TabableCard, CardTabContent } from "../../components/card/TabableCard";
import { rootBoxStyles } from "./deliveriesStyles";
import DeliverySearchContainer from "./elements/DeliverySearchContainer";
import AnalysesCard, { AnalysisData } from "../../components/card/AnalysesCard";
import ReturnSearchContainer from "./elements/ReturnSearchContainer";

const Deliveries = () => {
  return (
    <Box sx={rootBoxStyles}>
      <TabableCard
        sx={{
          gridRow: "1 / 14",
          gridColumn: "1 / 9",
        }}
      >
        <CardTabContent id="deliveries" name="Lieferungen" state={2}>
          <DeliverySearchContainer />
        </CardTabContent>
        <CardTabContent id="returned" name="Retoure" state={1}>
          <ReturnSearchContainer />
        </CardTabContent>
      </TabableCard>
      <AnalysesCard
        dataSet={[AnalysisData.PACKMON, AnalysisData.UMSMON]}
        sx={{ gridRow: "1 / 14", gridColumn: "9 / 13" }}
      />
    </Box>
  );
};

export default Deliveries;
