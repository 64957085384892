import { Box, Typography } from "@mui/material";
import Offer from "../../../strapi/models/Offer";
import ImageWrapper from "../../wrappers/ImageWrapper";
import {
  gridContainerStyles,
  typographyInfoStyles,
  imageWrapperContainerStyles,
  imageStyles,
  infoBoxOneStyles,
  infoBoxTwoStyles,
  teaserTextBoxStyles,
  teaserTextStyles,
} from "./offersStyles";

interface OfferCardContentProps {
  baseUrl: string;
  halfWidth?: boolean;
  highlight?: boolean;
  offerAttributes: Offer;
}

const OfferCardContent = (props: OfferCardContentProps) => {
  const { offerAttributes, baseUrl, halfWidth, highlight } = props;
  const {
    artikelName,
    bild,
    mindestmenge,
    packungsgroesse,
    preis,
    pzn,
    rabatt,
    teasertext,
    titel,
  } = offerAttributes;
  const { url } = bild.data.attributes;
  const teaserTextShown = teasertext && highlight;

  return (
    <>
      <Typography variant="h2" px={3} pt={3}>
        {titel}
      </Typography>
      <Box
        sx={{
          ...gridContainerStyles,
          gridTemplateColumns: {
            xs: "repeat(2, 50%)",
            lg: halfWidth ? "repeat(2, 50%)" : "repeat(3, 33%)",
          },
          gridTemplateRows: teaserTextShown
            ? "repeat(3, auto)"
            : "repeat(2, auto)",
          pt: highlight ? 5 : 0,
          pb: 0,
        }}
      >
        <ImageWrapper
          src={`${baseUrl}/contentmanager${url}`}
          alt="Angebot Bild"
          containerSx={{
            ...imageWrapperContainerStyles,
            gridRow: {
              xs: "1 / 2",
              lg: teaserTextShown ? "1 / 3" : "1 / 2",
            },
            height: halfWidth ? "150px" : "inherit",
          }}
          sx={{
            ...imageStyles,
            maxWidth: {
              xs: halfWidth ? "130px" : "220px",
              xl: halfWidth ? "170px" : "220px",
            },
          }}
        />
        {teaserTextShown ? (
          <Box sx={teaserTextBoxStyles}>
            <Typography sx={teaserTextStyles}>{teasertext}</Typography>
          </Box>
        ) : (
          <></>
        )}
        <Box
          sx={{
            ...infoBoxOneStyles,
            gridRow: teaserTextShown ? "2 / 3" : "1 / 2",
            pt: teaserTextShown ? 4 : 0,
          }}
        >
          <Typography
            variant="h2"
            color="primary"
            sx={{ whiteSpace: "nowrap" }}
          >
            {`${rabatt} % Rabatt*`}
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            sx={{ whiteSpace: "nowrap" }}
          >
            {`${preis} € pro Stück`}
          </Typography>

          <Typography
            sx={{ ...typographyInfoStyles, pt: 2, whiteSpace: "nowrap" }}
          >{`Ab einer Menge von ${mindestmenge} Stück`}</Typography>
        </Box>
        <Box
          sx={{
            ...infoBoxTwoStyles,
            gridColumn: {
              xs: "2 / 3",
              lg: halfWidth ? "2 / 3" : "3 / 4",
            },
            gridRow: halfWidth
              ? "2 / 3"
              : {
                  xs: teaserTextShown ? "3 / 4" : "2 / 3",
                  lg: teaserTextShown ? "2 / 3" : "1 / 2",
                },
            pt: teaserTextShown ? 4 : 0,
          }}
        >
          <Typography sx={typographyInfoStyles}>{artikelName}</Typography>
          <Typography sx={typographyInfoStyles}>{packungsgroesse}</Typography>
          <Typography sx={typographyInfoStyles}>PZN {pzn}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default OfferCardContent;
