/* eslint-disable @typescript-eslint/ban-ts-comment */
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material";
import { deDE } from "@mui/material/locale";
import { aepPalette } from "./aepPalette";
import { aepTypography } from "./aepTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import museoSans_W01_300 from "../assets/fonts/museoSans_W01_300.woff";
import museoSans_W01_300_Italic from "../assets/fonts/museoSans_W01_300_Italic.woff";
import museoSans_W01_500 from "../assets/fonts/museoSans_W01_500.woff";
import museoSans_W01_500_Italic from "../assets/fonts/museoSans_W01_500_Italic.woff";
import museoSans_W01_900 from "../assets/fonts/museoSans_W01_900.woff";
import museoSans_W01_900_Italic from "../assets/fonts/museoSans_W01_900_Italic.woff";

export const maxWidth = "1920px";

export const theme = createTheme(
  {
    palette: aepPalette,
    typography: aepTypography,
    spacing: (factor: number) => `${0.8 * factor}rem`,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1100,
        xl: 1300,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        html {
          font-size: 62.5%;
        }
        body {
          font-size: 1.4rem;
          max-width: ${maxWidth};
          margin: auto;
        }
        a {
          text-decoration: none;
        }
        @font-face {
          font-family: 'Museo Sans';
          font-style: normal;
          font-weight: 300;
          src: url(${museoSans_W01_300}) format('woff');
        }
        @font-face {
          font-family: 'Museo Sans';
          font-style: italic;
          font-weight: 300;
          src: url(${museoSans_W01_300_Italic}) format('woff');
        }
        @font-face {
          font-family: 'Museo Sans';
          font-style: normal;
          font-weight: 500;
          src: url(${museoSans_W01_500}) format('woff');
        }
        @font-face {
          font-family: 'Museo Sans';
          font-style: italic;
          font-weight: 500;
          src: url(${museoSans_W01_500_Italic}) format('woff');
        }
        @font-face {
          font-family: 'Museo Sans';
          font-style: normal;
          font-weight: 900;
          src: url(${museoSans_W01_900}) format('woff');
        }
        @font-face {
          font-family: 'Museo Sans';
          font-style: italic;
          font-weight: 900;
          src: url(${museoSans_W01_900_Italic}) format('woff');
        }
      `,
      },
      MuiAccordion: {
        defaultProps: {
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            boxShadow: "none",
            "&::before": {
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
          },
        },
      },
      MuiAccordionSummary: {
        defaultProps: {
          expandIcon: <ExpandMoreIcon fontSize="large" />,
        },
        styleOverrides: {
          root: {
            borderTop: `1px solid ${aepPalette.secondary.light}`,
            cursor: "pointer",
            flexDirection: "row-reverse",
            width: "100%",
            "&.Mui-expanded": {
              backgroundColor: aepPalette.secondary.light,
            },
            "&:hover, &:active": {
              backgroundColor: aepPalette.secondary.light,
            },
          },
          content: {
            alignItems: "center",
            justifyContent: "space-between",
            margin: 0,
          },
          expandIconWrapper: {
            transform: "rotate(-90deg)",
            "&.Mui-expanded": {
              transform: "rotate(0deg)",
            },
          },
        },
      },
      MuiAlert: {
        defaultProps: {
          variant: "filled",
        },
        styleOverrides: {
          message: {
            display: "flex",
            alignItems: "center",
            "& > div > p": {
              color: `${aepPalette.common.white}`,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            flexDirection: "row",
            margin: "0 auto",
            maxWidth: maxWidth,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "primary",
        },
        styleOverrides: {
          root: {
            backgroundColor: aepPalette.primary.main,
            border: `1px solid ${aepPalette.primary.main}`,
            borderRadius: "200px",
            boxShadow: "none",
            color: aepPalette.common.white,
            fontSize: "1.3rem",
            lineHeight: "1.8",
            padding: "0.8rem 3.6rem",
            textTransform: "none",
            height: "3rem",
            "&:active": {
              boxShadow: "0rem 0.4rem 0.6rem rgba(0, 0, 0, 0.1)",
            },
            "&:hover": {
              backgroundColor: `${aepPalette.primary.lighter}`,
            },
            "&:disabled": {
              backgroundColor: "#B1B0B0",
              color: aepPalette.common.white,
              borderColor: "#B1B0B0",
            },
          },
        },
        variants: [
          {
            props: { variant: "primary" },
            style: {
              backgroundColor: aepPalette.primary.main,
              color: aepPalette.common.white,
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: aepPalette.primary.dark,
              },
              "&:active": {
                backgroundColor: aepPalette.primary.dark,
                boxShadow: "inset 0px 0 px 5px black",
              },
              "&.Mui-disabled": {
                border: `1px solid ${aepPalette.base.light}`,
              },
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              backgroundColor: "white",
              border: `1px solid ${aepPalette.primary.light}`,
              color: aepPalette.font.dark,
              "&:hover, &.Mui-focusVisible": {
                backgroundColor: aepPalette.base.lighter,
                border: `1px solid ${aepPalette.base.lighter}`,
              },
              "&:active": {
                border: `1px solid ${aepPalette.primary.light}`,
              },
              "&.Mui-disabled": {
                border: `1px solid ${aepPalette.primary.lighter}`,
              },
            },
          },
        ],
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: `1px solid ${aepPalette.secondary.light}`,
            boxShadow: "none",
            position: "relative",
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "secondary",
        },
        styleOverrides: {
          root: {
            padding: 0,
            svg: {
              height: "1.8rem",
              width: "1.8rem",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: aepPalette.secondary.light,
            color: aepPalette.secondary.main,
            cursor: "pointer",
            fontSize: "1.3rem",
            fontWeight: 400,
            px: 1.5,
            transition: "background-color 0.2s",
            "&:active": {
              backgroundColor: aepPalette.secondary.light,
              boxShadow: "none",
            },
            "&:hover": {
              backgroundColor: aepPalette.secondary.dark,
              boxShadow: "none",
              color: aepPalette.common.white,
            },
          },
          icon: {
            color: aepPalette.common.white,
            transform: "translateY(0.2rem)",
          },
          deleteIcon: {
            fontSize: "16px",
            color: aepPalette.base.light,
            "&:hover": {
              color: aepPalette.common.white,
            },
          },
        },
        variants: [
          {
            props: { variant: "checked" },
            style: {
              backgroundColor: aepPalette.secondary.dark,
              color: aepPalette.common.white,
            },
          },
          {
            props: { variant: "term" },
            style: {
              backgroundColor: aepPalette.secondary.main,
              color: aepPalette.common.white,
              "&:hover": {
                backgroundColor: aepPalette.secondary.main,
                color: aepPalette.common.white,
              },
            },
          },
        ],
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: "0px !important",
          },
        },
      },
      MuiInput: {
        defaultProps: {
          color: "secondary",
        },
        styleOverrides: {
          root: {
            color: aepPalette.common.black,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: aepPalette.base.light,
            "&.Mui-focused": {
              color: `${aepPalette.secondary.dark} !important`,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: `${aepPalette.other.transparentBlue2} !important`,
            },
          },
        },
      },
      MuiMobileStepper: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: aepPalette.font.main,
          },
          dotActive: {
            backgroundColor: aepPalette.secondary.main,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            backgroundColor: aepPalette.other.transparentBlue,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontSize: "1.2rem",
            margin: "2px !important",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            marginTop: "8px",
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          inputProps: {
            style: {
              transform: "translateX(50px)",
            },
          },
        },
        styleOverrides: {
          standard: {
            backgroundColor: "transparent",
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            alignItems: "center",
            color: aepPalette.common.white,
            display: "flex",
            flexDirection: "column",
            whiteSpace: "pre-wrap",
            width: "100vw",
          },
        },
        defaultProps: {
          autoHideDuration: 3000,
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            maxWidth: "100%",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: "separate",
          },
        },
      },
      MuiTableRow: {
        defaultProps: {
          hover: true,
        },
        styleOverrides: {
          root: {
            position: "relative",
            "&:hover": {
              // important to overcome tr hover complications
              backgroundColor: `${aepPalette.secondary.light} !important`,
            },
          },
          hover: {},
        },
      },
      MuiTableCell: {
        defaultProps: {
          padding: "none",
        },
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${aepPalette.secondary.light}`,
            color: "inherit",
            fontSize: "1.3rem",
            fontWeight: "300",
            position: "relative",
          },
          head: {
            paddingTop: "16px",
            paddingBottom: "16px",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            "tr > th": {
              borderBottom: `1px solid ${aepPalette.secondary.light}`,
              fontWeight: "600",
              color: aepPalette.font.light,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          color: "secondary",
          variant: "standard",
        },
        styleOverrides: {
          root: {
            // ensure consistent display w helper text
            minHeight: "6.4rem",
            color: aepPalette.font.dark,
            "& label": {
              fontSize: "1.3rem",
              color: aepPalette.base.light,
            },
            input: {
              color: aepPalette.font.dark,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: aepPalette.secondary.main,
          },
          tooltip: {
            backgroundColor: aepPalette.secondary.main,
            color: aepPalette.common.white,
            fontSize: "1.3rem",
            lineHeight: "2rem",
            padding: "1rem 2.5rem",
            whiteSpace: "pre-wrap",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: aepPalette.font.main,
            margin: "0px",
          },
          // ignore, TS seems to not be aware of buttonText class
          // @ts-ignore
          buttonText: {
            color: aepPalette.common.white,
          },
          caption: {
            fontSize: "1rem",
          },
        },
      },
      MuiYearPicker: {
        styleOverrides: {
          root: {
            "& > div > button": {
              fontSize: "1.2rem",
            },
          },
        },
      },
    },
  },
  deDE
);

interface AepThemeProps {
  children: JSX.Element;
}

const AepTheme = (props: AepThemeProps): JSX.Element => {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default AepTheme;
