import TextField from "../textfield/TextField";

interface CounterFieldProps {
  loading: boolean;
  changeCount: (newCount?: number) => void;
  count: number;
  label: string;
}

const CounterField = (props: CounterFieldProps) => {
  const { loading, changeCount, count, label } = props;

  return (
    <TextField
      disabled={loading}
      InputLabelProps={{ style: { textAlign: "center", width: "100%" } }}
      inputProps={{
        style: {
          textAlign: "center",
        },
        min: 0,
      }}
      label={label}
      sx={{
        width: "46px",
        transform: "translateY(2px)",
      }}
      value={count}
      onChange={(e) => {
        changeCount(parseInt(e.target.value, 10));
      }}
      type="number"
      onBlur={(e) => {
        if (e.target.value == "") {
          changeCount(0);
        }
      }}
    />
  );
};

export default CounterField;
