import { TableCell, Typography } from "@mui/material";
import { FurtherReceiptDto } from "../../../../api";
import AnimatedTableRow from "../../../components/table/AnimatedTableRow";
import { ddmmyyyyFormat } from "../../../service/CustomDateService";
import { toCurrency } from "../../../service/CustomNumberService";

interface ReceiptsSearchTableRowProps {
  row: FurtherReceiptDto;
  transitionDelay: number;
}

const ReceiptsSearchTableRow = (props: ReceiptsSearchTableRowProps) => {
  const { row, transitionDelay } = props;

  return (
    <AnimatedTableRow
      transitionDelay={transitionDelay}
      key={row.deliveryNoteId}
      sx={{
        "& > td": {
          px: 1,
        },
        "& > td:first-of-type": {
          pl: 4,
        },
        "& > td:last-of-type": {
          pr: 4,
        },
      }}
    >
      <TableCell>
        <Typography variant="body2">{row.docType}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.deliveryNoteId}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {row.deliveryDate ? ddmmyyyyFormat(new Date(row.deliveryDate)) : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.pzn}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.articleName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.supplierName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.amount}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {toCurrency(row.price as number)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.boxNumber}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.batchNumber}</Typography>
      </TableCell>
    </AnimatedTableRow>
  );
};

export default ReceiptsSearchTableRow;
