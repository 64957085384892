import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { StrapiDownloadData } from "./DownloadSearchContainer";
import DownloadsTableRow from "./DownloadsTableRow";

interface DownloadsTableProps {
  downloads?: StrapiDownloadData[];
  loading?: boolean;
}

const DownloadsTable = (props: DownloadsTableProps) => {
  const { downloads } = props;

  return (
    <Table
      stickyHeader
      aria-label="order positions table"
      sx={{
        "& > thead > tr > th": {
          px: 1,
          "&:first-of-type": {
            pl: 5,
          },
        },
        "& > tbody > tr > td": {
          position: "relative",
          px: 1,
          "&:first-of-type": {
            pl: 5,
          },
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "15%" }}>
            <Typography variant="bodyBold">Kategorie</Typography>
          </TableCell>
          <TableCell sx={{ width: "30%" }}>
            <Typography
              variant="bodyBold"
              sx={{ maxWidth: "200px !important" }}
            >
              Dokument
            </Typography>
          </TableCell>
          <TableCell sx={{ width: "30%" }}>
            <Typography variant="bodyBold">Info</Typography>
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Typography variant="bodyBold">Datum</Typography>
          </TableCell>
          <TableCell sx={{ width: "5%" }} />
          <TableCell sx={{ width: "10%" }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {downloads?.map((downloadData) => {
          return (
            <DownloadsTableRow
              key={`download-${downloadData.id}`}
              downloadData={downloadData}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default DownloadsTable;
