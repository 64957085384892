import { FormikValues } from "formik";

export enum TableFilterType {
  textfield = "textfield",
  dateRange = "dateRange",
  dropdown = "dropdown",
  tabs = "tabs",
  chips = "chips",
}

export interface TableFilterMenuItem {
  label: string;
  value: string;
}

export interface TableFilterSearchField {
  type: TableFilterType;
  name: string;
  label: string;
  menuItems?: TableFilterMenuItem[];
  validate?: (value: string) => boolean;
  errorMessage?: string;
  disabled?: boolean;
}

export interface TableFilterProps {
  handleResetFilter: () => void;
  handleResetRows?: () => void;
  initialValues: FormikValues;
  onSubmit: (loadMore: boolean, searchParams?: FormikValues) => void;
  resetSearchParameters?: boolean;
  searchFields: TableFilterSearchField[];
  triggerSubmit?: boolean;
}
