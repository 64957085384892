import { Box, Stack, Typography } from "@mui/material";
import { deDateStringFormat } from "../../service/CustomDateService";
import NavButton from "../../components/button/NavButton";
import ImageWrapper from "../../wrappers/ImageWrapper";
import {
  itemDisplayDateStyles,
  itemHeaderStyles,
  itemImageWrapperStyles,
  itemNavButtonStyles,
  itemRootStackStyles,
  itemTeaserTextStyles,
} from "./newsStyles";
import StrapiData from "../../../strapi/models/StrapiData";
import Article from "../../../strapi/models/Article";

interface NewsItemProps {
  article: StrapiData<Article>;
  baseURL: string;
  fadeIn: boolean;
  timeout: number;
  toggleDetailView: () => void;
}

const NewsItem = (props: NewsItemProps) => {
  const { article, baseURL, toggleDetailView } = props;
  const { gueltigVon, heroBild, teaserText, titel } = article.attributes;
  const displayDate = new Date(gueltigVon);
  const bildUrl = heroBild.data?.attributes?.url;
  const img = `${baseURL}/contentmanager${bildUrl}`;

  return (
    <Stack sx={itemRootStackStyles}>
      {bildUrl ? (
        <ImageWrapper src={img} alt="dummy news" sx={itemImageWrapperStyles} />
      ) : (
        <Box
          sx={{
            ...itemImageWrapperStyles,
            backgroundColor: "transparent",
          }}
        />
      )}
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            ml: {
              xs: "",
              md: 2,
            },
          }}
        >
          <Typography variant="caption" sx={itemDisplayDateStyles}>
            {deDateStringFormat(displayDate)}
          </Typography>
          <Typography variant="h2" sx={itemHeaderStyles}>
            {titel}
          </Typography>
          <Typography variant="h4" sx={itemTeaserTextStyles}>
            {teaserText}
          </Typography>
        </Box>
        <NavButton
          buttonText={"Mehr erfahren"}
          onClick={toggleDetailView}
          sx={itemNavButtonStyles}
        />
      </Box>
    </Stack>
  );
};

export default NewsItem;
