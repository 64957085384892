import { TableRow, TableCell } from "@mui/material";
import { DeliveryNotePositionDto } from "../../../../api";
import { toCurrency } from "../../../service/CustomNumberService";

type DeliveryItemProps = {
  row: DeliveryNotePositionDto;
};

const taxTypes: Record<string, string> = { U19: "19%", U7: "7%", U07: "7%" };

const DeliveryDetailTableRow = ({ row }: DeliveryItemProps) => {
  return (
    <TableRow key={row.positionId}>
      <TableCell>{row.orderedQuantity} STK</TableCell>
      <TableCell>{row.articleName}</TableCell>
      <TableCell>{row.pzn}</TableCell>
      <TableCell>{toCurrency(row.price)}</TableCell>
      <TableCell>{toCurrency(row.price * row.orderedQuantity)}</TableCell>
      <TableCell>
        {typeof row.tax !== "undefined" ? taxTypes[row.tax.trim()] : "-"}
      </TableCell>
      <TableCell>{row.mark}</TableCell>
    </TableRow>
  );
};

export default DeliveryDetailTableRow;
