import { Typography, Box, Stack } from "@mui/material";
import { aepPalette } from "../../../../styles/aepPalette";

interface ChartTooltipProps {
  color: string;
  keys: string[];
  values: string[];
}

const ChartTooltip = (props: ChartTooltipProps) => {
  const { color, keys, values } = props;

  return (
    <Stack
      direction="column"
      sx={{
        p: 2,
        backgroundColor: aepPalette.common.white,
        boxShadow: `-3px 3px 4px ${aepPalette.secondary.light}`,
        border: `1px solid ${aepPalette.secondary.light}`,
      }}
    >
      {keys.map((key, index) => (
        <Stack
          key={key}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              height: "1rem",
              width: "1rem",
              backgroundColor: color,
              mr: 1,
            }}
          />
          <Typography variant="bodyBold" sx={{ mr: 1 }}>
            {key}:
          </Typography>
          <Typography variant="body2">{values[index]}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default ChartTooltip;
