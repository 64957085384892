import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import { OrderNotePositionDto } from "../../../../api";
import { CustomTableProps } from "../../../components/table/TableContainer";
import OrderPositionsTableRow from "./OrderPositionsTableRow";
import PositionsRowCommonHeader from "./PositionsRowCommonHeader";

interface OrderPositionsTableProps extends CustomTableProps {
  positions?: OrderNotePositionDto[];
  loading?: boolean;
}

export enum OrderPositionStatus {
  OPEN = "Auftrag angenommen",
  OPEN_NUMBER = 20,
  IN_PRODUCTION = "In produktion",
}

const OrderPositionsTable = (props: OrderPositionsTableProps) => {
  const {
    positions,
    allSelected,
    isSelected,
    handleSelectRow,
    handleSelectAll,
  } = props;

  const positionIds = positions?.map((position) => position.positionId);

  const handleSelectAllPositionsInOrder = () => {
    if (handleSelectAll && positionIds) {
      handleSelectAll(positionIds);
    }
  };

  const handleCheckedStatus = () => {
    if (allSelected && positionIds) {
      return allSelected(positionIds);
    } else {
      return false;
    }
  };

  const openPositions = positions?.filter(
    (position) =>
      position.stateNumber != undefined &&
      position.stateNumber <= OrderPositionStatus.OPEN_NUMBER
  );
  const showSelectAll = openPositions ? openPositions.length > 0 : false;

  return (
    <Table stickyHeader aria-label="order positions table">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            {showSelectAll && (
              <Checkbox
                checked={handleCheckedStatus()}
                onClick={handleSelectAllPositionsInOrder}
              />
            )}
          </TableCell>
          <PositionsRowCommonHeader />
        </TableRow>
      </TableHead>
      <TableBody>
        {positions?.map((positionData, index) => {
          return (
            <OrderPositionsTableRow
              key={`position-${index}`}
              positionData={positionData}
              isSelected={isSelected(positionData.positionId)}
              handleSelectRow={handleSelectRow}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default OrderPositionsTable;
