import { SxProps, Theme } from "@mui/material/styles";
import { aepPalette } from "../../../styles/aepPalette";
import { theme } from "../../../styles/AepThemeProvider";

export const rootBoxStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - 140px)",
  position: "relative",
};

export const labelStyles: SxProps<Theme> = {
  color: aepPalette.font.light,
  fontSize: "1.2rem",
  fontWeight: "300",
  lineHeight: "1.9rem",
  margin: "9px 12px 4px 12px",
  padding: "7px 8%",
  textTransform: "uppercase",
  maxHeight: "33px",
};

export const toggleStyles: SxProps<Theme> = {
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "1.4rem",
  lineHeight: "19px",
  margin: "4px 12px 4px -12px",
  padding: "8px 19px",
  "&:hover, &:active": {
    backgroundColor: aepPalette.base.lighter,
  },
};

export const greyPanelStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.base.light,
  position: "fixed",
  height: "100%",
  width: "100vw",
  top: 0,
  left: 0,
  zIndex: 2,
};

export const logoStyles: SxProps<Theme> = {
  position: "fixed",
  [theme.breakpoints.down("lg")]: {
    minWidth: "100px",
    top: 20,
    left: 20,
  },
  [theme.breakpoints.up("lg")]: {
    minWidth: "133px",
    top: 40,
    left: 40,
  },
  zIndex: 5,
};

export const employeeStyles: SxProps<Theme> = {
  position: "absolute",
  bottom: 34,
  width: "100%",
};
