import { Box, Collapse, SxProps, Theme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { aepPalette } from "../../../styles/aepPalette";
import {
  rootBoxStyles,
  linkBoxStyles,
  textStyles,
  linkStyles,
  urlLinkStyles,
} from "./navItemStyles";

interface NavItemProps {
  children?: JSX.Element | JSX.Element[];
  icon?: JSX.Element;
  // minimized indicates the parent container is not expanded
  minimized: boolean;
  route: string;
  sx?: SxProps<Theme>;
  text: string;
}

const NavItem = (props: NavItemProps) => {
  const { icon, minimized, route, sx, text } = props;

  const inside = (
    <Box sx={{ ...linkBoxStyles }}>
      {icon}
      <Collapse in={!minimized || !icon}>
        <span style={{ ...textStyles, marginLeft: icon ? "13px" : "0px" }}>
          {text}
        </span>
      </Collapse>
    </Box>
  );

  if (route.includes("https:")) {
    return (
      <Box sx={sx}>
        <Box sx={{ ...rootBoxStyles }}>
          <a href={route} style={urlLinkStyles} target="blank">
            {inside}
          </a>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={sx}>
      <Box sx={{ ...rootBoxStyles }}>
        <NavLink
          to={route}
          style={({ isActive }) => {
            return {
              ...linkStyles,
              backgroundColor: isActive
                ? aepPalette.base.lighter
                : "transparent",
            };
          }}
        >
          {inside}
        </NavLink>
      </Box>
    </Box>
  );
};

export default NavItem;
