import { SxProps, Theme } from "@mui/material/styles";
import { CSSProperties } from "react";
import { aepPalette } from "../../../styles/aepPalette";

export const rootBoxStyles: SxProps<Theme> = {};

export const widthOverrides: SxProps<Theme> = {
  paddingLeft: "19px",
  marginLeft: "-18px",
};

export const linkStyles: CSSProperties = {
  margin: "4px 12px",
  display: "flex",
  alignItems: "center",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "1.4rem",
  lineHeight: "19px",
  textDecoration: "none",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
};

export const linkBoxStyles: SxProps<Theme> = {
  borderRadius: "5px",
  padding: "6px 19px",
  paddingLeft: "19px",
  display: "flex",
  alignItems: "center",
  color: aepPalette.font.dark,
  flexDirection: "row",
  width: "100%",
  "&:hover, &:active": {
    backgroundColor: aepPalette.base.lighter,
  },
};

export const textStyles: CSSProperties = {
  alignItems: "center",
  display: "flex",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const urlLinkStyles: CSSProperties = {
  margin: "4px 12px",
  display: "flex",
  alignItems: "center",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "1.4rem",
  lineHeight: "19px",
  textDecoration: "none",
  justifyContent: "space-between",
  whiteSpace: "nowrap",
  backgroundColor: "transparent",
};
