import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const DeliveryIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 23 18" {...props}>
    <path d="M6.29804 14.9622C5.87867 14.9622 5.53741 15.2722 5.53741 15.6536C5.53741 16.0349 5.8785 16.3451 6.29804 16.3451C6.71741 16.3451 7.05867 16.035 7.05867 15.6536C7.05849 15.2722 6.7174 14.9622 6.29804 14.9622ZM6.29804 15.8296C6.19128 15.8296 6.10428 15.7507 6.10428 15.6535C6.10428 15.5564 6.1911 15.4773 6.29804 15.4773C6.4048 15.4773 6.4918 15.5563 6.4918 15.6535C6.49163 15.7507 6.40479 15.8296 6.29804 15.8296Z" />
    <path d="M17.2221 14.9622C16.8027 14.9622 16.4615 15.2722 16.4615 15.6536C16.4615 16.0349 16.8026 16.3451 17.2221 16.3451C17.6415 16.3451 17.9827 16.035 17.9827 15.6536C17.9826 15.2722 17.6415 14.9622 17.2221 14.9622ZM17.2221 15.8296C17.1154 15.8296 17.0284 15.7507 17.0284 15.6535C17.0284 15.5564 17.1152 15.4773 17.2221 15.4773C17.3289 15.4773 17.4159 15.5563 17.4159 15.6535C17.4159 15.7507 17.3289 15.8296 17.2221 15.8296Z" />
    <path d="M20.3545 2.23944C20.3246 2.12265 20.2104 2.04 20.0784 2.04H14.4676V0.25767C14.4676 0.11533 14.3408 0 14.1842 0H0.28344C0.126864 0 0 0.11533 0 0.25767C0 0.400011 0.126864 0.51534 0.28344 0.51534H13.9009V12.0301H3.12754V3.93027H6.29768C6.45426 3.93027 6.58112 3.81494 6.58112 3.6726C6.58112 3.53026 6.45426 3.41493 6.29768 3.41493L1.92476 3.41509C1.76819 3.41509 1.64132 3.53042 1.64132 3.67276C1.64132 3.8151 1.76819 3.93043 1.92476 3.93043H2.56086V15.6535C2.56086 15.7958 2.68772 15.9112 2.8443 15.9112H3.85654C3.99764 17.0227 5.038 17.8889 6.2979 17.8889C7.55779 17.8889 8.59814 17.0227 8.73943 15.9112H14.7806C14.9217 17.0227 15.9621 17.8889 17.222 17.8889C18.4819 17.8889 19.5222 17.0227 19.6635 15.9112H22.7166C22.8731 15.9112 23 15.7958 23 15.6535V12.6246C23 12.605 22.9975 12.5854 22.9927 12.5663L20.3545 2.23944ZM20.2718 4.19386L21.7869 10.1243H18.5249L17.0972 4.19386H20.2718ZM6.29817 17.3736C5.25482 17.3736 4.40592 16.6019 4.40592 15.6534C4.40592 14.7049 5.25482 13.9332 6.29817 13.9332C7.34153 13.9332 8.19043 14.7049 8.19043 15.6534C8.19043 16.6019 7.34153 17.3736 6.29817 17.3736ZM17.2223 17.3736C16.1789 17.3736 15.33 16.6019 15.33 15.6534C15.33 14.7049 16.1789 13.9332 17.2223 13.9332C18.2656 13.9332 19.1145 14.7049 19.1145 15.6534C19.1145 16.6019 18.2656 17.3736 17.2223 17.3736ZM22.4336 15.3958H19.6639C19.5228 14.2842 18.4823 13.4179 17.2224 13.4179C15.9625 13.4179 14.9222 14.2842 14.7811 15.3958H8.73984C8.59875 14.2842 7.55825 13.4179 6.29831 13.4179C5.03842 13.4179 3.99807 14.2842 3.85696 15.3958H3.12817V12.5454H14.1848C14.3414 12.5454 14.4683 12.4301 14.4683 12.2877V2.55515H19.8535L20.1405 3.67851H16.7452C16.6594 3.67851 16.5781 3.71393 16.5244 3.77459C16.4704 3.83541 16.45 3.91498 16.4681 3.99117L18.02 10.4368C18.0485 10.555 18.1638 10.6393 18.2968 10.6393H21.9186L22.4333 12.6539L22.4336 15.3958Z" />
    <path d="M5.56963 1.94297C5.56963 1.80063 5.44276 1.6853 5.28619 1.6853H0.771294C0.614718 1.6853 0.487854 1.80063 0.487854 1.94297C0.487854 2.08532 0.614718 2.20065 0.771294 2.20065H5.28619C5.44276 2.20065 5.56963 2.08532 5.56963 1.94297Z" />
    <path d="M8.56728 7L7.91302 9.34782H10.0117L10.1476 8.85408H8.93588L9.06028 8.41949H10.1845L10.3204 7.92576H9.1962L9.31599 7.49374H10.5162L10.6521 7H8.56728Z" />
    <path d="M6.61596 7.62231L6.72949 8.44264H6.00485L6.61596 7.62231ZM6.23915 7L4.39133 9.34782H5.33335L5.62562 8.95695H6.80437L6.85509 9.34782H7.91306L7.4348 7H6.23915Z" />
    <path d="M12.1211 7.78946C12.0605 8.0209 11.9307 8.09805 11.6258 8.09805H11.4506L11.6063 7.49374H11.8659C12.1124 7.49374 12.173 7.59403 12.1211 7.78946ZM12.3309 7H10.873L10.2609 9.34782H11.1218L11.3186 8.59436H11.9913C12.4217 8.59436 12.8089 8.38863 12.956 7.81775C13.1161 7.20315 12.8241 7 12.3309 7" />
    <path d="M4.28657 9.73926L4 10.1306H12.5032L12.6087 9.73926H4.28657Z" />
  </IconWrapper>
);

export default DeliveryIcon;
