/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useContext, RefObject } from "react";
import { BarChartData } from "../elements/BarChart";
import Chart from "../Chart";
import CustomerContext from "../../../contexts/CustomerContext";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import SnackbarContext from "../../../contexts/SnackbarContext";

interface PopulatedBarChartProps {
  containerRef?: RefObject<HTMLElement>;
  dataSet: "PACKMON" | "UMSMON";
  inCarousel?: boolean;
  liftLoadingState?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopulatedBarChart = (props: PopulatedBarChartProps) => {
  const { containerRef, dataSet, inCarousel, liftLoadingState } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [barChartData, setBarChartData] = useState<BarChartData>();
  const [fullBarChartData, setFullBarChartData] = useState<BarChartData>();
  const [mini, setMini] = useState<boolean>(false);

  const { customerNumber } = useContext(CustomerContext);
  const { handleHttpError } = useContext(SnackbarContext);

  const api = new ApiWrapper().analysisControllerApi();

  useEffect(() => {
    let isApiSubscribed = true;
    if (isApiSubscribed && customerNumber) {
      setLoading(true);

      const sixMonthsPromise = api
        .getData(customerNumber, dataSet, 6)
        .then((res) => {
          const response = res.data as unknown;
          setBarChartData(response as BarChartData);
        })
        .catch((err) => {
          setBarChartData({data: []} as unknown as BarChartData)
          handleHttpError(err)
        });

      const thirteenMonthsPromise = api
        .getData(customerNumber, dataSet, 13)
        .then((res) => {
          const response = res.data as unknown;
          setFullBarChartData(response as BarChartData);
        })
        .catch((err) => {
          setFullBarChartData({data: []} as unknown as BarChartData)
          handleHttpError(err)
        });

      Promise.all([sixMonthsPromise, thirteenMonthsPromise]).finally(() => {
        setLoading(false);
      });
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber, mini]);

  useEffect(() => {
    if (liftLoadingState) {
      liftLoadingState(loading);
    }
  }, [loading]);

  return barChartData && fullBarChartData ? (
    <Chart
      loading={loading}
      inCarousel={inCarousel}
      type="bar"
      data={barChartData}
      fullData={fullBarChartData}
      upliftMiniState={setMini}
      containerRef={containerRef}
    />
  ) : (
    <></>
  );
};

export default PopulatedBarChart;
