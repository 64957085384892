import { useContext, useState } from "react";
import TableContainer from "../../components/table/TableContainer";
import { TableFilterType } from "../../components/table/tableFilter/models";
import { useKeycloak } from "@react-keycloak/web";
import { CustomerContext } from "../../contexts/CustomerContext";
import StrapiApi from "../../../strapi/StrapiApi";
import Download from "../../../strapi/models/Download";
import StrapiData from "../../../strapi/models/StrapiData";
import DownloadsTable from "./DownloadsTable";
import SnackbarContext from "../../contexts/SnackbarContext";
import DownloadCategory from "../../../strapi/models/DownloadCategory";

export type StrapiDownloadData = StrapiData<Download>;

interface DownloadSearchFormikValues {
  searchTerms?: string[];
  pzn?: string;
  selectedTab?: string;
}

const DownloadSearchContainer = () => {
  const { keycloak } = useKeycloak();
  const { customerNumber } = useContext(CustomerContext);
  const { handleHttpError } = useContext(SnackbarContext);
  const strapiApi = new StrapiApi(keycloak.token || "", customerNumber);

  const [loading, setLoading] = useState<boolean>(false);
  const [downloads, setDownloads] = useState<Array<StrapiDownloadData>>([]);
  const [downloadCategories, setDownloadCategories] = useState<Array<string>>(
    []
  );

  const initialValues: DownloadSearchFormikValues = {
    searchTerms: [],
    pzn: "",
    selectedTab: "",
  };

  const extractCategoreis = (data: StrapiData<DownloadCategory>[]) => {
    const categories = data.map((dlCategory) => {
      return dlCategory.attributes.name;
    });

    return categories.filter((v, i, a) => a.indexOf(v) === i);
  };

  const loadDownloads = (
    loadMore = false,
    searchParams?: DownloadSearchFormikValues
  ) => {
    if (customerNumber) {
      strapiApi.getDownloadCategories().then((res) => {
        const categories = extractCategoreis(res.data.data);

        setDownloadCategories(categories);
      });

      strapiApi
        .getDownloads(
          searchParams?.selectedTab || "",
          searchParams?.pzn || "",
          searchParams?.searchTerms || []
        )
        .then((res) => {
          setDownloads(res.data.data);
        })
        .catch(handleHttpError);
    }
  };

  return (
    <TableContainer
      sx={{ height: "calc(100% - 125px)" }}
      hasDetailViewSwitch
      noData={downloads.length === 0}
      noDataMessage="Keine Ergebnisse"
      fullSizeRowsInit
      loadCallback={loadDownloads}
      loading={loading}
      tableFilterProps={{
        initialValues: initialValues,
        searchFields: [
          {
            type: TableFilterType.chips,
            name: "searchTerms",
            label: "Ihr Suchbegriff",
          },
          {
            type: TableFilterType.textfield,
            name: "pzn",
            label: "PZN",
          },
          {
            type: TableFilterType.tabs,
            name: "category",
            label: "Kategorie",
            menuItems: [
              {
                label: "Alle",
                value: "",
              },
              ...downloadCategories
                .sort((a, b) => a.localeCompare(b))
                .map((category) => ({
                  value: category,
                  label: category,
                })),
            ],
          },
        ],
      }}
      Table={({
        handleSelectRow,
        isSelected,
        handleSelectAll,
        allSelected,
      }) => <DownloadsTable downloads={downloads} loading={loading} />}
    />
  );
};

export default DownloadSearchContainer;
