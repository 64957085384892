import {
  createContext,
  useContext,
  useEffect,
  useState,
  FunctionComponent,
  Dispatch,
  SetStateAction
} from "react";
import { SpecialOrderRequest, SpecialOrderResponse, UserDtoPermissionsEnum } from "../../api";
import CustomerContext from "./CustomerContext";
import SnackbarContext from "./SnackbarContext";
import ApiWrapper from "../wrappers/ApiWrapper";
import UserContext from "./UserContext";

export interface SpecialOrdersContextType {
  specialOrderRequests: SpecialOrderRequest[];
  updateSpecialOrderRequests: (
    specialOrder: SpecialOrderRequest,
    remove?: boolean
  ) => void;
  specialOrderResponses: SpecialOrderResponse[];
  containsSpecialOrderRequest: (orderId: number) => boolean;
  loading: boolean;
  loadSpecialOrders: () => void;
  postOrders: (orderRequests: SpecialOrderRequest[]) => void;
  setSelectedOrderIds: Dispatch<SetStateAction<string[]>>;
}

export const SpecialOrdersContext = createContext<SpecialOrdersContextType>({
  specialOrderResponses: [],
  specialOrderRequests: [],
  updateSpecialOrderRequests: () => null,
  containsSpecialOrderRequest: () => false,
  loading: false,
  loadSpecialOrders: () => null,
  postOrders: () => null,
  setSelectedOrderIds: () => null
});

export const SpecialOrdersContextProvider: FunctionComponent<{
  children?: JSX.Element;
}> = (props) => {
  const { children } = props;
  const api = new ApiWrapper().specialOrdersControllerApi();

  const { handleHttpError, showSnackbar } = useContext(SnackbarContext);
  const { customerNumber } = useContext(CustomerContext);
  const { checkUserRole } = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [specialOrderRequests, setSpecialOrderRequests] = useState<
    SpecialOrderRequest[]
  >([]);
  const [specialOrderResponses, setSpecialOrderResponses] = useState<
    SpecialOrderResponse[]
  >([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const [showSpecialOrdersInNavigation, setShowSpecialOrdersInNavigation] =
    useState<boolean>(false);

  const updateSpecialOrderRequests = (
    alteredSpecialOrderRequest: SpecialOrderRequest,
    remove?: boolean
  ) => {
    const stateSlice = specialOrderRequests.slice();

    const indexToAlter = stateSlice.findIndex(
      (orderRequest) => orderRequest.id === alteredSpecialOrderRequest.id
    );

    if (indexToAlter !== -1) {
      if (remove) {
        stateSlice.splice(indexToAlter, 1);
      } else {
        stateSlice.splice(indexToAlter, 1, alteredSpecialOrderRequest);
      }
    } else {
      stateSlice.push(alteredSpecialOrderRequest);
    }

    setSpecialOrderRequests(stateSlice);
  };

  const resetSpecialOrders = () => {
    setSpecialOrderRequests([]);
    setSpecialOrderResponses([]);
  };

  const containsSpecialOrderRequest = (orderRequestId: number): boolean => {
    return (
      specialOrderRequests.findIndex(
        (orderRequest) => orderRequest.id === orderRequestId
      ) !== -1
    );
  };

  const loadSpecialOrders = (): void => {
    if (customerNumber && !loading) {
      setLoading(true);
      resetSpecialOrders();

      api
        .getSpecialOrders(customerNumber)
        .then((res) => {
          setSpecialOrderResponses(res.data);
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }
  };

  const postOrders = (orderRequests: SpecialOrderRequest[]): void => {
    if (customerNumber && !loading) {
      setLoading(true);

      api
        .postSpecialOrder(customerNumber, orderRequests)
        .then((res) => {
          const { failedOrders, successfulOrders } = res.data;

          const successArr = successfulOrders?.map(
            (order) => order.articleName
          );
          const errArr = failedOrders?.map((order) => order.articleName);

          const wasSuccess = successArr && successArr.length > 0;
          const wasFailure = errArr && errArr.length > 0;

          if (successArr && wasSuccess && errArr && wasFailure) {
            successArr.unshift(
              "Die folgenden Sonderbestellungen wurden erfolgreich übertragen und werden nach Ablauf der Bestellfrist verarbeitet:"
            );
            errArr.unshift(
              "Die folgenden Sonderbestellungen wurden nicht übertragen:"
            );
            const message = successArr.concat(errArr).join("\n");

            showSnackbar("warning", message);
          } else if (wasSuccess) {
            showSnackbar(
              "success",
              "Sonderbestellungen wurden erfolgreich übertragen und werden nach Ablauf der Bestellfrist verarbeitet."
            );
          } else if (wasFailure) {
            showSnackbar(
              "error",
              "Übermittlung von Sonderbestellungen fehlgeschlagen."
            );
          }
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setSelectedOrderIds([]);
    if (checkUserRole([UserDtoPermissionsEnum.Bestellungen])) {
      loadSpecialOrders(); // loadSpecialOrders once initially to determine if we should show "Sonderbestellungen" in application navigation menu
    }
  }, [customerNumber]);

  return (
    <SpecialOrdersContext.Provider
      value={{
        loadSpecialOrders,
        postOrders,
        specialOrderRequests,
        updateSpecialOrderRequests,
        specialOrderResponses,
        containsSpecialOrderRequest,
        loading,
        setSelectedOrderIds
      }}
    >
      {children}
    </SpecialOrdersContext.Provider>
  );
};

export default SpecialOrdersContext;
