import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./components/navbar/Navbar";
import { useKeycloak } from "@react-keycloak/web";
import PrivateWrapper from "./wrappers/PrivateWrapper";
import Dashboard from "./pages/dashboard/Dashboard";
import Orders from "./pages/orders/Orders";
import Deliveries from "./pages/deliveries/Deliveries";
import DeliveriesDetails from "./pages/deliveries/deliveriesDetails/DeliveriesDetails";
import AppBar from "./components/appbar/AppBar";
import { useContext, useEffect, useRef } from "react";
import Employees from "./pages/employees/Employees";
import {
  getJwtRoles,
  getUserFullName,
  getUserName,
} from "./service/JwtService";
import UserContext from "./contexts/UserContext";
import News from "./pages/news/News";
import Contact from "./pages/contact/Contact";
import Offers from "./pages/offers/Offers";
import ArticleAdmissions from "./pages/articleAdmissions/ArticleAdmissions";
import SpecialOrders from "./pages/specialOrders/SpecialOrders";
import UserProfile from "./pages/userProfile/UserProfile";
import PostBox from "./pages/postbox/PostBox";
import PageWrapper from "./wrappers/PageWrapper";
import Receipts from "./pages/receipts/Receipts";
import Downloads from "./pages/downloads/Downloads";

// synchronized timeout value in ms for sidebar related animations
export const timeout = 400;
// timeout for Fade and Grow components
export const fadeTimeout = 750;
export const growTimeout = 750;

const App = (): JSX.Element => {
  const { keycloak } = useKeycloak();
  const bodyRef = useRef<HTMLElement>();
  const { updateUserRoles, updateUserFullName, updateUserName } =
    useContext(UserContext);

  useEffect(() => {
    if (keycloak.token) {
      const userRoles = getJwtRoles(keycloak.token);
      const userFullName = getUserFullName(keycloak.token);
      const userName = getUserName(keycloak.token);

      updateUserRoles(userRoles);
      updateUserFullName(userFullName);
      updateUserName(userName);
    }
  }, [keycloak.token]);

  return (
    <div className="app" style={{ overflow: "hidden" }}>
      <PrivateWrapper>
        <AppBar bodyRef={bodyRef} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "calc(100vh - 140px)",
            position: "relative",
            overflow: "hidden",
            width: "100%",
            minWidth: "600px",
          }}
        >
          <Navbar timeout={timeout} />
          <Box ref={bodyRef} sx={{ flexGrow: 1, overflow: "auto" }}>
            <Routes>
              <Route
                path="/"
                element={<PageWrapper scrollable element={<Dashboard />} />}
              />
              <Route
                path="/bestellungen"
                element={<PageWrapper element={<Orders />} />}
              />
              <Route
                path="/bestellungen/lieferungen"
                element={<PageWrapper element={<Deliveries />} />}
              />

              <Route
                path="/bestellungen/lieferungen/details"
                element={<PageWrapper element={<DeliveriesDetails />} />}
              />
              <Route
                path="/bestellungen/sonderbestellungen"
                element={<PageWrapper element={<SpecialOrders />} />}
              />
              <Route
                path="/bestellungen/artikelneuaufnahme"
                element={<PageWrapper element={<ArticleAdmissions />} />}
              />
              <Route
                path="/news"
                element={<PageWrapper element={<News />} />}
              />
              <Route
                path="/kontakt"
                element={<PageWrapper element={<Contact />} />}
              />
              <Route
                path="/postfach"
                element={<PageWrapper element={<PostBox />} />}
              />
              <Route
                path="/angebote"
                element={<PageWrapper element={<Offers />} />}
              />
              <Route
                path="/mitarbeiter"
                element={<PageWrapper element={<Employees />} />}
              />
              <Route
                path="/profil"
                element={<PageWrapper element={<UserProfile />} />}
              />
              <Route
                path="/belege"
                element={<PageWrapper element={<Receipts />} />}
              />
              <Route
                path="/downloads"
                element={<PageWrapper element={<Downloads />} />}
              />
            </Routes>
          </Box>
        </Box>
      </PrivateWrapper>
    </div>
  );
};

export default App;
