import { Table, TableBody, Typography, Box } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export interface TableAccordionWrapperProps {
  children: JSX.Element[];
  tableHeader: string;
}

const TableAccordionWrapper = (props: TableAccordionWrapperProps) => {
  const { children, tableHeader } = props;

  return (
    <>
      <Box
        sx={{
          py: 2,
          pl: 7,
          borderBottom: `1px solid ${aepPalette.secondary.light}`,
        }}
      >
        <Typography variant="bodyBold">{tableHeader}</Typography>
      </Box>

      <Table stickyHeader aria-label="simple table">
        <TableBody>{children}</TableBody>
      </Table>
    </>
  );
};

export default TableAccordionWrapper;
