import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

interface DownloadIconProps extends SvgIconProps {
  bg?: boolean;
  downloading?: boolean;
}

const DownloadIcon = (props: DownloadIconProps) => {
  const { bg, downloading } = props;

  const dlIconSx: SxProps<Theme> = {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: bg ? "rgba(197, 217, 232, 0.5)" : "",
    border: bg ? "1px solid #C5D9E8" : "",
    borderRadius: "5px",
    boxSizing: "border-box",
    color: "base",
    cursor: "pointer",
    display: "flex",
    width: "3rem",
    height: "3rem",
  };

  if (!bg) {
    delete dlIconSx.height;
    delete dlIconSx.width;
  }

  return (
    <Box sx={dlIconSx}>
      {downloading ? (
        <CircularProgress
          color="secondary"
          style={{
            textAlign: "center",
            width: "3rem",
            height: "3rem",
          }}
        />
      ) : (
        <IconWrapper viewBox="5 5 21 21" {...props}>
          <path d="M15.4542 7.97778C15.3415 7.97778 15.2334 8.02027 15.1537 8.09589C15.074 8.1715 15.0292 8.27407 15.0292 8.38101V18.2197L11.7998 15.1754C11.7633 15.1291 11.7167 15.0909 11.6632 15.0633C11.6098 15.0356 11.5507 15.0192 11.49 15.0151C11.4292 15.011 11.3683 15.0193 11.3113 15.0395C11.2542 15.0597 11.2024 15.0913 11.1594 15.1322C11.1163 15.173 11.083 15.2222 11.0617 15.2763C11.0404 15.3304 11.0317 15.3883 11.036 15.4459C11.0403 15.5035 11.0576 15.5596 11.0868 15.6103C11.1159 15.661 11.1562 15.7052 11.2049 15.7399L15.1567 19.4697C15.2362 19.5436 15.3429 19.585 15.4542 19.585C15.5654 19.585 15.6722 19.5436 15.7516 19.4697L19.7035 15.7399C19.7522 15.7052 19.7924 15.661 19.8216 15.6103C19.8507 15.5596 19.868 15.5035 19.8724 15.4459C19.8767 15.3883 19.8679 15.3304 19.8466 15.2763C19.8253 15.2222 19.792 15.173 19.749 15.1322C19.7059 15.0913 19.6541 15.0597 19.5971 15.0395C19.5401 15.0193 19.4791 15.011 19.4184 15.0151C19.3576 15.0192 19.2986 15.0356 19.2451 15.0633C19.1917 15.0909 19.1451 15.1291 19.1086 15.1754L15.8791 18.2197V8.38101C15.8791 8.27407 15.8343 8.1715 15.7546 8.09589C15.675 8.02027 15.5669 7.97778 15.4542 7.97778Z" />
          <path d="M8.37927 18.4616C8.26658 18.4616 8.15849 18.5041 8.0788 18.5797C7.99911 18.6553 7.95435 18.7579 7.95435 18.8648V21.3648C7.95435 21.7926 8.13342 22.2029 8.45218 22.5053C8.77094 22.8078 9.20327 22.9778 9.65406 22.9778H21.2546C21.7054 22.9778 22.1377 22.8078 22.4565 22.5053C22.7753 22.2029 22.9543 21.7926 22.9543 21.3648V18.6632C22.9543 18.5563 22.9096 18.4537 22.8299 18.3781C22.7502 18.3025 22.6421 18.26 22.5294 18.26C22.4167 18.26 22.3086 18.3025 22.2289 18.3781C22.1492 18.4537 22.1045 18.5563 22.1045 18.6632V21.3648C22.1045 21.5787 22.0149 21.7839 21.8556 21.9351C21.6962 22.0863 21.48 22.1713 21.2546 22.1713H9.65406C9.42866 22.1713 9.2125 22.0863 9.05312 21.9351C8.89374 21.7839 8.8042 21.5787 8.8042 21.3648V18.8648C8.8042 18.7579 8.75943 18.6553 8.67974 18.5797C8.60005 18.5041 8.49197 18.4616 8.37927 18.4616Z" />
        </IconWrapper>
      )}
    </Box>
  );
};

export default DownloadIcon;
