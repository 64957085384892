import { useContext, useEffect, useState } from "react";
import { Box, Fade, Stack } from "@mui/material";
import { InvoiceInfoCard } from "./InvoiceInfoCard";
import InvoicesIcon from "../../../components/icons/InvoicesIcon";
import DashboardCard from "./DashboardCard";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { InvoiceDto, UserDtoPermissionsEnum } from "../../../../api";
import { CardProps } from "../../../components/card/Card";
import CustomerContext from "../../../contexts/CustomerContext";
import { Skeleton } from "@mui/material";
import SnackbarContext from "../../../contexts/SnackbarContext";
import { fadeTimeout } from "../../../App";
import UserContext from "../../../contexts/UserContext";

const LastInvoicesCard = (props: CardProps): JSX.Element => {
  const api = new ApiWrapper().invoicesControllerApi();
  const [invoices, setInvoices] = useState<InvoiceDto[]>([]);
  const [loading, setLoading] = useState(true);
  const { customerNumber } = useContext(CustomerContext);
  const { handleError } = useContext(SnackbarContext);
  const { checkUserRole } = useContext(UserContext);

  useEffect(() => {
    setInvoices([]);
    if (customerNumber) {
      setLoading(true);
      api
        .getAllInvoices(customerNumber)
        .then((res) => {
          setInvoices(res.data);
        })
        .catch(() =>
          handleError(
            "Aktuell können keine Daten angezeigt werden. Bitte kontaktieren Sie das Service Center."
          )
        )
        .finally(() => setLoading(false));
    }
  }, [customerNumber]);

  const renderInfoCards = () => {
    if (loading) {
      return (
        <Fade in={loading} timeout={fadeTimeout}>
          <Stack>
            <Skeleton animation="wave" variant="text" height="65px" />
            <Skeleton animation="wave" variant="text" height="65px" />
            <Skeleton animation="wave" variant="text" height="65px" />
          </Stack>
        </Fade>
      );
    }
    return invoices?.map((invoice, index): JSX.Element | null => {
      return (
        <Fade
          in={!loading}
          timeout={fadeTimeout}
          key={`lastInvoicesCard-${index}`}
        >
          <div>
            <InvoiceInfoCard invoice={invoice} fadeIn={!loading} />
          </div>
        </Fade>
      );
    });
  };

  return checkUserRole([UserDtoPermissionsEnum.Sammelrechnungen]) ? (
    <DashboardCard
      loading={loading}
      header="Letzte Rechnungen"
      icon={<InvoicesIcon sx={{ height: "30px", width: "24px" }} />}
      buttonText="Alle Rechnungen"
      to="/belege"
      {...props}
    >
      <Box sx={{ mt: 4, mr: 3, mb: 8 }}>{renderInfoCards()}</Box>
    </DashboardCard>
  ) : (
    <></>
  );
};

export default LastInvoicesCard;
