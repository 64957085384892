import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const ArticleIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="-4 0 22 27" {...props}>
    <path d="M11.6217 25H5.15616C4.94665 25 4.77789 24.8303 4.77789 24.6196C4.77789 24.4089 4.94665 24.2392 5.15616 24.2392H11.2435V9.15262C11.2435 6.24414 8.89234 3.87991 6 3.87991C3.10766 3.87991 0.756547 6.24414 0.756547 9.15262V24.2392H5.41222C5.62173 24.2392 5.79049 24.4089 5.79049 24.6196C5.79049 24.8303 5.62173 25 5.41222 25H0.378273C0.168768 25 0 24.8303 0 24.6196V9.15262C0 5.82279 2.68865 3.11914 6 3.11914C9.31135 3.11914 12 5.82279 12 9.15262V24.6196C12 24.8303 11.8312 25 11.6217 25V25Z" />
    <path d="M9.78274 7.9295C9.63725 7.9295 9.49757 7.84172 9.43356 7.69542C9.24733 7.25066 8.97963 6.84687 8.63628 6.5016C8.29292 6.15633 7.89719 5.88713 7.44908 5.69987C7.25703 5.61794 7.16392 5.39556 7.24539 5.20244C7.32687 5.00932 7.54801 4.91569 7.74006 4.99762C8.27546 5.22585 8.75267 5.54771 9.16586 5.96321C9.57905 6.3787 9.89913 6.85857 10.1261 7.39696C10.2076 7.59008 10.1203 7.81246 9.92241 7.89439C9.87585 7.91194 9.82347 7.92365 9.77692 7.92365L9.78274 7.9295Z" />
    <path d="M11.6217 11.1189H0.378273C0.168768 11.1189 0 10.9434 0 10.7385C0 10.5337 0.168768 10.3582 0.378273 10.3582H11.6217C11.8312 10.3582 12 10.5279 12 10.7385C12 10.9492 11.8312 11.1189 11.6217 11.1189Z" />
    <path d="M11.6217 18.7383H0.378273C0.168768 18.7383 0 18.5686 0 18.3579C0 18.1472 0.168768 17.9775 0.378273 17.9775H11.6217C11.8312 17.9775 12 18.1472 12 18.3579C12 18.5686 11.8312 18.7383 11.6217 18.7383Z" />
    <path d="M8.70611 2.52809H3.29389C3.08439 2.52809 2.91562 2.35838 2.91562 2.14771V0.380384C2.91562 0.16971 3.08439 0 3.29389 0H8.70029C8.9098 0 9.07857 0.16971 9.07857 0.380384V2.14771C9.07857 2.35838 8.9098 2.52809 8.70029 2.52809H8.70611ZM3.67217 1.76147H8.32202V0.760768H3.67217V1.76147Z" />
    <path d="M3.70126 4.37735C3.49176 4.37735 3.32299 4.20764 3.32299 3.99697V2.14772C3.32299 1.93704 3.49176 1.76733 3.70126 1.76733C3.91077 1.76733 4.07954 1.93704 4.07954 2.14772V3.99697C4.07954 4.20764 3.91077 4.37735 3.70126 4.37735Z" />
    <path d="M8.29874 4.37735C8.08923 4.37735 7.92046 4.20764 7.92046 3.99697V2.14772C7.92046 1.93704 8.08923 1.76733 8.29874 1.76733C8.50824 1.76733 8.67701 1.93704 8.67701 2.14772V3.99697C8.67701 4.20764 8.50824 4.37735 8.29874 4.37735Z" />
    <path d="M4.37634 2.52809C4.16683 2.52809 3.99806 2.35838 3.99806 2.14771V0.380384C3.99806 0.16971 4.16683 0 4.37634 0C4.58584 0 4.75461 0.16971 4.75461 0.380384V2.14771C4.75461 2.35838 4.58584 2.52809 4.37634 2.52809Z" />
    <path d="M5.45878 2.52809C5.24927 2.52809 5.08051 2.35838 5.08051 2.14771V0.380384C5.08051 0.16971 5.24927 0 5.45878 0C5.66828 0 5.83705 0.16971 5.83705 0.380384V2.14771C5.83705 2.35838 5.66828 2.52809 5.45878 2.52809Z" />
    <path d="M6.54122 2.52809C6.33172 2.52809 6.16295 2.35838 6.16295 2.14771V0.380384C6.16295 0.16971 6.33172 0 6.54122 0C6.75073 0 6.9195 0.16971 6.9195 0.380384V2.14771C6.9195 2.35838 6.75073 2.52809 6.54122 2.52809Z" />
    <path d="M7.62367 2.52809C7.41416 2.52809 7.24539 2.35838 7.24539 2.14771V0.380384C7.24539 0.16971 7.41416 0 7.62367 0C7.83317 0 8.00194 0.16971 8.00194 0.380384V2.14771C8.00194 2.35838 7.83317 2.52809 7.62367 2.52809Z" />
    <path d="M3.54995 25C3.45102 25 3.35208 24.9591 3.28225 24.8888C3.21241 24.8186 3.17168 24.7191 3.17168 24.6196C3.17168 24.5202 3.21241 24.4207 3.28225 24.3505C3.42192 24.21 3.67798 24.21 3.81765 24.3505C3.88749 24.4207 3.92822 24.5202 3.92822 24.6196C3.92822 24.7191 3.88749 24.8186 3.81765 24.8888C3.74782 24.9591 3.64888 25 3.54995 25Z" />
    <path d="M6.87876 17.2928H5.11542C4.91756 17.2928 4.76043 17.1348 4.76043 16.9358V15.7888H3.61979C3.42192 15.7888 3.26479 15.6308 3.26479 15.4319V13.6587C3.26479 13.4597 3.42192 13.3017 3.61979 13.3017H4.76043V12.1547C4.76043 11.9557 4.91756 11.7977 5.11542 11.7977H6.87876C7.07663 11.7977 7.23376 11.9557 7.23376 12.1547V13.3017H8.3744C8.57226 13.3017 8.72939 13.4597 8.72939 13.6587V15.4319C8.72939 15.6308 8.57226 15.7888 8.3744 15.7888H7.23376V16.9358C7.23376 17.1348 7.07663 17.2928 6.87876 17.2928ZM5.47042 16.5789H6.51795V15.4319C6.51795 15.2329 6.67507 15.0749 6.87294 15.0749H8.01358V14.0157H6.87294C6.67507 14.0157 6.51795 13.8577 6.51795 13.6587V12.5117H5.47042V13.6587C5.47042 13.8577 5.31329 14.0157 5.11542 14.0157H3.97478V15.0749H5.11542C5.31329 15.0749 5.47042 15.2329 5.47042 15.4319V16.5789V16.5789Z" />
  </IconWrapper>
);

export default ArticleIcon;
