import { SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const rootBoxStyles: SxProps<Theme> = {
  display: "grid",
  gap: 3,
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "repeat(12, 1fr)",
  overflow: "auto",
};

export const textfieldStyles: SxProps<Theme> = {
  fontSize: "1.3rem",
  width: "22.35%",
  lineHeight: "1.35rem",
};

export const deliverySearchButtonStyles: SxProps<Theme> = {
  position: "absolute",
  bottom: "15px",
  right: "15px",
  width: "200px",
  fontSize: "1.3rem",
  marginTop: "12px",
  pl: "0px",
  pr: "0px",
};

export const deliverySearchCardStyles: SxProps<Theme> = {
  marginBottom: "24px",
  py: 2,
  paddingLeft: "4.8%",
  paddingRight: "4.8%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const deliverySearchTableContainerStyles: SxProps<Theme> = {
  width: "100%",
  height: "calc(100% - 115px)",
  "::-webkit-scrollbar": {
    width: "3px",
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    backgroundColor: "#C4C4C4",
  },
};

export const deliveryDetailCardIconStyles: SxProps<Theme> = {
  position: "absolute",
  right: "53px",
  top: "44px",
  bottom: "44px",
  width: "24px",
  height: "24px",
  cursor: "pointer",
  color: aepPalette.base?.main,
};

export const deliveryDetailCardTableContainerStyles: SxProps<Theme> = {
  marginBottom: "52px",
  width: "100%",
  "::-webkit-scrollbar": {
    width: "3px",
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    backgroundColor: "#C4C4C4",
  },
};

export const deliveryDetailCardBoxStyles: SxProps<Theme> = {
  width: "100%",
  display: "flex",
  height: "112px",
  alignContent: "center",
  flexWrap: "wrap",
};

export const deliveryDetailCardTableBodyStyles: SxProps<Theme> = {
  "tr:last-child>td:first-of-type": {
    borderRadius: "0px",
  },
  "tr:last-child>td:last-child": {
    borderRadius: "0px",
  },
};

export const textFieldStyles: SxProps<Theme> = {
  fontSize: "1.3rem",
  width: "22.35%",
  lineHeight: "1.35rem",
};
