import { useEffect, useState } from "react";
import { DatePicker as MuiDatePicker } from "@mui/lab";
import DateRangePicker, { DatePickerDate } from "./DateRangePicker";
import TextField from "../textfield/TextField";
import { Box } from "@mui/material";
// HINT: set up parent component with following state, and pass setVal to DatePicker
// const [val, setVal] = useState<Date | DatePickerDate[] | undefined>();

interface DatePickerProps {
  range?: boolean;
  setValue: React.Dispatch<
    React.SetStateAction<Date | DatePickerDate[] | undefined>
  >;
  label: string;
  reset?: boolean;
}

const DatePicker = (props: DatePickerProps) => {
  const { range, setValue, label, reset } = props;
  const [dateRange, setDateRange] = useState<DatePickerDate[]>([null, null]);
  const [date, setDate] = useState<Date | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const toggleDatePicker = (): void => setIsDatePickerOpen(!isDatePickerOpen);

  useEffect(() => {
    range ? setValue(dateRange) : setValue(date as Date);
  }, [dateRange, date]);

  useEffect(() => {
    if (reset) {
      setDateRange([null, null]);
    }
  }, [reset]);

  return (
    <Box sx={{ mx: 1 }}>
      {range ? (
        <DateRangePicker
          open={isDatePickerOpen}
          toggleDatePicker={toggleDatePicker}
          label={label}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      ) : (
        <MuiDatePicker
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          label={label}
          onChange={(newValue: Date | null) => {
            setDate(newValue);
            toggleDatePicker();
          }}
          InputAdornmentProps={{
            onClick: toggleDatePicker,
          }}
          open={isDatePickerOpen}
          renderInput={(params) => <TextField {...params} />}
          value={date}
        />
      )}
    </Box>
  );
};

export default DatePicker;
