import { TableRow, TableCell, TableFooter, Typography } from "@mui/material";
import { toCurrency } from "../../../service/CustomNumberService";

type DeliveryDetailTableFooterProps = {
  sumNetto: number;
};

const DeliveryDetailTableFooter = ({
  sumNetto,
}: DeliveryDetailTableFooterProps) => (
  <TableFooter
    sx={{
      "& > tr > td": {
        p: 1,
      },
    }}
  >
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography variant="bodyBold">Gesamt (Netto)</Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
    <TableRow sx={{ "& > td": { borderBottom: "0px" } }}>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
      </TableCell>
      <TableCell>
      </TableCell>
      <TableCell>
        <Typography variant="bodyBold">{toCurrency(sumNetto)}</Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableFooter>
);

export default DeliveryDetailTableFooter;
