import InfoCard from "../../../components/card/InfoCard";
import { SxProps, Theme, Tooltip } from "@mui/material";
import { InvoiceDto } from "../../../../api";
import { toCurrency } from "../../../service/CustomNumberService";

interface InvoiceInfoCardProps {
  fadeIn: boolean;
  invoice: InvoiceDto;
  sx?: SxProps<Theme>;
}

const invoiceInfoCardStyles = {
  mb: 2,
};

export const InvoiceInfoCard = (props: InvoiceInfoCardProps): JSX.Element => {
  const { invoice } = props;
  const { invoiceId, invoiceDate, billingAmount, invoiceType } = invoice;

  const tooltipContent = `Rechnungssumme: ${toCurrency(
    billingAmount || 0
  )}\nBelegart: ${invoiceType}`;

  return (
    <Tooltip title={tooltipContent} arrow>
      <div>
        <InfoCard
          download
          header="Rechnungs-Nr."
          headerValue={invoiceId}
          key1="Rechnungsdatum"
          key1Value={invoiceDate}
          sx={invoiceInfoCardStyles}
          documentType="SR"
        />
      </div>
    </Tooltip>
  );
};
