import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Typography,
  Theme,
} from "@mui/material";
import AnimatedTableRow from "./AnimatedTableRow";

interface TableAccordionRowProps {
  header: string;
  children: JSX.Element;
  expanded?: boolean;
  handleExpansion?: (event: React.SyntheticEvent, expanded: boolean) => void;
  transitionDelay?: number;
  sx?: SxProps<Theme>;
}

const TableAccordionRow = (props: TableAccordionRowProps) => {
  const { children, expanded, handleExpansion, header, transitionDelay, sx } =
    props;

  return (
    <AnimatedTableRow transitionDelay={transitionDelay} sx={sx}>
      <td style={{ padding: 0 }}>
        <Accordion onChange={handleExpansion} expanded={expanded}>
          <AccordionSummary sx={{ borderBottom: "none", borderTop: "none" }}>
            <Typography variant="body2" sx={{ py: 1.5, pl: 2.1 }}>
              {header}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
              borderBottom: "none",
              borderTop: "none",
            }}
          >
            {children}
          </AccordionDetails>
        </Accordion>
      </td>
    </AnimatedTableRow>
  );
};

export default TableAccordionRow;
