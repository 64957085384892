import { useContext, useEffect, useState } from "react";
import { Box, Stack, Fade } from "@mui/material";
import { DeliveryShipmentInfoCard } from "./DeliveryShipmentInfoCard";
import DeliveryIcon from "../../../components/icons/DeliveryIcon";
import DashboardCard from "./DashboardCard";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { DeliveryDto } from "../../../../api";
import { CardProps } from "../../../components/card/Card";
import CustomerContext from "../../../contexts/CustomerContext";
import { Skeleton } from "@mui/material";
import { fadeTimeout } from "../../../App";
import SnackbarContext from "../../../contexts/SnackbarContext";
import UserContext from "../../../contexts/UserContext";
import { UserDtoPermissionsEnum } from "../../../../api";

const LastDeliveriesCard = (props: CardProps): JSX.Element => {
  const api = new ApiWrapper().deliveriesControllerApi();
  const [deliveries, setDeliveries] = useState<DeliveryDto[]>();
  const [loading, setLoading] = useState(true);
  const { customerNumber } = useContext(CustomerContext);
  const { handleError } = useContext(SnackbarContext);
  const { checkUserRole } = useContext(UserContext);

  useEffect(() => {
    setDeliveries([]);
    if (customerNumber) {
      setLoading(true);
      api
        .getAllDeliveries(customerNumber)
        .then((res) => {
          setDeliveries(res.data);
        })
        .catch(() =>
          handleError(
            "Aktuell können keine Daten angezeigt werden. Bitte kontaktieren Sie das Service Center."
          )
        )
        .finally(() => setLoading(false));
    }
  }, [customerNumber]);

  const renderInfoCards = () => {
    if (loading) {
      return (
        <Fade in={loading} timeout={fadeTimeout}>
          <Stack>
            <Skeleton animation="wave" variant="text" height="65px" />
            <Skeleton animation="wave" variant="text" height="65px" />
            <Skeleton animation="wave" variant="text" height="65px" />
          </Stack>
        </Fade>
      );
    }
    return deliveries?.map((delivery, index): JSX.Element | null => {
      return (
        <Fade
          key={`lastDeliveriesCard-${index}`}
          in={!loading}
          timeout={fadeTimeout}
        >
          <div>
            <DeliveryShipmentInfoCard
              deliveryNo={delivery.deliveryNoteId}
              deliveryDate={delivery.deliveryDate as string}
            />
          </div>
        </Fade>
      );
    });
  };

  return checkUserRole([UserDtoPermissionsEnum.Lieferscheine]) ? (
    <DashboardCard
      loading={loading}
      header="Letzte Lieferungen"
      icon={<DeliveryIcon sx={{ height: "30px", width: "35px" }} />}
      buttonText="Alle Lieferungen"
      to="/bestellungen/lieferungen"
      {...props}
    >
      <Box sx={{ mt: 4, mr: 3, mb: 8 }}>{renderInfoCards()}</Box>
    </DashboardCard>
  ) : (
    <></>
  );
};

export default LastDeliveriesCard;
