import { Box, SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

interface IconButtonProps {
  disabled?: boolean;
  icon: JSX.Element;
  onClick?: (e: any) => void;
  sx?: SxProps<Theme>;
}

const IconButton = (props: IconButtonProps) => {
  const { disabled, icon, onClick, sx } = props;

  return (
    <Box
      sx={{
        cursor: "pointer",
        borderRadius: "5px",
        px: 0.75,
        py: 0.5,
        "&:hover, &.Mui-focusVisible": {
          backgroundColor: aepPalette.base.lighter,
        },
        ...sx,
        width: "fit-content",
        height: "fit-content",
        zIndex: 5,
      }}
      onClick={disabled ? () => null : onClick}
    >
      {icon}
    </Box>
  );
};

export default IconButton;
