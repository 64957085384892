import Card from "./Card";
import { Typography, Theme, SxProps } from "@mui/material";
import SalesContact from "../../../strapi/models/SalesContact";
import ImageWrapper from "../../wrappers/ImageWrapper";
import {} from "@mui/system";

interface ContactCardProps {
  baseUrl: string;
  contactInfo: SalesContact;
  sx?: SxProps<Theme>;
}

const SalesContactCard = (props: ContactCardProps): JSX.Element => {
  const { baseUrl, contactInfo, sx } = props;
  const bildUrl = contactInfo.bild.data?.attributes?.url;
  const img = `${baseUrl}/contentmanager${bildUrl}`;

  return (
    <Card
      sx={{
        px: 3,
        ...sx,
      }}
    >
      <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
        Ihr Ansprechpartner direkt vor Ort
      </Typography>
      {bildUrl ? (
        <ImageWrapper
          containerSx={{ mb: 2 }}
          src={img}
          alt="contact-profile-picture"
          sx={{ width: "100%", maxHeight: "242px", objectFit: "cover" }}
        />
      ) : (
        <></>
      )}
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {contactInfo?.vorname.toUpperCase() +
          " " +
          contactInfo?.nachname.toUpperCase()}
      </Typography>
      <Typography variant="body2">Außendienstmitarbeiter</Typography>
      <Typography variant="body2">{contactInfo?.telefonnummer}</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {contactInfo?.emailaddresse}
      </Typography>
    </Card>
  );
};

export default SalesContactCard;
