import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const InvoiceIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 20 21" {...props}>
    <path d="M16.1817 4.86814L11.0606 0.095509C11.0303 0.0317888 10.9395 0 10.8788 0H0.303016C0.121236 0 0 0.127294 0 0.318166V20.6818C0 20.8727 0.121233 21 0.303016 21H15.9699C16.1517 21 16.2729 20.8727 16.2729 20.6818V5.12268C16.2729 5.02731 16.2122 4.93181 16.182 4.86808L16.1817 4.86814ZM11.1816 1.01839L15.1817 4.77289H11.1816V1.01839ZM0.605868 20.3639V0.636635H10.5756V5.12315C10.5756 5.31401 10.6968 5.44131 10.8786 5.44131H15.6665V20.3641L0.605868 20.3639Z" />
    <path d="M18.4243 4.54988C17.5455 4.54988 16.8485 5.28173 16.8485 6.20442V15.7816V15.8771L18.1213 20.7772C18.1515 20.9045 18.2728 20.9999 18.4243 20.9999C18.5758 20.9999 18.6668 20.9044 18.7273 20.7772L20.0001 15.8452V15.7497V6.17255C20.0001 5.28162 19.3031 4.5498 18.4243 4.5498L18.4243 4.54988ZM17.4546 8.20907H19.394V15.432H17.4546V8.20907ZM18.4243 5.18637C18.9697 5.18637 19.394 5.63183 19.394 6.20457V7.60453H17.4545V6.20457C17.4545 5.63183 17.9091 5.18637 18.4242 5.18637H18.4243ZM18.4243 19.4728L17.5455 16.1001H19.3031L18.4243 19.4728Z" />
    <path d="M1.84843 2.41807H9.09096C9.27274 2.41807 9.39398 2.29077 9.39398 2.0999C9.39398 1.90903 9.27275 1.78174 9.09096 1.78174H1.84843C1.66665 1.78174 1.54541 1.90903 1.54541 2.0999C1.54541 2.29077 1.69692 2.41807 1.84843 2.41807Z" />
    <path d="M1.84843 4.1681H9.09096C9.27274 4.1681 9.39398 4.0408 9.39398 3.84992C9.39398 3.65904 9.27275 3.53174 9.09096 3.53174H1.84843C1.66665 3.53174 1.54541 3.65904 1.54541 3.84992C1.54541 4.00901 1.69692 4.1681 1.84843 4.1681Z" />
    <path d="M1.84843 5.9181H9.09096C9.27274 5.9181 9.39398 5.7908 9.39398 5.59992C9.39398 5.40904 9.27275 5.28174 9.09096 5.28174H1.84843C1.66665 5.28174 1.54541 5.40904 1.54541 5.59992C1.54541 5.79095 1.69692 5.9181 1.84843 5.9181Z" />
    <path d="M14.3938 7.57251H1.87833C1.69655 7.57251 1.57532 7.6998 1.57532 7.89068V19.0909C1.57532 19.2817 1.69655 19.409 1.87833 19.409H14.3938C14.5756 19.409 14.6968 19.2817 14.6968 19.0909V7.89105C14.6968 7.73197 14.5453 7.57274 14.3938 7.57274V7.57251ZM4.87835 13.1728H2.18134V10.9137H4.87835V13.1728ZM5.48439 10.9137H14.0905V13.1728H5.48439V10.9137ZM2.18137 13.8091H4.87839V16.0682H2.18137V13.8091ZM5.48439 13.8091H14.0905V16.0682H5.48439V13.8091ZM14.0905 10.2774H5.48439V8.20915H14.0905V10.2774ZM4.87832 8.20915V10.2774H2.1813V8.20915H4.87832ZM2.1813 16.7044H4.87832V18.7726H2.1813V16.7044ZM5.48432 18.7726V16.7044H14.0904L14.0906 18.7726H5.48432Z" />
  </IconWrapper>
);

export default InvoiceIcon;
