import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SxProps, Theme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TextButton from "./TextButton";
import { aepPalette } from "../../../styles/aepPalette";

interface NavButtonProps {
  back?: boolean;
  buttonText: string;
  onClick?: () => void;
  to?: string;
  small?: boolean;
  sx?: SxProps<Theme>;
}

const NavButton = (props: NavButtonProps) => {
  const { back, buttonText, to, small, sx, onClick } = props;

  return (
    <Link to={to || ""}>
      <TextButton
        onClick={onClick}
        sx={{
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: small ? "12px" : "",
          whiteSpace: "nowrap",
          py: 1,
          px: 2,
          "&:hover, &.Mui-focusVisible": {
            backgroundColor: aepPalette.base.lighter,
          },
          ...sx,
        }}
      >
        {back ? (
          <ArrowBackIcon
            sx={{
              marginLeft: "-2px",
              marginRight: "15px",
              fontSize: "2em",
              color: aepPalette.base.main,
            }}
          />
        ) : (
          <></>
        )}
        <Typography>{buttonText}</Typography>
        {!back ? (
          <ArrowForwardIcon
            sx={{
              marginLeft: "8px",
              fontSize: small ? "0.9rem" : "2rem",
            }}
          />
        ) : (
          <></>
        )}
      </TextButton>
    </Link>
  );
};

export default NavButton;
