import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DeliveryDto } from "../../../../api";
import { CustomTableProps } from "../../../components/table/TableContainer";
import DeliverySearchTableRow from "./DeliverySearchTableRow";

interface DeliverySearchTableProps extends CustomTableProps {
  deliveries: DeliveryDto[];
  loading: boolean;
}

const DeliverySearchTable = (props: DeliverySearchTableProps) => {
  const { deliveries, handleSelectRow, isSelected } = props;

  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "7%" }} align="center">
            <Checkbox disabled sx={{ opacity: 0 }} />
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <Typography variant="body2">Lieferscheinnummer</Typography>
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <Typography variant="body2">Belegdatum</Typography>
          </TableCell>
          <TableCell sx={{ width: "25%" }}>
            <Typography variant="body2">Status</Typography>
          </TableCell>
          <TableCell sx={{ width: "2%" }}></TableCell>
          <TableCell sx={{ width: "8%" }}></TableCell>
          <TableCell sx={{ width: "8%" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {deliveries.map((row, index) => (
          <DeliverySearchTableRow
            row={row}
            key={`delivery-${index}`}
            isSelected={isSelected}
            handleSelectRow={handleSelectRow}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default DeliverySearchTable;
