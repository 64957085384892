import { TableRow, TableCell, Checkbox, Typography } from "@mui/material";
import { OrderNotePositionDto } from "../../../../api";
import { OrderPositionStatus } from "./OrderPositionsTable";

interface OrderPositionsTableRowProps {
  positionData: OrderNotePositionDto;
  loading?: boolean;
  isSelected: boolean;
  handleSelectRow: (id: string) => void;
}

const OrderPositionsTableRow = (props: OrderPositionsTableRowProps) => {
  const { positionData, handleSelectRow, isSelected } = props;
  const positionId = positionData.positionId;

  return (
    <TableRow>
      <TableCell align="center">
        {positionData.stateNumber != undefined &&
          positionData.stateNumber <= OrderPositionStatus.OPEN_NUMBER && (
            <Checkbox
              onClick={() => handleSelectRow(positionId)}
              checked={isSelected}
            />
          )}
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.pzn}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.quantity}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.articleName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.state}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default OrderPositionsTableRow;
