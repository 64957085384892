import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import Contact from "./models/Contact";
import Article from "./models/Article";
import Offer from "./models/Offer";
import StrapiResponse from "./models/StrapiResponse";
import Banner from "./models/Banner";
import Download from "./models/Download";
import SalesContact from "./models/SalesContact";
import DownloadCategory from "./models/DownloadCategory";

class StrapiApi {
  constructor(private token: string, public customerNumber: string) {}

  axiosRequestConfig: AxiosRequestConfig = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseURL: window._env_.REACT_APP_API_URL,
    headers: {
      Authorization: "Bearer " + this.token,
    },
  };

  public getAllArticles(): Promise<AxiosResponse<StrapiResponse<Article>>> {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-articles?populate=*&sort[0]=gueltigVon%3Adesc`,
    });
  }

  public getLatesttArticle(): Promise<AxiosResponse<StrapiResponse<Article>>> {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-articles?populate=*&sort[0]=gueltigVon%3Adesc&pagination[pageSize]=1&pagination[page]=1`,
    });
  }

  public getCategorizedOffers(): Promise<AxiosResponse<StrapiResponse<Offer>>> {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-offers?populate=*&filters[kategorie][$notNull]=true&sort[0]=kategorie%3Adesc`,
    });
  }

  public getUncategorizedOffers(): Promise<
    AxiosResponse<StrapiResponse<Offer>>
  > {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-offers?populate=*&filters[kategorie][$null]=true&sort[0]=gueltigVon%3Adesc`,
    });
  }

  public getDashboardOffers(): Promise<AxiosResponse<StrapiResponse<Offer>>> {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-offers?populate=*&filters[dashboard][$eq]=true`,
    });
  }

  public getTopOffer(): Promise<AxiosResponse<StrapiResponse<Offer>>> {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-offers?populate=*&filters[kategorie][$eq]=Topartikel`,
    });
  }

  public getContacts(): Promise<AxiosResponse<StrapiResponse<Contact>>> {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-contacts?populate=*`,
    });
  }

  public getSalesContacts(): Promise<
    AxiosResponse<StrapiResponse<SalesContact>>
  > {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-sales-contacts?populate=*&sort[0]=createdAt%3Adesc`,
    });
  }

  public getBanners(): Promise<AxiosResponse<StrapiResponse<Banner>>> {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-banners?populate=*&sort[0]=gueltigVon%3Adesc`,
    });
  }

  public getDownloads(
    selectedTab: string,
    pzn: string,
    searchTerms: string[]
  ): Promise<AxiosResponse<StrapiResponse<Download>>> {
    let queryString = "";
    if (selectedTab) {
      queryString += `&filters[kategorie][name][$eq]=${selectedTab}`;
    }
    if (pzn) {
      queryString += `&filters[pzn][$eq]=${pzn}`;
    }

    if (searchTerms.length > 0) {
      searchTerms.forEach((term: string) => {
        queryString += `&filters[schlagwoerter][$containsi]=${term}`;
      });
    }

    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-downloads?populate=*${queryString}`,
    });
  }

  public getDownloadCategories(): Promise<
    AxiosResponse<StrapiResponse<DownloadCategory>>
  > {
    return axios({
      ...this.axiosRequestConfig,
      method: "get",
      url: `/contentmanager/${this.customerNumber}/api/aep-download-categories?populate=*`,
    });
  }
}

export default StrapiApi;
