import { useState, BaseSyntheticEvent, useEffect } from "react";
import { PickersDayProps } from "@mui/lab/PickersDay";
import DatePicker from "@mui/lab/DatePicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TextField from "../textfield/TextField";
import { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";
import { rootBoxStyles } from "./dateRangePickerStyles";
import {
  renderWeekPickerDay,
  isValidDateString,
  reformatDateString,
  stringToDate,
  ddmmyyyyFormat,
} from "../../service/CustomDateService";

export type DatePickerDate = Date | null;
interface DateRangePickerProps {
  dateRange: DatePickerDate[];
  setDateRange: Dispatch<SetStateAction<DatePickerDate[]>>;
  label: string;
  open: boolean;
  toggleDatePicker: () => void;
}

const DateRangePicker = (props: DateRangePickerProps): JSX.Element => {
  const { dateRange, setDateRange, open, toggleDatePicker, label } = props;
  const [dateRangeString, setDateRangeString] = useState("");
  const [errMsg, setErrMsg] = useState<string>("");

  const minDate = new Date(1900, 0, 1);
  const maxDate = new Date(2100, 0, 1);

  const isValidDateStringInRange = (dateString: string) =>
    isValidDateString(dateString, minDate, maxDate);

  useEffect(() => {
    if (dateRange.filter((val) => val).length === 0) {
      setDateRangeString("");
    }
  }, [dateRange]);

  const validateInput = (e: BaseSyntheticEvent) => {
    const input = e.target?.value;
    if (input) {
      if (!/\d\d?\.\d\d?\.\d\d\d\d\s?-\s?\d\d?\.\d\d?\.\d\d\d\d/.test(input)) {
        setErrMsg("ungültiger Zeitraum");
      } else {
        let startDateString = input.split("-")[0].trim();
        let endDateString = input.split("-")[1]?.trim();
        if (!isValidDateStringInRange(startDateString)) {
          setErrMsg("ungültiges Startdatum");
        } else if (!isValidDateStringInRange(endDateString)) {
          setErrMsg("ungültiges Enddatum");
        } else if (
          stringToDate(startDateString) > stringToDate(endDateString)
        ) {
          setErrMsg("das Enddatum muss nach dem Startdatum liegen");
        } else {
          startDateString = reformatDateString(startDateString);
          endDateString = reformatDateString(endDateString);
          setDateRangeString(`${startDateString} - ${endDateString}`);
          setDateRange([
            stringToDate(startDateString),
            stringToDate(endDateString),
          ]);
        }
      }
    }
  };

  const handleStringChange = (e: BaseSyntheticEvent) => {
    let input = e.target?.value;
    let startDate: Date | null = null;
    let endDate: Date | null = null;

    let startDateString = input.trim();
    let endDateString = "";

    // if no input
    if (!input) {
      setDateRange([null, null]);
    }

    setErrMsg("");
    if (/[^\d\s.-]/.test(input)) {
      setErrMsg("nur Zahlen, Punkte und Bindestrich sind erlaubt");
      setDateRangeString(input.replaceAll(/[^\d\s.-]/gi, ""));
      return;
    }

    if (input.length > dateRangeString.length) {
      if (input.indexOf("-") !== -1) {
        startDateString = input.split("-")[0].trim();
        if (isValidDateStringInRange(startDateString)) {
          startDateString = reformatDateString(startDateString) + " - ";
          startDate = stringToDate(startDateString);

          endDateString = input.split("-")[1].trim();
          if (endDateString.length === 10) {
            if (isValidDateStringInRange(endDateString)) {
              endDateString = reformatDateString(endDateString);
              endDate = stringToDate(endDateString);
            } else {
              setErrMsg("ungültiges Enddatum");
            }
          }
        } else {
          setErrMsg("ungültiges Startdatum");
        }
      } else {
        if (
          startDateString.length === 10 ||
          input.endsWith("-") ||
          input.endsWith(" ")
        ) {
          if (isValidDateStringInRange(startDateString)) {
            startDateString = reformatDateString(startDateString) + " - ";
            startDate = stringToDate(startDateString);
          } else {
            setErrMsg("ungültiges Startdatum");
          }
        }
      }

      if (errMsg.length === 0) {
        setDateRangeString(startDateString + endDateString);
        if (startDate && endDate) {
          if (startDate?.getTime() > endDate?.getTime()) {
            setDateRange([startDate, null]);
            setErrMsg("das Enddatum muss nach dem Startdatum liegen");
          } else {
            setDateRange([startDate, endDate]);
          }
        } else if (startDate) {
          setDateRange([startDate, null]);
        }
      }
    } else {
      if (/\s*-\s*$/.test(input)) {
        input = input.replaceAll(/[\s-]/gi, "");
      }
      setDateRangeString(input);
    }
  };

  const handleDateChange = (selectedDate: Date | null): void => {
    setErrMsg("");
    if (dateRange[0] && !dateRange[1] && selectedDate instanceof Date) {
      if (dateRange[0].getDay() === selectedDate.getDay()) {
        // if range is one day
        setDateRange([dateRange[0], selectedDate]);
        setDateRangeString(
          `${ddmmyyyyFormat(dateRange[0])} - ${ddmmyyyyFormat(selectedDate)}`
        );
      } else if (dateRange[0].getTime() < selectedDate.getTime()) {
        // if selected end date falls after start date, set new start date
        setDateRange([dateRange[0], selectedDate]);
        setDateRangeString(
          `${ddmmyyyyFormat(dateRange[0])} - ${ddmmyyyyFormat(selectedDate)}`
        );
        open && toggleDatePicker();
      } else {
        // set new start date
        setDateRange([selectedDate, null]);
        setDateRangeString(`${ddmmyyyyFormat(selectedDate)} - `);
      }
    } else {
      // reject invalid end date selection
      setDateRange([selectedDate, null]);
      setDateRangeString(`${ddmmyyyyFormat(selectedDate)} - `);
    }
  };

  return (
    <Box id="dateRangePickerAnchor" sx={rootBoxStyles}>
      <TextField
        error={!!errMsg}
        helperText={errMsg}
        inputProps={{ maxLength: 23 }}
        label={label}
        onChange={handleStringChange}
        onBlur={validateInput}
        placeholder="dd.mm.yyyy - dd.mm.yyyy"
        sx={{ width: "100%" }}
        value={dateRangeString}
      >
        <DateRangeIcon
          sx={{
            position: "absolute",
            top: 21,
            right: 0,
            cursor: "pointer",
            "&:hover": { color: aepPalette.secondary.main },
          }}
          onClick={toggleDatePicker}
        />
      </TextField>
      <DatePicker
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleDateChange}
        open={open}
        PopperProps={{
          anchorEl: document.getElementById("dateRangePickerAnchor"),
        }}
        renderDay={(
          date: Date,
          selectedDates: Array<Date | null>,
          pickersDayProps: PickersDayProps<Date>
        ) =>
          renderWeekPickerDay(date, selectedDates, pickersDayProps, dateRange)
        }
        renderInput={() => <></>}
        showTodayButton
        clearable
        value={dateRange[0] === null ? "" : dateRange[0]}
      />
    </Box>
  );
};

export default DateRangePicker;
