import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { MessageResponse } from "../../../../api";
import { aepPalette } from "../../../../styles/aepPalette";
import Card, { CardProps } from "../../../components/card/Card";
import CustomerContext from "../../../contexts/CustomerContext";
import SnackbarContext from "../../../contexts/SnackbarContext";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { overviewCardStyles, verticalDividerStyles } from "../postBoxStyles";
import MessageOverview from "./MessageOverview";

interface MessagesOverviewCardProps extends CardProps {
  loading: boolean;
  messages?: MessageResponse[];
  selectedMessage: number;
  setSelectedMessage: React.Dispatch<React.SetStateAction<number>>;
}

const MessagesOverviewCard = (props: MessagesOverviewCardProps) => {
  const { loading, messages, setSelectedMessage, selectedMessage } = props;
  const api = new ApiWrapper().messageControllerApi();
  const { customerNumber, updateUnreadMessageCount } =
    useContext(CustomerContext);
  const { handleHttpError } = useContext(SnackbarContext);

  const handleClick = (index: number): void => {
    // mark as read if unread
    if (messages) {
      api
        .patchMessage(customerNumber, messages[index].id, {
          readByPortal: true,
        })
        // update unread message count
        .then(() => {
          api.getUnreadMessageCount(customerNumber).then((res) => {
            updateUnreadMessageCount(res.data.count);
          });
        })
        .catch(handleHttpError);
    }
    setSelectedMessage(index);
  };

  return (
    <Card loading={loading} sx={overviewCardStyles}>
      <Box
        sx={{
          position: "sticky",
          backgroundColor: aepPalette.secondary.lighter,
          top: 0,
          zIndex: 2,
        }}
      >
        <Typography sx={{ p: 3 }} variant="h2">
          Ihre Nachrichten
        </Typography>
        <Box sx={verticalDividerStyles} />
      </Box>
      {(messages?.length || 0) > 0 ? (
        messages?.map((message, index) => (
          <MessageOverview
            unread={!messages[index].readByPortal}
            key={`message-${index}`}
            selectedMessage={selectedMessage}
            index={index}
            message={message}
            onClick={() => handleClick(index)}
          />
        ))
      ) : (
        <Typography sx={{ p: 3 }}>Keine Nachrichten</Typography>
      )}
    </Card>
  );
};

export default MessagesOverviewCard;
