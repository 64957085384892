import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReturnPositionDto } from "../../../../api";
import {
  reformatDateString,
  utcToGermanDateString,
} from "../../../service/CustomDateService";
import { toCurrency } from "../../../service/CustomNumberService";

interface ReturnSearchTableProps {
  returnPositionsData?: ReturnPositionDto[];
  key: string;
}

const ReturnSearchTable = (props: ReturnSearchTableProps) => {
  const { returnPositionsData } = props;

  return (
    <Table aria-label="return positions table">
      <TableHead>
        <TableRow
          sx={{
            "& > th": {
              pl: 1,
            },
          }}
        >
          <TableCell sx={{ width: "12.5%" }}>
            <Typography variant="bodyBold">Lieferscheinnr.</Typography>
          </TableCell>
          <TableCell sx={{ width: "12.5%" }}>
            <Typography variant="bodyBold">Liefer-Datum</Typography>
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Typography variant="bodyBold">PZN</Typography>
          </TableCell>
          <TableCell sx={{ width: "8%" }}>
            <Typography variant="bodyBold">Menge</Typography>
          </TableCell>
          <TableCell sx={{ width: "17%" }}>
            <Typography variant="bodyBold">Artikelbezeichung</Typography>
          </TableCell>
          <TableCell sx={{ width: "12.5%" }}>
            <Typography variant="bodyBold">EK-Preis</Typography>
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <Typography variant="bodyBold">Beschreibung</Typography>
          </TableCell>
          <TableCell sx={{ width: "12.5%" }}>
            <Typography variant="bodyBold">Lösung</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {returnPositionsData?.map((returnPositionData, index) => (
          <TableRow
            key={`return-position-${index}`}
            sx={{
              "& > td": {
                pl: 1,
              },
            }}
          >
            <TableCell>
              <Typography variant="body2">
                {returnPositionData.deliveryNoteId || ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {utcToGermanDateString(returnPositionData.deliveryDate, true) ||
                  ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {returnPositionData.pzn || ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {returnPositionData.quantity || ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {returnPositionData.articleName || ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {toCurrency(returnPositionData?.price || null) || ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {returnPositionData.description || ""}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {returnPositionData.solution || ""}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ReturnSearchTable;
