import { SvgIcon, SvgIconProps } from "@mui/material";

const IconWrapper = (props: SvgIconProps) => {
  const { children, color, fontSize, sx, viewBox } = props;
  const styles = {
    color: color ? "" : "font.dark",
    ...sx,
  };

  return (
    <SvgIcon
      component="svg"
      fontSize={fontSize || "large"}
      sx={styles}
      viewBox={viewBox}
    >
      {children}
    </SvgIcon>
  );
};

export default IconWrapper;
