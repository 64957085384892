/* tslint:disable */
/* eslint-disable */
/**
 * AEP Portal Backend
 * AEP Portal Backend
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArticleAdmissionRequest
 */
export interface ArticleAdmissionRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleAdmissionRequest
     */
    'pzn': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleAdmissionRequest
     */
    'frequency': ArticleAdmissionRequestFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof ArticleAdmissionRequest
     */
    'amount': number;
}

/**
    * @export
    * @enum {string}
    */
export enum ArticleAdmissionRequestFrequencyEnum {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly'
}

/**
 * 
 * @export
 * @interface CancelDto
 */
export interface CancelDto {
    /**
     * 
     * @type {string}
     * @memberof CancelDto
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CancelDto
     */
    'orderPosition': string;
    /**
     * 
     * @type {string}
     * @memberof CancelDto
     */
    'pzn': string;
}
/**
 * 
 * @export
 * @interface CancelErrorDto
 */
export interface CancelErrorDto {
    /**
     * 
     * @type {CancelDto}
     * @memberof CancelErrorDto
     */
    'orderObject'?: CancelDto;
    /**
     * 
     * @type {number}
     * @memberof CancelErrorDto
     */
    'errorCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof CancelErrorDto
     */
    'errorMessage'?: string;
}
/**
 * 
 * @export
 * @interface CancellationDto
 */
export interface CancellationDto {
    /**
     * 
     * @type {string}
     * @memberof CancellationDto
     */
    'cancellationId': string;
    /**
     * 
     * @type {string}
     * @memberof CancellationDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellationDto
     */
    'orderDate'?: string;
    /**
     * 
     * @type {Array<CancellationPositionDto>}
     * @memberof CancellationDto
     */
    'cancellationPositions'?: Array<CancellationPositionDto>;
}
/**
 * 
 * @export
 * @interface CancellationPositionDto
 */
export interface CancellationPositionDto {
    /**
     * 
     * @type {string}
     * @memberof CancellationPositionDto
     */
    'positionId': string;
    /**
     * 
     * @type {string}
     * @memberof CancellationPositionDto
     */
    'articleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CancellationPositionDto
     */
    'pzn': string;
    /**
     * 
     * @type {string}
     * @memberof CancellationPositionDto
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof CancellationPositionDto
     */
    'stateNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof CancellationPositionDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface CounterDto
 */
export interface CounterDto {
    /**
     * 
     * @type {string}
     * @memberof CounterDto
     */
    'customerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterDto
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterDto
     */
    'creationTimestamp'?: string;
}
/**
 * 
 * @export
 * @interface DeliveryDto
 */
export interface DeliveryDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveryDto
     */
    'deliveryNoteId': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDto
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryDto
     */
    'deliveryState'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    'deliveryStateNumber'?: number;
    /**
     * 
     * @type {Array<DeliveryNotePositionDto>}
     * @memberof DeliveryDto
     */
    'deliveryNotePositions'?: Array<DeliveryNotePositionDto>;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    'sumLowTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    'sumHighTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDto
     */
    'sumNetto'?: number;
}
/**
 * 
 * @export
 * @interface DeliveryNotePositionDto
 */
export interface DeliveryNotePositionDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveryNotePositionDto
     */
    'positionId': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryNotePositionDto
     */
    'orderedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryNotePositionDto
     */
    'deliveredQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNotePositionDto
     */
    'articleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNotePositionDto
     */
    'pzn': string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryNotePositionDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNotePositionDto
     */
    'tax'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNotePositionDto
     */
    'mark'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNotePositionDto
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface DispensationDto
 */
export interface DispensationDto {
    /**
     * 
     * @type {string}
     * @memberof DispensationDto
     */
    'dispensationId': string;
    /**
     * 
     * @type {string}
     * @memberof DispensationDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DispensationDto
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DispensationDto
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {Array<DispensationPositionDto>}
     * @memberof DispensationDto
     */
    'dispensationPositions'?: Array<DispensationPositionDto>;
}
/**
 * 
 * @export
 * @interface DispensationPositionDto
 */
export interface DispensationPositionDto {
    /**
     * 
     * @type {string}
     * @memberof DispensationPositionDto
     */
    'positionId': string;
    /**
     * 
     * @type {string}
     * @memberof DispensationPositionDto
     */
    'articleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DispensationPositionDto
     */
    'pzn': string;
    /**
     * 
     * @type {string}
     * @memberof DispensationPositionDto
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof DispensationPositionDto
     */
    'stateNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof DispensationPositionDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface FurtherReceiptDto
 */
export interface FurtherReceiptDto {
    /**
     * 
     * @type {string}
     * @memberof FurtherReceiptDto
     */
    'docType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FurtherReceiptDto
     */
    'deliveryNoteId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FurtherReceiptDto
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FurtherReceiptDto
     */
    'pzn'?: string;
    /**
     * 
     * @type {string}
     * @memberof FurtherReceiptDto
     */
    'articleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FurtherReceiptDto
     */
    'supplierName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FurtherReceiptDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof FurtherReceiptDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof FurtherReceiptDto
     */
    'boxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof FurtherReceiptDto
     */
    'batchNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FurtherReceiptTypeEnum {
    Defekt = 'DEFEKT',
    Charge = 'CHARGE',
    Hochp = 'HOCHP',
    Reimp = 'REIMP',
    Tfg = 'TFG',
    Tierarz = 'TIERARZ',
    Aeppr = 'AEPPR',
    Aeppl = 'AEPPL',
    Logvg = 'LOGVG',
    Valuta = 'VALUTA',
    Uebw = 'UEBW',
    Index = 'INDEX',
    Btm = 'BTM',
    Maxnl = 'MAXNL'
}

/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'invoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'invoiceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'billingAmount'?: number;
}
/**
 * 
 * @export
 * @interface MessageCounterResponse
 */
export interface MessageCounterResponse {
    /**
     * 
     * @type {number}
     * @memberof MessageCounterResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface MessagePatchRequest
 */
export interface MessagePatchRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MessagePatchRequest
     */
    'readByPortal'?: boolean;
}
/**
 * 
 * @export
 * @interface MessageRequest
 */
export interface MessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    'userid': string;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {number}
     * @memberof MessageResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'userid': string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof MessageResponse
     */
    'state': number;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'creationDate': string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'origin': MessageResponseOriginEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MessageResponse
     */
    'readByPortal': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageResponseOriginEnum {
    Aep = 'AEP',
    Portal = 'PORTAL'
}

/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'orderNoteId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'orderDate'?: string;
    /**
     * 
     * @type {Array<OrderNotePositionDto>}
     * @memberof OrderDto
     */
    'orderNotePositions'?: Array<OrderNotePositionDto>;
}
/**
 * 
 * @export
 * @interface OrderNotePositionDto
 */
export interface OrderNotePositionDto {
    /**
     * 
     * @type {string}
     * @memberof OrderNotePositionDto
     */
    'positionId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderNotePositionDto
     */
    'articleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderNotePositionDto
     */
    'pzn': string;
    /**
     * 
     * @type {string}
     * @memberof OrderNotePositionDto
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderNotePositionDto
     */
    'stateNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderNotePositionDto
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface PharmacyDto
 */
export interface PharmacyDto {
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'customerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'mainPharmacyNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'bga'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'status'?: PharmacyDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PharmacyDto
     */
    'statusDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PharmacyDtoStatusEnum {
    New = 'NEW',
    Upd = 'UPD',
    Del = 'DEL',
    Akt = 'AKT'
}

/**
 * 
 * @export
 * @interface PharmacyOwnerDto
 */
export interface PharmacyOwnerDto {
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'customerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'status'?: PharmacyOwnerDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PharmacyOwnerDto
     */
    'statusDate'?: string;
    /**
     * 
     * @type {PharmacyDto}
     * @memberof PharmacyOwnerDto
     */
    'mainPharmacy'?: PharmacyDto;
    /**
     * 
     * @type {Array<PharmacyDto>}
     * @memberof PharmacyOwnerDto
     */
    'pharmacies'?: Array<PharmacyDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PharmacyOwnerDtoStatusEnum {
    New = 'NEW',
    Upd = 'UPD',
    Del = 'DEL',
    Akt = 'AKT'
}

/**
 * 
 * @export
 * @interface ReceiptDto
 */
export interface ReceiptDto {
    /**
     * 
     * @type {string}
     * @memberof ReceiptDto
     */
    'receiptId': string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDto
     */
    'receiptDate': string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDto
     */
    'receiptName': string;
}
/**
 * 
 * @export
 * @interface ReturnDto
 */
export interface ReturnDto {
    /**
     * 
     * @type {number}
     * @memberof ReturnDto
     */
    'returnNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReturnDto
     */
    'returnState'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDto
     */
    'registrationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDto
     */
    'collectionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnDto
     */
    'boxes'?: string;
    /**
     * 
     * @type {Array<ReturnPositionDto>}
     * @memberof ReturnDto
     */
    'returnPositions'?: Array<ReturnPositionDto>;
}
/**
 * 
 * @export
 * @interface ReturnPositionDto
 */
export interface ReturnPositionDto {
    /**
     * 
     * @type {string}
     * @memberof ReturnPositionDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPositionDto
     */
    'pzn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPositionDto
     */
    'articleName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReturnPositionDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturnPositionDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReturnPositionDto
     */
    'deliveryNoteId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPositionDto
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnPositionDto
     */
    'solution'?: string;
}
/**
 * 
 * @export
 * @interface SpecialOrderCreationResultDto
 */
export interface SpecialOrderCreationResultDto {
    /**
     * Special Orders which could be placed
     * @type {Array<SpecialOrderCreationResultItemDto>}
     * @memberof SpecialOrderCreationResultDto
     */
    'successfulOrders'?: Array<SpecialOrderCreationResultItemDto>;
    /**
     * Special Orders which could not be placed (eg Today is out of ValidityRange)
     * @type {Array<SpecialOrderCreationResultItemDto>}
     * @memberof SpecialOrderCreationResultDto
     */
    'failedOrders'?: Array<SpecialOrderCreationResultItemDto>;
}
/**
 * 
 * @export
 * @interface SpecialOrderCreationResultItemDto
 */
export interface SpecialOrderCreationResultItemDto {
    /**
     * 
     * @type {number}
     * @memberof SpecialOrderCreationResultItemDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderCreationResultItemDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderCreationResultItemDto
     */
    'pzn': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderCreationResultItemDto
     */
    'articleName': string;
}
/**
 * 
 * @export
 * @interface SpecialOrderRequest
 */
export interface SpecialOrderRequest {
    /**
     * The Id returned by SpecialOrderResponse
     * @type {number}
     * @memberof SpecialOrderRequest
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SpecialOrderRequest
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface SpecialOrderResponse
 */
export interface SpecialOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof SpecialOrderResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderResponse
     */
    'pzn': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderResponse
     */
    'articleName': string;
    /**
     * 
     * @type {number}
     * @memberof SpecialOrderResponse
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof SpecialOrderResponse
     */
    'aek': number;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderResponse
     */
    'packageSize': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderResponse
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOrderResponse
     */
    'validUntil': string;
}
/**
 * 
 * @export
 * @interface TriggerReturnRequestDto
 */
export interface TriggerReturnRequestDto {
    /**
     * The quantity of boxes that should be returned.
     * @type {number}
     * @memberof TriggerReturnRequestDto
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * Academic title of the user
     * @type {string}
     * @memberof UserDto
     */
    'title'?: string;
    /**
     * First name of the user
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * generated User name
     * @type {string}
     * @memberof UserDto
     */
    'userName'?: string;
    /**
     * Initial password for user account
     * @type {string}
     * @memberof UserDto
     */
    'initialPassword'?: string;
    /**
     * Email address of the user
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * User application permissions
     * @type {Array<string>}
     * @memberof UserDto
     */
    'permissions'?: Array<UserDtoPermissionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoPermissionsEnum {
    Admin = 'Admin',
    AepImBlick = 'AEP im Blick',
    Analysen = 'Analysen',
    Bestellungen = 'Bestellungen',
    Postfach = 'Postfach',
    Stammdaten = 'Stammdaten',
    Lieferscheine = 'Lieferscheine',
    Monatsberichte = 'Monatsberichte',
    Nachbelastungen = 'Nachbelastungen',
    Gutschriften = 'Gutschriften',
    Sammelrechnungen = 'Sammelrechnungen',
    Statistiken = 'Statistiken'
}

/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * Academic title of the user
     * @type {string}
     * @memberof UserProfileDto
     */
    'title'?: string;
    /**
     * First name of the user
     * @type {string}
     * @memberof UserProfileDto
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof UserProfileDto
     */
    'lastName': string;
    /**
     * generated User name
     * @type {string}
     * @memberof UserProfileDto
     */
    'userName'?: string;
    /**
     * Email address of the user
     * @type {string}
     * @memberof UserProfileDto
     */
    'email'?: string;
    /**
     * The password for user account
     * @type {string}
     * @memberof UserProfileDto
     */
    'password'?: string;
}

/**
 * AnalysisControllerApi - axios parameter creator
 * @export
 */
export const AnalysisControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} analysisType The type of Analysis eg PACKMON, UMSMON and BESTZEIT
         * @param {number} [months] Show data for the last n months
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData: async (customerNumber: string, analysisType: string, months?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getData', 'customerNumber', customerNumber)
            // verify required parameter 'analysisType' is not null or undefined
            assertParamExists('getData', 'analysisType', analysisType)
            const localVarPath = `/customers/{customerNumber}/analysis/{analysisType}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"analysisType"}}`, encodeURIComponent(String(analysisType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (months !== undefined) {
                localVarQueryParameter['months'] = months;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisControllerApi - functional programming interface
 * @export
 */
export const AnalysisControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} analysisType The type of Analysis eg PACKMON, UMSMON and BESTZEIT
         * @param {number} [months] Show data for the last n months
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getData(customerNumber: string, analysisType: string, months?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getData(customerNumber, analysisType, months, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysisControllerApi - factory interface
 * @export
 */
export const AnalysisControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} analysisType The type of Analysis eg PACKMON, UMSMON and BESTZEIT
         * @param {number} [months] Show data for the last n months
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData(customerNumber: string, analysisType: string, months?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getData(customerNumber, analysisType, months, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisControllerApi - object-oriented interface
 * @export
 * @class AnalysisControllerApi
 * @extends {BaseAPI}
 */
export class AnalysisControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {string} analysisType The type of Analysis eg PACKMON, UMSMON and BESTZEIT
     * @param {number} [months] Show data for the last n months
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisControllerApi
     */
    public getData(customerNumber: string, analysisType: string, months?: number, options?: AxiosRequestConfig) {
        return AnalysisControllerApiFp(this.configuration).getData(customerNumber, analysisType, months, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArticleAdmissionControllerApi - axios parameter creator
 * @export
 */
export const ArticleAdmissionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new article admission
         * @param {string} customerNumber The Customernumber
         * @param {ArticleAdmissionRequest} [articleAdmissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postArticleAdmission: async (customerNumber: string, articleAdmissionRequest?: ArticleAdmissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('postArticleAdmission', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/articleAdmission`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleAdmissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleAdmissionControllerApi - functional programming interface
 * @export
 */
export const ArticleAdmissionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleAdmissionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new article admission
         * @param {string} customerNumber The Customernumber
         * @param {ArticleAdmissionRequest} [articleAdmissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postArticleAdmission(customerNumber: string, articleAdmissionRequest?: ArticleAdmissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postArticleAdmission(customerNumber, articleAdmissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleAdmissionControllerApi - factory interface
 * @export
 */
export const ArticleAdmissionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleAdmissionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new article admission
         * @param {string} customerNumber The Customernumber
         * @param {ArticleAdmissionRequest} [articleAdmissionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postArticleAdmission(customerNumber: string, articleAdmissionRequest?: ArticleAdmissionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postArticleAdmission(customerNumber, articleAdmissionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleAdmissionControllerApi - object-oriented interface
 * @export
 * @class ArticleAdmissionControllerApi
 * @extends {BaseAPI}
 */
export class ArticleAdmissionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new article admission
     * @param {string} customerNumber The Customernumber
     * @param {ArticleAdmissionRequest} [articleAdmissionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleAdmissionControllerApi
     */
    public postArticleAdmission(customerNumber: string, articleAdmissionRequest?: ArticleAdmissionRequest, options?: AxiosRequestConfig) {
        return ArticleAdmissionControllerApiFp(this.configuration).postArticleAdmission(customerNumber, articleAdmissionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CancellationsControllerApi - axios parameter creator
 * @export
 */
export const CancellationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCancellations: async (customerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllCancellations', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/cancellations`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CancellationsControllerApi - functional programming interface
 * @export
 */
export const CancellationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CancellationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCancellations(customerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancellationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCancellations(customerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CancellationsControllerApi - factory interface
 * @export
 */
export const CancellationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CancellationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCancellations(customerNumber: string, options?: any): AxiosPromise<Array<CancellationDto>> {
            return localVarFp.getAllCancellations(customerNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CancellationsControllerApi - object-oriented interface
 * @export
 * @class CancellationsControllerApi
 * @extends {BaseAPI}
 */
export class CancellationsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CancellationsControllerApi
     */
    public getAllCancellations(customerNumber: string, options?: AxiosRequestConfig) {
        return CancellationsControllerApiFp(this.configuration).getAllCancellations(customerNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CounterControllerApi - axios parameter creator
 * @export
 */
export const CounterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounter: async (customerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getCounter', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/counter`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CounterControllerApi - functional programming interface
 * @export
 */
export const CounterControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CounterControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCounter(customerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCounter(customerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CounterControllerApi - factory interface
 * @export
 */
export const CounterControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CounterControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCounter(customerNumber: string, options?: any): AxiosPromise<CounterDto> {
            return localVarFp.getCounter(customerNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CounterControllerApi - object-oriented interface
 * @export
 * @class CounterControllerApi
 * @extends {BaseAPI}
 */
export class CounterControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounterControllerApi
     */
    public getCounter(customerNumber: string, options?: AxiosRequestConfig) {
        return CounterControllerApiFp(this.configuration).getCounter(customerNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveriesControllerApi - axios parameter creator
 * @export
 */
export const DeliveriesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of deliveries to return per page
         * @param {boolean} [sortByDateDesc] Sort deliveries by publish date descending
         * @param {string} [pzn] 
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [deliveryNoteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveries: async (customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, fromDate?: string, toDate?: string, deliveryNoteId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllDeliveries', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/deliveries`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }

            if (sortByDateDesc !== undefined) {
                localVarQueryParameter['sortByDateDesc'] = sortByDateDesc;
            }

            if (pzn !== undefined) {
                localVarQueryParameter['pzn'] = pzn;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (deliveryNoteId !== undefined) {
                localVarQueryParameter['deliveryNoteId'] = deliveryNoteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} deliveryNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryById: async (customerNumber: string, deliveryNoteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getDeliveryById', 'customerNumber', customerNumber)
            // verify required parameter 'deliveryNoteId' is not null or undefined
            assertParamExists('getDeliveryById', 'deliveryNoteId', deliveryNoteId)
            const localVarPath = `/customers/{customerNumber}/deliveries/{deliveryNoteId}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveriesControllerApi - functional programming interface
 * @export
 */
export const DeliveriesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveriesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of deliveries to return per page
         * @param {boolean} [sortByDateDesc] Sort deliveries by publish date descending
         * @param {string} [pzn] 
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [deliveryNoteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDeliveries(customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, fromDate?: string, toDate?: string, deliveryNoteId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDeliveries(customerNumber, pageNumber, pageLimit, sortByDateDesc, pzn, fromDate, toDate, deliveryNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} deliveryNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryById(customerNumber: string, deliveryNoteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryById(customerNumber, deliveryNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveriesControllerApi - factory interface
 * @export
 */
export const DeliveriesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveriesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of deliveries to return per page
         * @param {boolean} [sortByDateDesc] Sort deliveries by publish date descending
         * @param {string} [pzn] 
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [deliveryNoteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveries(customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, fromDate?: string, toDate?: string, deliveryNoteId?: string, options?: any): AxiosPromise<Array<DeliveryDto>> {
            return localVarFp.getAllDeliveries(customerNumber, pageNumber, pageLimit, sortByDateDesc, pzn, fromDate, toDate, deliveryNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} deliveryNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryById(customerNumber: string, deliveryNoteId: string, options?: any): AxiosPromise<DeliveryDto> {
            return localVarFp.getDeliveryById(customerNumber, deliveryNoteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveriesControllerApi - object-oriented interface
 * @export
 * @class DeliveriesControllerApi
 * @extends {BaseAPI}
 */
export class DeliveriesControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {number} [pageNumber] The page to fetch
     * @param {number} [pageLimit] The number of deliveries to return per page
     * @param {boolean} [sortByDateDesc] Sort deliveries by publish date descending
     * @param {string} [pzn] 
     * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [deliveryNoteId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesControllerApi
     */
    public getAllDeliveries(customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, fromDate?: string, toDate?: string, deliveryNoteId?: string, options?: AxiosRequestConfig) {
        return DeliveriesControllerApiFp(this.configuration).getAllDeliveries(customerNumber, pageNumber, pageLimit, sortByDateDesc, pzn, fromDate, toDate, deliveryNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {string} deliveryNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveriesControllerApi
     */
    public getDeliveryById(customerNumber: string, deliveryNoteId: string, options?: AxiosRequestConfig) {
        return DeliveriesControllerApiFp(this.configuration).getDeliveryById(customerNumber, deliveryNoteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispensationsControllerApi - axios parameter creator
 * @export
 */
export const DispensationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDispensations: async (customerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllDispensations', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/dispensations`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispensationsControllerApi - functional programming interface
 * @export
 */
export const DispensationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispensationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDispensations(customerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispensationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDispensations(customerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispensationsControllerApi - factory interface
 * @export
 */
export const DispensationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispensationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDispensations(customerNumber: string, options?: any): AxiosPromise<Array<DispensationDto>> {
            return localVarFp.getAllDispensations(customerNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispensationsControllerApi - object-oriented interface
 * @export
 * @class DispensationsControllerApi
 * @extends {BaseAPI}
 */
export class DispensationsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispensationsControllerApi
     */
    public getAllDispensations(customerNumber: string, options?: AxiosRequestConfig) {
        return DispensationsControllerApiFp(this.configuration).getAllDispensations(customerNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsControllerApi - axios parameter creator
 * @export
 */
export const DocumentsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Will download the given document from the DMS
         * @param {string} customerNumber The Customernumber
         * @param {string} documentId The AEP document id
         * @param {string} documentDate The creation date of the document
         * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getDocument', 'customerNumber', customerNumber)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', documentId)
            // verify required parameter 'documentDate' is not null or undefined
            assertParamExists('getDocument', 'documentDate', documentDate)
            const localVarPath = `/documents/{customerNumber}/{documentId}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (documentDate !== undefined) {
                localVarQueryParameter['documentDate'] = (documentDate as any instanceof Date) ?
                    (documentDate as any).toISOString().substr(0,10) :
                    documentDate;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will download the given document from the DMS
         * @param {string} customerNumber The Customernumber
         * @param {string} documentId The AEP document id
         * @param {string} documentDate The creation date of the document
         * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAsBase64: async (customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getDocumentAsBase64', 'customerNumber', customerNumber)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocumentAsBase64', 'documentId', documentId)
            // verify required parameter 'documentDate' is not null or undefined
            assertParamExists('getDocumentAsBase64', 'documentDate', documentDate)
            const localVarPath = `/documents/{customerNumber}/{documentId}/base64`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (documentDate !== undefined) {
                localVarQueryParameter['documentDate'] = (documentDate as any instanceof Date) ?
                    (documentDate as any).toISOString().substr(0,10) :
                    documentDate;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsControllerApi - functional programming interface
 * @export
 */
export const DocumentsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Will download the given document from the DMS
         * @param {string} customerNumber The Customernumber
         * @param {string} documentId The AEP document id
         * @param {string} documentDate The creation date of the document
         * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(customerNumber, documentId, documentDate, documentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will download the given document from the DMS
         * @param {string} customerNumber The Customernumber
         * @param {string} documentId The AEP document id
         * @param {string} documentDate The creation date of the document
         * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentAsBase64(customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentAsBase64(customerNumber, documentId, documentDate, documentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsControllerApi - factory interface
 * @export
 */
export const DocumentsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsControllerApiFp(configuration)
    return {
        /**
         * Will download the given document from the DMS
         * @param {string} customerNumber The Customernumber
         * @param {string} documentId The AEP document id
         * @param {string} documentDate The creation date of the document
         * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options?: any): AxiosPromise<any> {
            return localVarFp.getDocument(customerNumber, documentId, documentDate, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Will download the given document from the DMS
         * @param {string} customerNumber The Customernumber
         * @param {string} documentId The AEP document id
         * @param {string} documentDate The creation date of the document
         * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAsBase64(customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options?: any): AxiosPromise<string> {
            return localVarFp.getDocumentAsBase64(customerNumber, documentId, documentDate, documentType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsControllerApi - object-oriented interface
 * @export
 * @class DocumentsControllerApi
 * @extends {BaseAPI}
 */
export class DocumentsControllerApi extends BaseAPI {
    /**
     * Will download the given document from the DMS
     * @param {string} customerNumber The Customernumber
     * @param {string} documentId The AEP document id
     * @param {string} documentDate The creation date of the document
     * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsControllerApi
     */
    public getDocument(customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options?: AxiosRequestConfig) {
        return DocumentsControllerApiFp(this.configuration).getDocument(customerNumber, documentId, documentDate, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will download the given document from the DMS
     * @param {string} customerNumber The Customernumber
     * @param {string} documentId The AEP document id
     * @param {string} documentDate The creation date of the document
     * @param {'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB'} [documentType] The type of the document to download. Possible values are SR (Rechnung), LS(Lieferschein), NB(Nachbelastung), GS(Gutschrift), MB(Monatsbericht), TB(LS or GS or NB)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsControllerApi
     */
    public getDocumentAsBase64(customerNumber: string, documentId: string, documentDate: string, documentType?: 'LS' | 'SR' | 'NB' | 'GS' | 'MB' | 'TB', options?: AxiosRequestConfig) {
        return DocumentsControllerApiFp(this.configuration).getDocumentAsBase64(customerNumber, documentId, documentDate, documentType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FurtherReceiptsControllerApi - axios parameter creator
 * @export
 */
export const FurtherReceiptsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {FurtherReceiptTypeEnum} receiptType 
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of receipts to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {string} [articleName] 
         * @param {number} [deliveryNoteId] 
         * @param {string} [supplierName] 
         * @param {string} [batchNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurtherReceipts: async (customerNumber: string, receiptType: FurtherReceiptTypeEnum, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getFurtherReceipts', 'customerNumber', customerNumber)
            // verify required parameter 'receiptType' is not null or undefined
            assertParamExists('getFurtherReceipts', 'receiptType', receiptType)
            const localVarPath = `/customers/{customerNumber}/furtherReceipts/{receiptType}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"receiptType"}}`, encodeURIComponent(String(receiptType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (pzn !== undefined) {
                localVarQueryParameter['pzn'] = pzn;
            }

            if (articleName !== undefined) {
                localVarQueryParameter['articleName'] = articleName;
            }

            if (deliveryNoteId !== undefined) {
                localVarQueryParameter['deliveryNoteId'] = deliveryNoteId;
            }

            if (supplierName !== undefined) {
                localVarQueryParameter['supplierName'] = supplierName;
            }

            if (batchNumber !== undefined) {
                localVarQueryParameter['batchNumber'] = batchNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {FurtherReceiptTypeEnum} receiptType 
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {string} [articleName] 
         * @param {number} [deliveryNoteId] 
         * @param {string} [supplierName] 
         * @param {string} [batchNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurtherReceiptsAsCSV: async (customerNumber: string, receiptType: FurtherReceiptTypeEnum, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getFurtherReceiptsAsCSV', 'customerNumber', customerNumber)
            // verify required parameter 'receiptType' is not null or undefined
            assertParamExists('getFurtherReceiptsAsCSV', 'receiptType', receiptType)
            const localVarPath = `/customers/{customerNumber}/furtherReceipts/{receiptType}/csv`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"receiptType"}}`, encodeURIComponent(String(receiptType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (pzn !== undefined) {
                localVarQueryParameter['pzn'] = pzn;
            }

            if (articleName !== undefined) {
                localVarQueryParameter['articleName'] = articleName;
            }

            if (deliveryNoteId !== undefined) {
                localVarQueryParameter['deliveryNoteId'] = deliveryNoteId;
            }

            if (supplierName !== undefined) {
                localVarQueryParameter['supplierName'] = supplierName;
            }

            if (batchNumber !== undefined) {
                localVarQueryParameter['batchNumber'] = batchNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FurtherReceiptsControllerApi - functional programming interface
 * @export
 */
export const FurtherReceiptsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FurtherReceiptsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {FurtherReceiptTypeEnum} receiptType 
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of receipts to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {string} [articleName] 
         * @param {number} [deliveryNoteId] 
         * @param {string} [supplierName] 
         * @param {string} [batchNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFurtherReceipts(customerNumber: string, receiptType: FurtherReceiptTypeEnum, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FurtherReceiptDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFurtherReceipts(customerNumber, receiptType, pageNumber, pageLimit, fromDate, toDate, pzn, articleName, deliveryNoteId, supplierName, batchNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {FurtherReceiptTypeEnum} receiptType 
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {string} [articleName] 
         * @param {number} [deliveryNoteId] 
         * @param {string} [supplierName] 
         * @param {string} [batchNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFurtherReceiptsAsCSV(customerNumber: string, receiptType: FurtherReceiptTypeEnum, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFurtherReceiptsAsCSV(customerNumber, receiptType, fromDate, toDate, pzn, articleName, deliveryNoteId, supplierName, batchNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FurtherReceiptsControllerApi - factory interface
 * @export
 */
export const FurtherReceiptsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FurtherReceiptsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {FurtherReceiptTypeEnum} receiptType 
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of receipts to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {string} [articleName] 
         * @param {number} [deliveryNoteId] 
         * @param {string} [supplierName] 
         * @param {string} [batchNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurtherReceipts(customerNumber: string, receiptType: FurtherReceiptTypeEnum, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options?: any): AxiosPromise<Array<FurtherReceiptDto>> {
            return localVarFp.getFurtherReceipts(customerNumber, receiptType, pageNumber, pageLimit, fromDate, toDate, pzn, articleName, deliveryNoteId, supplierName, batchNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {FurtherReceiptTypeEnum} receiptType 
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {string} [articleName] 
         * @param {number} [deliveryNoteId] 
         * @param {string} [supplierName] 
         * @param {string} [batchNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFurtherReceiptsAsCSV(customerNumber: string, receiptType: FurtherReceiptTypeEnum, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getFurtherReceiptsAsCSV(customerNumber, receiptType, fromDate, toDate, pzn, articleName, deliveryNoteId, supplierName, batchNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FurtherReceiptsControllerApi - object-oriented interface
 * @export
 * @class FurtherReceiptsControllerApi
 * @extends {BaseAPI}
 */
export class FurtherReceiptsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {FurtherReceiptTypeEnum} receiptType 
     * @param {number} [pageNumber] The page to fetch
     * @param {number} [pageLimit] The number of receipts to return per page
     * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [pzn] 
     * @param {string} [articleName] 
     * @param {number} [deliveryNoteId] 
     * @param {string} [supplierName] 
     * @param {string} [batchNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FurtherReceiptsControllerApi
     */
    public getFurtherReceipts(customerNumber: string, receiptType: FurtherReceiptTypeEnum, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options?: AxiosRequestConfig) {
        return FurtherReceiptsControllerApiFp(this.configuration).getFurtherReceipts(customerNumber, receiptType, pageNumber, pageLimit, fromDate, toDate, pzn, articleName, deliveryNoteId, supplierName, batchNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {FurtherReceiptTypeEnum} receiptType 
     * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [pzn] 
     * @param {string} [articleName] 
     * @param {number} [deliveryNoteId] 
     * @param {string} [supplierName] 
     * @param {string} [batchNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FurtherReceiptsControllerApi
     */
    public getFurtherReceiptsAsCSV(customerNumber: string, receiptType: FurtherReceiptTypeEnum, fromDate?: string, toDate?: string, pzn?: string, articleName?: string, deliveryNoteId?: number, supplierName?: string, batchNumber?: string, options?: AxiosRequestConfig) {
        return FurtherReceiptsControllerApiFp(this.configuration).getFurtherReceiptsAsCSV(customerNumber, receiptType, fromDate, toDate, pzn, articleName, deliveryNoteId, supplierName, batchNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesControllerApi - axios parameter creator
 * @export
 */
export const InvoicesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of invoices to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvoices: async (customerNumber: string, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllInvoices', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/invoices`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesControllerApi - functional programming interface
 * @export
 */
export const InvoicesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of invoices to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInvoices(customerNumber: string, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInvoices(customerNumber, pageNumber, pageLimit, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesControllerApi - factory interface
 * @export
 */
export const InvoicesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of invoices to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInvoices(customerNumber: string, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<InvoiceDto>> {
            return localVarFp.getAllInvoices(customerNumber, pageNumber, pageLimit, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesControllerApi - object-oriented interface
 * @export
 * @class InvoicesControllerApi
 * @extends {BaseAPI}
 */
export class InvoicesControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {number} [pageNumber] The page to fetch
     * @param {number} [pageLimit] The number of invoices to return per page
     * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesControllerApi
     */
    public getAllInvoices(customerNumber: string, pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return InvoicesControllerApiFp(this.configuration).getAllInvoices(customerNumber, pageNumber, pageLimit, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageControllerApi - axios parameter creator
 * @export
 */
export const MessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all available message for the given customer number
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The amout of messages to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessages: async (customerNumber: string, pageNumber?: number, pageLimit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllMessages', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/messages`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the amount of unread messages for the given customernumber
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCount: async (customerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getUnreadMessageCount', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/messages/unread-count`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patches a specific message (currently only the readByPortal attribute could be patched)
         * @param {string} customerNumber The Customernumber
         * @param {number} messageId The messageId
         * @param {MessagePatchRequest} [messagePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMessage: async (customerNumber: string, messageId: number, messagePatchRequest?: MessagePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('patchMessage', 'customerNumber', customerNumber)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('patchMessage', 'messageId', messageId)
            const localVarPath = `/customers/{customerNumber}/messages/{messageId}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messagePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a message
         * @param {string} customerNumber The Customernumber
         * @param {MessageRequest} [messageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMessage: async (customerNumber: string, messageRequest?: MessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('postMessage', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/messages`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageControllerApi - functional programming interface
 * @export
 */
export const MessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all available message for the given customer number
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The amout of messages to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMessages(customerNumber: string, pageNumber?: number, pageLimit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMessages(customerNumber, pageNumber, pageLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the amount of unread messages for the given customernumber
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadMessageCount(customerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageCounterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadMessageCount(customerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patches a specific message (currently only the readByPortal attribute could be patched)
         * @param {string} customerNumber The Customernumber
         * @param {number} messageId The messageId
         * @param {MessagePatchRequest} [messagePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMessage(customerNumber: string, messageId: number, messagePatchRequest?: MessagePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMessage(customerNumber, messageId, messagePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a message
         * @param {string} customerNumber The Customernumber
         * @param {MessageRequest} [messageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMessage(customerNumber: string, messageRequest?: MessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMessage(customerNumber, messageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageControllerApi - factory interface
 * @export
 */
export const MessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageControllerApiFp(configuration)
    return {
        /**
         * Returns all available message for the given customer number
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The amout of messages to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessages(customerNumber: string, pageNumber?: number, pageLimit?: number, options?: any): AxiosPromise<Array<MessageResponse>> {
            return localVarFp.getAllMessages(customerNumber, pageNumber, pageLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the amount of unread messages for the given customernumber
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCount(customerNumber: string, options?: any): AxiosPromise<MessageCounterResponse> {
            return localVarFp.getUnreadMessageCount(customerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patches a specific message (currently only the readByPortal attribute could be patched)
         * @param {string} customerNumber The Customernumber
         * @param {number} messageId The messageId
         * @param {MessagePatchRequest} [messagePatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMessage(customerNumber: string, messageId: number, messagePatchRequest?: MessagePatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchMessage(customerNumber, messageId, messagePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a message
         * @param {string} customerNumber The Customernumber
         * @param {MessageRequest} [messageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMessage(customerNumber: string, messageRequest?: MessageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postMessage(customerNumber, messageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageControllerApi - object-oriented interface
 * @export
 * @class MessageControllerApi
 * @extends {BaseAPI}
 */
export class MessageControllerApi extends BaseAPI {
    /**
     * Returns all available message for the given customer number
     * @param {string} customerNumber The Customernumber
     * @param {number} [pageNumber] The page to fetch
     * @param {number} [pageLimit] The amout of messages to load
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getAllMessages(customerNumber: string, pageNumber?: number, pageLimit?: number, options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getAllMessages(customerNumber, pageNumber, pageLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the amount of unread messages for the given customernumber
     * @param {string} customerNumber The Customernumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getUnreadMessageCount(customerNumber: string, options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getUnreadMessageCount(customerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patches a specific message (currently only the readByPortal attribute could be patched)
     * @param {string} customerNumber The Customernumber
     * @param {number} messageId The messageId
     * @param {MessagePatchRequest} [messagePatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public patchMessage(customerNumber: string, messageId: number, messagePatchRequest?: MessagePatchRequest, options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).patchMessage(customerNumber, messageId, messagePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a message
     * @param {string} customerNumber The Customernumber
     * @param {MessageRequest} [messageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public postMessage(customerNumber: string, messageRequest?: MessageRequest, options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).postMessage(customerNumber, messageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MonitoringControllerApi - axios parameter creator
 * @export
 */
export const MonitoringControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/monitoring/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonitoringControllerApi - functional programming interface
 * @export
 */
export const MonitoringControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonitoringControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonitoringControllerApi - factory interface
 * @export
 */
export const MonitoringControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonitoringControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<string> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonitoringControllerApi - object-oriented interface
 * @export
 * @class MonitoringControllerApi
 * @extends {BaseAPI}
 */
export class MonitoringControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitoringControllerApi
     */
    public ping(options?: AxiosRequestConfig) {
        return MonitoringControllerApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersControllerApi - axios parameter creator
 * @export
 */
export const OrdersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancels the given order position
         * @param {string} customerNumber The Customernumber
         * @param {Array<CancelDto>} [cancelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderPositions: async (customerNumber: string, cancelDto?: Array<CancelDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('cancelOrderPositions', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/orders/cancel`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will return the orders for the given customer number
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of orders to return per page
         * @param {boolean} [sortByDateDesc] Sort orders by publish date descending
         * @param {string} [pzn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders: async (customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllOrders', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/orders`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }

            if (sortByDateDesc !== undefined) {
                localVarQueryParameter['sortByDateDesc'] = sortByDateDesc;
            }

            if (pzn !== undefined) {
                localVarQueryParameter['pzn'] = pzn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will trigger the return for the current month.
         * @param {string} customerNumber The Customernumber
         * @param {TriggerReturnRequestDto} triggerReturnRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerReturn: async (customerNumber: string, triggerReturnRequestDto: TriggerReturnRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('triggerReturn', 'customerNumber', customerNumber)
            // verify required parameter 'triggerReturnRequestDto' is not null or undefined
            assertParamExists('triggerReturn', 'triggerReturnRequestDto', triggerReturnRequestDto)
            const localVarPath = `/customers/{customerNumber}/orders/trigger-return`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(triggerReturnRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersControllerApi - functional programming interface
 * @export
 */
export const OrdersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancels the given order position
         * @param {string} customerNumber The Customernumber
         * @param {Array<CancelDto>} [cancelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrderPositions(customerNumber: string, cancelDto?: Array<CancelDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancelErrorDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrderPositions(customerNumber, cancelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will return the orders for the given customer number
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of orders to return per page
         * @param {boolean} [sortByDateDesc] Sort orders by publish date descending
         * @param {string} [pzn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrders(customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrders(customerNumber, pageNumber, pageLimit, sortByDateDesc, pzn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will trigger the return for the current month.
         * @param {string} customerNumber The Customernumber
         * @param {TriggerReturnRequestDto} triggerReturnRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerReturn(customerNumber: string, triggerReturnRequestDto: TriggerReturnRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerReturn(customerNumber, triggerReturnRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersControllerApi - factory interface
 * @export
 */
export const OrdersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersControllerApiFp(configuration)
    return {
        /**
         * Cancels the given order position
         * @param {string} customerNumber The Customernumber
         * @param {Array<CancelDto>} [cancelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderPositions(customerNumber: string, cancelDto?: Array<CancelDto>, options?: any): AxiosPromise<Array<CancelErrorDto>> {
            return localVarFp.cancelOrderPositions(customerNumber, cancelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Will return the orders for the given customer number
         * @param {string} customerNumber The Customernumber
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of orders to return per page
         * @param {boolean} [sortByDateDesc] Sort orders by publish date descending
         * @param {string} [pzn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders(customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, options?: any): AxiosPromise<Array<OrderDto>> {
            return localVarFp.getAllOrders(customerNumber, pageNumber, pageLimit, sortByDateDesc, pzn, options).then((request) => request(axios, basePath));
        },
        /**
         * Will trigger the return for the current month.
         * @param {string} customerNumber The Customernumber
         * @param {TriggerReturnRequestDto} triggerReturnRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerReturn(customerNumber: string, triggerReturnRequestDto: TriggerReturnRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.triggerReturn(customerNumber, triggerReturnRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersControllerApi - object-oriented interface
 * @export
 * @class OrdersControllerApi
 * @extends {BaseAPI}
 */
export class OrdersControllerApi extends BaseAPI {
    /**
     * Cancels the given order position
     * @param {string} customerNumber The Customernumber
     * @param {Array<CancelDto>} [cancelDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public cancelOrderPositions(customerNumber: string, cancelDto?: Array<CancelDto>, options?: AxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).cancelOrderPositions(customerNumber, cancelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will return the orders for the given customer number
     * @param {string} customerNumber The Customernumber
     * @param {number} [pageNumber] The page to fetch
     * @param {number} [pageLimit] The number of orders to return per page
     * @param {boolean} [sortByDateDesc] Sort orders by publish date descending
     * @param {string} [pzn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public getAllOrders(customerNumber: string, pageNumber?: number, pageLimit?: number, sortByDateDesc?: boolean, pzn?: string, options?: AxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).getAllOrders(customerNumber, pageNumber, pageLimit, sortByDateDesc, pzn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will trigger the return for the current month.
     * @param {string} customerNumber The Customernumber
     * @param {TriggerReturnRequestDto} triggerReturnRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public triggerReturn(customerNumber: string, triggerReturnRequestDto: TriggerReturnRequestDto, options?: AxiosRequestConfig) {
        return OrdersControllerApiFp(this.configuration).triggerReturn(customerNumber, triggerReturnRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceiptsControllerApi - axios parameter creator
 * @export
 */
export const ReceiptsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {'SR' | 'LS' | 'NB' | 'GS' | 'MB'} receiptType SR: Rechnung, LS: Lieferschein, NB: Nachbelastung, GS: Gutschrift, MB: Monatsbericht
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of receipts to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReceipts: async (customerNumber: string, receiptType: 'SR' | 'LS' | 'NB' | 'GS' | 'MB', pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllReceipts', 'customerNumber', customerNumber)
            // verify required parameter 'receiptType' is not null or undefined
            assertParamExists('getAllReceipts', 'receiptType', receiptType)
            const localVarPath = `/customers/{customerNumber}/receipts/{receiptType}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"receiptType"}}`, encodeURIComponent(String(receiptType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (pzn !== undefined) {
                localVarQueryParameter['pzn'] = pzn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceiptsControllerApi - functional programming interface
 * @export
 */
export const ReceiptsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceiptsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {'SR' | 'LS' | 'NB' | 'GS' | 'MB'} receiptType SR: Rechnung, LS: Lieferschein, NB: Nachbelastung, GS: Gutschrift, MB: Monatsbericht
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of receipts to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllReceipts(customerNumber: string, receiptType: 'SR' | 'LS' | 'NB' | 'GS' | 'MB', pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceiptDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllReceipts(customerNumber, receiptType, pageNumber, pageLimit, fromDate, toDate, pzn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceiptsControllerApi - factory interface
 * @export
 */
export const ReceiptsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceiptsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {'SR' | 'LS' | 'NB' | 'GS' | 'MB'} receiptType SR: Rechnung, LS: Lieferschein, NB: Nachbelastung, GS: Gutschrift, MB: Monatsbericht
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of receipts to return per page
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [pzn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReceipts(customerNumber: string, receiptType: 'SR' | 'LS' | 'NB' | 'GS' | 'MB', pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, options?: any): AxiosPromise<Array<ReceiptDto>> {
            return localVarFp.getAllReceipts(customerNumber, receiptType, pageNumber, pageLimit, fromDate, toDate, pzn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceiptsControllerApi - object-oriented interface
 * @export
 * @class ReceiptsControllerApi
 * @extends {BaseAPI}
 */
export class ReceiptsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {'SR' | 'LS' | 'NB' | 'GS' | 'MB'} receiptType SR: Rechnung, LS: Lieferschein, NB: Nachbelastung, GS: Gutschrift, MB: Monatsbericht
     * @param {number} [pageNumber] The page to fetch
     * @param {number} [pageLimit] The number of receipts to return per page
     * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [pzn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptsControllerApi
     */
    public getAllReceipts(customerNumber: string, receiptType: 'SR' | 'LS' | 'NB' | 'GS' | 'MB', pageNumber?: number, pageLimit?: number, fromDate?: string, toDate?: string, pzn?: string, options?: AxiosRequestConfig) {
        return ReceiptsControllerApiFp(this.configuration).getAllReceipts(customerNumber, receiptType, pageNumber, pageLimit, fromDate, toDate, pzn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReturnsControllerApi - axios parameter creator
 * @export
 */
export const ReturnsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber 
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of returns to return per page
         * @param {string} [pzn] 
         * @param {'' | '1' | '5' | '9'} [state] 1: open, 5: in process, 9: completed
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReturns: async (customerNumber: string, pageNumber?: number, pageLimit?: number, pzn?: string, state?: '' | '1' | '5' | '9', fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllReturns', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/returns`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }

            if (pzn !== undefined) {
                localVarQueryParameter['pzn'] = pzn;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReturnsControllerApi - functional programming interface
 * @export
 */
export const ReturnsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReturnsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber 
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of returns to return per page
         * @param {string} [pzn] 
         * @param {'' | '1' | '5' | '9'} [state] 1: open, 5: in process, 9: completed
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllReturns(customerNumber: string, pageNumber?: number, pageLimit?: number, pzn?: string, state?: '' | '1' | '5' | '9', fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReturnDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllReturns(customerNumber, pageNumber, pageLimit, pzn, state, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReturnsControllerApi - factory interface
 * @export
 */
export const ReturnsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReturnsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber 
         * @param {number} [pageNumber] The page to fetch
         * @param {number} [pageLimit] The number of returns to return per page
         * @param {string} [pzn] 
         * @param {'' | '1' | '5' | '9'} [state] 1: open, 5: in process, 9: completed
         * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
         * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReturns(customerNumber: string, pageNumber?: number, pageLimit?: number, pzn?: string, state?: '' | '1' | '5' | '9', fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<ReturnDto>> {
            return localVarFp.getAllReturns(customerNumber, pageNumber, pageLimit, pzn, state, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReturnsControllerApi - object-oriented interface
 * @export
 * @class ReturnsControllerApi
 * @extends {BaseAPI}
 */
export class ReturnsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber 
     * @param {number} [pageNumber] The page to fetch
     * @param {number} [pageLimit] The number of returns to return per page
     * @param {string} [pzn] 
     * @param {'' | '1' | '5' | '9'} [state] 1: open, 5: in process, 9: completed
     * @param {string} [fromDate] The startDate of the dateRange in the format dd.mm.yyyy
     * @param {string} [toDate] The endDate of the dateRange in the format dd.mm.yyyy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReturnsControllerApi
     */
    public getAllReturns(customerNumber: string, pageNumber?: number, pageLimit?: number, pzn?: string, state?: '' | '1' | '5' | '9', fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return ReturnsControllerApiFp(this.configuration).getAllReturns(customerNumber, pageNumber, pageLimit, pzn, state, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecialOrdersControllerApi - axios parameter creator
 * @export
 */
export const SpecialOrdersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialOrders: async (customerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getSpecialOrders', 'customerNumber', customerNumber)
            const localVarPath = `/specialOrders/{customerNumber}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates special Order Entries
         * @param {string} customerNumber The Customernumber
         * @param {Array<SpecialOrderRequest>} [specialOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSpecialOrder: async (customerNumber: string, specialOrderRequest?: Array<SpecialOrderRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('postSpecialOrder', 'customerNumber', customerNumber)
            const localVarPath = `/specialOrders/{customerNumber}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specialOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecialOrdersControllerApi - functional programming interface
 * @export
 */
export const SpecialOrdersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecialOrdersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialOrders(customerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialOrderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialOrders(customerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates special Order Entries
         * @param {string} customerNumber The Customernumber
         * @param {Array<SpecialOrderRequest>} [specialOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSpecialOrder(customerNumber: string, specialOrderRequest?: Array<SpecialOrderRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecialOrderCreationResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSpecialOrder(customerNumber, specialOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecialOrdersControllerApi - factory interface
 * @export
 */
export const SpecialOrdersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecialOrdersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialOrders(customerNumber: string, options?: any): AxiosPromise<Array<SpecialOrderResponse>> {
            return localVarFp.getSpecialOrders(customerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates special Order Entries
         * @param {string} customerNumber The Customernumber
         * @param {Array<SpecialOrderRequest>} [specialOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSpecialOrder(customerNumber: string, specialOrderRequest?: Array<SpecialOrderRequest>, options?: any): AxiosPromise<SpecialOrderCreationResultDto> {
            return localVarFp.postSpecialOrder(customerNumber, specialOrderRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecialOrdersControllerApi - object-oriented interface
 * @export
 * @class SpecialOrdersControllerApi
 * @extends {BaseAPI}
 */
export class SpecialOrdersControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialOrdersControllerApi
     */
    public getSpecialOrders(customerNumber: string, options?: AxiosRequestConfig) {
        return SpecialOrdersControllerApiFp(this.configuration).getSpecialOrders(customerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates special Order Entries
     * @param {string} customerNumber The Customernumber
     * @param {Array<SpecialOrderRequest>} [specialOrderRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialOrdersControllerApi
     */
    public postSpecialOrder(customerNumber: string, specialOrderRequest?: Array<SpecialOrderRequest>, options?: AxiosRequestConfig) {
        return SpecialOrdersControllerApiFp(this.configuration).postSpecialOrder(customerNumber, specialOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a user
         * @param {string} customerNumber The Customernumber
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (customerNumber: string, userDto?: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('createUser', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/users`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletes a user
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (customerNumber: string, userName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('deleteUser', 'customerNumber', customerNumber)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('deleteUser', 'userName', userName)
            const localVarPath = `/customers/{customerNumber}/users/{userName}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (customerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getAllUsers', 'customerNumber', customerNumber)
            const localVarPath = `/customers/{customerNumber}/users`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (customerNumber: string, userName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getUser', 'customerNumber', customerNumber)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getUser', 'userName', userName)
            const localVarPath = `/customers/{customerNumber}/users/{userName}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gets a userProfile
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (customerNumber: string, userName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('getUserProfile', 'customerNumber', customerNumber)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('getUserProfile', 'userName', userName)
            const localVarPath = `/customers/{customerNumber}/users/{userName}/profile`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a user
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (customerNumber: string, userName: string, userDto?: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('updateUser', 'customerNumber', customerNumber)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('updateUser', 'userName', userName)
            const localVarPath = `/customers/{customerNumber}/users/{userName}`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a userProfile
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {UserProfileDto} [userProfileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (customerNumber: string, userName: string, userProfileDto?: UserProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNumber' is not null or undefined
            assertParamExists('updateUserProfile', 'customerNumber', customerNumber)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('updateUserProfile', 'userName', userName)
            const localVarPath = `/customers/{customerNumber}/users/{userName}/profile`
                .replace(`{${"customerNumber"}}`, encodeURIComponent(String(customerNumber)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a user
         * @param {string} customerNumber The Customernumber
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(customerNumber: string, userDto?: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(customerNumber, userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletes a user
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(customerNumber: string, userName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(customerNumber, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(customerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(customerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(customerNumber: string, userName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(customerNumber, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gets a userProfile
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(customerNumber: string, userName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(customerNumber, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a user
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(customerNumber: string, userName: string, userDto?: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(customerNumber, userName, userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a userProfile
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {UserProfileDto} [userProfileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(customerNumber: string, userName: string, userProfileDto?: UserProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(customerNumber, userName, userProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a user
         * @param {string} customerNumber The Customernumber
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(customerNumber: string, userDto?: UserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.createUser(customerNumber, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletes a user
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(customerNumber: string, userName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(customerNumber, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(customerNumber: string, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getAllUsers(customerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(customerNumber: string, userName: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.getUser(customerNumber, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gets a userProfile
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(customerNumber: string, userName: string, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.getUserProfile(customerNumber, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a user
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(customerNumber: string, userName: string, userDto?: UserDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(customerNumber, userName, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a userProfile
         * @param {string} customerNumber The Customernumber
         * @param {string} userName The Username
         * @param {UserProfileDto} [userProfileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(customerNumber: string, userName: string, userProfileDto?: UserProfileDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfile(customerNumber, userName, userProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Creates a user
     * @param {string} customerNumber The Customernumber
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public createUser(customerNumber: string, userDto?: UserDto, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).createUser(customerNumber, userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletes a user
     * @param {string} customerNumber The Customernumber
     * @param {string} userName The Username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUser(customerNumber: string, userName: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deleteUser(customerNumber, userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAllUsers(customerNumber: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getAllUsers(customerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerNumber The Customernumber
     * @param {string} userName The Username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUser(customerNumber: string, userName: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUser(customerNumber, userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gets a userProfile
     * @param {string} customerNumber The Customernumber
     * @param {string} userName The Username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserProfile(customerNumber: string, userName: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUserProfile(customerNumber, userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a user
     * @param {string} customerNumber The Customernumber
     * @param {string} userName The Username
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUser(customerNumber: string, userName: string, userDto?: UserDto, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateUser(customerNumber, userName, userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a userProfile
     * @param {string} customerNumber The Customernumber
     * @param {string} userName The Username
     * @param {UserProfileDto} [userProfileDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUserProfile(customerNumber: string, userName: string, userProfileDto?: UserProfileDto, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateUserProfile(customerNumber, userName, userProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserImportControllerApi - axios parameter creator
 * @export
 */
export const UserImportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disablePharmacyOwners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/disable-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enablePharmacyOwners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/enable-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lastrun] The last run timestamp
         * @param {boolean} [incremental] Incremental or full import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPharmacyOwners: async (lastrun?: string, incremental?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lastrun !== undefined) {
                localVarQueryParameter['lastrun'] = (lastrun as any instanceof Date) ?
                    (lastrun as any).toISOString() :
                    lastrun;
            }

            if (incremental !== undefined) {
                localVarQueryParameter['incremental'] = incremental;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/migrate-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates Users and Pharmacies based on a JSON
         * @param {boolean} [incremental] Incremental or full import
         * @param {number} [step] which step to start import on (0 - pharmacy import, 1 - user creation, 2 - user deletion, 3 - user activation, 4 - pharmacy assignment)
         * @param {Array<PharmacyOwnerDto>} [pharmacyOwnerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers: async (incremental?: boolean, step?: number, pharmacyOwnerDto?: Array<PharmacyOwnerDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/user-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (incremental !== undefined) {
                localVarQueryParameter['incremental'] = incremental;
            }

            if (step !== undefined) {
                localVarQueryParameter['step'] = step;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pharmacyOwnerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserImportControllerApi - functional programming interface
 * @export
 */
export const UserImportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserImportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disablePharmacyOwners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disablePharmacyOwners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enablePharmacyOwners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enablePharmacyOwners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [lastrun] The last run timestamp
         * @param {boolean} [incremental] Incremental or full import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPharmacyOwners(lastrun?: string, incremental?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPharmacyOwners(lastrun, incremental, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates Users and Pharmacies based on a JSON
         * @param {boolean} [incremental] Incremental or full import
         * @param {number} [step] which step to start import on (0 - pharmacy import, 1 - user creation, 2 - user deletion, 3 - user activation, 4 - pharmacy assignment)
         * @param {Array<PharmacyOwnerDto>} [pharmacyOwnerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUsers(incremental?: boolean, step?: number, pharmacyOwnerDto?: Array<PharmacyOwnerDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUsers(incremental, step, pharmacyOwnerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserImportControllerApi - factory interface
 * @export
 */
export const UserImportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserImportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disablePharmacyOwners(options?: any): AxiosPromise<void> {
            return localVarFp.disablePharmacyOwners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enablePharmacyOwners(options?: any): AxiosPromise<void> {
            return localVarFp.enablePharmacyOwners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lastrun] The last run timestamp
         * @param {boolean} [incremental] Incremental or full import
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPharmacyOwners(lastrun?: string, incremental?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.importPharmacyOwners(lastrun, incremental, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateRoles(options?: any): AxiosPromise<void> {
            return localVarFp.migrateRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates Users and Pharmacies based on a JSON
         * @param {boolean} [incremental] Incremental or full import
         * @param {number} [step] which step to start import on (0 - pharmacy import, 1 - user creation, 2 - user deletion, 3 - user activation, 4 - pharmacy assignment)
         * @param {Array<PharmacyOwnerDto>} [pharmacyOwnerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsers(incremental?: boolean, step?: number, pharmacyOwnerDto?: Array<PharmacyOwnerDto>, options?: any): AxiosPromise<void> {
            return localVarFp.postUsers(incremental, step, pharmacyOwnerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserImportControllerApi - object-oriented interface
 * @export
 * @class UserImportControllerApi
 * @extends {BaseAPI}
 */
export class UserImportControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserImportControllerApi
     */
    public disablePharmacyOwners(options?: AxiosRequestConfig) {
        return UserImportControllerApiFp(this.configuration).disablePharmacyOwners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserImportControllerApi
     */
    public enablePharmacyOwners(options?: AxiosRequestConfig) {
        return UserImportControllerApiFp(this.configuration).enablePharmacyOwners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lastrun] The last run timestamp
     * @param {boolean} [incremental] Incremental or full import
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserImportControllerApi
     */
    public importPharmacyOwners(lastrun?: string, incremental?: boolean, options?: AxiosRequestConfig) {
        return UserImportControllerApiFp(this.configuration).importPharmacyOwners(lastrun, incremental, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserImportControllerApi
     */
    public migrateRoles(options?: AxiosRequestConfig) {
        return UserImportControllerApiFp(this.configuration).migrateRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates Users and Pharmacies based on a JSON
     * @param {boolean} [incremental] Incremental or full import
     * @param {number} [step] which step to start import on (0 - pharmacy import, 1 - user creation, 2 - user deletion, 3 - user activation, 4 - pharmacy assignment)
     * @param {Array<PharmacyOwnerDto>} [pharmacyOwnerDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserImportControllerApi
     */
    public postUsers(incremental?: boolean, step?: number, pharmacyOwnerDto?: Array<PharmacyOwnerDto>, options?: AxiosRequestConfig) {
        return UserImportControllerApiFp(this.configuration).postUsers(incremental, step, pharmacyOwnerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


