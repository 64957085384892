/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ResponsiveBar } from "@nivo/bar";
import { useState, useEffect } from "react";
import { BarDatum } from "@nivo/bar/dist/types";
import { chartTheme } from "../chartStyles";
import { ChartData, sortBySortPosition } from "../Chart";
import { chartPalette } from "../../../../styles/aepPalette";

export interface BarChartData {
  data: BarDatum[];
  headline?: string;
  unit?: string;
  xlabel: string;
  ylabel: string;
}
interface ColumnData extends ChartData {
  ySegments: BarDatum[];
}

interface FlattenedColumnData {
  sortPosition: string;
  x: string;
  [key: string]: number | string;
}

export interface BarChartProps {
  mini?: boolean;
  chartData: BarChartData;
}

const BarChart = (props: BarChartProps) => {
  const { chartData, mini } = props;
  const { xlabel, ylabel, data, unit } = chartData;
  const [columnData, setColumnData] = useState<BarDatum[]>([]);
  const [keys, setKeys] = useState<string[]>([]);

  const parseData = (): {
    columnKeys: string[];
    sortPositions: string[];
    barSegmentKeys: string[];
  } => {
    const barSegmentKeys: string[] = [];
    const columnKeys: string[] = [];
    const sortPositions: string[] = [];

    data?.forEach((dataPoint) => {
      if (!barSegmentKeys.includes(dataPoint.label as string)) {
        barSegmentKeys.push(dataPoint.label as string);
      }
      if (!sortPositions.includes(dataPoint.sortPosition as string)) {
        sortPositions.push(dataPoint.sortPosition as string);
      }
      if (!columnKeys.includes(dataPoint.x as string)) {
        columnKeys.push(dataPoint.x as string);
      }
    });

    return { columnKeys, sortPositions, barSegmentKeys };
  };

  const generateColumnData = (
    columnKeys: string[],
    sortPositions: string[]
  ): FlattenedColumnData[] => {
    const dataColumns = columnKeys.map((columnKey: string, index) => ({
      sortPosition: sortPositions[index],
      x: columnKey,
      ySegments: data.filter((dataPoint) => dataPoint.x === columnKey),
    }));

    return dataColumns.sort(sortBySortPosition).map((column: ColumnData) => {
      const flattenedObject = {
        sortPosition: column.sortPosition || "",
        x: column.x,
      };

      column.ySegments.forEach((segment: BarDatum) => {
        // @ts-ignore
        flattenedObject[segment.label] = segment.y;
      });

      return flattenedObject;
    });
  };

  const formatCategoricalData = () => {
    const { columnKeys, sortPositions, barSegmentKeys } = parseData();
    setKeys(barSegmentKeys);
    const columnData = generateColumnData(columnKeys, sortPositions);
    setColumnData(columnData);
  };

  const formatValue = (value: number | Date): string => {
    if (typeof value === "number" && unit === "€") {
      const formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });

      return formatter.format(value);
    }

    return `${value}${unit || ""}`;
  };

  useEffect(() => {
    formatCategoricalData();
  }, [data]);

  if (columnData.length > 0 && keys.length > 0) {
    return (
      <ResponsiveBar
        theme={chartTheme}
        data={columnData}
        keys={keys}
        colorBy="id"
        colors={chartPalette}
        groupMode="stacked"
        indexBy="x"
        layout="vertical"
        valueFormat={formatValue}
        margin={
          mini
            ? { top: 60, right: 20, bottom: 55, left: 70 }
            : { top: 80, right: 60, bottom: 120, left: 80 }
        }
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: xlabel,
          legendPosition: "middle",
          legendOffset: mini ? 40 : 50,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: `${ylabel} ${unit || ""}`,
          legendPosition: "middle",
          legendOffset: mini ? -60 : -70,
        }}
        enableGridX={false}
        enableGridY={true}
        enableLabel={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        isInteractive={!mini}
        legends={
          mini
            ? []
            : [
                {
                  anchor: "bottom",
                  dataFrom: "keys",
                  direction: "row",
                  itemWidth: 50,
                  itemHeight: 50,
                  translateY: 120,
                  itemsSpacing: 50,
                },
              ]
        }
        role="application"
        ariaLabel="Nivo bar chart"
        barAriaLabel={function (e) {
          return (
            e.id +
            ": x value is " +
            e.formattedValue +
            ", y value is " +
            e.indexValue +
            " in units " +
            unit
          );
        }}
      />
    );
  } else {
    return <></>;
  }
};

export default BarChart;
