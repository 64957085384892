import { Box, SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

interface TextDividerProps {
  sx?: SxProps<Theme>;
}

export const TextDivider = (props: TextDividerProps): JSX.Element => {
  const { sx } = props;

  return (
    <Box
      component="span"
      sx={{
        borderLeft: `1px solid ${aepPalette.secondary.light}`,
        fontSize: "2rem",
        position: "relative",
        ...sx,
      }}
    />
  );
};
