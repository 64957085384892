import React, { useState } from "react";

export interface CustomerContextType {
  customerNumber: string;
  updateCustomerNumber: (customerNumber: string) => void;
  unreadMessageCount: number;
  updateUnreadMessageCount: (count: number) => void;
}

export const CustomerContext = React.createContext<CustomerContextType>({
  customerNumber: "",
  updateCustomerNumber: () => null,
  unreadMessageCount: 0,
  updateUnreadMessageCount: (count: number) => null,
});

export const CustomerContextProvider: React.FunctionComponent<{
  children?: JSX.Element;
}> = (props) => {
  const { children } = props;
  const [customerNumber, setCustomerNumber] = useState("");
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const updateCustomerNumber = (customerNumber: string) => {
    setCustomerNumber(customerNumber);
  };

  const updateUnreadMessageCount = (count: number) => {
    setUnreadMessageCount(count);
  };

  return (
    <CustomerContext.Provider
      value={{
        customerNumber,
        updateCustomerNumber,
        unreadMessageCount,
        updateUnreadMessageCount,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
