import React, { useState } from "react";
import { UserDtoPermissionsEnum } from "../../api";

export interface UserContextType {
  checkUserRole: (user: UserDtoPermissionsEnum[]) => boolean;
  userRoles: UserDtoPermissionsEnum[];
  updateUserRoles: (roles: UserDtoPermissionsEnum[]) => void;
  userFullName: string;
  updateUserFullName: (userid: string) => void;
  userName: string;
  updateUserName: (userid: string) => void;
}

export const UserContext = React.createContext<UserContextType>({
  checkUserRole: () => false,
  userRoles: [],
  updateUserRoles: () => null,
  userFullName: "",
  updateUserFullName: () => null,
  userName: "",
  updateUserName: () => null,
});

export const UserContextProvider: React.FunctionComponent<{
  children?: JSX.Element;
}> = (props) => {
  const { children } = props;
  const [userRoles, setUserRoles] = useState<UserDtoPermissionsEnum[]>([]);
  const [userFullName, setuserFullName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");

  const checkUserRole = (roles: UserDtoPermissionsEnum[]): boolean =>
    userRoles.includes(UserDtoPermissionsEnum.Admin) ||
    roles.some((current) => userRoles.includes(current));

  const updateUserRoles = (roles: UserDtoPermissionsEnum[]): void => {
    setUserRoles(roles);
  };

  const updateUserFullName = (userFullName: string): void => {
    setuserFullName(userFullName);
  };

  const updateUserName = (userName: string): void => {
    setUserName(userName);
  };

  return (
    <UserContext.Provider
      value={{
        checkUserRole,
        userRoles,
        updateUserRoles,
        userFullName,
        updateUserFullName,
        userName,
        updateUserName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
