import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { aepPalette } from "../../../styles/aepPalette";

export const limitedRowStyles: CSSProperties = {
  maxWidth: "90px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const rootContainerStackStyles: SxProps<Theme> = {
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
};

export const submitButtonStyles: SxProps<Theme> = {
  justifySelf: "flex-end",
  position: "relative",
};

export const containerCardStyles: SxProps<Theme> = {
  flexGrow: 1,
  mt: 2,
  overflow: "auto",
  position: "relative",
  backgroundColor: aepPalette.common.white,
};

export const containerCardBoxStyles = (
  fullSizeRows?: boolean,
  hasDetailViewSwitch?: boolean
): SxProps<Theme> => ({
  // access nested table rows in accordion tables
  "& > table > tbody > tr > td > div > div > div > div > div > div > table > tbody > tr > td":
    {
      height: fullSizeRows && !hasDetailViewSwitch ? 60 : 40,
      transition: "height 0.3s",
    },
  // change all table cells density from parent
  "& > table > tbody > tr > td": {
    paddingTop: "5px",
    paddingBottom: "5px",
    transition: "height 0.3s",
    "& > p": fullSizeRows && hasDetailViewSwitch ? {} : limitedRowStyles,
  },
  // eliminate double border on last row
  "& > table > tbody > tr": {
    "&:last-child > td": {
      border: "0px",
    },
  },
  overflowX: "auto",
});

export const containerControlStackStyles = (
  selectableRows?: boolean,
  hasDetailViewSwitch?: boolean
): SxProps<Theme> => ({
  mt: 2,
  width: "100%",
  justifyContent:
    selectableRows && hasDetailViewSwitch
      ? "space-between"
      : hasDetailViewSwitch
      ? "flex-start"
      : "flex-end",
  alignItems: "center",
});
