import { Box, SxProps, Theme, Typography } from "@mui/material";
import Offer from "../../../../strapi/models/Offer";
import { aepPalette } from "../../../../styles/aepPalette";
import Card from "../../../components/card/Card";
import ImageWrapper from "../../../wrappers/ImageWrapper";

interface OfferCardProps {
  baseUrl: string;
  dashboard?: boolean;
  offerAttributes: Offer;
  sx?: SxProps<Theme>;
}

const typographyInfoStyles: SxProps<Theme> = {
  fontSize: {
    xs: "1.3rem",
    xl: "1rem",
  },
  lineHeight: {
    xs: "2rem",
    xl: "1.2rem",
  },
  color: aepPalette.base.main,
};

const OfferDashboardCard = (props: OfferCardProps) => {
  const { offerAttributes, baseUrl } = props;
  const {
    artikelName,
    bild,
    mindestmenge,
    packungsgroesse,
    preis,
    pzn,
    rabatt,
  } = offerAttributes;
  const { url } = bild.data.attributes;

  return (
    <Card
      secondary
      sx={{
        backgroundColor: "transparent",
        border: "none",
        pb: 4,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          transform: "translateY(10%)",
          display: "grid",
          overflow: "auto",
          px: "5%",
          width: "100%",
          minWidth: {
            xs: "640px",
            xl: "",
          },
          gridTemplateColumns: {
            xs: "repeat(3, 33%)",
            xl: "repeat(2, 50%)",
          },
          gridTemplateRows: {
            xs: "auto",
            xl: "repeat(2, auto)",
          },
          pt: 0,
          pb: 4,
        }}
      >
        <ImageWrapper
          src={`${baseUrl}/contentmanager${url}`}
          alt="Angebot Bild"
          containerSx={{
            textAlign: "center",
            gridColumn: "1 / 2",
            overflow: "hidden",
            maxHeight: {
              xs: "245px",
              xl: "350px",
            },
            pr: 3,
            gridRow: {
              xs: "1 / 2",
              xl: "1 / 3",
            },
            height: "150px",
          }}
          sx={{
            height: "100%",
            objectFit: "scale-down",
            maxWidth: "120px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gridColumn: "2 / 3",
            gridRow: "1 / 2",
            pt: 0,
            width: "fit-content",
          }}
        >
          <Typography
            color="primary"
            sx={{
              whiteSpace: "nowrap",
              fontSize: {
                xs: "2rem",
                xl: "1.6rem",
              },
              fontWeight: {
                xs: 500,
                xl: 300,
              },
              lineHeight: {
                xs: "3rem",
                xl: "2rem",
              },
            }}
          >
            {`${rabatt} % Rabatt*`}
          </Typography>
          <Typography
            color="secondary"
            sx={{
              whiteSpace: "nowrap",
              fontSize: {
                xs: "2rem",
                xl: "1.6rem",
              },
              fontWeight: {
                xs: 500,
                xl: 300,
              },
              lineHeight: {
                xs: "3rem",
                xl: "2rem",
              },
            }}
          >
            {`${preis} € pro Stück`}
          </Typography>

          <Typography
            sx={{ ...typographyInfoStyles, pt: 1, whiteSpace: "nowrap" }}
          >{`Ab einer Menge von ${mindestmenge} Stück`}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gridColumn: {
              xs: "3 / 4",
              xl: "2 / 3",
            },
            gridRow: {
              xs: "1 / 2",
              xl: "2 / 3",
            },
            pt: 0,
          }}
        >
          <Typography sx={typographyInfoStyles}>{artikelName}</Typography>
          <Typography sx={typographyInfoStyles}>{packungsgroesse}</Typography>
          <Typography sx={typographyInfoStyles}>PZN {pzn}</Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default OfferDashboardCard;
