import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FurtherReceiptDto } from "../../../../api";
import { tablePageDefaultLength } from "../../../components/table/TableContainer";
import FurtherReceiptsSearchTableRow from "./FurtherReceiptsSearchTableRow";

interface FurtherReceiptsSearchTableProps {
  receipts: FurtherReceiptDto[];
  loading: boolean;
}

const ReceiptsSearchTable = (props: FurtherReceiptsSearchTableProps) => {
  const { receipts } = props;

  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow
          sx={{
            "& > th": {
              px: 1,
            },
            "& > th:first-of-type": {
              pl: 4,
            },
            "& > th:last-of-type": {
              pr: 4,
            },
            position: "relative",
          }}
        >
          <TableCell sx={{ width: "11%" }}>
            <Typography variant="body2">Belegart</Typography>
          </TableCell>
          <TableCell sx={{ width: "11%" }}>
            <Typography variant="body2">Belegnummer</Typography>
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Typography variant="body2">Lieferdatum</Typography>
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Typography variant="body2">PZN</Typography>
          </TableCell>
          <TableCell sx={{ width: "12%" }}>
            <Typography variant="body2">Artikelbezeichnung</Typography>
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Typography variant="body2">Lieferant</Typography>
          </TableCell>
          <TableCell sx={{ width: "8%" }}>
            <Typography variant="body2">Menge</Typography>
          </TableCell>
          <TableCell sx={{ width: "9%" }}>
            <Typography variant="body2">Preis</Typography>
          </TableCell>
          <TableCell sx={{ width: "9%" }}>
            <Typography variant="body2">BoxNr.</Typography>
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Typography variant="body2">ChargenNr.</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {receipts.map((row, index) => (
          <FurtherReceiptsSearchTableRow
            transitionDelay={index % tablePageDefaultLength}
            row={row}
            key={`receipt-${index}`}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ReceiptsSearchTable;
