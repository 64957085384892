import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { aepPalette } from "../../../styles/aepPalette";

export const rootBoxStyles: SxProps<Theme> = {
  px: 2,
  pb: 3,
  backgroundColor: aepPalette.secondary.lighter,
  border: `1px solid ${aepPalette.secondary.light}`,
  borderRadius: "10px",
  position: "relative",
};

export const tableCellStyles: SxProps<Theme> = {
  paddingBottom: "16px !important",
  paddingTop: "16px !important",
};

export const tableCellInputStyles: CSSProperties = {
  width: "100px",
  backgroundColor: aepPalette.secondary.lighter,
  borderRadius: "5px",
  border: `1px solid ${aepPalette.secondary.light}`,
  color: aepPalette.font.light,
  fontSize: "1.2rem",
  lineHeight: "2rem",
  textAlign: "center",
  padding: "5px auto",
  outline: "none",
};
