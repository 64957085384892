import { SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const rootBoxStyles: SxProps<Theme> = {
  display: "grid",
  columnGap: "2.5%",
  rowGap: 3,
  gridTemplateColumns: {
    md: "repeat(2, 48.75%)",
    xs: "100%",
  },
};

export const typographyInfoStyles: SxProps<Theme> = {
  fontSize: "1.3rem",
  lineHeight: "2rem",
  color: aepPalette.base.main,
  whiteSpace: "nowrap",
};

export const gridContainerStyles: SxProps<Theme> = {
  display: "grid",
  flexGrow: 1,
  overflow: "auto",
  px: "5%",
  width: "100%",
};

export const imageWrapperContainerStyles: SxProps<Theme> = {
  textAlign: "center",
  gridColumn: "1 / 2",
  overflow: "hidden",
  maxHeight: {
    xs: "245px",
    lg: "350px",
  },
  pr: 3,
};

export const imageStyles: SxProps<Theme> = {
  height: "100%",
  objectFit: "scale-down",
};

export const teaserTextBoxStyles: SxProps<Theme> = {
  gridColumn: {
    xs: "2 / 3",
    lg: "2 / 4",
  },
  gridRow: "1 / 2",
};

export const teaserTextStyles: SxProps<Theme> = {
  fontSize: "1.3rem",
  fontWeight: 300,
  lineHeight: "2rem",
  color: aepPalette.font.dark,
  whiteSpace: "pre-wrap",
};

export const infoBoxOneStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gridColumn: "2 / 3",
};

export const infoBoxTwoStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
