import { Box } from "@mui/material";

interface PageWrapperProps {
  element: JSX.Element;
  scrollable?: boolean;
}

const fullPageHeight = "calc(100vh - 140px)";

const PageWrapper = (props: PageWrapperProps) => {
  const { element, scrollable } = props;
  return (
    <Box
      sx={{
        position: "relative",
        pb: 5,
        pr: { xs: "2rem", xl: "4rem" },
        ml: 1,
        minHeight: "600px",
        maxHeight: scrollable ? "1600px" : "calc(100vh - 140px)",
        height: {
          xs: scrollable ? "180vh" : "calc(100vh - 140px)",
          xl: fullPageHeight,
        },
        "& > div": {
          height: "100%",
        },
      }}
    >
      {element}
    </Box>
  );
};

export default PageWrapper;
