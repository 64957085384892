import { Box, Chip, Collapse } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserDtoPermissionsEnum } from "../../../../api";
import { aepPalette } from "../../../../styles/aepPalette";
import { useRef, useState } from "react";
import {
  expandMoreIconStyles,
  permissionChipDropdownBoxOuterStyles,
  permissionChipDropdownBoxInnerStyles,
} from "../employeesStyles";

interface PermissionChipDropdownProps {
  children: JSX.Element | JSX.Element[];
  checkAccessRights: (
    pageName: UserDtoPermissionsEnum | UserDtoPermissionsEnum[]
  ) => boolean;
  disabled?: boolean;
  label: string;
  childPermissions: UserDtoPermissionsEnum[];
  onClick?: () => void;
}

const PermissionChipDropdown = (
  props: PermissionChipDropdownProps
): JSX.Element => {
  const {
    checkAccessRights,
    children,
    childPermissions,
    disabled,
    label,
    onClick,
  } = props;
  const [open, setOpen] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <Box component="span" sx={permissionChipDropdownBoxOuterStyles}>
      <Box
        component="span"
        sx={permissionChipDropdownBoxInnerStyles}
        ref={parentRef}
      >
        <Chip
          disabled={disabled}
          onClick={onClick}
          id={label}
          icon={
            <Collapse
              orientation="horizontal"
              in={checkAccessRights(childPermissions)}
              timeout={100}
            >
              <CheckIcon />
            </Collapse>
          }
          label={label}
          variant={checkAccessRights(childPermissions) ? "checked" : "filled"}
          sx={{
            m: 0.5,
            pr: 4,
          }}
        />
        <ExpandMoreIcon
          onClick={() => setOpen(!open)}
          sx={{
            ...expandMoreIconStyles,
            transform: open ? "rotate(180deg)" : "",
            color: checkAccessRights(childPermissions)
              ? aepPalette.base.lighter
              : aepPalette.secondary.dark,
          }}
        />
      </Box>
      <Box
        component="span"
        sx={{
          overflow: "visible",
          maxWidth: parentRef.current?.offsetWidth + "px",
          display: "flex",
          flexDirection: "column",
          marginBottom: open ? "16px" : 0,
          maxHeight: open ? "1000px" : "0px",
          transition: "max-height linear 0.3s, margin-bottom linear 0.3s",
          "& > *": {
            maxHeight: open ? "1000px" : "0px",
            transition: "max-height linear 0.3s, opacity linear 0.3s",
            opacity: open ? 1 : 0,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PermissionChipDropdown;
