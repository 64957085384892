import { Box } from "@mui/material";
import FilterTab from "./FilterTab";
import { TableFilterMenuItem } from "./models";

interface FilterTabsFieldProps {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  menuItems?: TableFilterMenuItem[];
  selectedTab: string;
}

const FilterTabsField = (props: FilterTabsFieldProps) => {
  const { setValue, menuItems, selectedTab } = props;

  return (
    <Box
      sx={{
        mt: 7,
        pl: 6,
        display: "flex",
      }}
    >
      {menuItems?.map((item, index) => (
        <FilterTab
          key={index}
          setValue={setValue}
          label={item.label}
          value={item.value}
          selected={selectedTab === item.value}
        />
      ))}
    </Box>
  );
};

export default FilterTabsField;
