import { useContext, useEffect, useState } from "react";
import { UserProfileDto } from "../../../api";
import ApiWrapper from "../../wrappers/ApiWrapper";
import CustomerContext from "../../contexts/CustomerContext";
import SnackbarContext from "../../contexts/SnackbarContext";
import { rootBoxStyles } from "./userProfileStyles";
import UserContext from "../../contexts/UserContext";
import UserProfileForm from "./UserProfileForm";
import Card from "../../components/card/Card";

const UserProfile = () => {
  const api = new ApiWrapper().userControllerApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserProfileDto>();
  const { customerNumber } = useContext(CustomerContext);
  const { userName } = useContext(UserContext);
  const { handleHttpError } = useContext(SnackbarContext);

  const getUserProfile = () => {
    if (userName && customerNumber) {
      setLoading(true);
      api
        .getUserProfile(customerNumber, userName)
        .then((res) => {
          setUserData(res.data);
        })
        .catch(handleHttpError)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed) {
      getUserProfile();
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [userName, customerNumber]);

  return (
    <Card sx={rootBoxStyles} loading={loading}>
      {userData && <UserProfileForm userData={userData} />}
    </Card>
  );
};

export default UserProfile;
