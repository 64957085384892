import { useEffect, useContext, useState } from "react";
import UserContext from "../../contexts/UserContext";
import { UserDtoPermissionsEnum } from "../../../api";
import DynamicGrid from "../../components/grid/DynamicGrid";
import AnalysesCard from "../../components/card/AnalysesCard";
import LastDeliveriesCard from "./cards/LastDeliveriesCard";
import LastInvoicesCard from "./cards/LastInvoicesCard";
import OffersCard from "./cards/OffersCard";
import NewsCard from "./cards/NewsCard";

export enum DashboardCardIds {
  DELIVERY = 0,
  ANALYSES = 1,
  LAST_DELIVERIES = 2,
  LAST_INVOICES = 3,
  NEWS = 4,
  OFFERS = 5,
}

const Dashboard = () => {
  const { userRoles, checkUserRole } = useContext(UserContext);
  const [cardsToShow, setCardsToShow] = useState<number[]>([]);

  const mapCardsToRoles = (): DashboardCardIds[] => {
    // Possible Configurations
    const adminConfig = [
      DashboardCardIds.DELIVERY,
      DashboardCardIds.LAST_DELIVERIES,
      DashboardCardIds.LAST_INVOICES,
      DashboardCardIds.OFFERS,
      DashboardCardIds.ANALYSES,
      DashboardCardIds.NEWS,
    ];
    const bestellungenConfig = [DashboardCardIds.DELIVERY];
    const lieferscheineConfig = [DashboardCardIds.LAST_DELIVERIES];
    const sammelrechnungenConfig = [DashboardCardIds.LAST_INVOICES];
    const analysenConfig = [DashboardCardIds.ANALYSES];
    const aepImBlickConfig = [DashboardCardIds.NEWS, DashboardCardIds.OFFERS];

    let cards: number[] = [];

    if (checkUserRole([UserDtoPermissionsEnum.Admin])) {
      cards = adminConfig;
    } else {
      if (checkUserRole([UserDtoPermissionsEnum.Bestellungen])) {
        cards = cards.concat(bestellungenConfig);
      }
      if (checkUserRole([UserDtoPermissionsEnum.Bestellungen])) {
        cards = cards.concat(lieferscheineConfig);
      }
      if (checkUserRole([UserDtoPermissionsEnum.Sammelrechnungen])) {
        cards = cards.concat(sammelrechnungenConfig);
      }
      if (checkUserRole([UserDtoPermissionsEnum.Analysen])) {
        cards = cards.concat(analysenConfig);
      }
      if (checkUserRole([UserDtoPermissionsEnum.AepImBlick])) {
        cards = cards.concat(aepImBlickConfig);
      }
    }

    return cards;
  };

  useEffect(() => {
    setCardsToShow(mapCardsToRoles());
  }, [userRoles]);

  return (
    <DynamicGrid
      shownCardIds={cardsToShow}
      cardRenderObjects={[
        {
          component: AnalysesCard,
          id: DashboardCardIds.ANALYSES,
        },
        {
          component: LastDeliveriesCard,
          id: DashboardCardIds.LAST_DELIVERIES,
        },
        {
          component: LastInvoicesCard,
          id: DashboardCardIds.LAST_INVOICES,
        },
        {
          component: NewsCard,
          id: DashboardCardIds.NEWS,
        },
        {
          component: OffersCard,
          id: DashboardCardIds.OFFERS,
        },
      ]}
      xlInitialGrid={[
        [2, 2, 2, 2, 3, 3, 3, 3, 1, 1, 1, 1],
        [2, 2, 2, 2, 3, 3, 3, 3, 1, 1, 1, 1],
        [2, 2, 2, 2, 3, 3, 3, 3, 1, 1, 1, 1],
        [2, 2, 2, 2, 3, 3, 3, 3, 1, 1, 1, 1],
        [2, 2, 2, 2, 3, 3, 3, 3, 1, 1, 1, 1],
        [2, 2, 2, 2, 3, 3, 3, 3, 1, 1, 1, 1],
        [2, 2, 2, 2, 3, 3, 3, 3, 1, 1, 1, 1],
        [4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5],
        [4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5],
        [4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5],
        [4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5],
        [4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5],
      ]}
      xsInitialGrid={[
        [2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3],
        [2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3],
        [2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
        [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      ]}
    />
  );
};

export default Dashboard;
