import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const SpecialOrdersIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 29 13" {...props}>
    <path d="M28.9848 0.0319456C28.7939 0.0290319 27.8311 -0.00739018 28.0843 0.00135112H1.15087L0 0.0144631L0.0442642 2.82916L1.81483 2.81313L3.41526 13.5897C3.49826 13.9903 3.71128 14.3502 4.0156 14.6066C4.31991 14.863 4.69893 15.0014 5.08901 15H24.1461C24.5362 15.0029 24.9152 14.863 25.2196 14.6066C25.5239 14.3502 25.7355 13.9903 25.8199 13.5897L27.3954 2.83062H29L28.9848 0.0319456ZM24.965 13.3755C24.9235 13.5751 24.8184 13.7529 24.6676 13.8796C24.5169 14.0064 24.3287 14.0748 24.1351 14.0748H5.08901C4.89812 14.0734 4.71276 14.0034 4.56337 13.8767C4.41398 13.75 4.31161 13.5737 4.27012 13.377L2.70289 2.82916H26.4617L24.9637 13.3741L24.965 13.3755ZM0.84932 0.968721L28.0829 0.925015C28.3277 0.945411 28.0829 0.665689 28.0829 0.925015V1.91569H0.864536" />
    <path d="M16.4926 12.8586L17.0113 9.67093L14.8119 7.41276L17.851 6.94802L19.2107 4.04736L20.5704 6.94802L23.6095 7.41276L21.4101 9.67093L21.9288 12.8586L19.2107 11.3536L16.4926 12.8586V12.8586ZM19.2107 10.548L21.0297 11.5547L20.6825 9.42035L22.1543 7.90956L20.1195 7.59779L19.2093 5.65576L18.2991 7.59779L16.2644 7.90956L17.7361 9.42035L17.3889 11.5547L19.2093 10.548H19.2107Z" />
  </IconWrapper>
);

export default SpecialOrdersIcon;
