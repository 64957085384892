import { Box, Stack, Typography } from "@mui/material";
import PermissionChip from "./PermissionChip";
import Checkbox from "@mui/material/Checkbox";
import { UserDtoPermissionsEnum } from "../../../../api";
import PermissionChipDropdown from "./PermissionChipDropdown";

interface EmployeePermissionsProps {
  permissions: UserDtoPermissionsEnum[];
  setPermissions: React.Dispatch<
    React.SetStateAction<UserDtoPermissionsEnum[]>
  >;
}

type PagePermission = UserDtoPermissionsEnum | UserDtoPermissionsEnum[];

const EmployeePermissions = (props: EmployeePermissionsProps) => {
  const { permissions, setPermissions } = props;

  const checkAccessRights = (requiredPermissions: PagePermission) => {
    const isAdmin = permissions.includes(UserDtoPermissionsEnum.Admin);

    if (isAdmin) {
      return true; // Admin has access to all pages
    }

    if (Array.isArray(requiredPermissions)) {
      return requiredPermissions.every((name) => permissions.includes(name));
    } else {
      return permissions.includes(requiredPermissions);
    }
  };

  const handleUpdateChip = (pageName: UserDtoPermissionsEnum) => {
    if (permissions.includes(pageName)) {
      setPermissions((prevState) => [
        ...prevState.filter((name) => name !== pageName),
      ]);
    } else {
      setPermissions((prevState) => [...prevState, pageName]);
    }
  };

  const handleUpdateMultipleChips = (pageNames: UserDtoPermissionsEnum[]) => {
    const isInPermissions = (pageName: UserDtoPermissionsEnum) =>
      permissions.includes(pageName);

    if (pageNames.every(isInPermissions)) {
      setPermissions((prevState) => [
        ...prevState.filter((name) => !pageNames.includes(name)),
      ]);
    } else {
      setPermissions((prevState) => [...prevState, ...pageNames]);
    }
  };

  const BelegePermissions = [
    UserDtoPermissionsEnum.Lieferscheine,
    UserDtoPermissionsEnum.Monatsberichte,
    UserDtoPermissionsEnum.Nachbelastungen,
    UserDtoPermissionsEnum.Gutschriften,
    UserDtoPermissionsEnum.Sammelrechnungen,
  ];

  return (
    <>
      <Typography variant="body1" color="font.main" sx={{ py: 2 }}>
        Berechtigungen Ihres Mitarbeiters
      </Typography>
      <Typography variant="body2" color="font.main" sx={{ py: 2 }}>
        Berechtigung zum Admin
      </Typography>
      <Stack direction="row" alignItems="flex-start" sx={{ pt: 2 }}>
        <Checkbox
          color="secondary"
          checked={permissions.includes(UserDtoPermissionsEnum.Admin)}
          onClick={() => {
            if (permissions.includes(UserDtoPermissionsEnum.Admin)) {
              setPermissions((prevState) => [
                ...prevState.filter(
                  (permission) => permission !== UserDtoPermissionsEnum.Admin
                ),
              ]);
            } else {
              setPermissions((prevState) => [
                ...prevState,
                UserDtoPermissionsEnum.Admin,
              ]);
            }
          }}
          inputProps={{
            "aria-label": "Mitarbeiter zum Admin berechtigen",
          }}
          sx={{ mr: 2 }}
        />
        <Stack>
          <Typography
            id=""
            variant="body2"
            color="font.main"
            sx={{ pb: 1, pt: "2px" }}
          >
            Mitarbeiter zum Admin berechtigen
          </Typography>
          <Typography
            variant="small"
            color="font.main"
            sx={{ pb: 2, maxWidth: "332px" }}
          >
            Ihr Mitarbeiter hat Zugriff auf alle Ansichten und
            Bearbeitungsfunktionen (Belege, Bestellungen, Analysen, AEP im
            Blick, Postfach und Stammdaten)
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="body2" color="font.main" sx={{ pb: 2, pt: 3 }}>
        Einzelberechtigungen für Ihren Mitarbeiter
      </Typography>
      <Box sx={{ mt: 1, mb: 3 }}>
        <PermissionChipDropdown
          disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
          label="Belege"
          childPermissions={BelegePermissions}
          onClick={() => handleUpdateMultipleChips(BelegePermissions)}
          checkAccessRights={checkAccessRights}
        >
          <PermissionChip
            disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
            label={UserDtoPermissionsEnum.Gutschriften}
            onClick={() =>
              handleUpdateChip(UserDtoPermissionsEnum.Gutschriften)
            }
            checkAccessRights={checkAccessRights}
          />
          <PermissionChip
            disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
            label={UserDtoPermissionsEnum.Lieferscheine}
            onClick={() =>
              handleUpdateChip(UserDtoPermissionsEnum.Lieferscheine)
            }
            checkAccessRights={checkAccessRights}
          />
          <PermissionChip
            disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
            label={UserDtoPermissionsEnum.Monatsberichte}
            onClick={() =>
              handleUpdateChip(UserDtoPermissionsEnum.Monatsberichte)
            }
            checkAccessRights={checkAccessRights}
          />
          <PermissionChip
            disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
            label={UserDtoPermissionsEnum.Nachbelastungen}
            onClick={() =>
              handleUpdateChip(UserDtoPermissionsEnum.Nachbelastungen)
            }
            checkAccessRights={checkAccessRights}
          />
          <PermissionChip
            disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
            label={UserDtoPermissionsEnum.Sammelrechnungen}
            onClick={() =>
              handleUpdateChip(UserDtoPermissionsEnum.Sammelrechnungen)
            }
            checkAccessRights={checkAccessRights}
          />
        </PermissionChipDropdown>
        <PermissionChip
          disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
          label={UserDtoPermissionsEnum.Bestellungen}
          onClick={() => handleUpdateChip(UserDtoPermissionsEnum.Bestellungen)}
          checkAccessRights={checkAccessRights}
        />
        <PermissionChip
          disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
          label={UserDtoPermissionsEnum.Analysen}
          onClick={() => handleUpdateChip(UserDtoPermissionsEnum.Analysen)}
          checkAccessRights={checkAccessRights}
        />
        <PermissionChip
          disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
          label={UserDtoPermissionsEnum.AepImBlick}
          onClick={() => handleUpdateChip(UserDtoPermissionsEnum.AepImBlick)}
          checkAccessRights={checkAccessRights}
        />
        <PermissionChip
          disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
          label={UserDtoPermissionsEnum.Postfach}
          onClick={() => handleUpdateChip(UserDtoPermissionsEnum.Postfach)}
          checkAccessRights={checkAccessRights}
        />
        <PermissionChip
          disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
          label={UserDtoPermissionsEnum.Stammdaten}
          onClick={() => handleUpdateChip(UserDtoPermissionsEnum.Stammdaten)}
          checkAccessRights={checkAccessRights}
        />
        <PermissionChip
          disabled={permissions.includes(UserDtoPermissionsEnum.Admin)}
          label={UserDtoPermissionsEnum.Statistiken}
          onClick={() => handleUpdateChip(UserDtoPermissionsEnum.Statistiken)}
          checkAccessRights={checkAccessRights}
        />
      </Box>
    </>
  );
};

export default EmployeePermissions;
