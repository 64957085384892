import { useKeycloak } from "@react-keycloak/web";

interface PrivateWrapperProps {
  children: JSX.Element | JSX.Element[];
}

const PrivateWrapper = (props: PrivateWrapperProps): JSX.Element => {
  const { children } = props;
  const { keycloak, initialized } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  if (isLoggedIn) {
    return <>{children}</>;
  } else {
    if (initialized) {
      keycloak.login();
    }
    return <></>;
  }
};

export default PrivateWrapper;
