import React from "react";
import { CustomerContextProvider } from "./CustomerContext";
import { SnackbarContextProvider } from "./SnackbarContext";
import { UserContextProvider } from "./UserContext";
import { SpecialOrdersContextProvider } from "./SpecialOrdersContext";

export const ContextsProvider: React.FunctionComponent<{
  children?: JSX.Element;
}> = (props): JSX.Element => {
  const { children } = props;
  return (
    <SnackbarContextProvider>
      <CustomerContextProvider>
        <UserContextProvider>
          <SpecialOrdersContextProvider>
            {children}
          </SpecialOrdersContextProvider>
        </UserContextProvider>
      </CustomerContextProvider>
    </SnackbarContextProvider>
  );
};

export default ContextsProvider;
