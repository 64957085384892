import { Typography } from "@mui/material";
import Card from "../../components/card/Card";
import DownloadSearchContainer from "./DownloadSearchContainer";

const Downloads = () => {
  return (
    <Card sx={{ px: 2, py: 3 }}>
      <Typography sx={{ mb: 4 }} variant="h2">
        Downloadbereich
      </Typography>
      <DownloadSearchContainer />
    </Card>
  );
};

export default Downloads;
