import Card from "./Card";
import { Typography } from "@mui/material";
import Contact from "../../../strapi/models/Contact";
import { contactCardTypographyStyles } from "../../pages/contact/contactStyles";
import { Link } from "react-router-dom";

interface ContactCardProps {
  contactInfo?: Contact;
  isArticleAdmission?: boolean;
}

const ContactCard = (props: ContactCardProps): JSX.Element => {
  const { isArticleAdmission, contactInfo } = props;

  return (
    <Card
      sx={{
        px: 3,
      }}
    >
      <Typography variant="h2" sx={{ pt: 2, pb: 4 }}>
        {isArticleAdmission
          ? "Haben Sie Fragen zur Artikelneuaufnahme?"
          : "Weitere Kontaktmöglichkeiten"}
      </Typography>
      {isArticleAdmission && (
        <Link to="../../kontakt" style={{ cursor: "pointer" }}>
          <Typography
            variant="body2"
            sx={{ mb: 3, textDecoration: "underline" }}
          >
            Kontaktformular
          </Typography>
        </Link>
      )}
      <Typography variant="body2" sx={contactCardTypographyStyles}>
        {contactInfo?.emailaddresse}
      </Typography>
      <Typography variant="body2" sx={contactCardTypographyStyles}>
        T: {contactInfo?.telefonnummer}
      </Typography>
      <Typography
        variant="body2"
        sx={{ ...contactCardTypographyStyles, whiteSpace: "pre-wrap" }}
      >
        {contactInfo?.erreichbarkeit}
      </Typography>
      <Typography variant="body2" sx={contactCardTypographyStyles}>
        F: {contactInfo?.faxnummer}
      </Typography>
    </Card>
  );
};

export default ContactCard;
