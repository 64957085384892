import { SxProps, Theme } from "@mui/material";
import { theme } from "../../../styles/AepThemeProvider";

export const rootBoxStyles: SxProps<Theme> = {
  display: "grid",
  gap: 3,
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "100%",
};

export const orderButtonStyles: SxProps<Theme> = {
  position: "absolute",
  bottom: "15px",
  right: "15px",
  width: "200px",
  fontSize: "1.3rem",
  marginTop: "12px",
  pl: "0px",
  pr: "0px",
};

export const textfieldStyles: SxProps<Theme> = {
  fontSize: "1.3rem",
  width: "22.35%",
  lineHeight: "1.35rem",
  margin: "0 16px",
};

export const orderSearchButtonStyles: SxProps<Theme> = {
  width: "18.2%",
  margin: "12px 16px 0",
  fontSize: "1.3rem",
};

export const ordersCardStyles: SxProps<Theme> = {
  marginBottom: "24px",
  py: 2,
  paddingLeft: "4.8%",
  paddingRight: "4.8%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const dailyOrderTableRowSeparatorStyles: SxProps<Theme> = {
  "td:nth-of-type(3)::after": {
    content: `""`,
    display: "block",
    margin: "15px 0",
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
  },
  ":hover td:nth-of-type(3)::after": {
    content: `""`,
    display: "block",
    margin: "15px 0",
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
  },
};
