import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import NavButton from "../../../components/button/NavButton";
import DeliveryDetailCard from "./DeliveryDetailCard";
import { useContext, useEffect, useState } from "react";
import CustomerContext from "../../../contexts/CustomerContext";

export type DeliveryNoteReturn = {
  id: string;
  reason: string;
};

const DeliveriesDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedRows = state as string[];
  const { customerNumber } = useContext(CustomerContext);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (!initialRender) {
      navigate(-1);
    } else {
      setInitialRender(false);
    }
  }, [customerNumber]);

  return selectedRows ? (
    <Box>
      <Typography variant="h2">Lieferscheinansicht</Typography>
      <NavButton
        back
        buttonText="Zur Liste"
        onClick={() => navigate(-1)}
        sx={{ width: "14rem", my: 1 }}
      />
      {selectedRows.map((id: string, i) => (
        <DeliveryDetailCard index={i} key={`detail-row${id}`} deliveryId={id} />
      ))}
      <Box sx={{ height: "50px" }} />
    </Box>
  ) : (
    <Navigate to="../bestellungen/lieferungen" replace />
  );
};

export default DeliveriesDetails;
