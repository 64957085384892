import {
  CancellationsControllerApi,
  CounterControllerApi,
  DeliveriesControllerApi,
  DispensationsControllerApi,
  DocumentsControllerApi,
  OrdersControllerApi,
  UserControllerApi,
  InvoicesControllerApi,
  MessageControllerApi,
  SpecialOrdersControllerApi,
  ArticleAdmissionControllerApi,
  AnalysisControllerApi,
  ReceiptsControllerApi,
  FurtherReceiptsControllerApi,
  ReturnsControllerApi,
} from "../../api";
import { Configuration } from "../../api/configuration";
import { useKeycloak } from "@react-keycloak/web";

class ApiWrapper {
  public accessToken = () => {
    const { keycloak } = useKeycloak();
    return keycloak.token || "";
  };

  private configuration = () => {
    return new Configuration({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      basePath: window._env_.REACT_APP_API_URL,
      baseOptions: {
        headers: { authorization: `Bearer ${this.accessToken()}` },
      },
    });
  };

  public deliveriesControllerApi = () => {
    return new DeliveriesControllerApi(this.configuration());
  };

  public ordersControllerApi = () => {
    return new OrdersControllerApi(this.configuration());
  };

  public dispensationsControllerApi = () => {
    return new DispensationsControllerApi(this.configuration());
  };

  public cancellationsControllerApi = () => {
    return new CancellationsControllerApi(this.configuration());
  };

  public userControllerApi = () => {
    return new UserControllerApi(this.configuration());
  };

  public documentsControllerApi = () => {
    return new DocumentsControllerApi(this.configuration());
  };

  public invoicesControllerApi = () => {
    return new InvoicesControllerApi(this.configuration());
  };

  public messageControllerApi = () => {
    return new MessageControllerApi(this.configuration());
  };

  public counterControllerApi = () => {
    return new CounterControllerApi(this.configuration());
  };

  public specialOrdersControllerApi = () => {
    return new SpecialOrdersControllerApi(this.configuration());
  };

  public articleAdmissionControllerApi = () => {
    return new ArticleAdmissionControllerApi(this.configuration());
  };

  public analysisControllerApi = () => {
    return new AnalysisControllerApi(this.configuration());
  };

  public receiptsControllerApi = () => {
    return new ReceiptsControllerApi(this.configuration());
  };

  public furtherReceiptsControllerApi = () => {
    return new FurtherReceiptsControllerApi(this.configuration());
  };

  public returnsControllerApi = () => {
    return new ReturnsControllerApi(this.configuration());
  };
}

export default ApiWrapper;
