import { Stack, Typography } from "@mui/material";
import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import { useEffect, useState } from "react";
import CenteredTextMessage from "../../other/CenteredTextMessage";
import { sortBySortPosition, ChartData } from "../Chart";
import { chartTheme } from "../chartStyles";
import ChartTooltip from "./ChartTooltip";

export interface SwarmPlotData {
  data: SwarmPlotDataPoint[];
  headline?: string;
  nodeSizeLabel?: string;
  unit?: string;
  xlabel: string;
  ylabel: string;
}

interface SwarmPlotDataPoint extends ChartData {
  size: number;
  y: number;
}

interface SwarmPlotProps {
  mini?: boolean;
  chartData: SwarmPlotData;
}

const SwarmPlot = (props: SwarmPlotProps) => {
  const { chartData, mini } = props;
  const { data, nodeSizeLabel, unit, xlabel, ylabel } = chartData;
  const [groups, setGroups] = useState<string[]>([]);
  const [nodeSizeValues, setNodeSizeValues] = useState<[number, number]>([
    0, 100,
  ]);
  const [yAxisMin, setYAxisMin] = useState<number>(0);
  const [yAxisMax, setYAxisMax] = useState<number>(2000);

  const parseData = (): {
    groupsArr: string[];
    minSize: number;
    maxSize: number;
  } => {
    let groupsArr: string[] = [];
    let minSize = 0;
    let maxSize = 100;

    const yValues = data
      .map((datapoint) => datapoint.y)
      .sort(function (a, b) {
        return a - b;
      });
    const minY = Math.floor(Math.min(...yValues) / 200) * 200;
    const maxY = Math.ceil(Math.max(...yValues) / 200) * 200;

    setYAxisMin(minY);
    setYAxisMax(maxY);

    data.sort(sortBySortPosition).forEach((dataPoint) => {
      if (!groupsArr.includes(dataPoint.x)) {
        groupsArr.push(dataPoint.x);
      }
      if (dataPoint.size < minSize) {
        minSize = dataPoint.size;
      }
      if (dataPoint.size > maxSize) {
        maxSize = dataPoint.size;
      }
    });

    // ensure x values are integers and fill in missing numbers to create linear horizontal scale
    const groupsArrIntegers = groupsArr.map((item) => {
      if (typeof item === "string") {
        return parseInt(item, 10);
      } else {
        return item;
      }
    });

    const maxX = Math.max(...groupsArrIntegers);
    const minX = Math.min(...groupsArrIntegers);

    if (!!maxX && !!minX && !groupsArrIntegers.includes(NaN)) {
      const fullRangeX: string[] = [];

      for (let i = minX; i <= maxX; i++) {
        let xVal = i.toString();
        // add preceding 0
        if (i < 10) {
          xVal = "0" + xVal;
        }
        fullRangeX.push(xVal);
      }

      groupsArr = fullRangeX;
    }

    return { groupsArr, minSize, maxSize };
  };

  useEffect(() => {
    const { groupsArr, minSize, maxSize } = parseData();
    setNodeSizeValues([minSize, maxSize]);
    setGroups(groupsArr);
  }, [data]);

  return groups.length > 0 && data.length > 0 ? (
    <>
      <ResponsiveSwarmPlot
        theme={chartTheme}
        tooltip={(values) => {
          return (
            <ChartTooltip
              color={values.color}
              keys={[nodeSizeLabel || ""]}
              values={[values.data.size.toString()]}
            />
          );
        }}
        data={data}
        groupBy="x"
        groups={groups}
        value="y"
        valueFormat={(value: number | Date) => `${value}${unit || ""}`}
        valueScale={{
          type: "linear",
          min: yAxisMin,
          max: yAxisMax,
          reverse: false,
        }}
        size={{
          key: "size",
          values: nodeSizeValues,
          sizes: mini ? [6, 50] : [10, 90],
        }}
        spacing={1}
        colors={{ scheme: "red_blue" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.6]],
        }}
        margin={
          mini
            ? { top: 70, right: 20, bottom: 55, left: 70 }
            : { top: 110, right: 60, bottom: 140, left: 80 }
        }
        enableGridY={true}
        axisTop={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: xlabel,
          legendPosition: "middle",
          legendOffset: mini ? 40 : 60,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: ylabel,
          legendPosition: "middle",
          legendOffset: mini ? -60 : -70,
        }}
        axisRight={null}
      />
      {nodeSizeLabel && (
        <Stack
          direction="row"
          sx={{
            justifyContent: "center",
            width: "100%",
            mt: mini ? 2 : -2,
          }}
        >
          <Typography
            sx={{ fontSize: "1.1rem", lineHeight: "1.3rem", fontWeight: 300 }}
          >
            *Knotengröße zeigt:
          </Typography>
          <Typography
            sx={{ fontSize: "1.1rem", lineHeight: "1.3rem", fontWeight: 500 }}
          >
            {nodeSizeLabel}
          </Typography>
        </Stack>
      )}
    </>
  ) : (
    <CenteredTextMessage message="Für die Vorschau sind keine Daten vorhanden" />
  );
};

export default SwarmPlot;
