import { useContext } from "react";
import { Formik, FormikErrors } from "formik";
import { Box, Typography, TextField, Button, Stack } from "@mui/material";
import Card, { CardProps } from "../../../components/card/Card";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { formTextFieldStyles, buttonStyles } from "../contactStyles";
import CustomerContext from "../../../contexts/CustomerContext";
import UserContext from "../../../contexts/UserContext";
import SnackbarContext from "../../../contexts/SnackbarContext";

export interface ContactMessageCardProps extends CardProps {
  buttonText?: string;
}

interface LocalFormikValues {
  subject: string;
  message: string;
}

const initialValues: LocalFormikValues = {
  subject: "",
  message: "",
};

const ContactMessageCard = (props: ContactMessageCardProps): JSX.Element => {
  const api = new ApiWrapper().messageControllerApi();
  const { customerNumber } = useContext(CustomerContext);
  const { userFullName } = useContext(UserContext);
  const { showSnackbar, handleError } = useContext(SnackbarContext);
  const { sx } = props;

  return (
    <Card sx={sx}>
      <Box sx={{ px: 3, pt: 2 }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            form: {
              width: "100%",
            },
          }}
        >
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: FormikErrors<LocalFormikValues> = {};
              if (!values.subject) {
                errors.subject = "Erforderlich";
              }
              if (!values.message) {
                errors.message = "Erforderlich";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const { status } = await api.postMessage(customerNumber, {
                  subject: values.subject,
                  message: values.message,
                  userid: userFullName,
                });
                if (status === 201) {
                  showSnackbar(
                    "success",
                    "Nachricht wurde erfolgreich versendet"
                  );
                }
              } catch (error) {
                handleError("Nachricht konnte nicht versendet werden");
              }
              setSubmitting(false);
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h2" color="font.main" sx={{ pb: 2 }}>
                  Kontakt
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    sx={formTextFieldStyles}
                    error={!!(errors.subject && touched.subject)}
                    helperText={errors.subject}
                    label="Betreff"
                    name="subject"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subject}
                    inputProps={{ maxLength: 100 }}
                  />
                  <TextField
                    fullWidth
                    sx={formTextFieldStyles}
                    error={!!(errors.message && touched.message)}
                    helperText={errors.message}
                    label="Nachricht"
                    multiline
                    minRows={11}
                    maxRows={11}
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.message}
                    inputProps={{ maxLength: 1000 }}
                  />
                </Stack>
                <Button type="submit" disabled={isSubmitting} sx={buttonStyles}>
                  {isSubmitting
                    ? "Anfrage wird gesendet..."
                    : "Anfrage absenden"}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Card>
  );
};

export default ContactMessageCard;
