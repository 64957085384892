import { BadgeProps, Box } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const CustomBadge = (props: BadgeProps) => {
  const { badgeContent, children } = props;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: aepPalette.primary.light,
          borderRadius: "50%",
          color: "white",
          display: "flex",
          fontSize: "13px",
          height: "25px",
          justifyContent: "center",
          position: "absolute",
          right: "-14px",
          top: "-12px",
          width: "25px",
        }}
      >
        {badgeContent}
      </Box>
      {children}
    </Box>
  );
};
