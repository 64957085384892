import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, SxProps, Theme } from "@mui/material";
import { iconStyles } from "./toggleStyles";
import CollapseIcon from "../icons/CollapseIcon";

interface ToggleProps {
  active: boolean;
  iconType: "arrowHorizontal" | "arrowVertical";
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const Toggle = (props: ToggleProps): JSX.Element => {
  const { active, iconType, onClick, sx } = props;

  const activeIcon =
    iconType === "arrowHorizontal" ? (
      <CollapseIcon style={{ ...iconStyles }} />
    ) : (
      <KeyboardArrowDownIcon style={{ ...iconStyles }} fontSize="medium" />
    );
  const inactiveIcon =
    iconType === "arrowHorizontal" ? (
      <CollapseIcon flip="true" style={{ ...iconStyles }} />
    ) : (
      <KeyboardArrowRightIcon style={{ ...iconStyles }} fontSize="medium" />
    );

  return (
    <Box onClick={onClick} sx={sx}>
      {active ? activeIcon : inactiveIcon}
    </Box>
  );
};

export default Toggle;
