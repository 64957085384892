import { Box, SxProps, Theme } from "@mui/material";

interface TextButtonProps {
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const TextButton = (props: TextButtonProps): JSX.Element => {
  const { children, onClick, sx } = props;

  return (
    <Box
      sx={{
        textDecoration: "none",
        color: "secondary.dark",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default TextButton;
