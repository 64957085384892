import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

interface CountdownIconProps extends SvgIconProps {
  flip?: string;
}

const CountdownIcon = (props: CountdownIconProps) => (
  <IconWrapper style={{ cursor: "pointer" }} viewBox="0 0 29 27" {...props}>
    <path d="M5.93342 4.46624L5.95047 4.44368L5.17761 3.62244L5.15482 3.645C4.87056 3.90376 4.59778 4.17922 4.34208 4.46052L4.31928 4.43796V4.48857L4.30798 4.49995L5.15482 5.2537L5.17187 5.23114C5.41628 4.96688 5.67199 4.70811 5.93346 4.46624L5.93342 4.46624Z" />
    <path d="M3.5863 7.41407C3.76826 7.099 3.96133 6.7896 4.16033 6.50278L4.17737 6.48022L3.2396 5.83887L3.2283 5.86143C3.00669 6.1765 2.80212 6.50278 2.60886 6.8345L2.59181 6.82332L2.58606 6.88512L3.57498 7.43641L3.5863 7.41407Z" />
    <path d="M1.82436 8.44299C1.67668 8.803 1.54585 9.1742 1.42652 9.55103L1.42078 9.57908L2.47791 9.89965L2.51775 9.91084V9.87729C2.62003 9.53984 2.73936 9.19615 2.87574 8.86478L2.88704 8.83672L1.83565 8.42041L1.82436 8.44299Z" />
    <path d="M14.4758 0C12.8434 0 11.2475 0.282308 9.73131 0.839276L10.127 1.89396C11.5159 1.38389 12.9794 1.1251 14.4758 1.1251C21.3706 1.1251 26.9795 6.67624 26.9795 13.5C26.9795 20.3238 21.3706 25.875 14.4758 25.875C7.58102 25.875 1.97212 20.3238 1.97212 13.5C1.97212 13.1485 1.98798 12.7952 2.01751 12.4499L2.06291 12.4538L2.01751 12.4089C2.05161 12.0489 2.097 11.6946 2.15964 11.357L2.16539 11.3289L1.04564 11.1376L1.03989 11.1657C0.971701 11.5425 0.92056 11.9363 0.886469 12.3243L0.880721 12.3524L0.88528 12.353C0.852969 12.7306 0.835327 13.1165 0.835327 13.5001C0.835327 20.9436 6.95472 27 14.4757 27C21.9967 27 28.1161 20.9436 28.1161 13.5001C28.1161 6.05651 21.9967 0.000115622 14.4757 0.000115622L14.4758 0Z" />
    <path d="M11.0658 18H9.92916V19.1249H19.0227V18H17.8861V16.0795L15.2795 13.4999L17.8859 10.9203V8.99989H19.0225V7.875H9.92896V8.99989H11.0656V10.9203L13.6722 13.4999L11.0658 16.0795V18ZM12.2024 10.4545V8.99974H16.7492V10.4545L14.4758 12.7045L12.2024 10.4545ZM14.4758 14.2952L16.7492 16.5452V17.9999H12.2024V16.5452L14.4758 14.2952Z" />
  </IconWrapper>
);

export default CountdownIcon;
