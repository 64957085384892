export const aepPalette = {
  common: {
    black: "#000",
    white: "#fff",
  },
  base: {
    lightest: "#F8F8F8",
    lighter: "#F5F6F8",
    light: "#B1B0B0",
    main: "#7A7979",
  },
  primary: {
    lighter: "#DDB2B3",
    light: "#E3000F",
    main: "#BB1C0E",
    dark: "#95160B",
  },
  secondary: {
    lighter: "#FCFDFF",
    light: "#C5D9E8",
    main: "#486998",
    dark: "#334D6E",
    darker: "#334D6E",
  },
  warning: {
    main: "#F0AA57",
  },
  error: {
    main: "#D6313C",
  },
  success: {
    main: "#65A414",
  },
  font: {
    light: "#5A5858",
    main: "#4B5D77",
    dark: "#333333",
  },
  other: {
    transparentBlue: "rgba(197, 217, 232, 0.2)",
    transparentBlue2: "rgba(72, 105, 152, 0.2)",
  },
};

export const chartPalette = [
  "#9FC4E3",
  "#C5D9E8",
  "#D01606",
  "#E6EDF2",
  "#F4B2AC",
];
