import { aepPalette } from "./aepPalette";

export const aepTypography = {
  fontFamily: "Museo Sans",
  fontSize: 14,
  h1: {
    color: aepPalette.font?.main,
    fontSize: "2.6rem",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "3.1rem",
  },
  h2: {
    color: aepPalette.font?.main,
    fontSize: "2rem",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "3rem",
  },
  h3: {
    color: aepPalette.font?.main,
    fontSize: "1.6rem",
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "2.8rem",
  },
  h4: {
    color: aepPalette.font?.main,
    fontSize: "1.4rem",
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "2.3rem",
  },
  body1: {
    color: aepPalette.font?.main,
    fontSize: "1.4rem",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "2rem",
  },
  body2: {
    color: aepPalette.font?.main,
    fontSize: "1.3rem",
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "2rem",
  },
  bodyBold: {
    color: aepPalette.font?.main,
    fontSize: "1.3rem",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "2rem",
  },
  button: {
    fontSize: "1.3rem",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "1.5rem",
  },
  subline: {
    fontSize: "1.6rem",
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "2.2rem",
  },
  caption: {
    fontSize: "1.1rem",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "1.5rem",
  },
  small: {
    fontSize: "1rem",
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: "1.2rem",
  },
};
