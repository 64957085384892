import { useContext, useEffect, useState } from "react";
import { FurtherReceiptDto, FurtherReceiptTypeEnum } from "../../../../api";
import TableContainer, {
  tablePageDefaultLength,
} from "../../../components/table/TableContainer";
import CustomerContext from "../../../contexts/CustomerContext";
import SnackbarContext from "../../../contexts/SnackbarContext";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import { TableFilterType } from "../../../components/table/tableFilter/models";
import FurtherReceiptsSearchTable from "./FurtherReceiptsSearchTable";
import IconButton from "../../../components/button/IconButton";
import DownloadIcon from "../../../components/icons/DownloadIcon";
import { Stack, Typography } from "@mui/material";
import { aepPalette } from "../../../../styles/aepPalette";

export enum ReceiptType {
  Gutschrift = "Gutschrift",
  Lieferschein = "Lieferschein",
  Monatsbericht = "Monatsbericht",
  Nachbelastung = "Nachbelastung",
  Sammelrechnung = "Sammelrechnung",
}

export const compressReceiptType = (type?: ReceiptType) => {
  switch (type) {
    case ReceiptType.Gutschrift:
      return "GS";
    case ReceiptType.Lieferschein:
      return "LS";
    case ReceiptType.Monatsbericht:
      return "MB";
    case ReceiptType.Nachbelastung:
      return "NB";
    case ReceiptType.Sammelrechnung:
      return "SR";
    default:
      return;
  }
};

const FurtherReceiptSearchContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [receipts, setReceipts] = useState<FurtherReceiptDto[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { handleHttpError, handleError } = useContext(SnackbarContext);
  const { customerNumber } = useContext(CustomerContext);

  const api = new ApiWrapper().furtherReceiptsControllerApi();

  interface FurtherReceiptSearchFormikValues {
    docType?: FurtherReceiptTypeEnum;
    deliveryNoteId?: number;
    pzn?: string;
    fromDate?: string;
    toDate?: string;
    articleName?: string;
    supplierName?: string;
    batchNumber?: string;
  }

  const [searchParamsState, setSearchParamsState] =
    useState<FurtherReceiptSearchFormikValues>();
  const [noDataMessage, setNoDataMessage] = useState<string>(
    "Bitte wählen Sie eine Belegart und Zeitraum für die Suche"
  );

  useEffect(() => {
    setPageNumber(0);
    setReceipts([]);
  }, [customerNumber]);

  const downloadReceipts = () => {
    if (customerNumber && !loading) {
      setDownloading(true);

      api
        .getFurtherReceiptsAsCSV(
          customerNumber,
          searchParamsState?.docType || FurtherReceiptTypeEnum.Charge,
          searchParamsState?.fromDate || undefined,
          searchParamsState?.toDate || undefined,
          searchParamsState?.pzn,
          searchParamsState?.articleName,
          searchParamsState?.deliveryNoteId,
          searchParamsState?.supplierName,
          searchParamsState?.batchNumber
        )
        .then((data) => {
          const element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:application/text/csv;base64," + encodeURIComponent(data.data)
          );
          element.setAttribute(
            "download",
            `apoportal-weitere-belege-${customerNumber}.csv`
          );
          element.click();
        })
        .catch((e) => {
          if (e?.response?.data?.message != undefined) {
            handleError(e.response.data.message);
          } else {
            handleError(
              "Der Belegdownload ist aktuell nicht möglich. Bitte kontaktieren Sie das Service Center."
            );
          }
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  };

  const loadReceipts = (
    loadMore: boolean,
    searchParams?: FurtherReceiptSearchFormikValues
  ): void => {
    if (!searchParams) {
      setSearchParamsState({});
    } else {
      setSearchParamsState(searchParams);
    }
    // this check guards from firing multiple calls
    if (customerNumber && !loading) {
      setLoading(true);

      api
        .getFurtherReceipts(
          customerNumber,
          (searchParams?.docType as FurtherReceiptTypeEnum) || undefined,
          loadMore ? pageNumber : 0,
          tablePageDefaultLength,
          searchParams?.fromDate || undefined,
          searchParams?.toDate || undefined,
          searchParams?.pzn,
          searchParams?.articleName,
          searchParams?.deliveryNoteId,
          searchParams?.supplierName,
          searchParams?.batchNumber
        )
        .then((res) => {
          const newReceipts = res.data;

          if (loadMore) {
            setReceipts((existingReceipts) => {
              return existingReceipts.concat(newReceipts);
            });
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          } else {
            setReceipts(newReceipts);
            setPageNumber(1);

            if (newReceipts.length === 0) {
              setNoDataMessage("Keine Belege verfügbar");
            } else {
              setNoDataMessage(
                "Bitte wählen Sie eine Belegart und Zeitraum für die Suche"
              );
            }
          }
        })
        .catch((e) => {
          handleHttpError(e);
          setPageNumber(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const initialValues: FurtherReceiptSearchFormikValues = {
    docType: "" as unknown as FurtherReceiptTypeEnum,
    deliveryNoteId: undefined,
    pzn: "",
    fromDate: "",
    toDate: "",
    articleName: "",
    supplierName: "",
    batchNumber: "",
  };

  return (
    <>
      <TableContainer
        hasDetailViewSwitch
        pageable
        loadCallback={loadReceipts}
        loading={loading}
        noData={receipts.length === 0}
        noDataMessage={noDataMessage}
        noInitialApiCall
        tableFilterProps={{
          initialValues: initialValues,
          searchFields: [
            {
              type: TableFilterType.dropdown,
              name: "docType",
              label: "*Belegart",
              menuItems: [
                { label: "Defekt", value: FurtherReceiptTypeEnum.Defekt },
                { label: "Chargen", value: FurtherReceiptTypeEnum.Charge },
                { label: "Hochpreiser", value: FurtherReceiptTypeEnum.Hochp },
                { label: "Reimporte", value: FurtherReceiptTypeEnum.Reimp },
                { label: "Transfusion", value: FurtherReceiptTypeEnum.Tfg },
                {
                  label: "Tierarzneimittel",
                  value: FurtherReceiptTypeEnum.Tierarz,
                },
                { label: "AEP Premium", value: FurtherReceiptTypeEnum.Aeppr },
                { label: "AEP Plus", value: FurtherReceiptTypeEnum.Aeppl },
                {
                  label: "AEP Logistik-PZNs",
                  value: FurtherReceiptTypeEnum.Logvg,
                },
                { label: "Valuta", value: FurtherReceiptTypeEnum.Valuta },
                { label: "Überweiser", value: FurtherReceiptTypeEnum.Uebw },
                { label: "Index-Liste", value: FurtherReceiptTypeEnum.Index },
                {
                  label: "Betäubungsmittel",
                  value: FurtherReceiptTypeEnum.Btm,
                },
                {
                  label: "Offene MaxNachlieferung",
                  value: FurtherReceiptTypeEnum.Maxnl,
                },
              ],
              validate: (value: string) => {
                const enumValues = Object.values(FurtherReceiptTypeEnum);
                return enumValues.includes(
                  value as unknown as FurtherReceiptTypeEnum
                );
              },
              errorMessage: "Erforderlich",
            },
            {
              type: TableFilterType.textfield,
              name: "deliveryNoteId",
              label: "Belegnummer",
            },
            {
              type: TableFilterType.textfield,
              name: "pzn",
              label: "PZN",
            },
            {
              type: TableFilterType.dateRange,
              name: "",
              label: "Zeitraum",
            },
            {
              type: TableFilterType.textfield,
              name: "articleName",
              label: "Artikelbezeichnung",
            },
            {
              type: TableFilterType.textfield,
              name: "supplierName",
              label: "Lieferant",
            },
            {
              type: TableFilterType.textfield,
              name: "batchNumber",
              label: "ChargenNummer",
            },
          ],
        }}
        Table={() => (
          <FurtherReceiptsSearchTable receipts={receipts} loading={loading} />
        )}
      />

      {receipts.length > 0 && (
        <Stack
          direction="row"
          sx={{
            position: "absolute",
            bottom: 41,
            right: 0,
            mr: -0.5,
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: aepPalette.font.light }}>
            CSV-Datei exportieren
          </Typography>
          <IconButton
            disabled={downloading}
            sx={{ ml: 1 }}
            icon={<DownloadIcon downloading={downloading} bg />}
            onClick={downloadReceipts}
          />
        </Stack>
      )}
    </>
  );
};

export default FurtherReceiptSearchContainer;
