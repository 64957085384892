import { AlertColor } from "@mui/material";
import React, { useState } from "react";
import { CancelDto, CancelErrorDto } from "../../api";
import Snackbar from "../components/snackbar/Snackbar";

export interface SnackbarContextType {
  showSnackbar: (newSeverity: AlertColor, newMessage: string) => void;
  handleHttpError: (e: Error) => void;
  handleError: (errorMessage: string) => void;
  handleCancelOrderPositions: (
    positions: CancelDto[],
    failedPositionErrors: CancelErrorDto[],
    onCancelSuccess: () => void
  ) => void;
}

export const SnackbarContext = React.createContext<SnackbarContextType>({
  showSnackbar: () => null,
  handleHttpError: () => null,
  handleError: () => null,
  handleCancelOrderPositions: () => null,
});
// ADJUST separator character here for splitting up mulitline message text
export const multilineSeparator = "&";

export const SnackbarContextProvider: React.FunctionComponent<{
  children?: JSX.Element;
}> = (props) => {
  const { children } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [message, setMessage] = useState<string>("");
  const [autoHideDuration, setAutoHideDuration] = useState<number>(3000);

  const showSnackbar = (
    newSeverity: AlertColor,
    newMessage: string,
    autoHideDuration?: number
  ) => {
    setSeverity(newSeverity);
    setMessage(newMessage);
    if (autoHideDuration) {
      setAutoHideDuration(autoHideDuration);
    }
    setOpen(true);
  };

  const handleError = (errorMessage: string) => {
    showSnackbar("error", errorMessage);
  };

  const handleHttpError = (e: Error) => {
    console.error(e);
    console.log("Error")
    console.log(e)
  showSnackbar(
      "error",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e.response?.data?.message || "Es ist ein Systemfehler aufgetreten" +
        ". \n Bitte kontaktieren Sie das Service Center." || "HTTP-Fehler"
    );
  };

  const handleCancelOrderPositions = (
    positions: CancelDto[],
    failedPositionErrors: CancelErrorDto[],
    onCancelSuccess: () => void
  ) => {
    let severity: AlertColor = "error";

    const failedPositions = failedPositionErrors.map(
      (errorObj) => errorObj.orderObject
    );
    const succesfulPositions = positions.filter(
      (position) =>
        !failedPositions
          .map((failedPosition) => failedPosition?.orderPosition)
          .includes(position.orderPosition)
    );

    if (failedPositions.length > 0 && succesfulPositions.length > 0) {
      severity = "warning";
    } else if (failedPositionErrors.length < 1) {
      severity = "success";
    }

    // if at least some of the positions are cancelled
    if (severity !== "error") {
      onCancelSuccess();
    }

    let errArr: string[] = [];
    let successArr: string[] = [];

    if (failedPositionErrors.length > 0) {
      errArr = failedPositionErrors.map(
        (position) =>
          `Position ${position.orderObject?.orderPosition}: ${position.errorMessage}`
      );
    }

    if (succesfulPositions.length > 0) {
      successArr = positions.map((position) => {
        return `Bestellung ${position.orderPosition}: Wurde erfolgreich storniert.`;
      });
    }
    const message = errArr.concat(successArr).join("\n");

    showSnackbar(severity, message);
  };

  return (
    <SnackbarContext.Provider
      value={{
        handleHttpError,
        showSnackbar,
        handleError,
        handleCancelOrderPositions,
      }}
    >
      {children}
      <Snackbar
        autoHideDuration={autoHideDuration}
        onClose={() => setOpen(false)}
        open={open}
        message={message}
        alertprops={{ severity: severity }}
      />
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
