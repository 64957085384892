import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { MessageResponse } from "../../../../api";
import { aepPalette } from "../../../../styles/aepPalette";
import { ddmmyyyyFormat } from "../../../service/CustomDateService";
import {
  overviewInnerStackStyles,
  overviewStackStyles,
  overviewStatusBoxStyles,
} from "../postBoxStyles";

interface MessageOverviewProps {
  index: number;
  message: MessageResponse;
  onClick: () => void;
  selectedMessage: number;
  unread?: boolean;
}

const MessageOverview = (props: MessageOverviewProps) => {
  const { message, onClick, selectedMessage, index, unread } = props;
  const [messageUnread, setMessageUnread] = useState(unread);

  const handleClick = () => {
    setMessageUnread(false);

    onClick();
  };

  const computeMessageStateColor = (state: number) => {
    switch (state) {
      case 0:
        return aepPalette.error.main;
      case 1:
        return aepPalette.warning.main;
      case 2:
        return aepPalette.success.main;
      default:
        return aepPalette.common.white;
    }
  };

  const computeMessageStateText = (state: number) => {
    switch (state) {
      case 0:
        return "Offen";
      case 1:
        return "In Bearbeitung";
      case 2:
        return "Abgeschlossen";
      default:
        return "Unbekannt";
    }
  };

  return (
    <Stack
      onClick={handleClick}
      direction="column"
      sx={{
        ...overviewStackStyles,
        backgroundColor:
          selectedMessage === index ? aepPalette.base.lighter : "",
        borderBottom:
          selectedMessage === index
            ? `1px solid ${aepPalette.base.lighter}`
            : "",
      }}
    >
      <Stack direction="row" sx={overviewInnerStackStyles}>
        <Typography variant="h3" sx={{ fontWeight: messageUnread ? 600 : 300 }}>
          {message.userid}
        </Typography>
        {message.origin !== "AEP" && (
          <Tooltip arrow title={computeMessageStateText(message.state)}>
            <Box
              sx={{
                ...overviewStatusBoxStyles,
                backgroundColor: computeMessageStateColor(message.state),
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <Box sx={{ pb: 1.5 }}>
        <Typography
          variant="body2"
          sx={{
            display: "inline",
            fontWeight: messageUnread ? 600 : 300,
            color: "black",
          }}
        >
          {message.subject}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          pb: 1,
        }}
      >
        {ddmmyyyyFormat(new Date(message.creationDate))}
      </Typography>
    </Stack>
  );
};

export default MessageOverview;
