import { Box, Fade, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import OfferCard from "./OfferCard";
import StrapiApi from "../../../strapi/StrapiApi";
import { useKeycloak } from "@react-keycloak/web";
import SnackbarContext from "../../contexts/SnackbarContext";
import StrapiData from "../../../strapi/models/StrapiData";
import Offer from "../../../strapi/models/Offer";
import { rootBoxStyles } from "./offersStyles";
import { aepPalette } from "../../../styles/aepPalette";
import { fadeTimeout } from "../../App";
import Carousel from "../../components/carousel/Carousel";
import OfferCardContent from "./OfferCardContent";
import Banner from "../../../strapi/models/Banner";
import BannerCarousel from "./BannerCarousel";
import CustomerContext from "../../contexts/CustomerContext";
import CeneteredTextMessage from "../../components/other/CenteredTextMessage";

type StrapiOfferData = StrapiData<Offer>;

const Offers = () => {
  const { keycloak } = useKeycloak();
  const { customerNumber } = useContext(CustomerContext);
  const strapiApi = new StrapiApi(keycloak.token || "", customerNumber);
  const { handleHttpError } = useContext(SnackbarContext);
  const [highlightOffers, setHighlightOffers] = useState<
    Array<StrapiOfferData>
  >([]);
  const [priceHitOffers, setPriceHitOffers] = useState<Array<StrapiOfferData>>(
    []
  );
  const [offers, setOffers] = useState<Array<StrapiOfferData>>([]);
  const [banners, setBanners] = useState<Array<Banner>>([]);
  const [loading, setLoading] = useState(true);

  const filterHighlightOffers = (offers: Array<StrapiOfferData>) => {
    let filteredOffers: StrapiOfferData[] = [];

    const dailyOffer = offers.filter(
      (offer) => offer.attributes.kategorie === "Tagesknueller"
    ).slice(0, 1);
    const topOffers = offers.filter(
      (offer) => offer.attributes.kategorie === "Topartikel"
    );

    if (dailyOffer.length > 0) {
      filteredOffers = dailyOffer;
    } else {
      filteredOffers = topOffers; //At this point we don't care how many there are (even 0), as deciding is done in render logic
    }

    return filteredOffers;
  };

  const filterPriceHitOffers = (offers: Array<StrapiOfferData>) =>
    offers.filter((offer) => offer.attributes.kategorie === "Preishit");

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed && customerNumber) {
      setLoading(true);
      strapiApi
        .getCategorizedOffers()
        .then((res) => {
          const resHighLightOffers = filterHighlightOffers(res.data.data);
          const resPriceHitOffers = filterPriceHitOffers(res.data.data);
          setHighlightOffers(resHighLightOffers);
          setPriceHitOffers(resPriceHitOffers);
        })
        .then(() =>
          strapiApi.getUncategorizedOffers().then((res) => {
            setOffers(res.data.data);
          })
        )
        .then(() => {
          strapiApi.getBanners().then((res) => {
            setBanners(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              res.data.data.filter(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (banner) => banner.attributes?.bild?.data?.attributes?.url
              )
            );
          });
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  return (
    <Box position="relative">
      <BannerCarousel
        banners={banners}
        loading={loading}
        baseUrl={strapiApi.axiosRequestConfig.baseURL || ""}
        sx={{ mb: 2.5 }}
      />
      {highlightOffers.length > 1 ? (
        <OfferCard loading={loading} highlight sx={{ pb: 0, mb: 3 }}>
          <Carousel maxSteps={highlightOffers.length} sx={{ height: "100%" }}>
            {highlightOffers.map((offer) => (
              <OfferCardContent
                key={offer.id}
                highlight
                offerAttributes={offer.attributes}
                baseUrl={strapiApi.axiosRequestConfig.baseURL || ""}
              />
            ))}
          </Carousel>
        </OfferCard>
      ) : highlightOffers.length > 0 ? (
        highlightOffers.map(
          (offer, index) =>
            index < 2 && (
              <OfferCard
                loading={loading}
                highlight
                key={offer.id}
                sx={{ mb: 3 }}
              >
                <OfferCardContent
                  highlight
                  offerAttributes={offer.attributes}
                  baseUrl={strapiApi.axiosRequestConfig.baseURL || ""}
                />
              </OfferCard>
            )
        )
      ) : (
        <></>
      )}
      {priceHitOffers.length > 0 ? (
        <OfferCard loading={loading} highlight sx={{ pb: 0, mb: 3 }}>
          <Carousel maxSteps={priceHitOffers.length} sx={{ height: "100%" }}>
            {priceHitOffers.map((offer) => (
              <OfferCardContent
                key={offer.id}
                highlight
                offerAttributes={offer.attributes}
                baseUrl={strapiApi.axiosRequestConfig.baseURL || ""}
              />
            ))}
          </Carousel>
        </OfferCard>
      ) : (
        <></>
      )}
      <Box sx={rootBoxStyles}>
        {offers.map((offer) => (
          <OfferCard loading={loading} key={offer.id} sx={{ mb: 3 }}>
            <OfferCardContent
              halfWidth
              offerAttributes={offer.attributes}
              baseUrl={strapiApi.axiosRequestConfig.baseURL || ""}
            />
          </OfferCard>
        ))}
      </Box>
      {highlightOffers.length == 0 &&
      priceHitOffers.length == 0 &&
      offers.length == 0 ? (
        <CeneteredTextMessage message="Für die Vorschau sind keine Daten vorhanden" />
      ) : (
        <></>
      )}
      <Fade in={!loading && offers.length > 0} timeout={fadeTimeout}>
        <Typography
          sx={{
            mt: 8,
            color: aepPalette.base.main,
            textTransform: "uppercase",
            fontSize: "1.1rem",
            fontWeight: 500,
          }}
        >
          *Rabatt auf den AEP gem. AMPreisV. Solange der Vorrat reicht.
        </Typography>
      </Fade>
      <Box height={64} />
    </Box>
  );
};

export default Offers;
