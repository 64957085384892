import { Checkbox, TableCell } from "@mui/material";
import { DeliveryDto } from "../../../../api";
import { TextDivider } from "../../../components/other/TextDivider";
import { useNavigate } from "react-router-dom";
import { CustomTableProps } from "../../../components/table/TableContainer";
import DocumentLoader from "../../../components/documentloader/DocumentLoader";
import CustomerContext from "../../../contexts/CustomerContext";
import { useContext } from "react";
import AnimatedTableRow from "../../../components/table/AnimatedTableRow";
import IconButtonDetailView from "../../../components/table/IconButtonDetailView";

interface DeliverySearchTableRowProps extends CustomTableProps {
  row: DeliveryDto;
}

const DeliverySearchTableRow = (props: DeliverySearchTableRowProps) => {
  const { isSelected, row, handleSelectRow, transitionDelay } = props;
  const { customerNumber } = useContext(CustomerContext);
  const navigate = useNavigate();

  const labelId = `enhanced-table-checkbox-${row.deliveryNoteId}`;

  return (
    <AnimatedTableRow
      transitionDelay={transitionDelay}
      key={row.deliveryNoteId}
    >
      <TableCell align="center">
        <Checkbox
          color="secondary"
          checked={isSelected(row.deliveryNoteId)}
          onClick={() => handleSelectRow(row.deliveryNoteId)}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell>{row.deliveryNoteId}</TableCell>
      <TableCell>{row.deliveryDate}</TableCell>
      <TableCell>{row.deliveryState}</TableCell>
      <TableCell>
        <TextDivider />
      </TableCell>
      <TableCell sx={{ position: "relative" }}>
        {(row.deliveryStateNumber == 45 || row.deliveryStateNumber == 60) && (
          <DocumentLoader
            customerNumber={customerNumber}
            documentDate={row.deliveryDate}
            documentId={row.deliveryNoteId}
            documentType="LS"
          />
        )}
      </TableCell>
      <TableCell>
        <IconButtonDetailView
          onClick={() => navigate("details", { state: [row.deliveryNoteId] })}
        />
      </TableCell>
    </AnimatedTableRow>
  );
};

export default DeliverySearchTableRow;
