import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";
import { Box } from "@mui/material";

const ZackUndDaIcon = () => (
  <Box>
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2208 9.05129H21.1743C20.8842 9.05129 20.6487 8.81581 20.6487 8.52574V2.47925C20.6487 1.66256 19.9862 1 19.1695 1H10.5305C9.71385 1 9.05129 1.66256 9.05129 2.47925V8.52574C9.05129 8.81581 8.81581 9.05129 8.52574 9.05129H2.47925C1.66256 9.05129 1 9.71385 1 10.5305V19.1695C1 19.9862 1.66256 20.6487 2.47925 20.6487H8.52574C8.81581 20.6487 9.05129 20.8842 9.05129 21.1743V27.2208C9.05129 28.0374 9.71385 28.7 10.5305 28.7H19.1695C19.9862 28.7 20.6487 28.0374 20.6487 27.2208V21.1743C20.6487 20.8842 20.8842 20.6487 21.1743 20.6487H27.2208C28.0374 20.6487 28.7 19.9862 28.7 19.1695V10.5305C28.7 9.71385 28.0374 9.05129 27.2208 9.05129"
        stroke="#333333"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.82812 18.675V17.3253L9.65496 13.0599L7.05611 13.6529L7.30872 11.0283H13.8829V12.4037L10.1859 16.8704L14.1355 16.1136L13.8829 18.675H6.82812Z"
        stroke="#333333"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2563 17.4002V12.3149C18.2563 12.3149 20.3532 12.4241 20.3532 14.8517C20.3532 17.2793 18.2563 17.4002 18.2563 17.4002ZM22.8396 12.5675C22.1332 11.541 20.8413 11.0283 18.966 11.0283L16.0621 11.0915L16 18.675H18.4736C21.7543 18.675 23.3952 17.2867 23.3952 14.5113C23.3952 13.7631 23.21 13.1145 22.8396 12.5675Z"
        stroke="#333333"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

export default ZackUndDaIcon;
