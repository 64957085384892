import PickersDay, { PickersDayProps } from "@mui/lab/PickersDay";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import CustomPickersDay from "../components/datepicker/CustomPickersDay";
import { DatePickerDate } from "../components/datepicker/DateRangePicker";
import { isValid, parse } from "date-fns";
import { de } from "date-fns/locale";

export const ddmmyyyyFormat = (date: Date | null): string => {
  if (date === null) return "";

  let day: string | number = date.getDate();
  let month: string | number = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = `0${day}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}.${month}.${year}`;
};

export const deDateStringFormat = (date: Date | null): string => {
  if (date === null) return "";

  const day: number = date.getDate();
  const month: number = date.getMonth();
  const year = date.getFullYear();
  const monthNames = [
    "JAN",
    "FEB",
    "MÄRZ",
    "APR",
    "MAI",
    "JUNI",
    "JULI",
    "AUG",
    "SEPT",
    "OKT",
    "NOV",
    "DEZ",
  ];

  return `${day}.${monthNames[month]} ${year}`;
};

export const renderWeekPickerDay = (
  date: Date,
  selectedDates: Array<Date | null>,
  pickersDayProps: PickersDayProps<Date>,
  dateRange: DatePickerDate[]
) => {
  if (!dateRange[0] || !dateRange[1]) {
    return <PickersDay {...pickersDayProps} />;
  }

  const start = dateRange[0];
  const end = dateRange[1];

  const dayIsBetween = isWithinInterval(date, { start, end });
  const isFirstDay = isSameDay(date, start);
  const isLastDay = isSameDay(date, end);

  return (
    <CustomPickersDay
      {...pickersDayProps}
      disableMargin
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
};

export const isValidDateString = (
  dateString: string,
  minDate?: Date,
  maxDate?: Date
): boolean => {
  const dateObj = parse(dateString, "P", new Date(), { locale: de });
  return (
    /^\d\d?\.\d\d?\.\d\d\d\d$/.test(dateString) &&
    isValid(dateObj) &&
    (!minDate || minDate.getTime() < dateObj.getTime()) &&
    (!maxDate || maxDate.getTime() > dateObj.getTime())
  );
};

export const reformatDateString = (dateString: string): string => {
  return ddmmyyyyFormat(stringToDate(dateString));
};

export const stringToYMD = (dateString: string): string => {
  const parts = dateString.split(".");
  const day = parts[0].length == 1 ? `0${parts[0]}` : parts[0];
  const month = parts[1].length == 1 ? `0${parts[1]}` : parts[1];
  return `${parts[2]}-${month}-${day}`;
};

export const stringToDate = (dateString: string): Date => {
  const parts = dateString.split(".");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  return new Date(year, month - 1, day);
};

export const extractDatesFromPeriod = (
  period: Date | DatePickerDate[] | undefined
) => {
  let fromDate = undefined;
  let toDate = undefined;
  if (Array.isArray(period)) {
    if (period[0] !== null) {
      fromDate = ddmmyyyyFormat(period[0]);
    }
    if (period[1] !== null) {
      toDate = ddmmyyyyFormat(period[1]);
    }
  }
  return { fromDate, toDate };
};

export const utcToGermanDateString = (
  utcString: string | undefined,
  excludeTimestamp?: boolean
): string => {
  const date = utcString
    ? new Date(utcString)
    : new Date("1970-01-01T00:00:00");

  let returnDate = date.toLocaleString("de-DE");

  if (excludeTimestamp) {
    returnDate = returnDate.split(",")[0];
  }

  return returnDate;
};

export const isWithinLastSixMonths = (dateToCompare: Date): boolean => {
  const date = new Date();
  date.setMonth(date.getMonth() - 6);
  return dateToCompare > date;
};
