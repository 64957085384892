import { TableRow, TableCell, Typography } from "@mui/material";
import { CancellationPositionDto } from "../../../../api";

interface CancellationPositionsTableRowProps {
  positionData: CancellationPositionDto;
  loading?: boolean;
}

const CancellationPositionsTableRow = (
  props: CancellationPositionsTableRowProps
) => {
  const { positionData } = props;

  return (
    <TableRow
      sx={{
        "& > td": {
          pl: 4,
        },
      }}
    >
      <TableCell>
        <Typography variant="body2">{positionData.pzn}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.quantity}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.articleName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.state}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default CancellationPositionsTableRow;
