import * as React from "react";
import { SxProps, useTheme, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { useEffect, useState } from "react";

interface CarouselProps {
  children: JSX.Element | JSX.Element[];
  maxSteps: number;
  sx?: SxProps<Theme>;
}

const Carousel = (props: CarouselProps) => {
  const { children, maxSteps, sx } = props;
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [autoScrollTimer, setAutoScrollTimer] = useState<NodeJS.Timeout>();

  const handleNext = (clear?: boolean) => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1;
    });
    if (autoScrollTimer && clear) {
      clearInterval(autoScrollTimer);
    }
  };

  const handleBack = (clear?: boolean) => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1
    );
    if (autoScrollTimer && clear) {
      clearInterval(autoScrollTimer);
    }
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    const interval = setInterval(() => handleNext(), 5000);
    setAutoScrollTimer(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        height: "100%",
        ...sx,
      }}
    >
      {maxSteps > 1 ? (
        <Box
          sx={{
            height: "100%",
            "& > div:first-of-type": {
              height: "91.5%",
              position: "relative",
              overflow: "hidden",
            },
            "& > div > div > div": {
              overflow: "hidden !important",
            },
          }}
        >
          <SwipeableViews
            id="SWIPEABLE VIEWS"
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {children}
          </SwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <KeyboardArrowRight
                onClick={() => handleNext(true)}
                sx={{ cursor: "pointer", fontSize: "2.3rem" }}
              />
            }
            backButton={
              <KeyboardArrowLeft
                onClick={() => handleBack(true)}
                sx={{ cursor: "pointer", fontSize: "2.3rem" }}
              />
            }
          />
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default Carousel;
