import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import BarChart, { BarChartData } from "./elements/BarChart";
import { RefObject, useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import IconButton from "../button/IconButton";
import {
  headerStyles,
  iconStyles,
  modalBoxStyles,
  rootBoxStyles,
} from "./chartStyles";
import SwarmPlot, { SwarmPlotData } from "./elements/SwarmPlot";
import CenteredTextMessage from "../other/CenteredTextMessage";
import OneLineTypography from "../other/OneLineTypography";

export interface ChartData {
  sortPosition?: string;
  x: string;
}

export const sortBySortPosition = (a: ChartData, b: ChartData) => {
  if (a.sortPosition && b.sortPosition) {
    return a.sortPosition > b.sortPosition ? 1 : -1;
  } else {
    return a.x > b.x ? 1 : -1;
  }
};

interface ChartProps {
  containerRef?: RefObject<HTMLElement>;
  data: BarChartData | SwarmPlotData;
  fullData: BarChartData | SwarmPlotData;
  inCarousel?: boolean;
  loading: boolean;
  type: "bar" | "swarm";
  upliftMiniState?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Chart = (props: ChartProps) => {
  const {
    containerRef,
    data,
    fullData,
    inCarousel,
    loading,
    type,
    upliftMiniState,
  } = props;
  const defaultHeight = containerRef?.current?.clientHeight || 350;
  const { width } = useWindowDimensions();
  const [mini, setMini] = useState<boolean>(false);
  // for full screen view
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(defaultHeight);
  const containerWidth = containerRef?.current?.clientWidth as number;
  const miniThreshold = 800;
  const hasData = fullData?.data?.length > 0;

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  const renderChart = (full?: boolean) =>
    type === "bar" ? (
      <BarChart
        mini={full ? false : mini}
        chartData={full ? (fullData as BarChartData) : (data as BarChartData)}
      />
    ) : (
      <SwarmPlot
        mini={full ? false : mini}
        chartData={full ? (fullData as SwarmPlotData) : (data as SwarmPlotData)}
      />
    );

  useEffect(() => {
    if (containerWidth > miniThreshold) {
      setMini(false);
    } else {
      setMini(true);
    }
  }, [width]);

  // effect to prevent background component resizing on modal open
  useEffect(() => {
    if (!modalOpen) {
      setHeight(defaultHeight);
    }
  }, [defaultHeight]);

  useEffect(() => {
    if (upliftMiniState) upliftMiniState(mini);
  }, [mini]);

  return loading ? (
    <Box
      sx={{
        opacity: loading ? 1 : 0,
        mt: 4,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        color="secondary"
        style={{
          textAlign: "center",
          width: "2.5rem",
          height: "2.5rem",
          marginTop: "45%",
        }}
      />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          height: inCarousel
            ? `${type === "swarm" ? height - 114 : height - 120}px`
            : `${type === "swarm" ? height - 79 : height - 62}px`,
          cursor: hasData ? "zoom-in" : "auto",
          "& > div > div > svg  > g > g > rect": {
            // important to reach into inaccessible Nivo styles
            cursor: hasData ? "zoom-in !important" : "auto",
          },
          "& > h3": {
            textAlign: "center",
          },
          ...rootBoxStyles,
        }}
        onClick={handleClick}
      >
        {data?.headline && (
          <OneLineTypography
            tooltipPlacement="top"
            text={data.headline}
            sx={{ position: "absolute", top: 28, textAlign: "center" }}
          />
        )}
        {hasData ? (
          renderChart(!mini)
        ) : (
          <CenteredTextMessage message="Für die Vorschau sind keine Daten vorhanden" />
        )}
      </Box>
      {hasData && (
        <Modal
          sx={{ position: "fixed" }}
          open={modalOpen}
          onClose={handleClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalBoxStyles}>
            <IconButton
              onClick={handleClick}
              icon={<CloseFullscreenIcon />}
              sx={iconStyles}
            />
            {fullData?.headline && (
              <Typography variant="h2" sx={headerStyles}>
                {fullData?.headline}
              </Typography>
            )}
            {renderChart(true)}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Chart;
