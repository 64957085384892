import { useContext } from "react";
import { TabableCard, CardTabContent } from "../../components/card/TabableCard";
import ReceiptsSearchContainer from "./mainTab/ReceiptsSearchContainer";
import FurtherReceiptSearchContainer from "./statisticTab/FurtherReceiptSearchContainer";
import UserContext from "../../contexts/UserContext";
import { UserDtoPermissionsEnum } from "../../../api";

const BelegePermissions = [
  UserDtoPermissionsEnum.Lieferscheine,
  UserDtoPermissionsEnum.Monatsberichte,
  UserDtoPermissionsEnum.Nachbelastungen,
  UserDtoPermissionsEnum.Gutschriften,
  UserDtoPermissionsEnum.Sammelrechnungen,
];

const Receipts = () => {
  const { checkUserRole } = useContext(UserContext);

  return (
    <TabableCard
      sx={{
        gridRow: "1 / 2",
        gridColumn: "1 / 3",
      }}
    >
      {checkUserRole(BelegePermissions) ? (
        <CardTabContent id="ReceiptsTab" name="Belege" state={1}>
          <ReceiptsSearchContainer />
        </CardTabContent>
      ) : (
        <></>
      )}
      {checkUserRole([UserDtoPermissionsEnum.Statistiken]) ? (
        <CardTabContent id="ReceiptsStatsTab" name="Statistik" state={2}>
          <FurtherReceiptSearchContainer />
        </CardTabContent>
      ) : (
        <></>
      )}
    </TabableCard>
  );
};

export default Receipts;
