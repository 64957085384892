import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import { DispensationPositionDto } from "../../../../api";
import DispensationPositionsTableRow from "./DispensationPositionsTableRow";
import { CustomTableProps } from "../../../components/table/TableContainer";
import PositionsRowCommonHeader from "./PositionsRowCommonHeader";

interface DispensationPositionsTableProps extends CustomTableProps {
  positions?: DispensationPositionDto[];
}

export enum OrderPositionStatus {
  OPEN = "Auftrag angenommen",
  IN_PRODUCTION = "In produktion",
}

const DispensationPositionsTable = (props: DispensationPositionsTableProps) => {
  const {
    positions,
    isSelected,
    allSelected,
    handleSelectRow,
    handleSelectAll,
  } = props;

  const positionIds = positions?.map((position) => position.positionId);

  const handleSelectAllPositionsInDispensation = () => {
    if (handleSelectAll && positionIds) handleSelectAll(positionIds);
  };

  const handleCheckedStatus = () => {
    if (allSelected && positionIds) {
      return allSelected(positionIds);
    } else {
      return false;
    }
  };

  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <Checkbox
              checked={handleCheckedStatus()}
              onClick={handleSelectAllPositionsInDispensation}
            />
          </TableCell>
          <PositionsRowCommonHeader />
        </TableRow>
      </TableHead>
      <TableBody>
        {positions?.map((positionData, index) => {
          return (
            <DispensationPositionsTableRow
              key={`position-${index}`}
              positionData={positionData}
              isSelected={isSelected(positionData.positionId)}
              handleSelectRow={handleSelectRow}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default DispensationPositionsTable;
