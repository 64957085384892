import { TableCell, Typography } from "@mui/material";

const PositionsRowCommonHeader = () => {
  return (
    <>
      <TableCell sx={{ width: "15%" }}>
        <Typography variant="bodyBold">PZN</Typography>
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Typography variant="bodyBold">Menge</Typography>
      </TableCell>
      <TableCell sx={{ width: "45%" }}>
        <Typography variant="bodyBold">Artikelbezeichnung</Typography>
      </TableCell>
      <TableCell sx={{ width: "20%" }}>
        <Typography variant="bodyBold">Status</Typography>
      </TableCell>
    </>
  );
};

export default PositionsRowCommonHeader;
