import { useContext, useEffect, useState } from "react";
import { Box, Collapse, Typography } from "@mui/material";
import Toggle from "../toggle/Toggle";
import NavItem from "../navitem/NavItem";
import SearchIcon from "../icons/SearchIcon";
import ZackUndDaIcon from "../icons/ZackUndDaIcon";
import InvoiceIcon from "../icons/InvoiceIcon";
import DeliveryIcon from "../icons/DeliveryIcon";
import SpecialOrdersIcon from "../icons/SpecialOrdersIcon";
import ArticleIcon from "../icons/ArticleIcon";
import DiscoverIcon from "../icons/DiscoverIcon";
import NewsIcon from "../icons/NewsIcon";
import CollaborationIcon from "../icons/CollaborationIcon";
import {
  rootBoxStyles,
  labelStyles,
  toggleStyles,
  employeeStyles,
} from "./navbarStyles";
import HomeIcon from "../icons/HomeIcon";
import { theme } from "../../../styles/AepThemeProvider";
import UserContext from "../../contexts/UserContext";
import { UserDtoPermissionsEnum } from "../../../api";
import { useTablet } from "../../hooks/useTablet";
import DownloadIcon from "../icons/DownloadIcon";
import SpecialOrdersContext from "../../contexts/SpecialOrdersContext";

interface NavbarProps {
  timeout: number;
}

const BelegePermissions = [
  UserDtoPermissionsEnum.Lieferscheine,
  UserDtoPermissionsEnum.Monatsberichte,
  UserDtoPermissionsEnum.Nachbelastungen,
  UserDtoPermissionsEnum.Gutschriften,
  UserDtoPermissionsEnum.Sammelrechnungen,
];

const Navbar = (props: NavbarProps) => {
  const { timeout } = props;
  const [submenuExpanded, setSubmenuExpanded] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(!useTablet());
  const [userExpansionSetting, setUserExpansionSetting] = useState(false);

  const { checkUserRole } = useContext(UserContext);
  const { specialOrderResponses } = useContext(SpecialOrdersContext);

  useEffect(() => {
    sidebarExpanded
      ? setSubmenuExpanded(userExpansionSetting)
      : setSubmenuExpanded(true);
  }, [sidebarExpanded]);

  return (
    <Box
      sx={{
        ...rootBoxStyles,
        transition: `width ${timeout / 800}s`,
        width: sidebarExpanded ? "233px" : "86px",
        minHeight: "620px",
      }}
    >
      <Toggle
        active={sidebarExpanded}
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        iconType="arrowHorizontal"
        sx={{
          transition: `margin-left ${timeout / 1000}s`,
          mt: 0.5,
          ml: sidebarExpanded ? "195px" : "55px",
          [theme.breakpoints.down("md")]: { mt: 2 },
        }}
      />
      <NavItem
        minimized={!sidebarExpanded}
        route="/"
        text="Dashboard"
        icon={<HomeIcon />}
      />
      <Collapse orientation="vertical" in={sidebarExpanded} timeout={timeout}>
        <Typography sx={{ ...labelStyles }}>Ihre Apotheke</Typography>
      </Collapse>
      {checkUserRole([UserDtoPermissionsEnum.Bestellungen]) && (
        <>
          <Box sx={{ display: "flex" }}>
            <NavItem
              minimized={!sidebarExpanded}
              route="/bestellungen"
              text="Bestellungen"
              icon={<InvoiceIcon />}
              sx={{ maxWidth: sidebarExpanded ? "209px" : "86px" }}
            />
            <Collapse in={sidebarExpanded} orientation="horizontal">
              <Toggle
                sx={{ ...toggleStyles, pb: "6px" }}
                active={submenuExpanded}
                iconType="arrowVertical"
                onClick={() => {
                  setUserExpansionSetting(!userExpansionSetting);
                  setSubmenuExpanded(!submenuExpanded);
                }}
              />
            </Collapse>
          </Box>
          <Collapse
            orientation="vertical"
            in={submenuExpanded}
            timeout={timeout}
          >
            <NavItem
              minimized={!sidebarExpanded}
              route="/bestellungen/lieferungen"
              text="Lieferungen"
              icon={<DeliveryIcon />}
            />
            {specialOrderResponses.length > 0 && (
              <NavItem
                minimized={!sidebarExpanded}
                route="/bestellungen/sonderbestellungen"
                text="Sonderbestellungen"
                icon={<SpecialOrdersIcon />}
              />
            )}

            <NavItem
              minimized={!sidebarExpanded}
              route="/bestellungen/artikelneuaufnahme"
              text="Artikelneuaufnahme"
              icon={<ArticleIcon />}
            />
          </Collapse>
        </>
      )}
      {checkUserRole([
        ...BelegePermissions,
        UserDtoPermissionsEnum.Statistiken,
      ]) && (
        <NavItem
          minimized={!sidebarExpanded}
          route="/belege"
          text="Weitere Belege"
          icon={<SearchIcon />}
        />
      )}
      {checkUserRole([UserDtoPermissionsEnum.AepImBlick]) && (
        <>
          <Collapse
            orientation="vertical"
            in={sidebarExpanded}
            timeout={timeout}
          >
            <Typography sx={{ ...labelStyles }}>AEP Im Blick</Typography>
          </Collapse>
          <NavItem
            minimized={!sidebarExpanded}
            route="/angebote"
            text="Angebote"
            icon={<DiscoverIcon />}
          />
          <NavItem
            minimized={!sidebarExpanded}
            route="/news"
            text="News"
            icon={<NewsIcon />}
          />
          <NavItem
            minimized={!sidebarExpanded}
            route="/downloads"
            text="Downloads"
            icon={<DownloadIcon />}
          />
        </>
      )}
      <Collapse orientation="vertical" in={sidebarExpanded} timeout={timeout}>
        <Typography
          sx={{ ...labelStyles, whiteSpace: "nowrap", overflow: "hidden" }}
        >
          AEP Marketing Services
        </Typography>
      </Collapse>
      <NavItem
        route="https://www.aep.de/vorteile/aep-marketing-services/"
        minimized={!sidebarExpanded}
        text="ZACK + DA!"
        icon={<ZackUndDaIcon />}
      />
      {checkUserRole([UserDtoPermissionsEnum.Admin]) && (
        <NavItem
          minimized={!sidebarExpanded}
          route="/mitarbeiter"
          text="Mitarbeiter"
          icon={<CollaborationIcon />}
          sx={{
            ...employeeStyles,
            maxWidth: sidebarExpanded ? "209px" : "86px",
          }}
        />
      )}
    </Box>
  );
};

export default Navbar;
