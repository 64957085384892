import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReceiptDto, UserDtoPermissionsEnum } from "../../../../api";
import TableContainer, {
  tablePageDefaultLength,
} from "../../../components/table/TableContainer";
import CustomerContext from "../../../contexts/CustomerContext";
import SnackbarContext from "../../../contexts/SnackbarContext";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import {
  TableFilterMenuItem,
  TableFilterType,
} from "../../../components/table/tableFilter/models";
import ReceiptsSearchTable from "./ReceiptsSearchTable";
import UserContext from "../../../contexts/UserContext";

export enum ReceiptType {
  Gutschrift = "Gutschrift",
  Lieferschein = "Lieferschein",
  Monatsbericht = "Monatsbericht",
  Nachbelastung = "Nachbelastung",
  Sammelrechnung = "Sammelrechnung",
}

export const compressReceiptType = (type?: ReceiptType) => {
  switch (type) {
    case ReceiptType.Gutschrift:
      return "GS";
    case ReceiptType.Lieferschein:
      return "LS";
    case ReceiptType.Monatsbericht:
      return "MB";
    case ReceiptType.Nachbelastung:
      return "NB";
    case ReceiptType.Sammelrechnung:
      return "SR";
    default:
      return;
  }
};

const ReceiptsSearchContainer = () => {
  const { checkUserRole } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<TableFilterMenuItem[]>([]);
  const [selectableRows, setSelectableRows] = useState<boolean>(false);
  const [receipts, setReceipts] = useState<ReceiptDto[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [noDataMessage, setNoDataMessage] = useState<string>(
    "Bitte wählen Sie eine Belegart und Zeitraum für die Suche"
  );

  const { handleHttpError } = useContext(SnackbarContext);
  const { customerNumber } = useContext(CustomerContext);

  const api = new ApiWrapper().receiptsControllerApi();
  const navigate = useNavigate();

  interface ReceiptSearchFormikValues {
    receiptType?: ReceiptType;
    pzn?: string;
    fromDate?: string;
    toDate?: string;
  }

  const generateMenuItemsFromPermissions = (): TableFilterMenuItem[] => {
    const BelegePermissions = [
      {
        permission: UserDtoPermissionsEnum.Gutschriften,
        value: ReceiptType.Gutschrift,
      },
      {
        permission: UserDtoPermissionsEnum.Lieferscheine,
        value: ReceiptType.Lieferschein,
      },
      {
        permission: UserDtoPermissionsEnum.Monatsberichte,
        value: ReceiptType.Monatsbericht,
      },
      {
        permission: UserDtoPermissionsEnum.Nachbelastungen,
        value: ReceiptType.Nachbelastung,
      },
      {
        permission: UserDtoPermissionsEnum.Sammelrechnungen,
        value: ReceiptType.Sammelrechnung,
      },
    ];
    const menuItems: TableFilterMenuItem[] = [];

    BelegePermissions.forEach(
      (permissionObj: {
        permission: UserDtoPermissionsEnum;
        value: ReceiptType;
      }) => {
        if (checkUserRole([permissionObj.permission])) {
          menuItems.push({
            label: permissionObj.value,
            value: permissionObj.value,
          });
        }
      }
    );

    return menuItems;
  };

  useEffect(() => {
    setPageNumber(0);
    setReceipts([]);
    const menuItems = generateMenuItemsFromPermissions();
    setMenuItems(menuItems);
  }, [customerNumber]);

  const loadReceipts = (
    loadMore: boolean,
    searchParams?: ReceiptSearchFormikValues
  ): void => {
    const receiptType = compressReceiptType(searchParams?.receiptType);
    // this check guards from firing multiple calls
    if (receiptType && customerNumber && !loading) {
      setLoading(true);

      if (receiptType === "LS") {
        setSelectableRows(true);
      } else {
        setSelectableRows(false);
      }

      api
        .getAllReceipts(
          customerNumber,
          receiptType,
          loadMore ? pageNumber : 0,
          tablePageDefaultLength,
          searchParams?.fromDate || undefined,
          searchParams?.toDate || undefined,
          searchParams?.pzn || undefined
        )
        .then((res) => {
          const newReceipts = res.data;

          if (loadMore) {
            setReceipts((existingReceipts) => {
              return existingReceipts.concat(newReceipts);
            });
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          } else {
            setReceipts(newReceipts);
            setPageNumber(1);

            if (newReceipts.length === 0) {
              setNoDataMessage("Keine Belege verfügbar");
            } else {
              setNoDataMessage(
                "Bitte wählen Sie eine Belegart und Zeitraum für die Suche"
              );
            }
          }
        })
        .catch((e) => {
          handleHttpError(e);
          setPageNumber(0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const navigateToDetails = (stateValue: unknown[]) => {
    navigate("../bestellungen/lieferungen/details", {
      state: stateValue,
    });
  };

  const initialValues: ReceiptSearchFormikValues = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    receiptType: "",
    receiptNumber: "",
    fromDate: "",
    toDate: "",
  };
  const [lastSearchParameters, setLastSearchParameters] =
    useState<ReceiptSearchFormikValues>(initialValues);

  return (
    <TableContainer
      sx={{ maxWidth: "900px" }}
      buttonAction={navigateToDetails}
      buttonText="Auswahl anzeigen"
      pageable
      loadCallback={loadReceipts}
      loading={loading}
      noData={receipts.length === 0}
      noDataMessage={noDataMessage}
      noInitialApiCall
      tableFilterProps={{
        initialValues: initialValues,
        searchFields: [
          {
            type: TableFilterType.dropdown,
            name: "receiptType",
            label: "*Belegart",
            menuItems,
            validate: (value: string) => {
              const enumValues = Object.values(ReceiptType);
              return enumValues.includes(value as unknown as ReceiptType);
            },
            errorMessage: "Erforderlich",
          },
          {
            type: TableFilterType.textfield,
            name: "pzn",
            label: "PZN",
          },
          {
            type: TableFilterType.dateRange,
            name: "",
            label: "Zeitraum",
          },
        ],
      }}
      Table={({ handleSelectRow, isSelected }) => (
        <ReceiptsSearchTable
          receipts={receipts}
          loading={loading}
          handleSelectRow={handleSelectRow}
          isSelected={isSelected}
          selectableRows={selectableRows}
        />
      )}
      selectableRows={selectableRows}
    />
  );
};

export default ReceiptsSearchContainer;
