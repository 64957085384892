import { UserDtoPermissionsEnum } from "../../api";

type KeycloakUserRole =
  | "apoportal-pharmacy-admin"
  | "apoportal-aep-im-blick-user"
  | "apoportal-analysen-user"
  | "apoportal-belege-ls"
  | "apoportal-belege-mb"
  | "apoportal-belege-nb"
  | "apoportal-belege-gs"
  | "apoportal-belege-sr"
  | "apoportal-belege-stats"
  | "apoportal-bestellungen-user"
  | "apoportal-postfach-user"
  | "apoportal-stammdaten-user";

export const getJwtGroups = (token: string): Array<string> => {
  return getTokenBody(token).groups;
};

export const getPharmacies = (token: string): Array<string> => {
  return getJwtGroups(token)
    .filter((e) => e.indexOf("/apoportal-pharmacies/") !== -1)
    .map((e) => e.replace("/apoportal-pharmacies/", "").trim());
};

const fromKeycloakRoleString = (
  permissionName: KeycloakUserRole
): UserDtoPermissionsEnum | void => {
  switch (permissionName.toLowerCase()) {
    case "apoportal-pharmacy-admin":
      return UserDtoPermissionsEnum.Admin;
    case "apoportal-aep-im-blick-user":
      return UserDtoPermissionsEnum.AepImBlick;
    case "apoportal-analysen-user":
      return UserDtoPermissionsEnum.Analysen;
    case "apoportal-belege-ls":
      return UserDtoPermissionsEnum.Lieferscheine;
    case "apoportal-belege-mb":
      return UserDtoPermissionsEnum.Monatsberichte;
    case "apoportal-belege-nb":
      return UserDtoPermissionsEnum.Nachbelastungen;
    case "apoportal-belege-gs":
      return UserDtoPermissionsEnum.Gutschriften;
    case "apoportal-belege-sr":
      return UserDtoPermissionsEnum.Sammelrechnungen;
    case "apoportal-belege-stats":
      return UserDtoPermissionsEnum.Statistiken;
    case "apoportal-bestellungen-user":
      return UserDtoPermissionsEnum.Bestellungen;
    case "apoportal-postfach-user":
      return UserDtoPermissionsEnum.Postfach;
    case "apoportal-stammdaten-user":
      return UserDtoPermissionsEnum.Stammdaten;
    default:
      return;
  }
};

export const getJwtRoles = (token: string): UserDtoPermissionsEnum[] => {
  const rolesList = getTokenBody(token).realm_access.roles;

  return rolesList
    .map((role: KeycloakUserRole) => fromKeycloakRoleString(role))
    .filter((role: UserDtoPermissionsEnum) => role !== undefined);
};

export const getUserFullName = (token: string): string => {
  return getTokenBody(token).name;
};

export const getUserName = (token: string): string => {
  return getTokenBody(token).preferred_username;
};

export const getMainPharmacyId = (token: string): string => {
  return getTokenBody(token).defaultPharmacy.split("_")[1];
};

const getTokenBody = (token: string) => {
  const bodyText = atob(
    token.split(".")[1].replaceAll("_", "+").replaceAll("-", "/")
  );
  const bodyTextUTF8 = decodeURIComponent(
    bodyText
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .map((char) => {
        if (char == "%83") {
          return "%c3";
        }
        return char;
      })
      .join("")
  );
  return JSON.parse(bodyTextUTF8);
};
