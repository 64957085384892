import { Chip } from "@mui/material";
import React, { useState } from "react";
import TextField from "./TextField";

interface ChipsFieldProps {
  searchTerms: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
  currentValue: string;
  label: string;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
}

const ChipsField = (props: ChipsFieldProps): JSX.Element => {
  const { searchTerms, setValues, setCurrentValue, currentValue, label } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value)
  };

  const handleDelete = (event: any) => {
    const valueToRemove =
      event?.target?.parentElement?.innerText ||
      event?.target?.parentElement?.parentElement?.innerText ||
      "";

    setValues((prevState) => [
      ...prevState.filter((val) => val !== valueToRemove),
    ]);
  };

  const handleDeleteLastTerm = () => {
    setValues((prevState) => [
      ...prevState.filter((val, index) => index !== searchTerms.length - 1),
    ]);
  };

  const handleAdd = (newValue: string) => {
    if (newValue) {
      setValues((prevState) => [...prevState, newValue]);
      setCurrentValue("");
    }
  };

  return (
    <TextField
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleAdd(currentValue);
        }
        if (e.key === "Backspace" && currentValue === "") {
          e.preventDefault();
          handleDeleteLastTerm();
        }
      }}
      label={label}
      inputProps={{ style: { width: "auto" } }}
      InputProps={{
        sx: {
          position: "relative",
          flexFlow: "row wrap",
        },
        startAdornment:
          searchTerms.length > 0
            ? searchTerms?.map((term, index) => (
                <Chip
                  variant="term"
                  key={`chip-${term}-${index}`}
                  onDelete={handleDelete}
                  label={term}
                  sx={{
                    mx: 0.25,
                    mb: 0.25,
                    width: "fit-content",
                    height: "inherit",
                    minHeight: "20px",
                    py: 0,
                  }}
                />
              ))
            : null,
      }}
      onChange={handleChange}
      value={currentValue}
      sx={{ width: "100%", mb: 1 }}
    />
  );
};

export default ChipsField;
