import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const NewsIcon = (props: SvgIconProps) => (
  <IconWrapper viewBox="0 0 22 22" {...props}>
    <path d="M5.63623 8.21021H10.4344V12.7903H5.63623V8.21021Z" />
    <path d="M3.53082 0V18.7963H2.30864V17.4271H1.76548V19.3148H4.07403V0.518472H21.4567V20.4814H0.543161V2.20366L1.90119 2.20348V1.685L0 1.68519V21H22V0H3.53082Z" />
    <path d="M11.3395 10.2407H19.9625V10.7592H11.3395V10.2407Z" />
    <path d="M11.3395 8.25293H19.9625V8.7714H11.3395V8.25293Z" />
    <path d="M11.3395 12.25H19.9625V12.7685H11.3395V12.25Z" />
    <path d="M5.56787 14.2593H17.7901V14.7777H5.56787V14.2593Z" />
    <path d="M5.56787 16.2686H19.9629V16.787H5.56787V16.2686Z" />
    <path d="M5.56787 18.2778H19.9629V18.7963H5.56787V18.2778Z" />
    <path d="M19.4197 14.2593H19.9628V14.7777H19.4197V14.2593Z" />
    <path d="M18.3333 14.2593H18.8764V14.7777H18.3333V14.2593Z" />
    <path d="M2.44434 1.6853H2.98749V2.20377H2.44434V1.6853Z" />
    <path d="M9.06567 6.20513H11.4949V5.68666H9.60903V4.3155L11.3782 4.32038L11.379 3.80191L9.60906 3.79703V2.46745H11.4949V1.94897H9.0657L9.06567 6.20513Z" />
    <path d="M17.451 5.03658V6.22709H19.9799V3.83959H17.9943V2.48941H19.4368V3.16756H19.9799V1.9707H17.451V4.35802H19.4367V5.70858H17.9942V5.03654L17.451 5.03658Z" />
    <path d="M6.29298 2.46772L8.07859 6.17485L8.09432 6.16816H8.63011V1.96876H8.08695V4.95089L6.64118 1.94922H5.6814V6.16816H6.22456V2.46769L6.29298 2.46772Z" />
    <path d="M14.0342 1.93335V2.02197L13.2474 5.48859L12.4418 1.93659L11.9108 2.04655L12.8597 6.22914L12.8622 6.2286V6.23692H13.6447V6.18827L14.4479 2.64804L15.2575 6.21521V6.24053H16.0405V6.2116L16.9861 2.04655L16.455 1.9366L15.6485 5.48877L14.8558 1.99521V1.93336L14.0342 1.93335Z" />
  </IconWrapper>
);

export default NewsIcon;
