import { Box, CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { aepPalette } from "../../../styles/aepPalette";

interface TableLoadMoreRowProps {
  loading: boolean;
  loadCallback: () => void;
  hide: boolean;
}

const TableLoadMoreRow = (props: TableLoadMoreRowProps): JSX.Element => {
  const { loading, loadCallback, hide } = props;

  if (hide) {
    return <></>;
  }

  return (
    <Box
      sx={{
        height: "100px",
        width: "100%",
        textAlign: "center",
        borderTop: `1px solid ${aepPalette.secondary.light}`,
        py: 1,
      }}
    >
      {loading ? (
        <CircularProgress
          color="secondary"
          style={{ width: "18px", height: "18px" }}
        />
      ) : (
        <RefreshIcon
          color={"secondary"}
          fontSize="large"
          onClick={loadCallback}
          sx={{ cursor: "pointer" }}
        />
      )}
    </Box>
  );
};

export default TableLoadMoreRow;
