import {
  useEffect,
  useContext,
  useState,
} from "react";
import { Box, Fade } from "@mui/material";
import IconButton from "../../button/IconButton";
import { fadeTimeout } from "../../../App";
import CountdownIcon from "../../icons/CountdownIcon";
import CloseIcon from "@mui/icons-material/Close";
import CustomerContext from "../../../contexts/CustomerContext";
import { counterStyles } from "../appBarStyles";

interface CounterProps {
  sanitizedModalHtml: string;
}

const Counter = (props: CounterProps) => {
  const { sanitizedModalHtml } = props;

  const { customerNumber } = useContext(CustomerContext);
  const [showCounter, setShowCounter] = useState(false);

  const createMarkup = () => {
    return { __html: sanitizedModalHtml };
  }

  useEffect(() => {
    setShowCounter(false);
  }, [customerNumber]);

  return (
    <Box sx={counterStyles} component="div">
      <IconButton
        icon={<CountdownIcon />}
        onClick={() => {
          setShowCounter(!showCounter);
        }}
      />
      <Fade in={showCounter} timeout={fadeTimeout / 4}>
        <div style={{ width: "283px" }}>
          <CloseIcon
            onClick={() => setShowCounter(false)}
            sx={{ cursor: "pointer", position: "absolute", right: 10, top: 10 }}
          />
          <div dangerouslySetInnerHTML={createMarkup()}></div>
        </div>
      </Fade>
    </Box>
  );
};

export default Counter;
