import { TableRow, TableCell, Checkbox, Typography } from "@mui/material";
import { DispensationPositionDto } from "../../../../api";

interface DispensationPositionsTableRowProps {
  positionData: DispensationPositionDto;
  loading?: boolean;
  isSelected: boolean;
  handleSelectRow: (id: string) => void;
}

const DispensationPositionsTableRow = (
  props: DispensationPositionsTableRowProps
) => {
  const { positionData, handleSelectRow, isSelected } = props;

  return (
    <TableRow>
      <TableCell align="center">
        <Checkbox
          onClick={() => handleSelectRow(positionData.positionId)}
          checked={isSelected}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.pzn}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.quantity}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.articleName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{positionData.state}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default DispensationPositionsTableRow;
