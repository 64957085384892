import { SxProps, Theme } from "@mui/material";
import { aepPalette } from "../../../styles/aepPalette";

export const overviewCardStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.common.white,
  minWidth: "280px",
  mb: 4,
  mr: 3,
  overflow: "auto",
  position: "relative",
  width: "25%",
};

export const verticalDividerStyles: SxProps<Theme> = {
  width: "100%",
  borderBottom: `1px solid ${aepPalette.secondary.light}`,
};

export const overviewStackStyles: SxProps<Theme> = {
  cursor: "pointer",
  mx: 2,
  my: 1,
  pb: 1,
  px: 1,
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  "&:not(:last-child)": {
    borderBottom: `1px solid ${aepPalette.secondary.light}`,
  },
  "&:hover": {
    backgroundColor: aepPalette.base.lightest,
  },
};

export const overviewInnerStackStyles: SxProps<Theme> = {
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  py: 1,
};

export const overviewStatusBoxStyles: SxProps<Theme> = {
  height: "10px",
  width: "10px",
  borderRadius: "50%",
  zIndex: 1,
};

export const detailCardStyles: SxProps<Theme> = {
  mb: 4,
  width: "75%",
  position: "relative",
  height: "100%",
};

export const detailStackStyles: SxProps<Theme> = {
  height: "78px",
  width: "100%",
  borderBottom: `1px solid ${aepPalette.secondary.light}`,
  justifyContent: "space-between",
  py: 1.5,
  px: 4,
};

export const inlineText: SxProps<Theme> = { display: "inline" };

export const messageBodyStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.common.white,
  height: "100%",
  width: "100%",
};

export const messageBodyTextStyles: SxProps<Theme> = {
  py: 2,
  px: 4,
  lineHeight: "2rem",
  whiteSpace: "pre-wrap",
};
