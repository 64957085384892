import { Checkbox, TableCell } from "@mui/material";
import { TextDivider } from "../../../components/other/TextDivider";
import { useNavigate } from "react-router-dom";
import { CustomTableProps } from "../../../components/table/TableContainer";
import DocumentLoader, {
  DocumentLoaderDocumentType,
} from "../../../components/documentloader/DocumentLoader";
import CustomerContext from "../../../contexts/CustomerContext";
import { useContext } from "react";
import { ReceiptDto } from "../../../../api";
import { compressReceiptType, ReceiptType } from "./ReceiptsSearchContainer";
import AnimatedTableRow from "../../../components/table/AnimatedTableRow";
import IconButtonDetailView from "../../../components/table/IconButtonDetailView";

interface ReceiptsSearchTableRowProps extends CustomTableProps {
  row: ReceiptDto;
  transitionDelay: number;
  selectableRows: boolean;
}

const ReceiptsSearchTableRow = (props: ReceiptsSearchTableRowProps) => {
  const { isSelected, row, handleSelectRow, transitionDelay, selectableRows } =
    props;
  const { customerNumber } = useContext(CustomerContext);
  const navigate = useNavigate();

  const labelId = `enhanced-table-checkbox-${row.receiptId}`;

  let documentType = compressReceiptType(row.receiptName as ReceiptType);
  // Adjust document types for document loader
  if (documentType === "LS" || documentType === "GS") {
    documentType = "TB";
  }

  return (
    <AnimatedTableRow transitionDelay={transitionDelay} key={row.receiptId}>
      <TableCell align="center">
        {selectableRows && (
          <Checkbox
            color="secondary"
            checked={isSelected(row.receiptId)}
            onClick={() => handleSelectRow(row.receiptId)}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        )}
      </TableCell>
      <TableCell>{row.receiptName}</TableCell>
      <TableCell>{row.receiptId}</TableCell>
      <TableCell>{row.receiptDate}</TableCell>
      <TableCell>
        <TextDivider />
      </TableCell>
      <TableCell sx={{ position: "relative" }}>
        <DocumentLoader
          customerNumber={customerNumber}
          documentDate={row.receiptDate}
          documentId={row.receiptId}
          documentType={documentType as DocumentLoaderDocumentType}
        />
      </TableCell>
      <TableCell sx={{ position: "relative" }}>
        {row.receiptName === ReceiptType.Lieferschein ? (
          <IconButtonDetailView
            onClick={() =>
              navigate("../bestellungen/lieferungen/details", {
                state: [row.receiptId],
              })
            }
          />
        ) : (
          <></>
        )}
      </TableCell>
    </AnimatedTableRow>
  );
};

export default ReceiptsSearchTableRow;
