import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MessageResponse } from "../../../api";
import CustomerContext from "../../contexts/CustomerContext";
import SnackbarContext from "../../contexts/SnackbarContext";
import ApiWrapper from "../../wrappers/ApiWrapper";
import MessageDetailCard from "./elements/MessageDetailCard";
import MessagesOverviewCard from "./elements/MessagesOverviewCard";

const PostBox = () => {
  const api = new ApiWrapper().messageControllerApi();
  const { customerNumber } = useContext(CustomerContext);
  const { handleHttpError } = useContext(SnackbarContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<MessageResponse[]>();
  const [selectedMessage, setSelectedMessage] = useState<number>(-1);

  useEffect(() => {
    let isApiSubscribed = true;

    if (isApiSubscribed && customerNumber) {
      setLoading(true);

      api
        .getAllMessages(customerNumber)
        .then((res) => {
          setMessages(res.data);
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [customerNumber]);

  return (
    <Stack direction="row" sx={{ position: "relative" }}>
      <MessagesOverviewCard
        loading={loading}
        setSelectedMessage={setSelectedMessage}
        selectedMessage={selectedMessage}
        messages={messages}
      />
      {messages && messages[selectedMessage] && (
        <MessageDetailCard
          loading={loading}
          selectedMessage={messages && messages[selectedMessage]}
        />
      )}
    </Stack>
  );
};

export default PostBox;
