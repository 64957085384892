import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon";
import IconWrapper from "./IconWrapper";

const MailIcon = (props: SvgIconProps) => (
  <IconWrapper {...props} viewBox="0 16 23 15" width="23px">
    <path d="M22.9016 18.8928L11.5124 25.4004L11.2654 25.5415L11.0226 25.3967L0.11853 18.8886L0.6177 18.069L11.2789 24.4321L22.4213 18.0652L22.9016 18.8928Z" />
    <path d="M0.570102 18H23.3479V30.7798H8.71202L1.72903 30.78L1.69228 29.8219H22.3799V18.9583H1.05476V29.8219H1.69228L1.72903 30.78H0.0866699V18.0002L0.570102 18Z" />
  </IconWrapper>
);

export default MailIcon;
