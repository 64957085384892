import DashboardCard from "../../pages/dashboard/cards/DashboardCard";
import GraphsIcon from "../icons/GraphsIcon";
import { CardProps } from "@mui/material";
import { useContext, useRef, useState } from "react";
import PopulatedBarChart from "../chart/charts/PopulatedBarChart";
import PopulatedSwarmPlot from "../chart/charts/PopulatedSwarmPlot";
import Carousel from "../carousel/Carousel";
import UserContext from "../../contexts/UserContext";
import { UserDtoPermissionsEnum } from "../../../api";

export enum AnalysisData {
  PACKMON = "PACKMON",
  UMSMON = "UMSMON",
  BESTZEIT = "BESTZEIT",
}
interface AnalysesCardProps extends CardProps {
  // optional prop to limit data displayed
  dataSet?: AnalysisData[];
}

const AnalysesCard = (props: AnalysesCardProps): JSX.Element => {
  const { dataSet } = props;
  const cardRef = useRef<HTMLElement>(null);
  const { checkUserRole } = useContext(UserContext);

  const [loadingPACKMON, setLoadingPACKMON] = useState(false);
  const [loadingUMSMON, setLoadingUMSMON] = useState(false);
  const [loadingBESTZEIT, setLoadingBESTZEIT] = useState(false);

  let loadingCondition = loadingPACKMON && loadingUMSMON && loadingBESTZEIT;

  if (dataSet?.length === 1) {
    switch (dataSet[0]) {
      case AnalysisData.PACKMON:
        loadingCondition = loadingPACKMON;
        break;
      case AnalysisData.UMSMON:
        loadingCondition = loadingUMSMON;
        break;
      case AnalysisData.BESTZEIT:
        loadingCondition = loadingBESTZEIT;
        break;
      default:
        break;
    }
  }

  return checkUserRole([UserDtoPermissionsEnum.Analysen]) ? (
    <DashboardCard
      loading={loadingCondition}
      innerRef={cardRef}
      to="analysen"
      header="Analysen"
      icon={<GraphsIcon sx={{ width: "27px", height: "30px" }} />}
      sx={{ maxHeight: "75vh" }}
      {...props}
    >
      <Carousel maxSteps={dataSet?.length || 3}>
        {!dataSet || dataSet.includes(AnalysisData.PACKMON) ? (
          <PopulatedBarChart
            inCarousel
            dataSet={AnalysisData.PACKMON}
            liftLoadingState={setLoadingPACKMON}
            containerRef={cardRef}
          />
        ) : (
          <></>
        )}
        {!dataSet || dataSet.includes(AnalysisData.UMSMON) ? (
          <PopulatedBarChart
            inCarousel
            dataSet={AnalysisData.UMSMON}
            liftLoadingState={setLoadingUMSMON}
            containerRef={cardRef}
          />
        ) : (
          <></>
        )}
        {!dataSet || dataSet.includes(AnalysisData.BESTZEIT) ? (
          <PopulatedSwarmPlot
            inCarousel
            dataSet={AnalysisData.BESTZEIT}
            liftLoadingState={setLoadingBESTZEIT}
            containerRef={cardRef}
          />
        ) : (
          <></>
        )}
      </Carousel>
    </DashboardCard>
  ) : (
    <></>
  );
};

export default AnalysesCard;
