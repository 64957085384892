/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TableCell, Checkbox, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SpecialOrderResponse } from "../../../../api";
import AnimatedTableRow from "../../../components/table/AnimatedTableRow";
import { ddmmyyyyFormat } from "../../../service/CustomDateService";
import { toCurrency } from "../../../service/CustomNumberService";
import SpecialOrdersContext from "../../../contexts/SpecialOrdersContext";
import { tableCellInputStyles, tableCellStyles } from "../specialOrdersStyles";
import ErrorMessageOverlay from "./ErrorMessageOverlay";

interface SpecialOrdersTableRowProps {
  orderData: SpecialOrderResponse;
  transitionDelay?: number;
}

const SpecialOrdersTableRow = (props: SpecialOrdersTableRowProps) => {
  const { orderData, transitionDelay } = props;
  const orderId = orderData.id.toString();
  const { updateSpecialOrderRequests, containsSpecialOrderRequest } =
    useContext(SpecialOrdersContext);
  const [quantity, setQuantity] = useState<number>(orderData.amount);
  const [inputTouched, setInputTouched] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  const handleClick = () => {
    updateSpecialOrderRequests(
      {
        id: parseInt(orderId, 10),
        amount: quantity,
      },
      containsSpecialOrderRequest(orderData.id)
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = e.target.valueAsNumber;
    if (isNaN(newQuantity)) {
      newQuantity = 0;
    }
    setQuantity(newQuantity);
  };

  useEffect(() => {
    if (initialized) {
      updateSpecialOrderRequests(
        {
          id: parseInt(orderId, 10),
          amount: quantity,
        },
        false
      );
    }
  }, [quantity]);

  useEffect(() => {
    setQuantity(orderData.amount);
    setInitialized(true);
  }, [orderData]);

  return (
    <AnimatedTableRow transitionDelay={transitionDelay}>
      <TableCell align="center">
        <Checkbox
          onClick={handleClick}
          checked={containsSpecialOrderRequest(orderData.id)}
        />
      </TableCell>
      <TableCell sx={tableCellStyles}>
        <Typography variant="body2">{orderData.pzn}</Typography>
      </TableCell>
      <TableCell sx={tableCellStyles}>
        <Typography variant="body2">{orderData.articleName}</Typography>
      </TableCell>
      <TableCell sx={tableCellStyles}>
        <Typography variant="body2">{orderData.packageSize}</Typography>
      </TableCell>
      <TableCell sx={tableCellStyles}>
        <Typography variant="body2">{toCurrency(orderData.aek)}</Typography>
      </TableCell>
      <TableCell sx={{ ...tableCellStyles, position: "relative" }}>
        <input
          onChange={handleChange}
          onFocus={() => !inputTouched && setInputTouched(true)}
          style={tableCellInputStyles}
          min="0"
          max="9999"
          type="number"
          value={quantity}
        />
        {inputTouched && (quantity < 0 || quantity > 9999) ? (
          <ErrorMessageOverlay />
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell sx={tableCellStyles}>
        <Typography variant="body2">
          {ddmmyyyyFormat(new Date(orderData.validUntil))}
        </Typography>
      </TableCell>
    </AnimatedTableRow>
  );
};

export default SpecialOrdersTableRow;
