import { SxProps, Theme } from "@mui/material";

export const gridContainerStyles: SxProps<Theme> = {
  "& > div": {
    maxWidth: {
      xs: "472px",
      lg: "100%",
    },
    height: "fit-content",
  },
};

export const formTextFieldStyles: SxProps<Theme> = {
  mb: 1,
};

export const buttonStyles: SxProps<Theme> = {
  float: "right",
  position: "absolute",
  right: "24px",
  bottom: "24px",
  margin: "0",
};

export const contactCardTypographyStyles: SxProps<Theme> = { pb: 3 };
