import { SxProps, Theme } from "@mui/material/styles";
import { aepPalette } from "../../../styles/aepPalette";
import { fadeTimeout } from "../../App";

export const rootStyles: SxProps<Theme> = {
  backgroundColor: aepPalette.common.white,
  height: {
    xs: "140px",
    lg: "140px",
  },
  top: 0,
  zIndex: 4,
  pb: {
    xs: 2,
    lg: 3,
  },
  pt: {
    xs: 2,
    lg: 5,
  },
};

export const containerStyles: SxProps<Theme> = {
  flexDirection: {
    xs: "column",
    lg: "row",
  },
  width: "100%",
};

export const leftContainerStyles: SxProps<Theme> = {
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
};

export const imageStyles: SxProps<Theme> = {
  width: { xs: "170px", lg: "200px" },
  px: 4,
  mr: {
    xs: 0,
    lg: 5,
  },
};

export const greetingStyles: SxProps<Theme> = {
  minWidth: "250px",
  ml: 0,
  display: {
    xs: "none",
    lg: "block",
  },
};

export const greetingStylesTablet: SxProps<Theme> = {
  minWidth: "250px",
  mt: 1,
  ml: 5,
  display: {
    xs: "block",
    lg: "none",
  },
};

export const rightContainerStyles: SxProps<Theme> = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  pr: {
    xs: 2,
    xl: 6,
  },
};

export const selectStyles: SxProps<Theme> = {
  mx: 1,
  mb: "5px",
};

export const iconStackStyles = (hasCounter: boolean): SxProps<Theme> => ({
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  width: hasCounter ? "450px" : "420px",
  ml: 6,
  position: "relative",
});

export const avatarStyles: SxProps<Theme> = {
  backgroundColor: "transparent",
  border: `1px solid ${aepPalette.primary.main}`,
  color: aepPalette.primary.main,
  "&:hover": {
    boxShadow: `0 0 3px ${aepPalette.primary.dark}`,
    cursor: "pointer",
  },
};

export const textDividerStyles: SxProps<Theme> = {
  height: "34px",
};

export const counterStyles: SxProps<Theme> = {
  position: "relative",
  "& > div:last-of-type": {
    backgroundColor: "white",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    cursor: "pointer",
    padding: 3,
    position: "absolute",
    right: 0,
    zIndex: 100,
    fontFamily: "Museo Sans",
    fontSize: "1.3rem",
    fontWeight: 400,
  },
};