import { Box } from "@mui/material";
import { TabableCard, CardTabContent } from "../../components/card/TabableCard";
import AnalysesCard, { AnalysisData } from "../../components/card/AnalysesCard";
import { rootBoxStyles } from "./ordersStyles";
import DailyOrdersSearchContainer from "./tabs/DailyOrdersSearchContainer";
import CancellationsSearchContainer from "./tabs/CancellationsSearchContainer";
import DispensationsSearchContainer from "./tabs/DispensationsSearchContainer";

const Orders = () => {
  return (
    <Box sx={rootBoxStyles}>
      <TabableCard
        sx={{
          gridRow: "1 / 2",
          gridColumn: "1 / 3",
        }}
      >
        <CardTabContent id="dailyorders" name="Tagesbestellungen" state={1}>
          <DailyOrdersSearchContainer />
        </CardTabContent>
        <CardTabContent id="dispensations" name="Max-Nachlieferungen" state={2}>
          <DispensationsSearchContainer />
        </CardTabContent>
        <CardTabContent id="canceled" name="Storniert" state={3}>
          <CancellationsSearchContainer />
        </CardTabContent>
      </TabableCard>
      <AnalysesCard
        dataSet={[AnalysisData.BESTZEIT]}
        sx={{ gridRow: "1 / 2", gridColumn: "3 / 4" }}
      />
    </Box>
  );
};

export default Orders;
