import { createElement, FunctionComponent, useEffect, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { gridBoxStyles } from "./dynamicGridStyles";

type GridRow = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number
];

export type Grid = [
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow,
  GridRow
];

interface CardRenderObject {
  component: FunctionComponent<{ sx: SxProps<Theme> }>;
  id: number;
}

interface DynamicGridProps {
  shownCardIds: number[];
  cardRenderObjects: CardRenderObject[];
  xlInitialGrid: Grid;
  xsInitialGrid: Grid;
}

const DynamicGrid = (props: DynamicGridProps) => {
  const { shownCardIds, cardRenderObjects, xlInitialGrid, xsInitialGrid } =
    props;
  const [xlGrid, setXlGrid] = useState<Grid>(xlInitialGrid);
  const [xsGrid, setXsGrid] = useState<Grid>(xsInitialGrid);

  const limitGridSize = shownCardIds.length < 3;

  const stretchArr = (arr: Array<number | null>) => {
    // process row if there is empty space
    if (arr.includes(null)) {
      // analyze arr for repeats
      const uniqueCells: number[] = [];
      const stretchArr: number[] = [];

      arr.map((cell) => {
        if (cell !== null && !uniqueCells.includes(cell)) {
          uniqueCells.push(cell);
        }
      });

      if (uniqueCells.length > 0) {
        while (stretchArr.length < 12) {
          // distribution function, spaces evenly
          uniqueCells.forEach((cell) => stretchArr.push(cell));
        }
        return stretchArr.sort();
      }
    }
    // return arr if no processing is needed
    return arr;
  };

  const resizeGrid = (grid: Grid) => {
    // replace restricted card ids w null
    const nullifiedGrid = grid.map((row) =>
      row.map((dashboardCardId) =>
        shownCardIds.includes(dashboardCardId) ? dashboardCardId : null
      )
    );

    const horizStretchedGrid = nullifiedGrid.map((row) => stretchArr(row));

    const columns = [
      horizStretchedGrid.map((row) => row[0]),
      horizStretchedGrid.map((row) => row[1]),
      horizStretchedGrid.map((row) => row[2]),
      horizStretchedGrid.map((row) => row[3]),
      horizStretchedGrid.map((row) => row[4]),
      horizStretchedGrid.map((row) => row[5]),
      horizStretchedGrid.map((row) => row[6]),
      horizStretchedGrid.map((row) => row[7]),
      horizStretchedGrid.map((row) => row[8]),
      horizStretchedGrid.map((row) => row[9]),
      horizStretchedGrid.map((row) => row[10]),
      horizStretchedGrid.map((row) => row[11]),
    ];

    const processedColumns = columns.map((column) => stretchArr(column));

    const finalRows = [
      processedColumns.map((column) => column[0]),
      processedColumns.map((column) => column[1]),
      processedColumns.map((column) => column[2]),
      processedColumns.map((column) => column[3]),
      processedColumns.map((column) => column[4]),
      processedColumns.map((column) => column[5]),
      processedColumns.map((column) => column[6]),
      processedColumns.map((column) => column[7]),
      processedColumns.map((column) => column[8]),
      processedColumns.map((column) => column[9]),
      processedColumns.map((column) => column[10]),
      processedColumns.map((column) => column[11]),
    ];

    if (shownCardIds.length > 0) {
      return finalRows as Grid;
    } else {
      return grid;
    }
  };

  const parseGridColumn = (cardId: number, gridSize: "xs" | "xl"): string => {
    const grid = gridSize === "xs" ? xsGrid : xlGrid;
    let startCol = 0;
    let endCol = 0;

    grid.forEach((row) => {
      row.forEach((cell, j) => {
        if (cell === cardId) {
          if (startCol === 0) {
            startCol = j + 1;
          } else {
            endCol = j + 2;
          }
        }
      });
    });

    return `${startCol} / ${endCol}`;
  };

  const parseGridRow = (cardId: number, gridSize: string): string => {
    const grid = gridSize === "xs" ? xsGrid : xlGrid;
    let startRow = 0;
    let endRow = 0;

    grid.forEach((row, i) => {
      if (row.includes(cardId)) {
        if (startRow === 0) {
          startRow = i + 1;
        } else {
          endRow = i + 2;
        }
      }
    });

    return `${startRow} / ${endRow}`;
  };

  useEffect(() => {
    const newXlGrid = resizeGrid(xlGrid);
    const newXsGrid = resizeGrid(xsGrid);

    if (newXlGrid && newXsGrid) {
      setXlGrid(newXlGrid);
      setXsGrid(newXsGrid);
    }
  }, [shownCardIds]);

  const renderCard = (
    FC: FunctionComponent<{ sx: SxProps<Theme> }>,
    cardId: number
  ): JSX.Element | void => {
    if (shownCardIds.includes(cardId)) {
      return createElement(FC, {
        sx: {
          gridColumn: {
            xs: parseGridColumn(cardId, "xs"),
            xl: parseGridColumn(cardId, limitGridSize ? "xs" : "xl"),
          },
          gridRow: {
            xs: parseGridRow(cardId, "xs"),
            xl: parseGridRow(cardId, limitGridSize ? "xs" : "xl"),
          },
        },
        key: `dynamic-grid-card-${cardId}`,
      });
    }
  };

  return (
    <Box
      sx={{
        ...gridBoxStyles,
        height: limitGridSize ? "calc(100vh - 168px) !important" : "auto",
      }}
    >
      {cardRenderObjects.map((cardObj) =>
        renderCard(cardObj.component, cardObj.id)
      )}
    </Box>
  );
};

export default DynamicGrid;
