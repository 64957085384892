import { SxProps, TableRow, Theme } from "@mui/material";
import { useEffect, useState } from "react";

interface AnimatedTableRowProps {
  children: JSX.Element | JSX.Element[];
  transitionDelay?: number;
  sx?: SxProps<Theme>;
}

const AnimatedTableRow = (props: AnimatedTableRowProps) => {
  const { children, transitionDelay, sx } = props;

  const [render, setRender] = useState(false);

  useEffect(() => {
    setTimeout(() => setRender(true), 0);
  }, []);

  return (
    <TableRow
      sx={{
        opacity: render ? 1 : 0,
        transform: render ? "scaleY(1)" : "scaleY(0)",
        transition: `all 0.2s ease-in ${
          transitionDelay ? transitionDelay / 30 : 0
        }s`,
        transitionProperty: "transform, opacity",
        ...sx,
      }}
    >
      {children}
    </TableRow>
  );
};

export default AnimatedTableRow;
