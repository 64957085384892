import { SxProps, Theme, Tooltip, Typography } from "@mui/material";

interface OneLineTypographyProps {
  sx?: SxProps<Theme>;
  text?: string;
  tooltipPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "small"
    | "inherit"
    | "subline"
    | "buttonText"
    | "bodyBold";
}

const OneLineTypography = (props: OneLineTypographyProps) => {
  const { sx, text, tooltipPlacement, variant } = props;

  const renderText = (): JSX.Element => (
    <Typography
      variant={variant}
      sx={{
        cursor: "help",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        ...sx,
      }}
    >
      {text}
    </Typography>
  );

  return text ? (
    <Tooltip title={text} placement={tooltipPlacement}>
      {renderText()}
    </Tooltip>
  ) : (
    renderText()
  );
};

export default OneLineTypography;
