/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Popover,
  PopoverProps,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { Link } from "react-router-dom";
import { aepPalette } from "../../../../styles/aepPalette";

const menuItemStyles: SxProps<Theme> = {
  cursor: "pointer",
  px: 2,
  py: 2,
  "&:hover": {
    backgroundColor: aepPalette.base.lighter,
  },
};

const UserMenu = (props: PopoverProps) => {
  const { keycloak } = useKeycloak();
  const { anchorEl, onClose, open } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      open={open}
    >
      <Box sx={{ minWidth: "140px", py: 1 }}>
        {/* @ts-ignore */}
        <Link to="profil" onClick={onClose}>
          <Typography sx={menuItemStyles}>Profil</Typography>
        </Link>
        {!!keycloak.authenticated && (
          <Typography onClick={() => keycloak.logout()} sx={menuItemStyles}>
            Logout
          </Typography>
        )}
      </Box>
    </Popover>
  );
};

export default UserMenu;
