import { useContext, useState } from "react";
import ApiWrapper from "../../../wrappers/ApiWrapper";
import TableContainer, {
  tablePageDefaultLength,
} from "../../../components/table/TableContainer";
import SnackbarContext from "../../../contexts/SnackbarContext";
import { TableFilterType } from "../../../components/table/tableFilter/models";
import CustomerContext from "../../../contexts/CustomerContext";
import { ReturnDto } from "../../../../api";
import TableAccordionWrapper from "../../../components/table/TableAccordionWrapper";
import TableAccordionRow from "../../../components/table/TableAccordionRow";
import { utcToGermanDateString } from "../../../service/CustomDateService";
import ReturnSearchTable from "./ReturnSearchTable";

enum ReturnState {
  EMPTY = "",
  OPEN = "1",
  IN_PROGRESS = "5",
  CLOSED = "9",
}

interface ReturnSearchFormikValues {
  pzn?: string;
  fromDate?: string;
  toDate?: string;
  state?: ReturnState;
}

const ReturnSearchContainer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [returns, setReturns] = useState<ReturnDto[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const pageLength = 16;

  const { handleHttpError, showSnackbar } = useContext(SnackbarContext);
  const { customerNumber } = useContext(CustomerContext);

  const returnsApi = new ApiWrapper().returnsControllerApi();
  const ordersApi = new ApiWrapper().ordersControllerApi();

  const initialValues: ReturnSearchFormikValues = {
    pzn: "",
    toDate: "",
    fromDate: "",
    state: ReturnState.EMPTY,
  };

  const loadReturns = (
    loadMore: boolean,
    searchParams?: ReturnSearchFormikValues
  ): void => {
    if (customerNumber && !loading) {
      setLoading(true);

      returnsApi
        .getAllReturns(
          customerNumber,
          loadMore ? pageNumber : 0,
          pageLength,
          searchParams?.pzn || undefined,
          searchParams?.state || undefined,
          searchParams?.fromDate || undefined,
          searchParams?.toDate || undefined
        )
        .then((res) => {
          const newReturns = res.data;

          if (loadMore) {
            setReturns((existingReturns) => {
              return existingReturns.concat(newReturns);
            });
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          } else {
            setReturns(res.data);
            setPageNumber(1);
          }
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }
  };

  const triggerReturns = (stateValue: unknown[]): void => {
    let quantity = 0;

    if (typeof stateValue[0] === "number") {
      quantity = stateValue[0];
    }

    if (customerNumber && !loading) {
      setLoading(true);

      ordersApi
        .triggerReturn(customerNumber, { quantity: quantity })
        .then(() => {
          showSnackbar(
            "success",
            "Die Abholung der angemeldeten Retouren wird ausgelöst."
          );
        })
        .catch(handleHttpError)
        .finally(() => setLoading(false));
    }
  };

  return (
    <TableContainer
      hasButtonCounter
      buttonAction={triggerReturns}
      buttonText="Abholung auslösen"
      pageable
      loadCallback={loadReturns}
      loading={loading}
      tableFilterProps={{
        initialValues: initialValues,
        searchFields: [
          {
            type: TableFilterType.textfield,
            name: "pzn",
            label: "Suche nach PZN",
          },
          {
            type: TableFilterType.dropdown,
            name: "state",
            label: "Status",
            menuItems: [
              {
                label: "Erfasst",
                value: ReturnState.OPEN,
              },
              {
                label: "In Bearbeitung",
                value: ReturnState.IN_PROGRESS,
              },
              {
                label: "Abgeschlossen",
                value: ReturnState.CLOSED,
              },
            ],
          },
          {
            type: TableFilterType.dateRange,
            name: "",
            label: "Zeitraum",
          },
        ],
      }}
      Table={({
        handleSelectRow,
        isSelected,
        handleSelectAll,
        allSelected,
      }) => (
        <TableAccordionWrapper tableHeader="Retoure">
          {returns?.map((returnData, index) => {
            return (
              <TableAccordionRow
                key={`order-list-item-${index}`}
                transitionDelay={index % tablePageDefaultLength}
                header={`${utcToGermanDateString(
                  returnData.registrationDate,
                  true
                )}: Retoure ${returnData.returnNumber}, Status: ${
                  returnData.state
                }, Abholung: ${utcToGermanDateString(
                  returnData.collectionDate,
                  true
                )}, Boxen: ${returnData.boxes || ""}`}
              >
                <ReturnSearchTable
                  key={`position-${index}`}
                  returnPositionsData={returnData.returnPositions}
                />
              </TableAccordionRow>
            );
          })}
        </TableAccordionWrapper>
      )}
    />
  );
};

export default ReturnSearchContainer;
