/* eslint-disable @typescript-eslint/ban-ts-comment */
import Keycloak, { KeycloakConfig } from "keycloak-js";
import {
  AuthClientInitOptions,
  AuthClientEvent,
  AuthClientError,
} from "@react-keycloak/core/lib/types";

const keycloakConfig: KeycloakConfig = {
  // @ts-ignore
  url: window._env_.REACT_APP_KEYCLOAK_URL,
  realm: "apoportal-realm",
  // @ts-ignore
  clientId: window._env_.REACT_APP_KEYCLOAK_CLIENT_ID as string,
};

export const keycloakInitOptions: AuthClientInitOptions | undefined = {};

export const keycloak = Keycloak(keycloakConfig);

export const keycloakOnEvent = (
  event: AuthClientEvent,
  error?: AuthClientError
) => {
  switch (event) {
    case "onAuthSuccess":
      break;
    case "onTokenExpired":
      keycloak.updateToken(30).catch(() => {
        console.error("TOKEN REFRESH FAILED" + error);
      });
      break;
    default:
      break;
  }
};
