import { Box, Stack, Typography } from "@mui/material";
import showdown from "showdown";
import { aepPalette } from "../../../styles/aepPalette";
import { deDateStringFormat } from "../../service/CustomDateService";
import ImageWrapper from "../../wrappers/ImageWrapper";
import {
  itemDetailsRootStackStyles,
  itemDisplayDateStyles,
  itemHeaderStyles,
  itemTeaserTextStyles,
} from "./newsStyles";
import { aepTypography } from "../../../styles/aepTypography";
import StrapiData from "../../../strapi/models/StrapiData";
import Article from "../../../strapi/models/Article";

interface NewsItemDetailsProps {
  article: StrapiData<Article>;
  baseURL: string;
  fadeIn: boolean;
  id: string;
  timeout: number;
  lastInList?: boolean;
}

const NewsItemDetails = (props: NewsItemDetailsProps) => {
  const markdownConverter = new showdown.Converter();

  const { article, baseURL, id, lastInList } = props;
  const { bodyText, gueltigVon, heroBild, teaserText, titel } =
    article.attributes;

  const bodyTextHtml = markdownConverter.makeHtml(bodyText);
  const displayDate = new Date(gueltigVon);
  const bildUrl = heroBild.data?.attributes?.url;
  const img = `${baseURL}/contentmanager${bildUrl}`;

  const renderBodyText = (htmlString: string): JSX.Element => (
    <Box
      dangerouslySetInnerHTML={{ __html: htmlString }}
      sx={{
        a: { color: aepPalette.primary.main },
        h1: { ...aepTypography.h1 },
        h2: { ...aepTypography.h2 },
        h3: { ...aepTypography.h3 },
        p: { ...aepTypography.h4 },
        li: { ...aepTypography.h4 },
        ol: { pl: "20px" },
        ul: { pl: "20px" },
        blockquote: {
          fontWeight: 700,
        },
      }}
    />
  );

  return (
    <Box id={id} sx={{ width: "100%" }}>
      <Stack direction="column" sx={itemDetailsRootStackStyles}>
        <Box>
          <Typography variant="caption" sx={itemDisplayDateStyles}>
            {deDateStringFormat(displayDate)}
          </Typography>
          <Typography variant="h2" sx={{ ...itemHeaderStyles, mb: 2 }}>
            {titel}
          </Typography>
          <Typography variant="h4" sx={itemTeaserTextStyles}>
            {teaserText}
          </Typography>
        </Box>
        <ImageWrapper
          src={img}
          alt="dummy news"
          sx={{ objectFit: "cover", my: 4, maxWidth: "650px" }}
        />
        {renderBodyText(bodyTextHtml)}
        {!lastInList && (
          <Box
            sx={{
              width: "100%",
              borderBottom: `1px solid ${aepPalette.base.light}`,
              mt: 8,
            }}
          />
        )}
      </Stack>
    </Box>
  );
};

export default NewsItemDetails;
