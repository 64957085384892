import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
} from "@mui/material";
import { SpecialOrderResponse } from "../../../../api";
import {
  CustomTableProps,
  tablePageDefaultLength,
} from "../../../components/table/TableContainer";
import SpecialOrdersTableRow from "./SpecialOrdersTableRow";

interface SpecialOrdersTableProps extends CustomTableProps {
  orders?: SpecialOrderResponse[];
}

const SpecialOrdersTable = (props: SpecialOrdersTableProps) => {
  const { orders } = props;

  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow sx={{ position: "relative" }}>
          <TableCell align="center" sx={{ width: "5%" }}>
            <Checkbox sx={{ mr: 1, display: "none" }} />
          </TableCell>
          <TableCell sx={{ width: "10%" }}>
            <Typography variant="bodyBold">PZN</Typography>
          </TableCell>
          <TableCell sx={{ width: "33%" }}>
            <Typography variant="bodyBold">Artikelbezeichnung</Typography>
          </TableCell>
          <TableCell sx={{ width: "14%" }}>
            <Typography variant="bodyBold">Packungsgröße</Typography>
          </TableCell>
          <TableCell sx={{ width: "8%" }}>
            <Typography variant="bodyBold">AEK</Typography>
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <Typography variant="bodyBold">Anzahl Packungen</Typography>
          </TableCell>
          <TableCell sx={{ width: "15%" }}>
            <Typography variant="bodyBold">Bestellbar bis</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders?.map((orderData, index) => {
          return (
            <SpecialOrdersTableRow
              transitionDelay={index % tablePageDefaultLength}
              key={`position-${index}`}
              orderData={orderData}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SpecialOrdersTable;
